
import { reactive} from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";

export default {
  name: "FischbuchEintragPatentrevier",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
    setup() {
      const request = reactive({
        apiLoadMethod: "api/Form/loadForm",
        apiSaveMethod: "api/sync/syncFishbook",
        form: "FischbuchEintragPatentrevier",
        page: "FischbuchEintragPatentrevierDaten",
        remoteId: "",       
        routeName: "",
        filter: ""
      });
      return {
        request
      };
    }
  };
  