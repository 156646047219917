
import UserLogin from "@/components/User/UserLogin.vue"; // @ is an alias to /src
import UserError from "@/components/Shared/UserError.vue";
import { ref } from "vue";


export default {
  components: {
    UserLogin,
    UserError
  },
  name: "login-component",
  inheritAttrs: false,
  setup() {
    const errorMessage = ref("");
  
    function userError(message: string) {
      errorMessage.value = message;
    }
    return { userError, errorMessage };
  }
};
