import { DynamicGridRow } from "@/components/Shared/dynamicList/DynamicListTypes"
import { getWildBookEntriesWithDetail } from "./DynamicGridHuntingService"
import { TierartDynamicRecord, TierartRule, TierartRules } from "./TierartZusatzService"
import { DynamicDataSet, DynamicRecord } from "@/components/Shared/Dynamicform/DynamicFormData"
import { Identity } from "../backend/userIdentity"
import { JagdRevier, Tierart, WildbuchBeobachtungSpezialFall, addOrReplaceRecord, deleteRecord, loadAllRecords, wildbookTable } from "../database/asyncDb"
import { Out } from "../frontent/clientMessage"
import { from } from 'rxjs';

export async function assignFormValues(form: DynamicDataSet , reactiveData: DynamicDataSet) {
    for(const formRow of form.view) {
        for(const reactiveRow of reactiveData.view) {
            if (formRow.formularFeldname === reactiveRow.formularFeldname) {
                formRow.value = cleanupReactiveValue(reactiveRow.value)
            }
        }
    }
}

function cleanupReactiveValue(value: any) {
    if ( !value) return value
    if (typeof(value) === "string") return value
    if (typeof(value) === typeof("number")) return value
    if (typeof(value) === typeof("boolean")) return value
    try {
        if ( value.key ) {
            value = value.key
        }
    } catch(e) {
        Out.noOperation("")
    }
    return value
}

export async function findLocalHuntingRecord(viewId: string, id: string) : Promise<DynamicGridRow | undefined> {
    if ( ! viewId || !id || id.length < 1) return undefined
    const temp = await getWildBookEntriesWithDetail(viewId,true,undefined)
    if ( !temp ) return undefined
    const result = temp?.getValue()
    if( ! result || !result.rows) return undefined

    for( const row of result.rows) {
        if ( row.rowId === id) return row
        if ( row.id === id) return row
        if ( row.appSynchronId === id ) return row
        if ( row.cell && row.cell.readableIdentification && row.cell.readableIdentification.id === id) return row
    }
    return undefined
} 

export async function loadWildBookTable() : Promise<DynamicGridRow[]> {
    const result: DynamicGridRow[] = []
    const items = await loadAllRecords(wildbookTable) as unknown as any
    for( const row of items) {
        result.push(row.row)
    }
    return result
}

export async function isJagdPossible() : Promise<boolean> {
    const result: DynamicGridRow[] = []
    const item = await loadAllRecords(JagdRevier) as unknown as any
    if ( item && item.length > 0 && item[0].objectList) {
        for(const row of item[0].objectList) {
            if ( row.key && row.key.length > 0) return true
        }
    }
    
    return false
}


export async function findLocalHuntingRecordOverFormId(id: string) : Promise<DynamicGridRow | undefined> {
    if (!id || id.length < 1 ) return undefined
    const dataRows = await loadWildBookTable()
    if ( dataRows === undefined ) return undefined

    for(const row of dataRows as DynamicGridRow[]) {
        if (! row.detailForm || !row.detailForm.id) continue
        if ( row.detailForm.id === id) return row
    }
    return undefined

}



export async function deleteHuntingRecord(row: DynamicGridRow) {
    const dataRows = await loadWildBookTable() as any
    if ( dataRows === undefined ) return undefined
    for(const item of dataRows as DynamicGridRow[]) {
        if ( row.id === item.id  ) {
            await deleteRecord(wildbookTable , item.id ?? "")            
        }       
    }
}


export async function saveHuntingRecord(row: DynamicGridRow) {
    const dataRows = await loadWildBookTable() as any
    if ( dataRows === undefined ) return undefined
    for(const item of dataRows as DynamicGridRow[]) {
        if ( row.id === item.id  ) {
            await addOrReplaceRecord(wildbookTable, {id: row.id, row: row})
        }       
    }
}

export async function findLocalZusatzDatenRecords(idJagdAbgangBeobachtung: string | undefined) : Promise<TierartDynamicRecord[] | undefined> {
    if ( !idJagdAbgangBeobachtung || idJagdAbgangBeobachtung.length < 1) return undefined
    return await TierartRules.findZusatzInfoDatenRecords(idJagdAbgangBeobachtung) 
}

export async function findLocalTierartRules(idTierart: string | undefined) : Promise<TierartRule[] | undefined> {
    return await TierartRules.findZusatzInfoRuleRecords(idTierart) 
}

export async function saveZusatzDaten(kategorie: string, id: string | undefined, formRows: DynamicRecord[] | undefined) : Promise<TierartDynamicRecord[] | undefined> {
    if (  !id || id.length < 1) return undefined    
    if ( !formRows || formRows.length < 1) return undefined

    //const zusatzDaten = await findLocalZusatzDatenRecords(id)
    let allData = await TierartRules.allZusatzInfoDatenRecords()
    if ( ! allData ) allData = []
   

    let idTierart: string | undefined = undefined 
    for(const formRow of formRows) {
        if ( formRow.formularFeldname && formRow.formularFeldname.indexOf("Tierart") >= 0 ) {
            if ( formRow.value && typeof(formRow.value) === typeof("string"))
                idTierart = formRow.value
                break
        }
    }

    if ( ! idTierart || idTierart.length < 1 ) return


    for(let i=0; i < 20 ; i++) {
        if ( allData) {
            let found = false
            allData.forEach(
                (item, index) => {
                    if (allData && item.idJagdAbgangBeobachtung === id ) {
                        allData.splice(index,1)
                        found = true
                    }
                }
            )
            if( !found ) break
        }
    }
    const newRuleSet = await findLocalTierartRules(idTierart)
    if ( !newRuleSet) return

    const newTierartZusatzDaten: TierartDynamicRecord[] = []

    for( const ruleRow of newRuleSet) {
        if ( ruleRow.prozess && ruleRow.prozess.indexOf(kategorie) < 0 ) continue
        const row: TierartDynamicRecord = {
            idJagdAbgangBeobachtung: id,
            fkPerson: Identity.getIdentity().getValue().UserId,
            idTierart: idTierart,
            istWildbuchEingabeZusatzInfo: ruleRow.istWildbuchEingabeZusatzInfo,
            istJagdbar: ruleRow.istJagdbar,
            istFallwild: ruleRow.istFallwild,
            istGeschuetzt: ruleRow.istGeschuetzt,
            istBeobachtbar: ruleRow.istBeobachtbar,
            minGewicht: ruleRow.minGewicht,
            maxGewicht: ruleRow.maxGewicht,
            hatWildbuchEingabeZusatzInfo: ruleRow.hatWildbuchEingabeZusatzInfo,
            istWildbuchEingabeMehrere: ruleRow.istWildbuchEingabeMehrere,
            attributName: ruleRow.attributName,
            attributResourceId: ruleRow.attributResourceId,
            fkGeoGebiet: ruleRow.fkGeoGebiet,
            wert: ruleRow.wert,
            wertMin: ruleRow.wertMin,
            wertMax: ruleRow.wertMax,
            prozess: ruleRow.prozess,
            istAktiv: ruleRow.istAktiv,
            reihenfolge: ruleRow.reihenfolge,
            durchschnittsGewicht: ruleRow.durchschnittsGewicht,
            istFischbar: ruleRow.istFischbar,            
        }
        newTierartZusatzDaten.push(row)
    }

    await TierartRules.assignZusatzDatenFromForm(formRows, newTierartZusatzDaten)

    allData = allData.concat(newTierartZusatzDaten)

    await TierartRules.saveAllData(allData)

    return newTierartZusatzDaten

}

export class JagdRevierTitel {

    private static JagdReviere:object[] = []
    private static Tierarten:object[] = []
    private static WildbuchBeobachtungSpezialFall: object[] = []

    public static readableName: string
    public static readableNumber: string
    public static readableResourceId: string

    public static async getTitelFromForm( form: DynamicDataSet | undefined ) {

        let beobachtungSpezFall: string | undefined = undefined
        let revier: string | undefined = undefined
        let tierart: string | undefined = undefined
        if ( form && form.view && form.view.length > 0) {
            for( const row of form.view) {
                if ( ! row.formularFeldname ) continue

                if ( row.formularFeldname.indexOf("Tierart") >= 0 ) {
                    tierart = row.value                    
                }

                if ( row.formularFeldname.indexOf("Revier") >= 0) {
                    revier = row.value                    
                }

                if ( row.formularFeldname.indexOf("BeobachtungSpezFall") >= 0 ) {
                    beobachtungSpezFall = row.value
                }
            }
        }

        return await this.getTitel(revier,tierart,beobachtungSpezFall)
    }

    private static async getTitel(idJagdRevier: any, idTierart: any, idBeobachtungSpezialFall: any) {
        if ( !idJagdRevier ) return ""

        let idRevier: string | undefined = undefined
        if ( typeof(idJagdRevier) === typeof("string")) {
            idRevier = idJagdRevier
        } else {
            try {
                idRevier = idJagdRevier.key
            } catch(e) {
                Out.noOperation("")
            }
        }

        let idTier: string | undefined = undefined
        if ( idTierart) {
            if ( typeof(idTierart) === typeof("string")) {
                idTier = idTierart
            } else {
                try {
                    idTier = idTierart.key
                } catch(e) {
                    Out.noOperation("")
                }
            }
        }

        let idBeob: string | undefined = undefined
        if ( idBeobachtungSpezialFall) {
            if ( typeof(idBeobachtungSpezialFall) === typeof("string")) {
                idBeob = idBeobachtungSpezialFall
            } else {
                try {
                    idTier = idBeobachtungSpezialFall.key
                } catch(e) {
                    Out.noOperation("")
                }
            }
        }

        let result1 = ""
        let result2 = ""
        if ( idRevier) {
            await JagdRevierTitel.ensureStaticArrays()
            
            try{ 
                for(const row of JagdRevierTitel.JagdReviere as any ) {
                    if (row.key === idRevier) {
                        result1 = row.value
                        JagdRevierTitel.readableNumber = row.key
                        JagdRevierTitel.readableResourceId = row.valueResourecId
                        JagdRevierTitel.readableName = JagdRevierTitel.GetNamePart(row.value) ?? ""
                        break
                    }
                }

                if ( idTier) {
                    for(const row of JagdRevierTitel.Tierarten as any ) {
                        if (row.key === idTier) {
                            result2 =   "@<br/> " + row.preValue + "-" + row.value + "@<br/> @[0]  @WildbuchEintragBeobachtung.WildbuchEintragTierartBeobachtung.Anzahl"
                            break
                        }
                    }
                }

                if ( idBeob ) {
                    for(const row of JagdRevierTitel.WildbuchBeobachtungSpezialFall as any ) {
                        if (row.key === idBeob) {
                            result2 =   "@<br/> " + row.value 
                            break
                        }
                    }
                }


            } catch(e) {
                Out.noOperation("")
            }

            return result1 + result2
           
        }
    }

    private static GetNamePart( value: string | undefined) : string | undefined {
        if ( ! value || value.length < 1 ) return value

        const temp = value.split("-")
        if ( temp.length < 2) return value

        return temp[1]

    }

    private static async ensureStaticArrays() : Promise<void> {
     
        
        if ( !JagdRevierTitel.JagdReviere ||JagdRevierTitel.JagdReviere.length  < 1) {
            const JagdRevierTemp = await loadAllRecords(JagdRevier)
            for( const parentRow of JagdRevierTemp as any) {
                if ( parentRow.objectList) {
                    for(const row of parentRow.objectList) {
                        JagdRevierTitel.JagdReviere.push(row)
                    }

                }
            }                
        }

        if ( !JagdRevierTitel.Tierarten ||JagdRevierTitel.Tierarten.length  < 1) {
            const TierartTemp = await loadAllRecords(Tierart)
            for( const parentRow of TierartTemp as any) {
                if ( parentRow.objectList) {
                    for(const row of parentRow.objectList) {
                        JagdRevierTitel.Tierarten.push(row)
                    }

                }
            }                
        }
    
     
        if ( !JagdRevierTitel.WildbuchBeobachtungSpezialFall ||JagdRevierTitel.WildbuchBeobachtungSpezialFall.length  < 1) {
            const WildbuchBeobachtungSpezialFallTemp = await loadAllRecords(WildbuchBeobachtungSpezialFall)
            for( const parentRow of WildbuchBeobachtungSpezialFallTemp as any) {
                if ( parentRow.objectList) {
                    for(const row of parentRow.objectList) {
                        JagdRevierTitel.WildbuchBeobachtungSpezialFall.push(row)
                    }

                }
            }                
        }



        
        
        
    }
}