
import { reactive } from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";

export default {
  name: "RegistrationCommunicationData",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "",
      form: "RegistrierungBenutzer",
      page: "RegistrierungKommunikation",
      remoteId: "",
      
      
    });

    return {
      request,
    };
  }
};
