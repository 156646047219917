import { DynamicRecord } from "@/components/Shared/Dynamicform/DynamicFormData";
import { Out } from "../frontent/clientMessage";

export function correctFormValues(form: DynamicRecord[] ) : DynamicRecord[]  {
    if (!form) return form

    for(const row of form) {
        try {
            if (row.value === true) {
                row.value = "true"
            }
            if (row.value === false) {
                row.value = "false"
            }
        } catch(e) {
            Out.noOperation("")
        }
    }

    return form
}