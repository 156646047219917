
import { reactive } from 'vue'
import { DynamicGrid, DynamicGridRequest, ImageType } from '@/components/Shared/dynamicList/DynamicListTypes'
import { Out } from '@/infrastructure/frontent/clientMessage'
import { DynamicRecord } from '../Shared/Dynamicform/DynamicFormData'
import { AsyncRestClient } from '@/infrastructure/backend/async/AsyncRestClient'
import { makeValidUtcString } from '@/infrastructure/functional/datetimehelper'
import { ApplyDynamicFilterChannelForRoot } from '../Shared/dynamicList/filter/hooks/ApplyDynamicFilterChannel'
import { RemoteProdukt } from './RemoteProdukt'
import { BusinessLogicJahresWechsel } from './BusinessLogicJahresWechsel'
import { LocalStore } from '@/infrastructure/local-api/LocalStore'
import { Navigate } from "@/infrastructure/backend/async/Navigate"
import { HandleErrorMessages } from '@/infrastructure/backend/async/HandleErrorMessages';



    export default function webShopListBase( request: DynamicGridRequest ) {

        let gridObject : DynamicGrid | undefined = {userId: request.userId , viewId: request.viewId}
        const grid = reactive({ result: gridObject });

        async function getDynamicGridData( request: DynamicGridRequest , filter: Array<DynamicRecord> | undefined ) : Promise<void> {   
            const clonedRequest = JSON.parse( JSON.stringify(request) )

            const jahreswechsel = await RemoteProdukt.getJahresWechsel("webShopListBase")
            const businessJahresWechale = new BusinessLogicJahresWechsel(jahreswechsel)
            clonedRequest.referenzDatum = businessJahresWechale.getReferenzDatum()
            BusinessLogicJahresWechsel.setReferenzDatum(clonedRequest.referenzDatum)
            

            try {
                if ( clonedRequest && clonedRequest.revierNr && typeof(clonedRequest.revierNr) === "object" ) {
                    clonedRequest.revierNr = clonedRequest.revierNr.key                     
                }
                if ( filter === undefined) {
                    const filterRows = LocalStore.GetForm("WebShopFilter")
                    if( filterRows ) 
                        filter = JSON.parse( JSON.stringify(filterRows))
                }


                if ( filter !== undefined ) {
                    clonedRequest.dynamicFilterItems = JSON.parse( JSON.stringify(filter) )
                    for ( const row of clonedRequest.dynamicFilterItems) {
                        try {
                            if ( row.dateValue && row.dateValue !== undefined) {
                                row.value = row.dateValue.toString()
                            }

                            if(row.formularFeldname && row.formularFeldname === "ReferenzDatumFilter") {
                                if ( row.value !== "true" && row.value !== true) {
                                    clonedRequest.referenzDatum = businessJahresWechale.getCurrentYearReferenzDatum()
                                    BusinessLogicJahresWechsel.setReferenzDatum(clonedRequest.referenzDatum)
                                }
                            }
                        } catch (e) {
                            Out.noOperation(e)
                        }
                    }
                }
            } catch(e) {
                Out.noOperation()
            }
            gridObject = await getWebshopGridFromApi(clonedRequest)
            if ( gridObject === undefined) return

            if ( gridObject.rows) {
                for (const row of gridObject.rows ) {   

                    if ( row.cell.image && row?.cell?.image.imageType) {
                        row.cell.image.imagePath =  getGridIcon(row?.cell?.image?.imageState, row?.cell?.image?.imageType) ?? ""                       
                    }
                }    
            }

            if (filter && filter.length > 0 && gridObject && gridObject.rows && gridObject.rows.length > 0) {
               gridObject = await applyWebShopFilter(gridObject,filter)
            }

            grid.result = gridObject
        }


        
        async function applyWebShopFilter(grid: DynamicGrid, filter: DynamicRecord[]) : Promise<DynamicGrid> {
            return grid;
        }

        async function getWebshopGridFromApi(request: DynamicGridRequest) : Promise<DynamicGrid | undefined> {
            let gridObject : DynamicGrid | undefined = {userId: request.userId , viewId: request.viewId}

            if( Navigate.IsLocked() ) {
                return undefined
            }


            // const client = AsyncRestClient.Create(true);
            // if (client.isFailure) throw new Error("Error.getWebShopListData");
       
            // const result = await client
            //     .getValue()
            //     .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/WebShop/getProducts", JSON.stringify(request));  

            gridObject = await getProducts(process.env.VUE_APP_API + "api/WebShop/getProducts", request)

            //if (! result || result.isFailure) throw new Error(`${result.error}`)            
            //gridObject = JSON.parse(JSON.stringify(result.getValue())) as unknown as DynamicGrid 
            if (!gridObject || !gridObject.rows) return
            for (const row of gridObject.rows) {
                    if ( row.cell.readableIdentification?.startDate && row.cell.readableIdentification?.startDate.length > 0 ) {
                        row.cell.readableIdentification.startDate = makeValidUtcString(row.cell.readableIdentification?.startDate)
                    }    
                    if ( row.cell.readableIdentification?.stopDate && row.cell.readableIdentification?.stopDate.length > 0 ) {
                        row.cell.readableIdentification.stopDate = makeValidUtcString(row.cell.readableIdentification?.stopDate)
                    }          
            }
            return gridObject
        }

        async function getProducts (endpoint: string, request: DynamicGridRequest) :Promise<DynamicGrid | undefined> {
            HandleErrorMessages.SuppressError(true)
            for(let i=0; i <= 2; i++) {
                try{
                    if ( i == 2)  HandleErrorMessages.SuppressError(false)

                    if ( i === 0) {
                        const result = await requestProducts(endpoint, request)
                        if ( !result || result.isFailure || ! result.getValue() ) {
                            continue
                        }
                        return  JSON.parse(JSON.stringify(result.getValue())) as unknown as DynamicGrid 
                    } else {
                        const result = await requestProductsDefered(endpoint, request)
                        if ( !result || result.isFailure || ! result.getValue() ) {
                            continue
                        }
                        return  JSON.parse(JSON.stringify(result.getValue())) as unknown as DynamicGrid 
                    }
                } catch(e) {
                    console.error("getProducts Fehler => retry")
                }
            }
            HandleErrorMessages.SuppressError(false)
            return undefined
        }

        async function requestProducts(endpoint: string, request: DynamicGridRequest) :Promise<any> {
            const client = AsyncRestClient.Create(true);
            const result = await client
                .getValue()
                .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/WebShop/getProducts", JSON.stringify(request));  
            return result
        }

        async function requestProductsDefered(endpoint: string, request: DynamicGridRequest) :Promise<any> {
            await delay(2000)
            await requestProducts(endpoint,request)
        }

        function delay(ms: number) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }



        const getGridIcon = (imageState: string | null | undefined, imageType: string) => {
            if ( imageState === null || imageState === undefined || imageState === "") {
                imageState = "Valid"
            }

            const pathFish = "img/icons/fischli";
            const pathHunt = "img/icons/hirsch";
            const pathSynced = "img/icons/synced.svg";
            const pathNotSynced = "img/icons/notsynced.svg";
            const pathFailedSynced = "img/icons/failedsync.svg";


            let currentPath = ""

            switch(imageType) {
            case "fish":        
                currentPath = pathFish
                break    
            case "hunt":
                currentPath = pathHunt
                break
            case "synced":
                currentPath = pathSynced
                break                
            case "notsynced":
                currentPath = pathNotSynced
                break                                
            case "failedsync":
                currentPath = pathFailedSynced
                break                                
            }


            if ( currentPath && currentPath.indexOf(".svg") >= 0) {
                return currentPath
            }


            if (imageState.toLowerCase().trim() === "valid") {
                return currentPath + "-g.svg";
            }
            if (imageState.toLowerCase().trim() === "future") {
                return currentPath + "-o.svg";
            }
            if (imageState.toLowerCase().trim() === "past") {
                return currentPath + "-r.svg";
            }
            if (imageState.toLowerCase().trim() === "invalid") {
                return currentPath + "-r.svg";
            }
            return currentPath + ".svg";  
        }

      
        return {
            getDynamicGridData,
            grid
        }
    }
