import { isNull } from "@/infrastructure/functional/nullable"
import { AsyncRestClient } from "../backend/async/AsyncRestClient"
import { Out } from "./clientMessage"
import { Navigate } from "../backend/async/Navigate"

export interface FormValidationRules {
    formName: string,
    fields: [
        FormValidationRuleField
    ]
}

export interface FormValidationRuleField {    
    fieldName: string,            
    minLength?: number
    maxLength?: number
    regex?: string | undefined
    required?: boolean | undefined
    validationMessage?: string | undefined    
}

export interface FormValidationValues {
    formName?: string
    isValid?: boolean | undefined
    values: [
        FormValidationValueField
    ]   
}

export interface FormValidationValueField {    
    fieldName?: string,    
    fieldvalue?: string | number | Date | boolean | undefined,
    validationMessage?: string | undefined
    isValid?: boolean | undefined
}



export class FormValidation {
    
    private static rules: Array<FormValidationRules> = new Array<FormValidationRules>()
    

    private validationValues: FormValidationValues = {
        formName: "",
        isValid: true,
        values: [{}]
    }
    
    public constructor() {
        if ( FormValidation.rules.length === 0 ) {
            this.loadData()
        }
    }

    public Validate( ): FormValidationValues {
        
        if ( this.validationValues === undefined || this.validationValues.formName === "" || this.validationValues === undefined) {            
            return this.validationValues
        }

        this.validationValues.isValid = true

        FormValidation.rules.forEach(form => {
            if ( form.formName == this.validationValues.formName) {
                if ( form.fields === undefined || form.fields.length < 1 ) {
                    this.validationValues.isValid = true
                }
                form.fields.forEach(rule => {
                    this.validationValues.values.forEach(field=>{
                        if ( field.fieldName === rule.fieldName) {
                           field.isValid = this.ValidateARule(rule, field.fieldvalue)
                           if ( ! field.isValid) this.validationValues.isValid = false
                        }

                    })
                })
            }
        });
        return this.validationValues;
    }

    public FieldValidate(key: string, value: string | number | Date | boolean | undefined) : FormValidationValueField | undefined {

        let validatedField = undefined

        if ( this.validationValues === undefined || this.validationValues.formName === "" || this.validationValues === undefined) {            
            return undefined
        }

        const temp = key.split(".")
        const formName = temp[0]
        const fieldName = temp[1]
        

        FormValidation.rules.forEach(form => {
            if ( form.formName == formName) {
                if ( form.fields === undefined || form.fields.length < 1 ) {
                    this.validationValues.isValid = true
                }
                form.fields.forEach(rule => {
                    this.validationValues.values.forEach(field=>{
                        if ( field.fieldName === fieldName && rule.fieldName === fieldName) {                      
                           field.isValid = this.ValidateARule(rule, field.fieldvalue)
                           validatedField = field
                           return ;
                        }

                    })
                })
            }
        });
        return validatedField;

       
    }

    private ValidateARule(rule : FormValidationRuleField, value: string | number | Date | boolean | undefined ) : boolean {
       
        try {
        
            if ( isNull(rule)) return true
            if ( rule.required !== undefined && rule.required && ! value) return false
            
            if ( typeof value == "string") {            
                value = value ?? ""
                if ( ! isNull(rule.minLength) && rule.minLength && rule.minLength > 0 ) {
                    if ( value.length <= (rule.minLength ?? 0)) return false
                }
                if ( ! isNull(rule.maxLength) && rule.maxLength && rule.maxLength > 0 ) {
                    if ( value.length >= (rule.maxLength ?? 0)) return false
                }

                if (! isNull(rule.regex) &&  rule.regex && rule.regex.length > 2 ) {
                    if (!rule.required ) {
                        if ( ! value) return true
                        if ( typeof value === "string") {
                            if ( value.length < 1 ) return true
                        }   
                    } 


                    const regex = new  RegExp(rule.regex)
                    return regex.test(value)
                }
            }

            if ( typeof value == "number") {               
                value = value ?? 0
                if (! isNull(rule.minLength) && rule.minLength && rule.minLength > 0 ) {
                    if ( value <= (rule.minLength ?? 0)) return false
                }
                if (! isNull(rule.minLength) && rule.maxLength && rule.maxLength > 0 ) {
                    if ( value >= (rule.maxLength ?? 0)) return false
                }
            }

        return true
    } catch(e) {
        Out.error(e);
        return true
    }

}


    public AddKeyAndValue(key: string, value: string | number | Date | boolean | undefined ) {
        const temp = key.split(".")
        const formName = temp[0]
        const fieldName = temp[1]

        
        const fieldDefinition = this.GetValidationRuleMessage(formName, fieldName)
        if ( !fieldDefinition ) return


        if ( !this.validationValues.formName) {
            this.validationValues.formName = formName
        }
        if( this.validationValues.values === undefined || this.validationValues.values.length > 0 ) {
            this.validationValues.values = [{fieldName: fieldName, fieldvalue: value, isValid: true, validationMessage: fieldDefinition.validationMessage }]
            return
        }

        this.validationValues.values.forEach(fieldValue => {
            if ( fieldValue.fieldName == key) {
                fieldValue.fieldvalue = value
                fieldValue.isValid = true
                fieldValue.validationMessage = fieldDefinition.validationMessage
                return
            }
        })

        this.validationValues.values.push({fieldName: fieldName, fieldvalue: value, isValid: true, validationMessage: fieldDefinition.validationMessage })
    }

    private GetValidationRuleMessage(formName: string, fieldName: string) : FormValidationRuleField | undefined  {
        let fieldRule = undefined
        FormValidation.rules.forEach(form => {
            if ( form.formName == formName) {
                if ( form.fields === undefined || form.fields.length < 1 ) {
                    return 
                }
                form.fields.forEach(rule => {
                    if (rule.fieldName === fieldName) {
                        fieldRule = rule                        
                    }
                })
            }
        });
        return fieldRule
    }


    public ClearForm() {
        this.validationValues = {
            formName: "",
            isValid: true,
            values: [{}]
        }
    }

    public async loadData()  {
        if( Navigate.IsLocked() ) {
            return undefined
          }
      
        const client = AsyncRestClient.Create(true);        
        if (client.isFailure) return false;       
        const result = await client
            .getValue()
            .exchangeForComponents<string, any>(process.env.VUE_APP_API + "api/form/validation" , JSON.stringify({section: "*"}));
        if (result.isFailure) return false 
        const dataResult = result.getValue()
        if ( dataResult.isError) return false

        FormValidation.rules = result.getValue().result       
    }
        
}