
import { defineComponent } from 'vue';
export default defineComponent ({
    name: "NoDataRender",
    components: {},
    setup() {
        return {

        }
    }
})        
