import { Resource } from "@/infrastructure/resource/resource";
import {
  StateRule,
  StateTransitionOptions,
  StateResult,

  StateNavigationAction,
  Transition,
  // eslint-disable-next-line
  addNavigationElement,
  // eslint-disable-next-line
  addEmptyNavigationElement,
  addPreviousNext,
  // eslint-disable-next-line
  addPrevious,
  // eslint-disable-next-line
  addNext,  
  newTransition,
  newTransitionCondition
} from "../StateInterfaces";

import { BaseState }  from "../StateMachine";
import { StateHome } from "./StateHome";
import { StateLogin } from "./StateLogin";
import { StateRegisterUser } from "./StateRegisterUser";
import { NavigatonIcons } from "@/business/application/NavConst";
import {Commands} from "@/business/application/commands/Command";

export class StatePassword extends BaseState {
  private static mainState = "Password";
  private static transitions: Transition[] = [];
  private isBackward = false


  public constructor() {
    super()    
  }

  public getStartTransition(condition: string) : StateResult {
    StatePassword.transitions = []
    this.getTransitions()
    const t = this.getTransitions()[0];
    return this.getTransition(t);
  }

  public next(rule: StateRule, options: StateTransitionOptions): StateResult {
    return this.calculateState(
      rule,
      options,
      false,
      StatePassword.mainState
    );
  }

  public previous(
    rule: StateRule,
    options: StateTransitionOptions
  ): StateResult {
    return this.calculateState(
      rule,
      options,
      true,
      StatePassword.mainState
    );
  }

  protected checkState(
    transition: Transition,
    options: StateTransitionOptions
  ): StateResult {
    const context = this.getContext()
    let newResultState = this.getTransition(transition);
    let newState: any
    if (!options.lookahead && transition.newState != "") {
      switch (transition.newMainState) {
        case "RegisterUser":
          newState = new StateRegisterUser()
          newResultState = newState.getStartTransition("RegistrationLogin")
          this.getContext().transitionTo(newState,newResultState)      
          break;
        case "Home":
          newState = new StateHome();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState)             
          break;
        case "Login":
          newState = new StateLogin();
          newResultState = newState.getStartTransition("Login")
          this.getContext().transitionTo(newState,newResultState)             
          break;
        default:
          context.transitionTo(
            new StatePassword(),
            newResultState
          );
      }
    }
    return newResultState;
  }

  protected calculateState(
    rule: StateRule,
    options: StateTransitionOptions,
    isPrevious: boolean,
    // eslint-disable-next-line
    mainState: string
  ): StateResult {
    this.isBackward = isPrevious
    const context = this.getContext()
    const condition = this.calculateBusinessConditions(
      rule,
      context.getCurrentState()
    );


    if ( isPrevious) {
      let transition = this.getTransitions().filter(row => {
        if (row.nav === StateNavigationAction.Previous && row.oldState === context.getCurrentState().newState && row.condition === "TargetHome" ) {
          return true;
        }
      })
      if ( transition.length > 0 ) {
        return this.checkState(transition[0], options);
      }
      transition = this.getTransitions().filter(row => {
        if (row.nav === StateNavigationAction.Previous && row.oldState === context.getCurrentState().newState && row.condition === "" ) {
          return true;
        }
      })
      return this.checkState(transition[0], options);
    }

    else {
      const transitionNext = this.getTransitions().filter(row => {
        if (row.nav === StateNavigationAction.Next && row.oldState === context.getCurrentState().newState && row.condition === "" ) {
          return true;
        }
      })
      return this.checkState(transitionNext[0], options);
    }   
  }

  protected calculateBusinessConditions(
    rule: StateRule,
    resultState: StateResult
  ): string {
    return ""
  }

  public getTransitions(): Transition[] {
    if (StatePassword.transitions.length === 0) {

      //*****************************************
      //--> Start Request from other Main-State
      //*****************************************
      let t: Transition = { mainState: "RegisterUser", newMainState: "Password", nav: StateNavigationAction.Next, oldState: "RegisterUserLogin", newState: "WaitForConfirm", condition: "RegisterUser"};      
      t = addPreviousNext(t,Commands.PreviousPage, Commands.RulePasswordRequest);
      StatePassword.transitions.push(t);

      t = { mainState: "Login", newMainState: "Password", nav: StateNavigationAction.Next, oldState: "Login", newState: "WaitForConfirm", condition: "Login"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RulePasswordRequest);
      StatePassword.transitions.push(t);

      t = { mainState: "Home", newMainState: "Password", nav: StateNavigationAction.Next, oldState: "Home", newState: "WaitForConfirm", condition: "Home"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RulePasswordRequest);
      StatePassword.transitions.push(t);

     

      //*****************************************
      //--> Wizzard vorward
      //*****************************************
      t = newTransitionCondition("Password","WaitForConfirm", "Password" , "")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RulePassword);
      StatePassword.transitions.push(t);

      t = { mainState: "Password", newMainState: "Home", nav: StateNavigationAction.Next, oldState: "Password", newState: "Home", condition: ""};      
      t = addNext(t, Commands.RulePassword)
      StatePassword.transitions.push(t);



      //*****************************************
      //--> Wizzard backward
      //*****************************************
      t = newTransitionCondition("Password","Password", "WaitForConfirm", "", StateNavigationAction.Previous)
      t = addPreviousNext(t,Commands.PreviousPage, Commands.RulePasswordRequest);
      StatePassword.transitions.push(t);

      t = { mainState: "Password", newMainState: "Login", nav: StateNavigationAction.Previous, oldState: "WaitForConfirm", newState: "Login", condition: "TargetLogin"};  
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StatePassword.transitions.push(t);

      t = { mainState: "Password", newMainState: "Login", nav: StateNavigationAction.Previous, oldState: "WaitForConfirm", newState: "Login", condition: "TargetHome"};      
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StatePassword.transitions.push(t);


    }
    return StatePassword.transitions;
  }
}
