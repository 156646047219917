import { NamedListItem } from "@/infrastructure/generics/namedListItem";
import { DynamicDataSet } from "./DynamicFormData";

export class DynamicFormComboBox {
 
    public filterChanged(ereignis: any, filterBy: any, dynamicData: DynamicDataSet) {
        const datasourceName = ereignis.component.id
        if ( ereignis.filter.value.length <= 0) {
          for (const row of  dynamicData.view ) {
          if ( row.namedValueListDataSourceName === datasourceName) {
            if( row.namedValueListOrigin !== undefined) row.namedValueList = row.namedValueListOrigin.slice() as NamedListItem[];
          }
        }
        }
    
        for (const row of  dynamicData.view ) {
          if ( row.namedValueListDataSourceName === datasourceName) {
            row.namedValueList = filterBy((row.namedValueListOrigin as NamedListItem[]), ereignis.filter); 
          }
        }
      } 
}