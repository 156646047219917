import { StateService } from '@/infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { FilterSupportedLists } from './DynamicFilterTypes';


export interface FilterState {  
    showActiveData: boolean,
    attachTo: FilterSupportedLists
  }
  
const initialState: FilterState = {
    showActiveData: true,
    attachTo: undefined
  };

export async function CreateDynamicFilter() : Promise<FilterState> {   
    return initialState
}




export class ApplyDynamicFilterChannelForRoot extends StateService<FilterState>{
    public static service: ApplyDynamicFilterChannelForRoot;
    private static attachetTo: FilterSupportedLists = undefined
    private static firstAttached = true

    public static getService() : ApplyDynamicFilterChannelForRoot {
        if ( ApplyDynamicFilterChannelForRoot.service === undefined) {
            ApplyDynamicFilterChannelForRoot.service = new ApplyDynamicFilterChannelForRoot();
            this.service = ApplyDynamicFilterChannelForRoot.service
        }
        return ApplyDynamicFilterChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }

    public reset() : void {
        this.changeFilterState(initialState)
    }
    
    public subscription(): Observable<FilterState> {
        return this.subscribe();
    }

    public changeFilterState(filterState: FilterState) : void {
        
        ApplyDynamicFilterChannelForRoot.firstAttached = false
        if ( ApplyDynamicFilterChannelForRoot.attachetTo !== filterState.attachTo ) {
            ApplyDynamicFilterChannelForRoot.attachetTo = filterState.attachTo
            ApplyDynamicFilterChannelForRoot.firstAttached = true
        }
        this.changeState(filterState);
    }

   

    public isAttached(filterListName: FilterSupportedLists): boolean {

        if ( filterListName === undefined ) {
            return false
        }

        if ( ApplyDynamicFilterChannelForRoot.attachetTo == filterListName) {
            return true
        }

        return false

    
    }

    public isFirstAttached() {
        return ApplyDynamicFilterChannelForRoot.firstAttached
    }

    public get filterState(): FilterState {
        return this.state;
    }
}