import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { LocalWarenkorb, ShoppingCardLocal } from "./LocalWarenkorb";
import { correctFormValues } from "@/infrastructure/functional/formHelper";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { Identity } from "@/infrastructure/backend/userIdentity";
import { Navigate } from "@/infrastructure/backend/async/Navigate";
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
import { Resource } from "@/infrastructure/resource/resource";

export type CancelZahlung =
{
     userId: string
     language: string
//      shoppingCardId: string
//      transactionId: string
//      transactionReference: string
}

export type AcceptZahlung =
{
     userId: string
     language: string
    //  shoppingCardId: string
    //  transactionId: string
    //  transactionReference: string
}

export type StartZahlung =
{
     userId: string
     language: string
//     shoppingCardId: string
}

export class RemoteZahlung {
    public static async  startZahlung() : Promise<ShoppingCardLocal | undefined> {
        const request: ShoppingCardLocal | undefined = await LocalWarenkorb.GetShoppingCard()

        if ( request && request.shoppingCardLineItems) {
            for(const row of request.shoppingCardLineItems) {
                if(row.form) {
                   row.form = correctFormValues(row.form)
                }
            }
        }

        const startZahlung: StartZahlung = {
            userId: Identity.getIdentity().getValue().UserId,
            language: (await UserProfile.GetClientSettingsAsync()).language,
            //shoppingCardId: (request?.id ?? ""),
        }
        if( Navigate.IsLocked() ) {
            return undefined
        }
 

        const client = AsyncRestClient.Create(true);
        if (client.isFailure) throw new Error("Error.beginZahlung");
        const result = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/webShop/beginZahlung", JSON.stringify({ "StartZahlung" : startZahlung}));  
    
        if (result.isFailure) throw new Error(`${result.error}`)   
        
        if ((result as any).offline) {
            const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
            notficationState.resetNotificationState()
    
            notficationState.changeNotificationState({
            isNotification: true, text:  Resource.getResourceTextSyncMode("Offline.NotAllowed.Content", ""), title: Resource.getResourceTextSyncMode("Offline.NotAllowed.Title", "")})
            return undefined
           }
    

        const temp = result.getValue() as unknown as any
        if (request && temp.postFinanceScriptReference && temp.postFinanceScriptReference.length > 0) {
            const card = temp as ShoppingCardLocal

            request.postFinanceScriptReference = card.postFinanceScriptReference
            //request.transactionId = card.transactionId
            //request.artificalId = card.artificalId
            //request.transactionReference = card.transactionReference
            // request.brand = card.brand
            // request.providerStatus = card.providerStatus
            // request.amountIncludeTax = card.amountIncludeTax
            // request.transferDatum = card.transferDatum

            await LocalWarenkorb.SaveShoppingCard(request)

            return card
        }
        return undefined
    }

    public static async  acceptZahlung() : Promise<ShoppingCardLocal | undefined> {
        const request: ShoppingCardLocal | undefined = await LocalWarenkorb.GetShoppingCard()

        if ( request && request.shoppingCardLineItems) {
            for(const row of request.shoppingCardLineItems) {
                if(row.form) {
                   row.form = correctFormValues(row.form)
                }
            }
        }

        const acceptZahlung: AcceptZahlung = {
            userId: Identity.getIdentity().getValue().UserId,
            language: (await UserProfile.GetClientSettingsAsync()).language,
          }
        if( Navigate.IsLocked() ) {
            return undefined
        }
 
        const client = AsyncRestClient.Create(true);
        if (client.isFailure) throw new Error("Error.acceptZahlung");
        const result = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/webShop/acceptZahlung", JSON.stringify({ "AcceptZahlung" : acceptZahlung}));  
    
        if (result.isFailure) throw new Error(`${result.error}`)       
        
        if ((result as any).offline) {
            const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
            notficationState.resetNotificationState()
    
            notficationState.changeNotificationState({
            isNotification: true, text:  Resource.getResourceTextSyncMode("Offline.NotAllowed.Content", ""), title: Resource.getResourceTextSyncMode("Offline.NotAllowed.Title", "")})
            return undefined
           }
    

        const temp = result.getValue() as unknown as any
       

        if (request && temp && temp.amountIncludeTax ) {
            const card = temp as ShoppingCardLocal

            if( !card.providerStatus || card.providerStatus.length === 0) { 
                card.providerStatus = "PaymentAccepted"
            }

            request.brand = card.brand
            request.providerStatus = card.providerStatus
            request.amountIncludeTax = card.amountIncludeTax
            request.transferDatum = card.transferDatum
            await LocalWarenkorb.SaveShoppingCard(request)

            return request
        }
        return undefined
    }

    public static async cancelZahlung() : Promise<ShoppingCardLocal | undefined> {
        const request: ShoppingCardLocal | undefined = await LocalWarenkorb.GetShoppingCard()

        if ( request && request.shoppingCardLineItems) {
            for(const row of request.shoppingCardLineItems) {
                if(row.form) {
                   row.form = correctFormValues(row.form)
                }
            }
        }

        const cancelZahlung:CancelZahlung = {
            userId: Identity.getIdentity().getValue().UserId,
            language: (await UserProfile.GetClientSettingsAsync()).language,
         }

        if( Navigate.IsLocked() ) {
            return undefined
        }
      

        const client = AsyncRestClient.Create(true);
        if (client.isFailure) throw new Error("Error.cancelZahlung");
        const result = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/webShop/cancelZahlung", JSON.stringify({ "CancelZahlung" : cancelZahlung}));  
    
        if (result.isFailure) throw new Error(`${result.error}`)         
        
        if ((result as any).offline) {
            const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
            notficationState.resetNotificationState()
    
            notficationState.changeNotificationState({
            isNotification: true, text:  Resource.getResourceTextSyncMode("Offline.NotAllowed.Content", ""), title: Resource.getResourceTextSyncMode("Offline.NotAllowed.Title", "")})
            return undefined
           }
    

        const temp = result.getValue() as unknown as any
        if (request && temp.amountIncludeTax) {
            const card = temp as ShoppingCardLocal

            if( !card.providerStatus || card.providerStatus.length === 0) { 
                card.providerStatus = "PaymentAccepted"
            }


            request.brand = card.brand
            request.providerStatus = card.providerStatus
            request.amountIncludeTax = card.amountIncludeTax
            request.transferDatum = card.transferDatum

            await LocalWarenkorb.SaveShoppingCard(request)

            return card
        }
        return undefined
    }
}