import { Observable } from "rxjs";
import { StateService } from "../observables/stateService";

export interface RefreshAppLanguageState {   
    refresh: boolean;
  }
  
const initialState: RefreshAppLanguageState = {   
    refresh: false
  };



export class RefreshAppLanguage extends StateService<RefreshAppLanguageState>{
    public static service: RefreshAppLanguage;

    public static getRefreshAppService() : RefreshAppLanguage {
        if ( RefreshAppLanguage.service === undefined) {
            RefreshAppLanguage.service = new RefreshAppLanguage();
            this.service = RefreshAppLanguage.service
        }
        return RefreshAppLanguage.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<RefreshAppLanguageState> {
        return this.subscribe();
    }

    public refreshAppLanguageState(refresh: RefreshAppLanguageState) : void {
        this.changeState(refresh);
    }

    public get appLanguageState(): RefreshAppLanguageState {
        return this.state;
    }
}