import { Command } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";

export class LocalLoadPage extends Command {
  
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  
  constructor(
    dynamicForm: DynamicFormData,
    dataSet: DynamicDataSet
  ) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;
  }

  public execute(): any {
    const result = this.dynamicForm.getLocalPage(this.dataSet.teilFormularName ?? "");
    return result;
  }
}

