
  import { reactive} from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  
  export default {
    name: "FischbuchEintragPachtrevier",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
    setup() {
      const request = reactive({
        apiLoadMethod: "api/Form/loadForm",
        apiSaveMethod: "api/sync/syncFishbook",
        form: "FischbuchEintragPachtrevier",
        page: "FischbuchEintragPachtrevierDaten",
        remoteId: "",        
        routeName: "",
        filter: ""
      });
      return {
        request
      };
    }
  };
  