const multilanguage = false

export function isMultiLanguage() {
    return multilanguage
}

export function getBrowserLanguage() {
    
    if( !multilanguage ) return "de"
    
    const language = "de"
    const languageFr = "fr"
    try {
        const browserLanguage = getFirstBrowserLanguage()
        if ( !browserLanguage || browserLanguage.length < 1 ) return language

        if ( browserLanguage.toLowerCase().indexOf("de") >= 0) return language
        if ( browserLanguage.toLowerCase().indexOf("fr") >= 0) return languageFr

        return language

    } catch(e) {
        return language
    }
}


function getFirstBrowserLanguage() : string {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
    const nav = window.navigator,
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage']
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
     let   i
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
     let   language
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
     let   len
     let   shortLanguage = null;
    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
        for (i = 0; i < nav.languages.length; i++) {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
            language = nav.languages[i];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
            len = language.length;
            if (!shortLanguage && len) {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
                shortLanguage = language;
            }
            if (language && len>2) {
                return language;
            }
        }
    }

    // support for other well known properties in browsers
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
        language = nav[browserLanguagePropertyKeys[i]];
        //skip this loop iteration if property is null/undefined.  IE11 fix.
        if (language == null) { continue; } 
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
        len = language.length;
        if (!shortLanguage && len) {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
            shortLanguage = language;
        }
        if (language && len > 2) {
            return language;
        }
    }
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
    return shortLanguage;
}
