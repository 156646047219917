import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";
import { GeoCoordinates, GpsState } from "../gps";



export interface GpsListenerState {
    gpsPosition: GeoCoordinates | undefined 
    gpsTimeStamp: number | undefined 
    gpsState: GpsState | undefined 
    action: string | undefined
}


export class GpsListener extends StateService<GpsListenerState>{
    public static service: GpsListener;

    public static getService() : GpsListener {
        if (!GpsListener.service) {
            GpsListener.service = new GpsListener();
            this.service = GpsListener.service
        }
        return GpsListener.service;
    }

    private constructor() {
        super({gpsPosition: undefined, gpsTimeStamp: undefined, gpsState: undefined, action: undefined});       
    }
    
    public subscription(): Observable<GpsListenerState> {
        return this.subscribe();
    }

    public onGpsChanged(geoState: GpsListenerState) : void {               
        this.changeState(geoState);
    }

    public reset() : void {
        this.changeState({gpsPosition: undefined, gpsTimeStamp: undefined, gpsState: undefined, action: undefined});
    }

    public get gpsState(): GpsListenerState {
        return this.state;
    }
}