
  import { reactive, defineComponent, computed, onMounted, onUnmounted, ref} from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  import { DynamicFilterFormStateForRoot } from "./hooks/FilterForm";
  import { DynamicRecord } from "./DynamicFormData";
  import { DynamicSaveFormForRoot } from "./hooks/SaveFormData";
  import { RoutedButtonBarRequestChannelForRoot, RoutedButtonBarRequestState } from "../routed-button-bar/RoutedButtonBarRequestChannel"; 
  import { RoutedButtonBarResponseChannelForRoot, RoutedButtonBarResponseState } from "../routed-button-bar/RoutedButtonResponseChannel";
  import { useRoute } from "vue-router";
import { LocalStore } from "@/infrastructure/local-api/LocalStore";
import { Out } from "@/infrastructure/frontent/clientMessage";
import { Resource } from "@/infrastructure/resource/resource";
  
  export default defineComponent ({
    name: "DynamicFilterForm",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
    props: {
        apiLoadMethod: {
        type: String,
        default: "",
        require: true
        },
        apiSaveMethod: {
        type: String,
        default: "",
        require: true
        },
        form: {
        type: String,
        default: "",
        require: true
        },
        page: {
        type: String,
        default: "",
        require: true
        },
        remoteId: {
        type: String,
        default: "",
        require: true
        },
        mode: {
        type: String,
        default: "ApiMode",
        require: false
        },
        routeName: {
          type: String,
          default: "",
          require: false
        },
        filter: {
          type: String,
          default: "",
          require: false
        }
      
    },
    setup(props) {
      const request = reactive({
        apiLoadMethod: props.apiLoadMethod,
        apiSaveMethod: props.apiSaveMethod,
        form: props.form,
        page: props.page,
        remoteId: props.remoteId,
        mode: props.mode,
        filter: props.filter
      });

      const uiControl = reactive({
        filterVisible: false
      })

      const record: DynamicRecord = {
        teilFormularName: "",
        formularFeldname: "",
        feldTyp: "",
        istEditierbar: false,
        istPflichtfeld: false,
        istSichtbar: false,
        maxLaenge: 0,
        resourceId: "",
        teilFormularReihenfolge: 0,
        regulaererAusdruck: "",
        resourceIdErrorMeldungPflichtfeld: "",
        resourceIdErrorMeldungValidierung: "",
        resourceIdErrorMeldungMaxLaenge: "",
        layoutKlasse: "",
        isValidationError: false,
        validationMessage: "",
        value: undefined,
        tabellenFeldname: "",
        tabellenName: "",
        infoTextTitle: undefined,
        infoTextContent: undefined,
        infoResourceId: undefined,
        parentCascadeId: undefined
      }


      const formSave = reactive({
        content:[record]
      })

      const route=useRoute();    
      const clientRouteName = computed(() =>route.name?.toString())
      const filterActive = ref<string>("")

      const uIFilterState = DynamicFilterFormStateForRoot.getService(props.form)
      const saveForm = DynamicSaveFormForRoot.getService(props.form)
      const routedButtonBarRequest = RoutedButtonBarRequestChannelForRoot.getService()     
      const routeButtonBarResponse = RoutedButtonBarResponseChannelForRoot.getService()

      const onSaveForm = saveForm.subscription().subscribe({
        next: (value) => {
          if ( value.rows != undefined)
            formSave.content = value.rows
        }
      })

      const backState: RoutedButtonBarRequestState = {
        sourceRoute: clientRouteName.value,
        targetRoutes: {backTargetRoute: props.routeName , vorwardTargetRoute: props.routeName},
        command: "Back",
        topic: props.filter
        }

      async function isFilterActive() {
        if ( isFilterFormValue()) {
          filterActive.value = await Resource.getResourceText("Label.DefaultLabel.FilterAktiv")
          return
        }
        filterActive.value = await Resource.getResourceText("Label.DefaultLabel.KeinFilterAktiv")
      }
      
      function isFilterFormValue() : boolean {
        try {
          const filterForm = LocalStore.GetForm(props.filter)
          if ( filterForm) {
            for (let row of filterForm) {
              if ( row.value ) {
                if ( row.value !== "")
                  return true
              }
              if ( row.dateValue ) {
                if ( row.dateValue !== undefined)
                  return true
              }
            }
          }



        } catch(e) {
          Out.noOperation(e)
        }
        return false
      }
        
      const onRouteButtonBarResponse = routeButtonBarResponse.subscription().subscribe({
        next: (value) => {
          if ( value.targetRoute !== undefined && value.targetRoute === props.routeName && value.topic === props.filter )
            setTimeout( () => {
              onClosed(value)
            } , 0)
        }
      })


      async function onClosed(value: RoutedButtonBarResponseState) {      
        if ( uiControl.filterVisible) uiControl.filterVisible = ! uiControl.filterVisible       
        if ( value.command !== undefined && value.command === "Save") {
          uIFilterState.changeFilterState({showFilter: false, attachToForm: props.form, rows: formSave.content })  
        }
        isFilterActive()
      }	  


      function filterSymbolVisibleClass() {
        if ( ! uiControl.filterVisible ) {
          //uIFilterState.changeFilterState({showFilter: true, attachToForm: props.form, rows: undefined })                  
          return "row show__div"         
        }

        routedButtonBarRequest.changeRouteState(backState)
        //uIFilterState.changeFilterState({showFilter: false, attachToForm: props.form, rows: undefined })     
        return "row hide__div"
      }

      function filterVisibleClass() {
        if ( uiControl.filterVisible ) {
          //uIFilterState.changeFilterState({showFilter: true, attachToForm: props.form, rows: undefined })      
          return "row show__div"
        } 

        uIFilterState.changeFilterState({showFilter: false, attachToForm: props.form, rows: undefined })          
        isFilterActive() 
        return "row hide__div"
      }

      function showFilter() {
        isFilterActive()
        uIFilterState.changeFilterState({showFilter: true, attachToForm: props.form, rows: undefined })
        uiControl.filterVisible = ! uiControl.filterVisible
      }
      

      isFilterActive()

      onUnmounted(() => {
        onSaveForm.unsubscribe()
        onRouteButtonBarResponse.unsubscribe()
      })

      return {
        filterSymbolVisibleClass,
        filterVisibleClass,
        showFilter,        
        request,
        uiControl,
        formSave,
        filterActive
      };
    }
  });
  