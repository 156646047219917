

import { reactive , defineComponent, computed, ref} from "vue";
import { useRoute } from "vue-router";


export default defineComponent ({
  name: "LoaderPopup",
  props: {
    isInProgress: {
      type: Boolean,
       default: null,
      require: true
    },   
  },
  inheritAttrs: false,
  components: {},
  setup( props) {
    
    const route=useRoute();    
    const clientRouteName = computed(() =>route.name?.toString())

    

    const LoaderParameter = reactive({
      isInProgress: props.isInProgress,      
      })

    const sizing = ref<string>("")
    if ( clientRouteName.value === undefined || clientRouteName.value === "Login" ) {

        sizing.value = "sizing__page-landing"
    } else {
        sizing.value = "sizing__page"
    }

    return {    
      LoaderParameter,      
      sizing
    };
  }
});
