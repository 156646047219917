
import { AsyncCommand, Command } from "../Command";
import { DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { UserRegistration } from "@/components/registration/userRegistration"

export class RulePasswordRequest extends AsyncCommand {
  private dynamicForm: DynamicFormData;
  constructor(dynamicForm: DynamicFormData) {
    super();
    this.dynamicForm = dynamicForm;
    
  }
  public async asyncExecute(): Promise<any> {
    const userProfile: UserProfile = new UserProfile()    
    const userProfileData = await userProfile.GetProfileData()
    const userRegistration: UserRegistration = new UserRegistration()
    const result = await userRegistration.CreateUserPasswordTicket(userProfileData.userId)
    return result;
  }
}
