import {
  StateResult,
  NavigationStateElements,
  NavigationStateElement,
  InteractionUsage
} from "@/business/application/statemachine/StateInterfaces";
import {  
  StateMachineServiceLocator,
} from "@/business/application/statemachine/StateMachine";

import router from "@/router/index";
import { Out } from "../frontent/clientMessage";
import { Resource } from "../resource/resource";


function Map(store: any, f: StateResult): StateResult {
  const t = store.getters["uistatestore/getStateSet"] as StateResult;

  t.newMainState = f.newMainState
  t.newState = f.newState
  t.oldMainState = f.oldMainState
  t.oldState = f.oldState
  t.routeName = f.routeName
  t.command = f.command ?? ""

  store.commit("uistatestore/setStateSet", t);

  return t
}

function MapInteraction(store: any, f: StateResult): StateResult {
  const t = store.getters["uistatestore/getStateSet"] as StateResult;

  const iU: InteractionUsage = {isLocalSave: false, isRemoteLoad: false,isRemoteSave: false, isValidation: false}
  const i = t.interaction ?? iU

  i.isLocalSave = f.interaction?.isLocalSave ?? false
  i.isRemoteSave = f.interaction?.isRemoteSave ?? false
  i.isValidation = f.interaction?.isValidation ?? false
  i.isRemoteLoad = f.interaction?.isRemoteLoad ?? false
  
  t.interaction = i
  store.commit("uistatestore/setStateSet", t);
  return t
}


function MapNavigationItems(store: any, f: StateResult): StateResult {
  const t = store.getters["uistatestore/getStateSet"] as StateResult;

  const n: NavigationStateElement = {
    command: "",
    displayName: "",
    enabled: false,
    existent: false,
    imageUrl: "",
    visible: false,
  };
  const s: NavigationStateElements = { nav1: n, nav2: n, nav3: n, nav4: n };
  const navigation = t.navigation ?? s;

  navigation.nav1.enabled = f.navigation?.nav1.enabled ?? false;
  navigation.nav1.visible = f.navigation?.nav1.visible ?? false;
  navigation.nav1.existent = f.navigation?.nav1.existent ?? false;
  navigation.nav1.imageUrl = f.navigation?.nav1.imageUrl ?? "";
  navigation.nav1.command = f.navigation?.nav1.command ?? "";
  navigation.nav1.displayName = f.navigation?.nav1.displayName ?? "";

  navigation.nav2.enabled = f.navigation?.nav2.enabled ?? false;
  navigation.nav2.visible = f.navigation?.nav2.visible ?? false;
  navigation.nav2.existent = f.navigation?.nav2.existent ?? false;
  navigation.nav2.imageUrl = f.navigation?.nav2.imageUrl ?? "";
  navigation.nav2.command = f.navigation?.nav2.command ?? "";
  navigation.nav2.displayName = f.navigation?.nav2.displayName ?? "";

  navigation.nav3.enabled = f.navigation?.nav3.enabled ?? false;
  navigation.nav3.visible = f.navigation?.nav3.visible ?? false;
  navigation.nav3.existent = f.navigation?.nav3.existent ?? false;
  navigation.nav3.imageUrl = f.navigation?.nav3.imageUrl ?? "";
  navigation.nav3.command = f.navigation?.nav3.command ?? "";
  navigation.nav3.displayName = f.navigation?.nav3.displayName ?? "";

  navigation.nav4.enabled = f.navigation?.nav4.enabled ?? false;
  navigation.nav4.visible = f.navigation?.nav4.visible ?? false;
  navigation.nav4.existent = f.navigation?.nav4.existent ?? false;
  navigation.nav4.imageUrl = f.navigation?.nav4.imageUrl ?? "";
  navigation.nav4.command = f.navigation?.nav4.command ?? "";
  navigation.nav4.displayName = f.navigation?.nav4.displayName ?? "";

  t.navigation = navigation;
  store.commit("uistatestore/setStateSet", t);
  return t;
}

export function changeNavigation(store: any): void {
  const stateMachine = StateMachineServiceLocator.get();
  const stateResult = stateMachine.getCurrentResultState();
  ensureIconTitle(stateResult)
  Map(store, stateResult)
  MapInteraction(store, stateResult)
  MapNavigationItems(store, stateResult);
  if (stateResult.routeName !== undefined && stateResult.routeName.length > 0) {
    router.push({ name: stateResult.routeName });
    Out.pusRouterOut(`NavStateNotification.changeNavigation => RouteName: ${stateResult.routeName}` )
  }
}


function ensureIconTitle(stateResult: StateResult) {
  if ( stateResult.navigation) {
    if ( stateResult.navigation.nav1 && stateResult.navigation.nav1.command && stateResult.navigation.nav1.command === "Previous" ) {
      
      if ( !stateResult.navigation.nav1.displayName || stateResult.navigation.nav1.displayName === '' )
        stateResult.navigation.nav1.displayName = Resource.getResourceTextSyncMode("Navigation.Icon.Zurueck","")
    }
    if ( stateResult.navigation.nav2 && stateResult.navigation.nav2.command && stateResult.navigation.nav2.command === "NextPage" && stateResult.navigation.nav2.imageUrl && stateResult.navigation.nav2.imageUrl.indexOf("arenkorb") >= 0 ) {
      
      if ( !stateResult.navigation.nav2.displayName || stateResult.navigation.nav2.displayName === '' )
        stateResult.navigation.nav2.displayName = Resource.getResourceTextSyncMode("Navigation.Icon.Warenkorb","")
    }
    if ( stateResult.navigation.nav4 && stateResult.navigation.nav4.command && stateResult.navigation.nav4.command === "Information") {
      if ( !stateResult.navigation.nav4.displayName || stateResult.navigation.nav4.displayName === '' )
        stateResult.navigation.nav4.displayName = Resource.getResourceTextSyncMode("Navigation.Icon.Info","")
    }
  }
}
