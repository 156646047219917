import { StateService } from '../../../../infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { Identity } from '@/infrastructure/backend/userIdentity';
import { DynamicGrid , DynamicGridRow, DynamicGridRequest } from "@/components/Shared/dynamicList/DynamicListTypes"
import { SupportedDetailLists } from '../DynamicDetailListTypes';
import { RefreshServerObjects } from '@/infrastructure/resource/RefreshServerObjects';




export interface DynamicDetailParentListState {
    grid: DynamicGrid
    row: DynamicGridRow    
    request: DynamicGridRequest
  }
  

  const helper = () => {
    try {
        return Identity.getIdentity().getValue().UserId
    } catch(e) {
        return ""
    }
}

const initialState: DynamicDetailParentListState = {
    grid: {
        viewId: "",
        userId: helper()
    },
    row: {
        cell: {
          image:{},
          content: {},
          additional: {}
        }
    },
    request: {
        userId: "" ,  
        viewId: "",
        detailId: "",
        detailList: false,
        allRecords: false,
        lastRefreshDate: RefreshServerObjects.getLastGridFormRefreshDateSync(),
        lastViewIdRefreshState: RefreshServerObjects.getLastGridFormRefreshDateSync()
    }
    
  };

  export function createParentListState() : DynamicDetailParentListState {
    return initialState
  }

export async function CreateParentListState(grid: DynamicGrid, row: DynamicGridRow, request: DynamicGridRequest) : Promise<DynamicDetailParentListState> {
    return {
        grid: grid,
        row: row,
        request: request,
    }
}

export class DynamicDetailListParentListChannelForRoot extends StateService<DynamicDetailParentListState>{
    public static service: DynamicDetailListParentListChannelForRoot;
    private static attachetTo: SupportedDetailLists = undefined
    private static firstAttached = true

    public static getService() : DynamicDetailListParentListChannelForRoot {
        if ( DynamicDetailListParentListChannelForRoot.service === undefined) {
            DynamicDetailListParentListChannelForRoot.service = new DynamicDetailListParentListChannelForRoot();
            this.service = DynamicDetailListParentListChannelForRoot.service
        }
        return DynamicDetailListParentListChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<DynamicDetailParentListState> {
        return this.subscribe();
    }

    public changeParentState(state: DynamicDetailParentListState) : void {
        DynamicDetailListParentListChannelForRoot.firstAttached = false
        if ( DynamicDetailListParentListChannelForRoot.attachetTo !== state.request.detailId as SupportedDetailLists ) {
            DynamicDetailListParentListChannelForRoot.attachetTo = state.request.detailId as SupportedDetailLists 
            DynamicDetailListParentListChannelForRoot.firstAttached = true
        }
        this.changeState(state);
    }

    public isAttached(detailListName: SupportedDetailLists): boolean {
        if ( detailListName === undefined ) {
            return false
        }
        
        if ( DynamicDetailListParentListChannelForRoot.attachetTo == detailListName && DynamicDetailListParentListChannelForRoot.attachetTo !== undefined && detailListName !== undefined) {
            return true
        }
        
        return false
    }

    public isFirstAttached() {
        return DynamicDetailListParentListChannelForRoot.firstAttached
    }

    public get parentState(): DynamicDetailParentListState {
        return this.state;
    }
}

export { SupportedDetailLists };
