import { Result } from "@/infrastructure/functional/result";
import { getCookie } from "typescript-cookie";

export class RestClientHeader {
  private getXsrfCookie(): string | undefined {
    return getCookie("XSRF-TOKEN");
  }

  public getHeader(withCookies: boolean): Result<Map<string, string>> {
    const headerMap = new Map();
    
    //headerMap.set("cache-control", "no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0");
    headerMap.set("Service-Worker-Allowed", "/")

    const cookie: string | undefined = this.getXsrfCookie();
    if (cookie === undefined && withCookies)
      Result.fail<Map<string, string>>("Cookie for XSRF-TOKEN not found.");

    if (withCookies) headerMap.set("capptoken", cookie);
    headerMap.set("Accept", "application/json");
    headerMap.set("Content-Type", "application/json");
    //headerMap.set("eFjHeaderFest", "Dies ist ein Test");
    //headerMap.set("eFjHeaderCookieFest", "CfDJ8HDiUe-DtVBPp4qccIs-7IiJzKgwLz5Az96iIc9zr5UpTqfRspNyFYPFJSsMP9yeTUEcBubRizjo4c37J-o4xuJgGfwfnG0RQSUxJphd8-9Vrpc6FOKMtAxyS47xaLpKujRjd_EA7dIjDP-7utlxQ6ed0sSvH0PSYOpRraQP1LiQtL4PZImcp84y8LtRcJxAyA");
    //headerMap.set("eFjHeaderCookie", cookie ?? "-");
    return Result.ok<Map<string, string>>(headerMap);
  }
}
