
  import { reactive } from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  
  export default {
    name: "email-with-current-password",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
      setup() {    
      const request = reactive({
        apiLoadMethod: "api/anonymousForm/loadForm",
        apiSaveMethod: "",
        form: "EmailWithCurrentPassword",
        page: "EmailWithCurrentPasswordEmailCode",
        remoteId: "",
        waitForResponse: true
      });  

     


      return {
        request,
      };
    }
  };
  