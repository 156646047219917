import { OfflineForms } from '@/components/Shared/Dynamicform/DynamicFormPreload';
import { FishingEdit } from './FishingEdit';

import { DynamicGridRow } from '@/components/Shared/dynamicList/DynamicListTypes';
import fischereiFangDetailJson from '@/components/fishing/business/FischereiFangDetail.json';
import {v4 as uuidv4} from 'uuid';
import { ConvertDateToDayString, ConvertDateToIsoString, GenerateAppSynchronId, newDate } from '@/infrastructure/functional/datetimehelper';
import huntingRecordJson from '@/components/fishing/business/JagdAbgang.json';

export class HuntingRecord {

    public static async CreateJagdAbgangRow(kategorie: string) : Promise<DynamicGridRow> {   
        
        const row : DynamicGridRow = <DynamicGridRow>huntingRecordJson
        row.parentId = uuidv4();
        row.id =  row.parentId;
        row.appSynchronId = row.appSynchronId = GenerateAppSynchronId();
        row.cell.readableIdentification!.id = row.id
        row.cell.readableIdentification!.kategorie = await HuntingRecord.getFormNameFromKategorie(kategorie) ?? kategorie
        row.cell.readableIdentification!.readableId = row.id
        row.cell.readableIdentification!.startDate = ConvertDateToIsoString( newDate() )

        row.cell.additional.text = ConvertDateToDayString(newDate())

        row.isSynced = false
        row.parentId = row.id


    

        row.rows = undefined
        row.jsonDetail = undefined
        row.detailForm = await OfflineForms.getForm(`${await HuntingRecord.getFormNameFromKategorie(row.cell.readableIdentification!.kategorie)}`)
        row.detailForm.id = row.id
        return row
    }

    private static async getFormNameFromKategorie(kategorie: string) {
        if ( kategorie === "99.1") return "WildbuchEintragJagd"
        if ( kategorie === "99.2") return "WildbuchEintragFallwild"
        if ( kategorie === "99.3") return "WildbuchEintragBeobachtung"
        if ( kategorie === "99.4") return "WildbuchEintragBeobachtungSpez"
        return kategorie
    }
    
}