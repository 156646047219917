import { dataRestIcon } from "@progress/kendo-svg-icons"
import { Out } from "../frontent/clientMessage"


export const ConvertUtcStringToDateNullable = (utcDate: string) : Date | undefined  => {
    if ( utcDate === undefined || utcDate === null || utcDate.length < 10) return undefined
    utcDate = makeValidUtcString(utcDate)
    const result = new Date(utcDate)
    printDate("ConvertUtcStringToDateNullable" , result)
    return result
}

export const ConvertUtcStringToDate = (utcDate: string) : Date  => {
    if ( utcDate === undefined || utcDate === null || utcDate.length < 10) return newDate()
    utcDate = makeValidUtcString(utcDate)

    const result = new Date(utcDate)
    printDate("ConvertUtcStringToDate" , result)
    return result
}

export const ConvertUtcStringToDateOrUndefined = (utcDate: string) : Date | undefined  => {
    if ( utcDate === undefined || utcDate === null || utcDate.length < 10) return undefined
    utcDate = makeValidUtcString(utcDate)
    const result = new Date(utcDate)
    printDate("ConvertUtcStringToDateOrUndefined" , result)
    return result
}


export const makeValidUtcString = (utc: string) : string => {
    if ( !utc) return utc
    utc = utc.trim().replace(" ", "T")
    if ( utc.length > 10 ) {
        const temp: string[] = utc.split(":")
        if ( temp.length === 4) {
            utc = temp[0] + ":" + temp[1] + ":" + temp[2] + "." + temp[3]
        }
    }

    return utc
}
export const ConvertEuropeanToDate = (euDate: string ) : Date | undefined => {
    
    if ( euDate === null || euDate === undefined ) return undefined
    if ( euDate.length < 10) return undefined
    const utcDate: string[] = euDate.split(".")
    if ( utcDate.length !== 3) return undefined
    let date : Date | undefined = undefined
    try {
        date = new Date(`${utcDate[2]}-${utcDate[1]}-${utcDate[0]}`)
    } catch(e) {
        return undefined
    } 
    printDate("ConvertEuropeanToDate" , date)
    return date
}

export const ConvertCanonicalToDate = (euDate: string ) : Date | undefined => {
    
    if ( euDate === null || euDate === undefined ) return undefined
    if ( euDate.length < 10) return undefined
    const utcDate: string[] = euDate.split("-")
    if ( utcDate.length !== 3) return undefined
    let date : Date | undefined = undefined
    try {
        date = new Date(`${utcDate[0]}-${utcDate[1]}-${utcDate[2]}`)
    } catch(e) {
        return undefined
    } 
    printDate("ConvertCanonicalToDate" , date)
    return date
}

export const ConvertCanonicalToDayDate = (euDate: string , offset: boolean ) : Date | undefined => {
    
    if ( euDate === null || euDate === undefined ) return undefined
    if ( euDate.length < 10) return undefined
    const utcDate: string[] = euDate.split("-")
    if ( utcDate.length !== 3) return undefined
    let date : Date | undefined = undefined
    try {
        let day = utcDate[2].split("T")
        if ( day.length < 2) {
            day = utcDate[2].split(" ")
            if ( day.length < 2) 
                return undefined
    
        }

       
        if ( offset ) {
            date = new Date(`${utcDate[0]}-${utcDate[1]}-${utcDate[2]}`)

            let offset = date.getTimezoneOffset()
            if ( offset < 0) offset = -offset
            const result =  new Date(date.getTime() + offset * 60000)
            printDate("ConvertCanonicalToDayDate_offset" , result)
            return result;
        }

        date = new Date(`${utcDate[0]}-${utcDate[1]}-${day[0]}T00:00:00.00Z`)
        if ( ! date) {
            date = new Date(`${utcDate[0]}-${utcDate[1]}-${day[0]} 00:00:00.00Z`)
        }
    } catch(e) {
        return undefined
    } 
    printDate("ConvertCanonicalToDayDate" , date)
    return date
}


export const ConvertDateToIsoString = (date: Date) : string => {
    let offset = date.getTimezoneOffset()
    if ( offset < 0) offset = -offset
    const result =  new Date(date.getTime() + offset * 60000)
    //const result = date
    printDate("ConvertDateToIsoString" , result)
    return result.toISOString()
}

export const ConvertDateToDayString = (date: Date | undefined) : string => {
    if ( date === undefined) return ""

    return `${date.getDate().toString().padStart(2,"0")}.${(date.getMonth()+1).toString().padStart(2,"0")}.${date.getFullYear().toString().padStart(4,"0")}`

}

export const isToday = (dateTime: Date) => {
    const today = newDate()
    return dateTime.getDate() == today.getDate() &&
    dateTime.getMonth() == today.getMonth() &&
    dateTime.getFullYear() == today.getFullYear()
  }

  export function getCurrentDayDate() {
    const dateTime: Date = newDate()
    const result = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())
    printDate("getCurrentDayDate" , result)
    return result
  }

  export function getDateFromGermanStringDate(stringDate: string | undefined) : Date | undefined {
    if (! stringDate) return undefined
    if ( stringDate.length < 10) return undefined

    const temp = stringDate.split(".")
    if (temp.length < 3) return undefined

    try {
        const result = new Date(parseInt(temp[2]), parseInt(temp[1])-1, parseInt(temp[0]))
        printDate("getDateFromGermanStringDate" , result)
        return result
    } catch (e) {
     return undefined
    }

  }

    export function addMonths(date: Date, months: number) {
        date.setMonth(date.getMonth() + +months);
        return date;
    }

    export function addWeeks(date: Date, days: number) {
        date.setDate(date.getDate() + days * 7);
        return date;
    }

    export function addDays(date: Date, days: number) {
        date.setDate(date.getDate() + days);
        return date;
    }

  export const getDateWithoutTime = (dateTime: Date) : Date => {
    try {
        const result = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())
        printDate("getDateWithoutTime" , result)
        return result
        //return new Date(dateTime.getUTCFullYear(), dateTime.getUTCMonth(), dateTime.getDate())
    } catch(e) {
        console.error("getDateWithoutTime: " + e)
    }
    return newDate()
  }  

 export function GetEndOfDayIsoString() : string  {
    const end = GetCurrentEndOfDayDateTime();
    return end.toISOString()
}

export function GenerateCorrectTime(date: Date) {
    // let offset = date.getTimezoneOffset()
    // if ( offset < 0) offset = -offset
    // const result = new Date(date.getTime() - offset * 60000);
    const result = date
    printDate("GenerateCorrectTime" , result)
    return result
}

export function GetCurrentEndOfDayDateTime() : Date  {
    const dateResult: Date = newDate()
    let offset = dateResult.getTimezoneOffset()
    if ( offset < 0) offset = -offset
    dateResult.setUTCHours(23,59,59,999);
   
    const result = new Date(dateResult.getTime() - offset * 60000);
    printDate("GetCurrentEndOfDayDateTime" , result)
    return result
}

export function GetEndOfDayDateTime(date: Date) : Date  {
    const dateResult: Date = new Date(date)
    let offset = date.getTimezoneOffset()
    if ( offset < 0) offset = -offset
    dateResult.setUTCHours(23,59,59,999);
   
    const result = new Date(dateResult.getTime() - offset * 60000);
    printDate("GetEndOfDayDateTime" , result)
    return result
}

export function GetDateDiffMinutes(stop: Date | undefined , start: Date | undefined) : number {
    if (!stop || ! start) return 0

    const diff = (stop.getTime() - start.getTime()) / 1000;
    const raWDiff = Math.abs(Math.round(diff / 60));
    //let offset = start.getTimezoneOffset()
    //if ( offset < 0) offset = -offset
    return raWDiff 
}

export function GenerateAppSynchronId() {
    //yyyy-MM-ddTHH:mm:ss.fff
    const date = newDate()
    // let offset = dt.getTimezoneOffset()
    // if ( offset < 0) offset = -offset
    // const date = new Date(dt.getTime() - offset * 60000)
    const datePart = `${date.getFullYear().toString().padStart(4,"0")}-${(date.getMonth()+1).toString().padStart(2,"0")}-${date.getDate().toString().padStart(2,"0")}T`
    const timepart = `${date.getHours().toString().padStart(2,"0")}:${date.getMinutes().toString().padStart(2,"0")}:${date.getSeconds().toString().padStart(2,"0")}`
    const milliseconds = `${date.getMilliseconds().toString().padStart(3,"0")}`

    return datePart + " " + timepart + "." + milliseconds
}

export function isGreaterAppSynchronId(syncId1: string | undefined, syncId2: string | undefined) : boolean {
    if (!syncId1 || syncId1.length  < 1 ) return false
    if (!syncId2 || syncId2.length  < 1 ) return false
    if (syncId2 > syncId1) return true

    return false
}

export function isDifferentAppSynchronId(syncId1: string | undefined, syncId2: string | undefined) : boolean {
    if (!syncId1 || syncId1.length  < 1 ) return true
    if (!syncId2 || syncId2.length  < 1 ) return true
    if (syncId2 !== syncId1) return true

    return false
}

export function printDate(functionName: string , currentDate: Date) {
    Out.noOperation("")
}

export function printDateOk(functionName: string , currentDate: Date) {
   

    // Get local date components
    const localYear = currentDate.getFullYear();
    const localMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const localDay = currentDate.getDate();

    // Format the date as a string
    let localDateString = localYear + '-' + (localMonth < 10 ? '0' : '') + localMonth + '-' + (localDay < 10 ? '0' : '') + localDay;
    localDateString = localDateString + " " + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()

    // Print the local date
    console.error(functionName + ": " + localDateString);
}

export function newDate() {
    const result = new Date()
    //const result = new Date( d.getTime() - d.getTimezoneOffset()*60*1000 )
    printDate("newDate" , result)
    return result
}