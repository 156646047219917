import { AsyncCommand, Command } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";

import { UserRegistration } from "@/components/registration/userRegistration";

export class RulePasswordEmail extends AsyncCommand {
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  private store: any;

  constructor( dynamicForm: DynamicFormData, dataSet: DynamicDataSet, store: any) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;
    this.store = store;
  }

  public async asyncExecute(): Promise<any> {
    const userRegistration: UserRegistration = new UserRegistration()
    const result = await userRegistration.ChangePasswordEmail(this.dynamicForm.getValue("PasswordEmailCode") as string, this.dynamicForm.getValue("Email") as string, this.dynamicForm.getValue("Password") as string )
    return result;
  }
}

