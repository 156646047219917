import { ComboBoxDataSource } from "@/components/Shared/Dynamicform/ComboBoxDataSource";
import { Identity } from "../backend/userIdentity";
import { UserProfile } from "../backend/userProfile";
import { deleteDatabase, deleteUnsyncedDatabase, openDatabase } from "../database/asyncDb";
import { LoaderStateServiceForRoot } from "../observables/loaderState";
import { isAllSynced } from "./Sync";
import { OfflineForms } from "@/components/Shared/Dynamicform/DynamicFormPreload";
import { AsyncRestClient } from "../backend/async/AsyncRestClient";
import { ObjectSearchDataSource } from "@/components/Shared/Dynamicform/ObjectSearchDataSource";
import { TierartRules } from "./TierartZusatzService";
import { DynamicGridRequest } from "@/components/Shared/dynamicList/DynamicListTypes";
import { getDynamicGridFromApi } from "./DynamicGridServiceWorker";
import { Out } from "../frontent/clientMessage";
import { Resource } from "../resource/resource";
import { RefreshServerObjects } from "../resource/RefreshServerObjects";

export class UpdateAppVersion {

    private static _loaderState: LoaderStateServiceForRoot

   

    public static async updateToNewVersion() {
        if ( !this._loaderState) UpdateAppVersion._loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        UpdateAppVersion._loaderState.changeLoaderState({isInProgress: true})

        const isUser = Identity.isUserRole()
        const isSynced = await isAllSynced()

        if ( isSynced) {
            await deleteDatabase()
            await RefreshServerObjects.resetAll()
        } else {
            await deleteUnsyncedDatabase()
            await RefreshServerObjects.resetUserDefined()
        }
        await openDatabase()

        if ( isUser) {
            if ( isSynced) {
                await UpdateAppVersion.loadWhenSynced()
            } else {
                await UpdateAppVersion.loadWhenNotSynced()
            }
        } else {
           await UpdateAppVersion.loadWhenNotLoggedIn()
        }

        UpdateAppVersion._loaderState.changeLoaderState({isInProgress: false})
    }

    private static async loadWhenNotLoggedIn() {
        await UpdateAppVersion.loadResources()
        
        await ComboBoxDataSource.initNamedLists(true)
        await OfflineForms.initOfflineForms()
    }


    private static async loadWhenNotSynced() {
        await UpdateAppVersion.loadResources()
        
        await ComboBoxDataSource.initNamedLists(true)
        await OfflineForms.initOfflineForms()
         
        const identity = Identity.getIdentityViaCookie()
        const userProfile: UserProfile = new UserProfile()
        await userProfile.ChangeLastLoginDate() 

        if ( identity !== undefined) {
          try {

           
           
            
            await userProfile.ChangeUserId(identity.getValue().UserId)
            await userProfile.ChangeRoles(identity.getValue().Roles)

            await ComboBoxDataSource.initUserNamedList()
            await ObjectSearchDataSource.initUserNamedList()
            await TierartRules.initRules()

            
          } catch( e ) {
            Out.noOperation(e)
          }
        }     
    }
   
    
      

    private static async loadWhenSynced() {
        await UpdateAppVersion.loadResources()
        
        await ComboBoxDataSource.initNamedLists(false)
        await OfflineForms.initOfflineForms()
         
        const identity = Identity.getIdentityViaCookie()
        const userProfile: UserProfile = new UserProfile()
        await userProfile.ChangeLastLoginDate() 

        if ( identity !== undefined) {
          try {

           
            await UpdateAppVersion.loadFishbook()
            
            await userProfile.ChangeUserId(identity.getValue().UserId)
            await userProfile.ChangeRoles(identity.getValue().Roles)

            await ComboBoxDataSource.initUserNamedList()
            await ObjectSearchDataSource.initUserNamedList()
            await TierartRules.initRules()

            
          } catch( e ) {
            Out.noOperation(e)
          }
        }     
    }

    private static async loadFishbook() {
        const request1: DynamicGridRequest = {
                  userId: Identity.getIdentity().getValue().UserId,
                  viewId: "fishBookEntriesWithDetail",
                  activeData: true,
                  pageSize: 1,
                  revierNr: "",
                  routeName: "FishBook",
                  detailRouteName: "FishbookDetailRecord",
                  page: "FischbuchEintragPachtDaten",
                  form: "FischbuchEintragPacht",
                  language: (await UserProfile.GetClientSettingsAsync()).language,
                  detailId: 'FishbookDetailRecord',
                  detailList: true,
                  allRecords: true,
                  lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                  lastViewIdRefreshState: await RefreshServerObjects.getLastGridFishBookRefreshDate()
              }
          const gridObject1 = await getDynamicGridFromApi(request1, true)
          const request2: DynamicGridRequest = {
                  userId: Identity.getIdentity().getValue().UserId,
                  viewId: "personProductsWithDetail",
                  activeData: true,
                  pageSize: 1,
                  revierNr: "",
                  routeName: "",
                  detailRouteName: "",
                  page: "1",
                  language: (await UserProfile.GetClientSettingsAsync()).language,
                  detailId: '',
                  detailList: false,
                  allRecords: true,
                  lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                  lastViewIdRefreshState: await RefreshServerObjects.getLastGridProductsRefreshDate()
              }
          const gridObject2 = await getDynamicGridFromApi(request2, true)
  
          
          const request3: DynamicGridRequest = {
                  userId: Identity.getIdentity().getValue().UserId,
                  viewId: "wildBookEntriesWithDetail",
                  activeData: true,
                  pageSize: 1,
                  revierNr: "",
                  routeName: "",
                  detailRouteName: "",
                  page: "1",
                  language: (await UserProfile.GetClientSettingsAsync()).language,
                  detailId: '',
                  detailList: false,
                  allRecords: true,
                  lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                  lastViewIdRefreshState: await RefreshServerObjects.getLastGridWildBookRefreshDate()
                  
              }
          const gridObject3 = await getDynamicGridFromApi(request3, true)
  
      }
  

      private static async  loadResources() {
        const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        loaderState.changeLoaderState({isInProgress: true})
        await Resource.loadResources("UpdateAppVersion");
        await Resource.loadSyncWrapper()
        loaderState.changeLoaderState({isInProgress: false})
      }


}