import { OfflineForms } from '@/components/Shared/Dynamicform/DynamicFormPreload';
import { FishingEdit } from './FishingEdit';

import { DynamicGridRow } from '@/components/Shared/dynamicList/DynamicListTypes';
import fischereiFangDetailJson from '@/components/fishing/business/FischereiFangDetail.json';
import { EditState } from '@/infrastructure/observables/gridEventState';
import {v4 as uuidv4} from 'uuid';
import { GenerateAppSynchronId } from '@/infrastructure/functional/datetimehelper';


export class FishingRecordDetail {

    public async CreateFischereiFangDetailRow(parentId: string) : Promise<DynamicGridRow> {   
        const fishingEditState = FishingEdit.getEditFishingState()     
        const row : DynamicGridRow = <DynamicGridRow>fischereiFangDetailJson
        row.parentId = parentId
        row.id = uuidv4();
        row.appSynchronId = row.appSynchronId = GenerateAppSynchronId();
        row.cell.readableIdentification!.id = row.id
        row.cell.readableIdentification!.kategorie = fishingEditState.patent?.kategorie
        row.rows = undefined
        row.jsonDetail = undefined
        row.detailForm = await OfflineForms.getForm(`FischbuchEintrag${row.cell.readableIdentification!.kategorie}`)
        row.detailForm.id = row.id
        row.fangStatistikDigital = true
        return row
    }

    
}