import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserError = _resolveComponent("UserError")!
  const _component_UserLogin = _resolveComponent("UserLogin")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_UserError, { message: $setup.errorMessage }, null, 8, ["message"]),
    _createVNode(_component_UserLogin, { onUserError: $setup.userError }, null, 8, ["onUserError"])
  ]))
}