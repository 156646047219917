import { AsyncCommand, Command } from "../Command";
import { DynamicDataSet, DynamicFormData} from "@/components/Shared/Dynamicform/DynamicFormData";
import { UserRegistration } from "@/components/registration/userRegistration";

export class RuleEmailRequest extends AsyncCommand {
  private dynamicForm: DynamicFormData;  
  private dataSet: DynamicDataSet;

  constructor(dynamicForm: DynamicFormData, dataSet: DynamicDataSet) {
    super();
    this.dynamicForm = dynamicForm;   
    this.dataSet = dataSet;
    
  }

  public async asyncExecute(): Promise<any> {
    const userRegistration: UserRegistration = new UserRegistration()
    const result = await userRegistration.CreateUserEmailTicket(this.dynamicForm.getValue("Email") as string )
    return result;
  }
}
