import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";


export interface UnmountDynamicFormState {
    unMount: boolean | undefined
}

//__ Steuert die Sichtbarkeit des Formulars über die Klasse DynamicNavigation (Proxy)
export class UnmountDynamicForm extends StateService<UnmountDynamicFormState>{
    public static service: UnmountDynamicForm;

    public static getService() : UnmountDynamicForm {
        if (!UnmountDynamicForm.service) {
            UnmountDynamicForm.service = new UnmountDynamicForm();
            this.service = UnmountDynamicForm.service
        }
        return UnmountDynamicForm.service;
    }

    private constructor() {
        super({unMount: undefined});       
    }
    
    public subscription(): Observable<UnmountDynamicFormState> {
        return this.subscribe();
    }

    public unMount(unmount: UnmountDynamicFormState) : void {               
        this.changeState(unmount);
    }

    public reset() : void {
        this.changeState({unMount: undefined});
    }

    public get unMountState(): UnmountDynamicFormState {
        return this.state;
    }
}