import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";
import { action, direction } from "../DynamicNavigationTypes";




export interface NavigateDynamicFormState {
    result: boolean | undefined 
    action: action | undefined
    direction: direction | undefined    
    page: string | undefined
}



//__ Zwei Wege Kommunikation zwischen dem Formular und dessen Rückmeldung an die Klasse DynamicNavigation (Proxy)
export class NavigateDynamicForm extends StateService<NavigateDynamicFormState>{
    public static service: NavigateDynamicForm;

    public static getService() : NavigateDynamicForm {
        if (!NavigateDynamicForm.service) {
            NavigateDynamicForm.service = new NavigateDynamicForm();
            this.service = NavigateDynamicForm.service
        }
        return NavigateDynamicForm.service;
    }

    private constructor() {
        super({direction: undefined, action: undefined, result: undefined, page: undefined});       
    }
    
    public subscription(): Observable<NavigateDynamicFormState> {
        return this.subscribe();
    }

    public navigate(navigate: NavigateDynamicFormState) : void {               
        this.changeState(navigate);
    }

    public reset() : void {
        this.changeState({direction: undefined, action: undefined, result: undefined, page: undefined});
    }

    public get navigationState(): NavigateDynamicFormState {
        return this.state;
    }
}