import { DynamicGrid, DynamicGridRow } from "./DynamicListTypes"

export class DynamicListStore {
    private static _selectedId: DynamicGridRow | undefined = undefined

    public static SetSelectedGridRow(selectedGridRow: DynamicGridRow | undefined) : void {
        DynamicListStore._selectedId = selectedGridRow
    }
    public static GetSelectedGridRow() : DynamicGridRow | undefined {
        return DynamicListStore._selectedId 
    }
}