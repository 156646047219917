import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04880da4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row g-0" }
const _hoisted_2 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_qrcode_vue, {
        value: $setup.QrCode,
        size: $setup.size,
        level: "H"
      }, null, 8, ["value", "size"])
    ])
  ]))
}