import { LightLog, LogTyp } from "@/business/log/LightLog"
import { LastSyncedObjects, addOrReplaceRecord, getRecord } from "../database/asyncDb"
import { ConvertDateToIsoString } from "../functional/datetimehelper"

export interface ILastSyncedObjects {
    lastGridRefreshDate: string
    lastFormRefreshDate: string
    lastResourceRefreshDate: string
    lastComboRefreshDate: string

    lastGridProductsRefreshDate: string
    lastGridWildBookRefreshDate: string
    lastGridFishBookRefreshDate: string
}

export class RefreshServerObjects {

    private static lastGridRefreshDateSync = "2000-01-01 01:01:01.00Z"
    public static startDateSync = "2000-01-01 01:01:01.00Z"

    public static async resetLogin() {
        await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastFormRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridFishBookRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridProductsRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridWildBookRefreshDate(RefreshServerObjects.startDateSync.slice())
        //await RefreshServerObjects.addLastResourceRefreshDate(RefreshServerObjects.startDateSync.slice())
    }

    public static async resetUserDefined() {
        await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastFormRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridFishBookRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridProductsRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridWildBookRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastResourceRefreshDate(RefreshServerObjects.startDateSync.slice())
    }

    public static async  resetAll() {
        await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastFormRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridFishBookRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridProductsRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridWildBookRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastResourceRefreshDate(RefreshServerObjects.startDateSync.slice())
    }

    public static async refreshSyncDates() : Promise<void> {
        await RefreshServerObjects.GetLastSyncedObjects()
    }

    public static getCurrentLastRefreshDate() : string {
        return ConvertDateToIsoString(new Date)
    }

    public static async getLastGridFormRefreshDate() : Promise<string> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        return lastObjects.lastFormRefreshDate
    }

    public static async addLastGridRefreshDate(date: string) : Promise<void> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        lastObjects.lastGridRefreshDate = date
        await RefreshServerObjects.AddLastSyncedObjects(lastObjects)
    }

    public static getLastGridFormRefreshDateSync() : string {
        return RefreshServerObjects.lastGridRefreshDateSync.slice()
    }

    public static async getLastFormRefreshDate() : Promise<string> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        return lastObjects.lastFormRefreshDate
    }

    public static async addLastFormRefreshDate(date: string) : Promise<void> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        lastObjects.lastFormRefreshDate = date
        await RefreshServerObjects.AddLastSyncedObjects(lastObjects)
    }

    public static async getLastResourceRefreshDate() : Promise<string> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        return lastObjects.lastResourceRefreshDate
    }

    public static async addLastResourceRefreshDate(date: string) : Promise<void> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        lastObjects.lastResourceRefreshDate = date
        await RefreshServerObjects.AddLastSyncedObjects(lastObjects)
    }

    public static async getLastComboRefreshDate() : Promise<string> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        return lastObjects.lastComboRefreshDate
    }

    public static async addLastComboRefreshDate(date: string) : Promise<void> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        lastObjects.lastComboRefreshDate = date
        await RefreshServerObjects.AddLastSyncedObjects(lastObjects)
    }


    public static async getLastGridProductsRefreshDate() : Promise<string> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        return lastObjects.lastGridProductsRefreshDate
    }

    public static async addLastGridProductsRefreshDate(date: string) : Promise<void> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        lastObjects.lastGridProductsRefreshDate = date
        await RefreshServerObjects.AddLastSyncedObjects(lastObjects)
    }

    public static async getLastGridWildBookRefreshDate() : Promise<string> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        return lastObjects.lastGridWildBookRefreshDate
    }

    public static async addLastGridWildBookRefreshDate(date: string) : Promise<void> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        lastObjects.lastGridWildBookRefreshDate = date
        await RefreshServerObjects.AddLastSyncedObjects(lastObjects)
    }

    public static async getLastGridFishBookRefreshDate() : Promise<string> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        return lastObjects.lastGridFishBookRefreshDate
    }

    public static async addLastGridFishBookRefreshDate(date: string) : Promise<void> {
        const lastObjects = await RefreshServerObjects.GetLastSyncedObjects()
        lastObjects.lastGridFishBookRefreshDate = date
        await RefreshServerObjects.AddLastSyncedObjects(lastObjects)
    }

    private static async GetLastSyncedObjects() : Promise<ILastSyncedObjects> {
        let result: ILastSyncedObjects = {
            lastGridRefreshDate: RefreshServerObjects.startDateSync.slice(),
            lastFormRefreshDate: RefreshServerObjects.startDateSync.slice(),
            lastResourceRefreshDate: RefreshServerObjects.startDateSync.slice(),
            lastComboRefreshDate: RefreshServerObjects.startDateSync.slice(),
            lastGridProductsRefreshDate: RefreshServerObjects.startDateSync.slice(),
            lastGridWildBookRefreshDate: RefreshServerObjects.startDateSync.slice(),
            lastGridFishBookRefreshDate: RefreshServerObjects.startDateSync.slice(),
        }
        try {
          
            const temp =  await getRecord(LastSyncedObjects, "1")
            if ( temp) {
                result  = temp as ILastSyncedObjects
            } else {
                await RefreshServerObjects.AddLastSyncedObjects(result)
            }
            RefreshServerObjects.lastGridRefreshDateSync = result.lastFormRefreshDate.slice()
            return result    
        } catch (e) {
            LightLog.add("userProfile.GetLastSyncedObjects" , `Exeception: ${e}`, LogTyp.Fehler)
        }
        return result
    }

    private static async AddLastSyncedObjects(lastSyncedObjects: ILastSyncedObjects) : Promise<void> {
        const record = JSON.parse( JSON.stringify(lastSyncedObjects)) as ILastSyncedObjects
        const data = {
            id: "1",        
            ...record
        }
        await addOrReplaceRecord(LastSyncedObjects, data)
    }

    

}