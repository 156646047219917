import { newDate } from '@/infrastructure/functional/datetimehelper';
import { GpsListener, GpsListenerState } from './hooks/GpsListener';

export type GeoCoordinates = {
    latitude: number 
    longitude: number
}

export enum GpsState {
    PermissionDenied,
    NetworkDown,
    LocationTimeout,
    PositionDelivered,
    GeneralError    
}

export class Gps  {

    private static gpsPosition: GeoCoordinates | undefined 
    private static gpsTimeStamp: number | undefined 
    private static gpsState: GpsState | undefined 
    private static action: string | undefined

    private static gpsListener = GpsListener.getService()

    public static CalculateGps(action: string | undefined): void {
        if ( ! navigator.geolocation ) {
            Gps.gpsPosition = undefined
            Gps.action = action
            return
        }
        Gps.action = action
        navigator.geolocation.getCurrentPosition(Gps.onSuccess, Gps.onError, Gps.options);
    }

    public static GetCurrentGpsSignal() : GpsListenerState | undefined {
        if ( !Gps.gpsTimeStamp ) 
            return undefined

        const gpsTimestamp = new Date(Gps.gpsTimeStamp)
        const currentTimestamp = newDate()
        const seconds = (currentTimestamp.getTime() - gpsTimestamp.getTime()) / 1000;
        if ( seconds > 300) 
            return undefined

        const listenerState: GpsListenerState = {
                gpsPosition: Gps.gpsPosition,
                gpsTimeStamp: Gps.gpsTimeStamp,
                gpsState: Gps.gpsState,
                action: Gps.action
        }     
        return listenerState
    }

    private static options = {
        enableHighAccuracy: true,
        timeout: 50000,
        maximumAge: 60000
    }

    private static onSuccess(position: any) :void {
        Gps.gpsPosition = position.coords
        Gps.gpsTimeStamp = position.timestamp
        Gps.gpsState = GpsState.PositionDelivered

        const listenerState: GpsListenerState = {
            gpsPosition: Gps.gpsPosition,
            gpsTimeStamp: Gps.gpsTimeStamp,
            gpsState: Gps.gpsState,
            action: Gps.action
        }        
        Gps.gpsListener.onGpsChanged(listenerState)

    }

    private static onError(error: any) : void {
        if ( error.code == 1 ) {
            Gps.gpsState = GpsState.PermissionDenied
            Gps.gpsPosition = undefined
        }
        if ( error.code == 2 ) {
            Gps.gpsState = GpsState.NetworkDown
        }
        if ( error.code == 3 ) {
            Gps.gpsState = GpsState.LocationTimeout
        }

        if ( error.code > 3 && error.code < 1) {
            Gps.gpsState = GpsState.GeneralError
        }
        

        const listenerState: GpsListenerState = {
            gpsPosition: Gps.gpsPosition,
            gpsTimeStamp: Gps.gpsTimeStamp,
            gpsState: Gps.gpsState,
            action: Gps.action
        }     
        Gps.gpsListener.onGpsChanged(listenerState)
    }



}