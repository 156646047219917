import { AsyncCommand, Command } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";


export class RuleFischfangEintrag extends AsyncCommand {
    private dynamicForm: DynamicFormData;  
    private dataSet: DynamicDataSet;
    private apiLoadMethod: string
    private dataView: string
    private clientRouteName: string
    
  
    constructor(dynamicForm: DynamicFormData, dataSet: DynamicDataSet , apiLoadMethod: string, dataView: string , clientRouteName: string ) {
      super();
      this.dynamicForm = dynamicForm;   
      this.dataSet = dataSet;
      this.apiLoadMethod = apiLoadMethod
      this.dataView = dataView
      this.clientRouteName = clientRouteName
    }
  public async asyncExecute(): Promise<any> {
    if (!this.clientRouteName || this.clientRouteName === '' ) this.clientRouteName = "Fishing"
    const result = await this.dynamicForm.getRemoteForm(this.apiLoadMethod, this.dataView, this.clientRouteName, "", undefined)      
    return result;
  }
}