export enum NavigatonIcons {
  Previous = "img/icons/previous_white.svg",
  Next = "img/icons/next_white.svg",
  Settings = "img/icons/settings_white.svg",
  GeneralSettings = "img/icons/settings_white.svg",
  Information = "img/icons/info_white.svg",
  Products = "img/icons/products_white.svg",
  Home = "img/icons/home.svg",
  Person="img/icons/person.svg",
  Sprache="",
  FischFanege="img/icons/fischbuch.svg",
  Wildbuch="img/icons/jagen.svg",
  Abmelden="img/icons/logout.svg",
  Pdf="img/icons/report.svg",
  Fischbuch="img/icons/fischbuch.svg",
  WebShop="img/icons/einkauf.svg",
  Warenkorb="img/icons/warenkorb.svg"
}




