import { Transition } from "@vue/runtime-dom";
import { NavigatonIcons } from "@/business/application/NavConst";
import { Resource } from "@/infrastructure/resource/resource";

export interface StateResult {
  newMainState: string;
  oldMainState: string;
  newState: string;
  oldState: string;
  routeName: string;
  interaction?: InteractionUsage;
  command?: string;
  navigation?: NavigationStateElements;
  isBackWard?: boolean
}

// export interface StateOnlyResult {
 
// }

export interface StateRule {
  navigation: StateNavigationAction;
  conditions: RuleCondition[];
}

export interface RuleCondition {
  nameId: string;
  value: string;
}

export type StateTransitionOptions = {
  lookahead: boolean;
};

export enum StateNavigationAction {
  Next = "Next",
  Previous = "Previous",
  Decision = "Decision",
  Remain = "Remain",
  Jump = "Jump",
  NotDefined = "NotDefined"
}

export interface Transition {
  mainState: string;
  newMainState: string;
  nav: StateNavigationAction;
  oldState: string;
  newState: string;
  condition: string;
  interaction?: InteractionUsage;
  navigation?: NavigationStateElements;
}

export interface InteractionUsage {
  isValidation: boolean;
  isLocalSave: boolean;
  isRemoteSave: boolean;
  isRemoteLoad: boolean;
}

export interface NavigationStateElements {
  nav1: NavigationStateElement;
  nav2: NavigationStateElement;
  nav3: NavigationStateElement;
  nav4: NavigationStateElement;
}

export interface NavigationStateElement {
  displayName: string;
  imageUrl: string;
  enabled: boolean;
  visible: boolean;
  existent: boolean;
  command: string;
}

export function addInteraction(
  transition: Transition,
  // eslint-disable-next-line
  itemIndex: number, 
  // eslint-disable-next-line
  interaction: InteractionUsage 
): Transition {
  if (transition.interaction === undefined) {
    transition.interaction = {
      isLocalSave: false,
      isRemoteSave: false,
      isValidation: false,
      isRemoteLoad: false
    };
  }
  return transition;
}

export function addNavigationElement(
  transition: Transition,
  itemIndex: number,
  navItem: NavigationStateElement
): Transition {
  if (transition.navigation === undefined) {
    transition.navigation = {
      nav1: {
        displayName: "",
        imageUrl: "",
        existent: false,
        visible: false,
        enabled: false,
        command: ""
      },
      nav2: {
        displayName: "",
        imageUrl: "",
        existent: false,
        visible: false,
        enabled: false,
        command: ""
      },
      nav3: {
        displayName: "",
        imageUrl: "",
        existent: false,
        visible: false,
        enabled: false,
        command: ""
      },
      nav4: {
        displayName: "",
        imageUrl: "",
        existent: false,
        visible: false,
        enabled: false,
        command: ""
      }
    };
  }

  switch (itemIndex) {
    case 1:
      transition.navigation.nav1 = {
        displayName: navItem.displayName,
        imageUrl: navItem.imageUrl,
        existent: navItem.existent,
        visible: navItem.visible,
        enabled: navItem.enabled,
        command: navItem.command
      };
      break;
    case 2:
      transition.navigation.nav2 = {
        displayName: navItem.displayName,
        imageUrl: navItem.imageUrl,
        existent: navItem.existent,
        visible: navItem.visible,
        enabled: navItem.enabled,
        command: navItem.command
      };
      break;
    case 3:
      transition.navigation.nav3 = {
        displayName: navItem.displayName,
        imageUrl: navItem.imageUrl,
        existent: navItem.existent,
        visible: navItem.visible,
        enabled: navItem.enabled,
        command: navItem.command
      };
      break;
    case 4:
      transition.navigation.nav4 = {
        displayName: navItem.displayName,
        imageUrl: navItem.imageUrl,
        existent: navItem.existent,
        visible: navItem.visible,
        enabled: navItem.enabled,
        command: navItem.command
      };
      break;
  }

  return transition;
}

export function addEmptyNavigationElement(
  transition: Transition,
  itemIndex: number
): Transition {
  return addNavigationElement(transition, itemIndex, {
    command: "",
    displayName: "",
    enabled: false,
    existent: false,
    imageUrl: "",
    visible: false
  });
}

export function addPreviousNext(
  transition: Transition,
  cmdPrevious: string,
  cmdNext: string
): Transition {
  addNavigationElement(transition, 1, {
    command: cmdPrevious,
    displayName:  Resource.getResourceTextSyncMode("Navigation.Icon.Zurueck",""),
    enabled: true,
    existent: true,
    imageUrl: NavigatonIcons.Previous,
    visible: true
  });

  addEmptyNavigationElement(transition, 2);
  addEmptyNavigationElement(transition, 3);

  addNavigationElement(transition, 4, {
    command: cmdNext,
    displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Vor",""),
    enabled: true,
    existent: true,
    imageUrl: NavigatonIcons.Next,
    visible: true
  });
  return transition;
}

export function addPrevious(
  transition: Transition,
  cmdPrevious: string
): Transition {
  addNavigationElement(transition, 1, {
    command: cmdPrevious,
    displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Zurueck",""),
    enabled: true,
    existent: true,
    imageUrl: NavigatonIcons.Previous,
    visible: true
  });
  addEmptyNavigationElement(transition, 2);
  addEmptyNavigationElement(transition, 3);
  addEmptyNavigationElement(transition, 4);
  return transition;
}

export function addNext(transition: Transition, cmdNext: string): Transition {
  addEmptyNavigationElement(transition, 1);
  addEmptyNavigationElement(transition, 2);
  addEmptyNavigationElement(transition, 3);
  addNavigationElement(transition, 4, {
    command: cmdNext,
    displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Vor",""),
    enabled: true,
    existent: true,
    imageUrl: NavigatonIcons.Next,
    visible: true
  });
  return transition;
}

export function newTransition(mainState: string, oldState: string, newState: string, navState: StateNavigationAction = StateNavigationAction.Next ) : Transition {
  const t: Transition = { mainState: mainState, newMainState: mainState, nav: navState, oldState: oldState, newState: newState, condition: ""};
  return t
}
export function newMainTransition(mainState: string, oldState: string, newMainState: string, newState: string, navState: StateNavigationAction = StateNavigationAction.Next ) : Transition {
  const t: Transition = { mainState: mainState, newMainState: newMainState, nav: navState, oldState: oldState, newState: newState, condition: ""};
  return t
}

export function newTransitionCondition(mainState: string, oldState: string, newState: string , condition: string, navState: StateNavigationAction = StateNavigationAction.Next ) : Transition {
  const t: Transition = { mainState: mainState, newMainState: mainState, nav: navState, oldState: oldState, newState: newState, condition: condition};
  return t
}

export function newMainTransitionCondition(mainState: string, oldState: string, newMainState: string,  newState: string , condition: string, navState: StateNavigationAction = StateNavigationAction.Next ) : Transition {
  const t: Transition = { mainState: mainState, newMainState: newMainState, nav: navState, oldState: oldState, newState: newState, condition: condition};
  return t
}


