import { OfflineForms } from "@/components/Shared/Dynamicform/DynamicFormPreload";
import { Resource } from "../resource/resource";
import { ComboBoxDataSource } from "@/components/Shared/Dynamicform/ComboBoxDataSource";
import { Identity } from "../backend/userIdentity";
import { UserProfile } from "../backend/userProfile";
import { ObjectSearchDataSource } from "@/components/Shared/Dynamicform/ObjectSearchDataSource";
import { DynamicGridRequest } from "@/components/Shared/dynamicList/DynamicListTypes";
import { getDynamicGridFromApi } from "./DynamicGridServiceWorker";
import { TierartRules } from "./TierartZusatzService";
import { RefreshServerObjects } from "../resource/RefreshServerObjects";
import { Out } from "../frontent/clientMessage";

export class SyncMassData  {

    public static async  load() {
        try {
            await Resource.loadResources("SyncMassData");
            await OfflineForms.initOfflineForms()
            await TierartRules.initRules()
            await this.loadGridViews()
            await ComboBoxDataSource.initUserNamedList()
            await ObjectSearchDataSource.initUserNamedList()     
        }  catch(e) {
            Out.noOperation("")
        }
      }
  
      private static async  loadGridViews() {
          const request2: DynamicGridRequest = {
                  userId: Identity.getIdentity().getValue().UserId,
                  viewId: "personProductsWithDetail",
                  activeData: true,
                  pageSize: 1,
                  revierNr: "",
                  routeName: "",
                  detailRouteName: "",
                  page: "1",
                  language: (await UserProfile.GetClientSettingsAsync()).language,
                  detailId: '',
                  detailList: false,
                  allRecords: true,
                  lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                  lastViewIdRefreshState: await RefreshServerObjects.getLastGridProductsRefreshDate()
              }
          await getDynamicGridFromApi(request2, true)
      }
  

}