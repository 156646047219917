import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";



export interface PaymentCancelState {
   IsCanceled: boolean | undefined
}

export class PaymentCancelService extends StateService<PaymentCancelState>{
    public static service: PaymentCancelService;

    public static getService() : PaymentCancelService {
        if (!PaymentCancelService.service) {
            PaymentCancelService.service = new PaymentCancelService();
            this.service = PaymentCancelService.service
        }
        return PaymentCancelService.service;
    }

    private constructor() {
        super({IsCanceled: undefined});       
    }
    
    public subscription(): Observable<PaymentCancelState> {
        return this.subscribe();
    }

    public cancelStateChanged(cancelState: PaymentCancelState) : void {               
        this.changeState(cancelState);
    }

    public reset() : void {
        this.changeState({IsCanceled: undefined});
    }

    public get CancelState(): PaymentCancelState {
        return this.state;
    }
}