/** New Service Worker registration 
*   Vue Composition API hook Module
* 
*   Author: Norbert Dries
*   Last Change Date: 09.08.2021
*
*   Statemachine GOF state pattern used
*   Commands depends on state (main-state and sub-state) GOF command-pattern used
*
*/

import { ref } from "vue"

import { AppConst } from "@/business/AppConst"
import { RoutingConstants } from "@/business/application/statemachine/constants/RoutingConstants";
import { StateConstants, StringToStateConstants } from "@/business/application/statemachine/constants/StateConstants";

import { Resource, ResourceText } from "@/infrastructure/resource/resource";

import { ServiceWorkerEvent } from "@/business/application/AppInterfaces"

import { openDialog , ModalDialogType } from "@/infrastructure/observables/ModalDialogNotification"

import { ErrorLog } from "@/business/error/ErrorLog"
import { LightLog, LogTyp } from "@/business/log/LightLog"

import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
import { StateNavigationAction } from "@/business/application/statemachine/StateInterfaces";
import { StateLogin } from "@/business/application/statemachine/concrete/StateLogin";
import { StateHome } from "@/business/application/statemachine/concrete/StateHome"
import { StateHunting } from "@/business/application/statemachine/concrete/StateHunting"
import { StateFishing } from "@/business/application/statemachine/concrete/StateFishing"

import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";

import { UserProfile } from './../infrastructure/backend/userProfile';
import { newDate } from "@/infrastructure/functional/datetimehelper";
import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";



export default function startAppState(store: any) {
  const modulName = "StartAppState"  
  const refreshing = ref(false)  
  const updateExists = ref(false)
 
  const shouldRefresh = ref(false)

  const lastUpdate = newDate()

  const conditionName = "*"
  const  conditionValue = "UpdateApp"

  

  const routeStateMapping = new Map<string,string>(
    [
      [RoutingConstants.Login, StateConstants.Login],
      [RoutingConstants.Home, StateConstants.Home],
      [RoutingConstants.Hunting, StateConstants.Hunting],
      [RoutingConstants.Fishing, StateConstants.Fishing],
    ])
  

  let waiter: ServiceWorkerRegistration

  //__ interact with vue router-listener in App.vue
  //__ ensures that windows.reload ist not to early invoked
  function isRefresh() {
    const temp = shouldRefresh.value
    shouldRefresh.value = false
    return temp
  }

  //__ the whole app is always in a certain state. State und routename is a one to one relation
  function getMainStateFromRouteName( routeName: string ) : string {
    return routeStateMapping.get(routeName) ?? StateConstants.Login
  }

  //__ the app has four re-entry possibilities only: Login, Home, Hunting and Fishing
  async function setLastViewState( store: any, routeName: string ) : Promise<void> {    
    try {      
      const userProfile: UserProfile = new UserProfile()      
      await userProfile.ChangeLastMainState(getMainStateFromRouteName( routeName ))
    }
    catch (e) {
      ErrorLog.add(modulName , e , true, true)            
    }
  }

  //__ state possibilities (re-entry) for Version handling
  interface StateCondition {
    State: StateHome|StateLogin|StateFishing|StateHunting,
    condition:string
  }

  const newStateConditionMapping = new Map<string, StateCondition>(
    [
      [StateConstants.Login, {State: new StateLogin(),condition: conditionValue }],
      [StateConstants.Home, {State: new StateHome(),condition: conditionValue }],
      [StateConstants.Fishing, {State: new StateFishing(),condition: conditionValue }],
      [StateConstants.Hunting, {State: new StateHunting(),condition: conditionValue }],
    ])
  
    function getStateConditionMapping(state: StateConstants) : StateCondition {
      return newStateConditionMapping.get(state) ??  {State: new StateLogin(),condition: conditionValue };
    }

  //__ state handling includes state changes in vuex and at least index db store
  async function navigateToLastPage() {
    try {
      const stateMachine = StateMachineServiceLocator.get();
      const userProfile: UserProfile = new UserProfile()      
      const userProfileData = await userProfile.GetProfileData()

      const state = StringToStateConstants(userProfileData.lastMainViewMainState)
      const lastState = getStateConditionMapping(state)

      let condition = [{ nameId: conditionName, value: lastState.condition }]
      stateMachine.setAppUpdateState(lastState.State);

      if ( ! userProfileData.online && userProfileData.lastMainViewMainState === StateConstants.Login ) {
        if ( userProfileData.lastLogin > new Date(AppConst.MinDate)) {
          stateMachine.setAppUpdateState(new StateHome());
          condition = [{ nameId: conditionName, value: conditionValue}]
        } else {
          stateMachine.setAppUpdateState(new StateLogin());
          condition = [{ nameId: conditionName, value: conditionValue }]
        }
      }


      stateMachine.next(
        {
          navigation: StateNavigationAction.Jump,
          conditions: condition,
        },
        { lookahead: false }
      );
      changeNavigation(store);
    } catch (e) {
      ErrorLog.add(modulName , e , true, true)               
    }    

    shouldRefresh.value = true
    
 
  } 

  //__ detect updated service worker and load the last re-entry routes
  if (navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing.value) return
      refreshing.value = true    
      navigateToLastPage()
    })  
  }

  //__ shows an update dialog to user. This functionality is automaticly triggered on an update
  function updateAvailable(event: any) { 
    if ( (event as ServiceWorkerEvent).detail === undefined ) {
      ErrorLog.add(modulName , new Error(Resource.getResourceUpdateAppText(ResourceText.ServiceWorkerRegisterUndefined)), true , true)   
    }
    waiter = event.detail

    const dt = newDate()

    if ( Math.abs( dt.getTime() - lastUpdate.getTime() ) > 120 ) {
      updateExists.value = false
    }

    if (! updateExists.value)  {
      updateExists.value = true          
      openDialog(store,{name: Resource.getResourceUpdateAppText(ResourceText.UpdateModalDialogName) , isCancel: true, isOk: true, open: true, titel: Resource.getResourceUpdateAppText(ResourceText.UpdateAppVersionTitle), text: Resource.getResourceUpdateAppText(ResourceText.UpdateAppVersionText), type: ModalDialogType.VersionUpdate})
    }
  }

  //__ manual update possibility, when user canceled the automatic shown dialog for update
  function openUpdate() {    
    updateExists.value = true    
    openDialog(store,{name: Resource.getResourceUpdateAppText(ResourceText.UpdateModalDialogName) , isCancel: true, isOk: true, open: true, titel: Resource.getResourceUpdateAppText(ResourceText.UpdateAppVersionTitle), text: Resource.getResourceUpdateAppText(ResourceText.UpdateAppVersionText), type: ModalDialogType.VersionUpdate})
  }

  //__ receives update possibilty from injected service worker
  document.addEventListener('swUpdated', updateAvailable, { once: true })

  //__ puts the new service from waiting state into working state
  function refreshApp() {
    updateExists.value = false    
    if (waiter === null || !waiter || !waiter.waiting) return
    LightLog.add(modulName, "SKIP_WAITING", LogTyp.Info)
    waiter.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  function isServiceWorkerWaiting() : boolean {   
    if (waiter === null || !waiter || !waiter.waiting) {
      return false
    }
    return true
  }

  return {
    refreshApp,
    updateExists,
    isRefresh,
    openUpdate,
    isServiceWorkerWaiting,
    setLastViewState,
    navigateToLastPage
  }
}