   
import { RoutedButtonBarRequestChannelForRoot, RoutedButtonBarRequestState } from "@/components/Shared/routed-button-bar/RoutedButtonBarRequestChannel";

import iconbutton from "@/components/Shared/IconNavigation.vue";
import { defineComponent, onUnmounted, reactive, ref } from "vue";
import { RoutedButtonBarResponseChannelForRoot } from "@/components/Shared/routed-button-bar/RoutedButtonResponseChannel";
import { RouteTargets } from "@/components/Shared/routed-button-bar/RoutedButtonBarTypes";
import { Out } from "@/infrastructure/frontent/clientMessage";
import { disableButtonTime } from "@/business/AppConst";
import { Resource } from "@/infrastructure/resource/resource";
  
export default defineComponent ({
  name: "RoutedNavigationBar",
    inheritAttrs: false,
    components: {
      iconbutton,
    },
  
    setup() {

        const disabledRoutedButton = ref<boolean>(false)
      
        let routedTargetRoutes: RouteTargets = {
          backTargetRoute: undefined,
          vorwardTargetRoute: undefined          
        }


        let routedButtonBarRequestState: RoutedButtonBarRequestState = {
          sourceRoute: undefined,
          targetRoutes: routedTargetRoutes,
          command: undefined,
          topic: undefined
        }    

        const request = reactive({
          routeState: routedButtonBarRequestState,
        })

        const routeRequestChannel = RoutedButtonBarRequestChannelForRoot.getService();
        const onChannelChanged = routeRequestChannel.subscription().subscribe({
          next: (value) => {
            request.routeState.command = value.command
            request.routeState.sourceRoute = value.sourceRoute
            if ( request.routeState.targetRoutes ) {              
              request.routeState.targetRoutes!.backTargetRoute = value.targetRoutes?.backTargetRoute
              request.routeState.targetRoutes!.vorwardTargetRoute = value.targetRoutes?.vorwardTargetRoute
              request.routeState.topic = value.topic
            }
          }
        })


        let routedDiasabledTimer = 10
        function back() {
          disabledRoutedButton.value = true
          routedDiasabledTimer = setTimeout( ()=> {
            disabledRoutedButton.value = false
          }, disableButtonTime)

          const routeResponseChannel =  RoutedButtonBarResponseChannelForRoot.getService();
          if (request.routeState.targetRoutes && request.routeState.targetRoutes.backTargetRoute && request.routeState.targetRoutes !== undefined) 
            routeResponseChannel.changeRouteState( { targetRoute: request.routeState.targetRoutes.backTargetRoute.toString() , command: "Back" , topic: request.routeState.topic })
        }
  
        function next() {
          disabledRoutedButton.value = true
          routedDiasabledTimer = setTimeout( ()=> {
            disabledRoutedButton.value = false
          }, disableButtonTime)

          const routeResponseChannel =  RoutedButtonBarResponseChannelForRoot.getService();
          if (request.routeState.targetRoutes && request.routeState.targetRoutes.vorwardTargetRoute && request.routeState.targetRoutes !== undefined) {
            routeResponseChannel.changeRouteState( { targetRoute: request.routeState.targetRoutes.vorwardTargetRoute.toString() , command: "Save" , topic: request.routeState.topic })
            routeResponseChannel.changeRouteState( { targetRoute: request.routeState.targetRoutes.vorwardTargetRoute.toString() , command: "Undefined" , topic: request.routeState.topic })
          }

        }




        function getBackImageUrl() {
            return "img/icons/previous_white.svg"
        }
  
        function getBackText() {
            return Resource.getResourceTextSyncMode("Navigation.Icon.Zurueck","")
        }
     
  
        function getForwardImageUrl() {
            return "img/icons/next_white.svg"
        }
  
        function getForwardText() {
            return "Weiter"
        }

        onUnmounted(() => {
          onChannelChanged.unsubscribe();
          try {
            clearTimeout( routedDiasabledTimer )
          } catch(e) {
            Out.noOperation("");
          }
        });
    
      return {
        back,
        next,
        getBackImageUrl,
        getBackText,
        getForwardImageUrl,
        getForwardText,
        disabledRoutedButton,
        request
      };
    },
  });
  