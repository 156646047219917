export type NavigationMessage = 
    "UserExistence.Login" | 
    "UserExistence.RegistrationLogin" | 
    "UserExistence.KnownButHasNoEmail" | 
    "UserExistence.ValidButWrongPassword" | 
    "UserExistence.RefreshUserData" |
    "UserExistence.PrivacyPolicy" |
    "UserExistence.ActivationByEmailInput" |
    "UserExistence.Activation" |
    "UserExistence.Unknown" |
    "UserExistence.ExistsButNoAccess" |
    "UserExistence.Valid" |
    "UserExistence.ValidButWrongLoginData" |
    "UserExistence.Known"





export function getNavigationStateFromMessage( message: NavigationMessage ) : string {
    if (message == "UserExistence.Login") {
        return "Home"
    }
    return message;
}
