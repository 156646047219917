import { newDate } from "@/infrastructure/functional/datetimehelper"


export interface ErrorRecord{
  id: number
  timestamp: string 
  title: string
  message: string
}

export class ErrorLog {
  static records: ErrorRecord[] = []
  static maxcRecords = 50
  static currentRecord = 0

  public static reset() {
    this.records = []
    this.currentRecord = 1
  }


  public static add(moduleIdentifier: string, e: any, show: boolean, rethrow: boolean) {
    this.currentRecord = this.currentRecord + 1
    if ( this.currentRecord > 50) {
      this.records = []
      this.currentRecord = 1
    }

    let callerName = "";
    try { throw new Error(); }
    catch (e: any) { 
        const re = /(\w+)@|at (\w+) \(/g, st = e.stack
        let m: any;
        re.exec(st), m = re.exec(st);
        if ( m !== null ) {
          callerName = m[1] || m[2];
        }
    }
    

    const messageText =  moduleIdentifier + "." + callerName

    const currentDateTime = newDate().toLocaleString("de-DE")
    ErrorLog.records.push({id: this.currentRecord, title: "Fehler !", message: messageText, timestamp: currentDateTime})
    console.log()
    if ( rethrow ) {
      throw(e)
    }
  }

  public static getErrors() : ErrorLog[] {
    return ErrorLog.records.reverse();
  }

}