
  import { reactive } from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  
  export default {
    name: "general-settings-component",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
    setup() {
      const request = reactive({
        apiLoadMethod: "api/AnonymousForm/loadForm",
        apiSaveMethod: "api/AnonymousForm/saveForm",
        form: "GenerelleEinstellungen",
        page: "GenerelleEinstellungenDaten",
        remoteId: "",
        waitForResponse: true
        
  
      });
  
      return {
        request
      };
    }
  };
  