import { DynamicRecord } from '@/components/Shared/Dynamicform/DynamicFormData';

export type DynamicLoadFormData = {
    form: string,
    page: string,
    filter: string
    route: string
    mode: string,
    geoDisplayClass: string
    records: DynamicRecord[],
    remoteId: string
}

export class DynamicLoadFromListData {

    private static data: DynamicLoadFormData | undefined

    public static storeDynamicData( formData : DynamicLoadFormData ) : void {
        DynamicLoadFromListData.data = formData
    }

    public static getDynamicData() : DynamicLoadFormData | undefined {
        return DynamicLoadFromListData.data       
    }

    public static reset() {
        DynamicLoadFromListData.data = undefined
    }

}




