import { DynamicFormData, DynamicDataSet, DynamicRecord } from './DynamicFormData';
import { StateNavigationAction } from "../../../business/application/statemachine/StateInterfaces";
import { Commands } from '../../../business/application/commands/Command';
import { openDialog } from '@/infrastructure/observables/ModalDialogNotification';
import { DynamicFormCommandHandler } from './DynamicFormCommandHandler';
import { changeNavigation } from '@/infrastructure/observables/NavStateNotification';
import { StateMachineServiceLocator } from '../../../business/application/statemachine/StateMachine';
import { LocalStore } from "@/infrastructure/local-api/LocalStore"
import { FishingEdit } from '@/components/fishing/business/FishingEdit';
import { DynamicCloseForm } from './hooks/CloseForm';
import { DynamicFormBusinessCases } from './DynamicFormBusinessCases';
import { Out } from '@/infrastructure/frontent/clientMessage';
import { Resource } from '@/infrastructure/resource/resource';
import { SyncMassData } from '@/infrastructure/serviceworker-related/SyncMassData';
import { deleteDatabase, deleteUserContent } from '@/infrastructure/database/asyncDb';
import { LoaderStateServiceForRoot } from '@/infrastructure/observables/loaderState';
import { LocalWarenkorb } from '@/components/webshop/LocalWarenkorb';
import {v4 as uuidv4} from 'uuid';
import { NavigateDynamicForm } from '../dynamicNavigation/Hooks/NavigateDynamicForm';
import { RemoteWarenKorb, SaveProductResult } from '@/components/webshop/RemoteWarenKorb';
import { NotificationStateServiceForRoot } from '@/infrastructure/observables/notficationState';
import { UserProfile } from '@/infrastructure/backend/userProfile';
import { StateLogin } from '@/business/application/statemachine/concrete/StateLogin';
import { RefreshServerObjects } from '@/infrastructure/resource/RefreshServerObjects';
import { NamedListItem } from '@/infrastructure/generics/namedListItem';
import { UserRegistration } from '@/components/registration/userRegistration';
import { LocalVerificationStore } from '@/infrastructure/local-api/LocalVerificationCodeStore';
import { DynamicGridRequest } from '../dynamicList/DynamicListTypes';
import { Identity } from '@/infrastructure/backend/userIdentity';
import { getDynamicGridFromApi } from '@/infrastructure/serviceworker-related/DynamicGridServiceWorker';
import { AppLanguage } from '@/infrastructure/backend/AppLanguage';

type EmailDialogType = "ResendPasswordTicket" | "ResendVerificationTicket" |
                      "SendPasswordTicket" | "SendVerificationTicket"  | "SendUserRegistrationVerificationTicket" | "FishingNotOnSameDay"


export class DynamicFormNavigation {

    private formCommands = new DynamicFormCommandHandler();
    private stateMachine = StateMachineServiceLocator.get();
    private userProfile = new UserProfile()


    public async navigate(store: any, formDataset: DynamicDataSet,  dynamicData: DynamicFormData, nav: any, clientRouteName: string) : Promise<DynamicDataSet> {
      const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        try {
          if ( !LocalStore.IsFormNavigation())
          return formDataset
        } catch(e) {
          Out.noOperation("")
        }
      
        let navigation = StateNavigationAction.NotDefined;
        const conditions:any = [];
        switch (nav.command) {

          case Commands.Products.toString(): {
            this.navigateOnMenu(store,"Products","Products")
            break;
          }

          case Commands.Fischbuch.toString(): {
            this.navigateOnMenu(store,"FishBook","FishBook")
            break;
          }

          case Commands.Wildbuch.toString(): {
            this.navigateOnMenu(store,"Wildbuch","Wildbuch")
            break;
          }

          case Commands.RuleStartFishing.toString(): {
            await this.formCommands.ruleStartFishing(formDataset, dynamicData)
            break;
          }
          case Commands.RuleStopFishing.toString(): {
            await this.formCommands.ruleStopFishing(formDataset, dynamicData)
            break;
          }

          case Commands.RuleDynamicSave.toString(): {
            formDataset = await this.formCommands.saveForm(formDataset,dynamicData, clientRouteName);
            break;
          }

          case Commands.RuleDynamicChange.toString(): {
            formDataset = await this.formCommands.ruleDynamicChange(formDataset,dynamicData);
            break;
          }

          case Commands.RuleDynamicShouldDelete.toString(): {
            formDataset = await this.formCommands.ruleDynamicShouldDelete(formDataset,dynamicData);
            break;
          }

          case Commands.RuleDynamicDelete.toString(): {
            formDataset = await this.formCommands.ruleDynamicDelete(formDataset,dynamicData);
            break;
          }


          case Commands.NextPage.toString(): {
            // if ( formDataset.view && formDataset.view.length > 0 && formDataset.viewId && formDataset.viewId.indexOf("WildbuchEintrag") >=0 ) {
            //   let maxAnzahl = 0
            //   for(const row of formDataset.view) {
            //     if(row.tabellenFeldname && row.tabellenFeldname === "FkTierart" && row.value && row.value.length > 0 
            //     && row.namedValueListOrigin && row.namedValueListOrigin) {
            //       for (const item of row.namedValueListOrigin as any ) {
            //         if(item.key === row.value && item.typ )  {
            //           console.log(item.value)
            //           console.log(item.typ)
            //           if( item.typ === "false") {
            //             maxAnzahl = 1
            //             break
            //           }
            //         }
            //       }


            //       break
            //     }
            //   }
           
            //   for(const row of formDataset.view) {
            //     if(row.tabellenFeldname && row.tabellenFeldname === "AnzahlTiere") {
            //       row.maxLaenge = maxAnzahl
            //       console.log(row.maxLaenge)
            //     }
            //   }
              

            // }

            // xxx xxx xxx @@@ WildbuchEintragLokalisierungJagd  WildbuchEintragJagd


            formDataset = this.formCommands.nextPage(formDataset, dynamicData, conditions);
            if (formDataset.tempTaskResult?.isSucceeded) {    
              if ( formDataset.viewId === "WildbuchEintragFallwild" ||  formDataset.viewId === "WildbuchEintragJagd") {
                await this.formCommands.manageZusatzInfo(store)
              }   
              navigation = StateNavigationAction.Next;
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
          case Commands.PreviousPage.toString(): {
            formDataset = this.formCommands.previousPage(formDataset, dynamicData, conditions);
            if (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Previous;
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }

          
         

          case Commands.Information.toString(): {
            navigation = StateNavigationAction.Jump;
            const content =  await Resource.getResourceText(`InfoText.${formDataset.viewId}.Content`)
            const title =  await Resource.getResourceText(`InfoText.${formDataset.viewId}.Title`)
            openDialog(store, {name: "Information", isOk: true, isCancel: false, titel:title, text:content, open: true, type: "info"})
            break;
          }
          case Commands.Pdf.toString(): {
            navigation = StateNavigationAction.Jump;
            const content =  await Resource.getResourceText(`Pdf.Print.Content`)
            const title =  await Resource.getResourceText(`Pdf.Print.Title`)
            openDialog(store, {name: "Druck", isOk: true, isCancel: false, titel: title, text: content, open: true, type: "info"})
            break;
          }
          case Commands.RuleRegisterLogin.toString(): {
            formDataset = await this.formCommands.ruleNavigateRegisterUser(formDataset,dynamicData,store);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RuleRegisterUserCheck", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
          case Commands.RuleUserProfile.toString(): {
            formDataset = await this.formCommands.ruleNavigateUserProfile(formDataset,dynamicData,store);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RuleUserProfile", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
    
          case Commands.RuleEmailVerificationRequest.toString(): {
            formDataset = await this.formCommands.ruleEmailVerificationRequest (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RuleEmailVerificationRequest", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
          case Commands.RuleEmailVerification.toString(): {
            formDataset = await this.formCommands.ruleEmailVerification (formDataset,dynamicData,store);
            if  (formDataset.tempTaskResult?.isSucceeded) {              
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RuleEmailVerification", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
    
          //**************************************** 
          // Password vergessen Email Code Dialog
          case Commands.RulePasswordEmailRequest.toString(): {
            formDataset = await this.formCommands.rulePasswordEmailRequest (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RulePasswordEmailRequest", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
              await this.showCodeSendDialog("SendPasswordTicket")
            }
            break;
          }
          case Commands.RulePasswordEmail.toString(): {
            formDataset = await this.formCommands.rulePasswordEmail (formDataset,dynamicData,store);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RulePasswordEmail", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
          case Commands.RulePasswordRequest.toString(): {
            formDataset = await this.formCommands.rulePasswordRequest (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RulePasswordRequest", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
    
        

          case Commands.RulePassword.toString(): {
            formDataset = await this.formCommands.rulePassword (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {   
              loaderState.changeLoaderState({isInProgress: true})              
              await SyncMassData.load()
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RulePassword", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }

    
          case Commands.RulePasswordForAuthenticatedUser.toString(): {
            formDataset = await this.formCommands.rulePasswordForAuthenticatedUser (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RulePasswordForAuthenticatedUser", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
    
          //**************************************** 
          //__ Benutzerdaten  ueberpruefen wenn noch kein App User Email Code und Dialog
          //__ Datenschutzbestimmungen ueberpruefen Email Code und Dialog
          //__ Benutzerregistrierung neu  Email Code und Dialog
           case Commands.RuleSendEmailActivateNewUser.toString(): {
            formDataset = await this.formCommands.ruleSendMailActivateNewUser (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Next;
              //conditions.push({nameId: "RuleSendEmailActivateNewUser", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
              await this.showCodeSendDialog("SendUserRegistrationVerificationTicket") 
              
            }
            break;
          }
    
          
           case Commands.RuleActivateNewUser.toString(): {
            formDataset = await this.formCommands.ruleActivateNewUser (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              loaderState.changeLoaderState({isInProgress: true})   
              await deleteUserContent()
              await SyncMassData.load()
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RuleActivateNewUser", value: "RuleActivateNewUser"})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
    
          case Commands.RuleActivateNewUserForAuthenticatedUser.toString(): {
            formDataset = await this.formCommands.ruleActivateNewUserForAuthenticatedUser(formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              loaderState.changeLoaderState({isInProgress: true})   
              //await deleteUserContent()
              await SyncMassData.load()
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RuleActivateNewUserForAuthenticatedUser", value: formDataset.state})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }
    
          case Commands.RuleSendEmail.toString(): {
            formDataset = await this.formCommands.ruleEmailRequest (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RuleEmailRequest", value: "RuleEmailRequest"})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }          

          case Commands.RuleEmail.toString(): {
            formDataset = await this.formCommands.ruleEmail (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              navigation = StateNavigationAction.Jump;
              conditions.push({nameId: "RuleEmail", value: "RuleEmail"})
              this.navigateOnCommand(store,conditions,navigation)
            }
            break;
          }          
    
          case Commands.SaveAndJump.toString(): {
            if(formDataset.view && ( formDataset.viewId === "GenerelleEinstellungen" || formDataset.viewId === "AppEinstellungen")) {
              loaderState.changeLoaderState({isInProgress: true})         
              let language: undefined | string = undefined
              for(const row of formDataset.view) {
                if( row.formularFeldname === "Sprache") {
                  if( row.value ) {
                    language = row.value
                  }
                }
              }
              if(language &&  (await UserProfile.GetClientSettingsAsync()).language !== language) {
                await AppLanguage.changeLanguage(language)
              }


              navigation = StateNavigationAction.Jump;
              this.navigateOnCommand(store,conditions,navigation)
              loaderState.changeLoaderState({isInProgress: false})         
            } else {
              formDataset = await this.formCommands.saveForm(formDataset,dynamicData, clientRouteName);
              if (formDataset.tempTaskResult?.isSucceeded) {
                navigation = StateNavigationAction.Jump;
                this.navigateOnCommand(store,conditions,navigation)
              }
            }
            break;
          }
          case Commands.Jump.toString(): {
            navigation = StateNavigationAction.Jump;
            this.navigateOnCommand(store,conditions,navigation)
            break;
          }
          case Commands.Save.toString(): {
            formDataset = await this.formCommands.saveForm(formDataset,dynamicData, clientRouteName);
            break;
          }

          case Commands.RuleUserProfileShowPasswordEmail.toString(): {
            this.formCommands.ruleUserProfileShowPasswordEmail(formDataset,dynamicData);
            break
          } 
          case Commands.RuleUserProfileSavePasswordEmail.toString(): {
            this.formCommands.ruleUserProfileSavePasswordEmail(formDataset,dynamicData);            
            break
          } 

          case Commands.RuleSendPasswordTicket.toString(): {
            const userRegistration: UserRegistration = new UserRegistration()
            const verificationData = LocalVerificationStore.LoadVerificationData()
            if ( verificationData.email !== "" && verificationData.email.indexOf("@") > 0 ) {
              userRegistration.CreateUserPasswordEmailTicket(verificationData.email) 
              await this.showCodeSendDialog("SendPasswordTicket")
            }
            break
          }

          case Commands.RuleSendActivationTicket.toString(): {
            const userRegistration: UserRegistration = new UserRegistration()
            const verificationData = LocalVerificationStore.LoadVerificationData()
            //if ( verificationData.email !== "" && verificationData.email.indexOf("@") > 0 )
              userRegistration.CreateUserActivationTicket(verificationData.email)
              await this.showCodeSendDialog("SendVerificationTicket")
            break
          }
        }
    
        
        return formDataset
      }

      private navigateOnMenu(store:any, nameId: string, newMainState: string) {
        this.stateMachine.next(
          {
            navigation: StateNavigationAction.Jump,
            conditions: [{ nameId: nameId, value: newMainState }],
          },
          { lookahead: false }
        );
        changeNavigation(store);
      }

      private navigateOnCommand(store:any, conditions: any , navigation: StateNavigationAction) {
        if ( navigation === StateNavigationAction.Previous) {
          this.stateMachine.previous(
            { navigation: navigation,conditions: conditions},
            { lookahead: false}
          );
          changeNavigation(store);
          return
        }

        this.stateMachine.next(
          { navigation: navigation, conditions: conditions },
          { lookahead: false }
        );
        changeNavigation(store);
      }
      
      private navigateOnActionButton(store:any, condition: any, ruleId: string ) {
        const navigation = StateNavigationAction.Next;
        const conditions = [];
        conditions.push({ nameId: "*", value: condition });
        this.stateMachine.next(
          {
            navigation: navigation,
            conditions: conditions
          },
          {
            lookahead: false
          }
        );
        changeNavigation(store);
      }
      
      public async actionButton(store: any, row: DynamicRecord, formDataset: DynamicDataSet,  dynamicData: DynamicFormData, clientRouteName: string) {
        const service = DynamicCloseForm.getService()
        const fishingEditState = FishingEdit.getEditFishingState()
        const addFishingPrefixFormName = "FischbuchEintrag"
        const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        const dynamicNavigation = NavigateDynamicForm.getService()
        const notficationState = NotificationStateServiceForRoot.getNotificationStateService()   
        const userProfile = new UserProfile()
        let result: SaveProductResult | string = ""
        const fishingAllowed = FishingEdit.isFishingOnSameDay()
        
        const userRegistration: UserRegistration = new UserRegistration()


        switch (row.formularFeldname) {

          case "ZurKasse":
            if( !this.formCommands.isLocalPageValid(formDataset,dynamicData))
              return

              if( ! (await (userProfile.GetProfileData())).online) {
                const offlineTextTitle = await Resource.getResourceText(`Offline.NotAllowed.Title`)
                const offlineTextContent = await Resource.getResourceText(`Offline.NotAllowed.Content`)
                const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
                notficationState.changeNotificationState({isNotification: true, text:  offlineTextContent, title: offlineTextTitle})
                return
              }

            loaderState.changeLoaderState({isInProgress: true})
            result = await RemoteWarenKorb.createWebShopProducts()
            if (!RemoteWarenKorb.isValid(result)) {
             
              loaderState.changeLoaderState({isInProgress: false})
              notficationState.changeNotificationState({
              isNotification: true, text: RemoteWarenKorb.getProofMessage(result) , title: await Resource.getResourceText("WebShop.Proof.Failed.Title")})
              break
            }
            //loaderState.changeLoaderState({isInProgress: false})
            this.navigateOnActionButton(store, "WebShopKasse", "*");
            break;


          case "RuleDynamicFilter" : {
            await this.formCommands.ruleDynamicFilter(formDataset, dynamicData)
            break;
          }

          case "RuleStartFishing": {
            await this.formCommands.ruleStartFishing(formDataset, dynamicData)
            break;
          }
          case "RuleStopFishing": {
            await this.formCommands.ruleStopFishing(formDataset, dynamicData)
            break;
          }

          case "RuleDynamicChange" : {
             await this.formCommands.ruleDynamicChange(formDataset,dynamicData);
             break;
          }

          case "RuleDynamicSave":
            formDataset = await this.formCommands.saveForm(formDataset,dynamicData, clientRouteName);
            if (formDataset.tempTaskResult?.isSucceeded) {
              if (clientRouteName === "WebShopDaten") {
                const id = uuidv4()
                await LocalWarenkorb.AddProductItem(id , formDataset.view)
                const result = await RemoteWarenKorb.proofWebShopProducts()
                if (!RemoteWarenKorb.isValid(result)) {
                  const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
                  notficationState.changeNotificationState({
                    isNotification: true, text: RemoteWarenKorb.getProofMessage(result) , title: await Resource.getResourceText("WebShop.Proof.Failed.Title")})
                    await LocalWarenkorb.DeleteProductItem(id)
                    break
                }
                dynamicNavigation.navigate({direction: "from", action: "RuleDynamicSave", result: true,  page: undefined})
                dynamicNavigation.reset()
              }
              this.navigateOnActionButton(store,"SaveAndJump", "SaveAndJump");   
              service.changeFormState({close: true})
              service.changeFormState({close: false})
             }
            break;
          case "RuleDynamicShouldDelete" : {
            await this.formCommands.ruleDynamicShouldDelete(formDataset,dynamicData);
            break;
         }
         case "RuleDynamicDelete" : {
            await this.formCommands.ruleDynamicDelete(formDataset,dynamicData);
            const business = new DynamicFormBusinessCases()
            const parentChild = await business.deleteDynamicGridRow(formDataset, clientRouteName, formDataset.id ?? "")
            // if ( clientRouteName.indexOf("WildBook") >= 0)
            //   this.navigateOnActionButton(store,"SaveAndJump", "SaveAndJump");  
            service.changeFormState({close: true})
            service.changeFormState({close: false})
          break;
          }

          case "RuleFischfangEintrag":
            
            if ( ! fishingAllowed ) {
              this.showCodeSendDialog("FishingNotOnSameDay")
              return
            }
            formDataset = await this.formCommands.ruleFischfangEintrag (formDataset,dynamicData,"",addFishingPrefixFormName + fishingEditState.patent!.kategorie, "");
            if  (formDataset.tempTaskResult?.isSucceeded) {
              this.navigateOnActionButton(store,addFishingPrefixFormName +  fishingEditState.patent!.kategorie, addFishingPrefixFormName +  fishingEditState.patent!.kategorie);  
            }
            break;

          case "RuleActivateNewUser":
            formDataset = await this.formCommands.ruleActivateNewUser (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              loaderState.changeLoaderState({isInProgress: true})   
              await deleteUserContent()
              await SyncMassData.load()
              this.navigateOnActionButton(store,"RuleActivateNewUser", "RuleActivateNewUser");              
            }
            break

           case "RuleActivateNewUserForAuthenticatedUser": 
           formDataset = await this.formCommands.ruleActivateNewUser (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              loaderState.changeLoaderState({isInProgress: true})   
              //await deleteUserContent()
              await SyncMassData.load()
              this.navigateOnActionButton(store,"RuleActivateNewUserForAuthenticatedUser", "RuleActivateNewUserForAuthenticatedUser");              
            }
            break

          //**************************************** 
          //__ Benutzerdaten  ueberpruefen wenn noch kein App User Email Code und Dialog
          //__ Datenschutzbestimmungen ueberpruefen Email Code und Dialog
          //__ Benutzerregistrierung neu  Email Code und Dialog
          case "RuleSendEmailActivateNewUser":
            formDataset = await this.formCommands.ruleSendMailActivateNewUser (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {
              this.navigateOnActionButton(store,"RuleSendEmailActivateNewUser", "RuleSendEmailActivateNewUser");  
              await this.showCodeSendDialog("SendUserRegistrationVerificationTicket")                         
            }
            break
         
       
          case "BenutzerSpeichern":
          case "SaveAndJump":
            if(formDataset.view && (formDataset.viewId === "GenerelleEinstellungen") || formDataset.viewId === "AppEinstellungen") {
              loaderState.changeLoaderState({isInProgress: true})         
              let language: undefined | string = undefined
              for(const row of formDataset.view) {
                if( row.formularFeldname === "Sprache") {
                  if( row.value ) {
                    language = row.value
                  }
                }
              }
              if(language &&  (await UserProfile.GetClientSettingsAsync()).language !== language) {
                await AppLanguage.changeLanguage(language)
              }
              this.navigateOnActionButton(store,"SaveAndJump", "SaveAndJump");  
              loaderState.changeLoaderState({isInProgress: false})          
            } else {
              formDataset = await this.formCommands.saveForm(formDataset,dynamicData, clientRouteName);
              if (formDataset.tempTaskResult?.isSucceeded) {
                this.navigateOnActionButton(store,"SaveAndJump", "SaveAndJump");   
              }
          }
            break;
          case "RulePassword":
            formDataset = await this.formCommands.rulePassword (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {    
              loaderState.changeLoaderState({isInProgress: true})            
              await SyncMassData.load()
              this.navigateOnActionButton(store,"RulePassword", formDataset.state);  
              loaderState.changeLoaderState({isInProgress: false})   
            }
            break;
          
          //**************************************** 
          // Password vergessen Email Code Dialog
          case "RulePasswordEmailRequest":
            formDataset = await this.formCommands.rulePasswordEmailRequest (formDataset,dynamicData);
            if  (formDataset.tempTaskResult?.isSucceeded) {          
              this.navigateOnActionButton(store,"RulePasswordEmailRequest", formDataset.state);  
              await this.showCodeSendDialog("SendPasswordTicket")
            }
            break;            
            case "RuleEmail":
              formDataset = await this.formCommands.ruleEmail (formDataset,dynamicData);
              if  (formDataset.tempTaskResult?.isSucceeded) {
                this.navigateOnActionButton(store,"RuleEmail", "RuleEmail");  
              }
              break;
            case "RuleEmailRequest":
              formDataset = await this.formCommands.ruleEmailRequest (formDataset,dynamicData);
              if  (formDataset.tempTaskResult?.isSucceeded) {          
                this.navigateOnActionButton(store,"RuleEmailRequest", "RuleEmailRequest");  
              }
              break;
            case "RuleUserProfileShowPasswordEmail": 
              this.formCommands.ruleUserProfileShowPasswordEmail(formDataset,dynamicData);
              break;    
            case "RuleUserProfileSavePasswordEmail": 
              this.formCommands.ruleUserProfileSavePasswordEmail(formDataset,dynamicData);
              break;   
              
            case "RuleSendPasswordTicket": {
              const verificationData = LocalVerificationStore.LoadVerificationData()
              if ( verificationData.email !== "" && verificationData.email.indexOf("@") > 0 ) {
                userRegistration.CreateUserPasswordEmailTicket(verificationData.email) 
                await this.showCodeSendDialog("ResendPasswordTicket")
              }
              break
            }
  
            
            case "RuleSendActivationTicket": {
               const verificationData = LocalVerificationStore.LoadVerificationData()
              //if ( verificationData.email !== "" && verificationData.email.indexOf("@") > 0 )
                userRegistration.CreateUserActivationTicket(verificationData.email)
                await this.showCodeSendDialog("ResendVerificationTicket")
              break
            }
                  

          // case "BenutzerSpeichern":
          //   this.navigateOnActionButton(store, "SaveAndHome", "*");
          //   break;
          case "JagdDatenSpeichern":
            this.navigateOnActionButton(store, "WildBook", "*");
            break;
          case "FallwildDatenSpeichern":
            this.navigateOnActionButton(store, "WildBook", "*");
            break;
          case "BeobachtungDatenSpeichern":
            this.navigateOnActionButton(store, "WildBook", "*");
            break;
          case "BeobachtungSpezDatenSpeichern":
            this.navigateOnActionButton(store, "WildBook", "*");
            break;
          case "FilterAufrufWildbuch":
            this.navigateOnActionButton(store, "WildBookFilter", "*");
            break;
          case "FilterAnwendenWildbuch":
            this.navigateOnActionButton(store, "WildBookFilterRequest", "*");
            break;
          case "FischfangSpeichern":
            this.navigateOnActionButton(store, "FishBookFilter", "*");
            break;
          case "FilterAufrufFischbuch":
            this.navigateOnActionButton(store, "FishBookFilter", "*");
            break;
          case "FilterAnwendenFischbuch":
            this.navigateOnActionButton(store, "FishBookFilterRequest", "*");
            break;
          case "Abbrechen":
            this.navigateOnActionButton(store, "WebShopWarenKorb", "*");
            break;
          case "KartenAuswahl":
            this.navigateOnActionButton(store, "WebShopKasse", "*");
            break;
          case "FilterAufrufWebshop":
            this.navigateOnActionButton(store, "WebShopFilter", "*");
            break;
          case "FilterAnwendenWebshop":
            this.navigateOnActionButton(store, "WebShopFilterRequest", "*");
            break;
          case "AbbrechenBezahlen":
            this.navigateOnActionButton(store, "WebShopKasse", "*");
            break;
          case "Bezahlen":
            this.navigateOnActionButton(store, "BackToWebShop", "*");
            break;
        }
      }

      public async showCodeSendDialog( dialogMessageType: EmailDialogType ) : Promise<void> {
      
        let verificationCodeContent = ""
        let verificationCodeTitle = ""

        switch (dialogMessageType) {
          case 'ResendPasswordTicket': {
            verificationCodeContent =  await Resource.getResourceText(`Email.Verification.ResendPasswordCode.Content`)
            verificationCodeTitle =  await Resource.getResourceText(`Email.Verification.ResendPasswordCode.Titel`)    
            break
          }
          case 'ResendVerificationTicket': {
            verificationCodeContent =  await Resource.getResourceText(`Email.Verification.ResendCode.Content`)
            verificationCodeTitle =  await Resource.getResourceText(`Email.Verification.ResendCode.Titel`)    
            break
          }
          case 'SendVerificationTicket': {
            verificationCodeContent =  await Resource.getResourceText(`Email.Verification.SendCode.Content`)
            verificationCodeTitle =  await Resource.getResourceText(`Email.Verification.SendCode.Titel`)    
            break
          }
          case 'SendUserRegistrationVerificationTicket': {
            verificationCodeContent =  await Resource.getResourceText(`Email.RegistrierungVerification.SendCode.Content`)
            verificationCodeTitle =  await Resource.getResourceText(`Email.RegistrierungVerification.SendCode.Titel`)    
            break
          }
          case 'SendPasswordTicket': {
            verificationCodeContent =  await Resource.getResourceText(`Email.Verification.SendPasswordCode.Content`)
            verificationCodeTitle =  await Resource.getResourceText(`Email.Verification.SendPasswordCode.Titel`)    
            break
          }
          case 'FishingNotOnSameDay': {
            verificationCodeContent =  await Resource.getResourceText(`Fishing.FishingNotOnSameDay.Content`)
            verificationCodeTitle =  await Resource.getResourceText(`Fishing.FishingNotOnSameDay.Titel`)    
            break
          }
        }

 
        const verificationData = LocalVerificationStore.LoadVerificationData()
        if ( verificationData && verificationData.email && verificationData.email.length > 0 && verificationCodeContent) {
          verificationCodeContent = verificationCodeContent.replace("@Email@", verificationData.email)
        }

        if ( verificationCodeContent ) {
          verificationCodeContent = verificationCodeContent.replace("@Email@", "")
        }

        const notficationState = NotificationStateServiceForRoot.getNotificationStateService()  
        notficationState.resetNotificationState()   
        notficationState.changeNotificationState({isNotification: true, text:  verificationCodeContent, title: verificationCodeTitle})
        

      }


      
}

