import { AsyncCommand } from "../Command";
import { DynamicFormData,DynamicDataSet} from "@/components/Shared/Dynamicform/DynamicFormData";
import { UserRegistration } from '@/components/registration/userRegistration';


export class RuleActivateNewUser extends AsyncCommand {
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  private clientRouteName: string
  
  constructor( dynamicForm: DynamicFormData, dataSet: DynamicDataSet, clientRouteName: string) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;    
    this.clientRouteName = clientRouteName;
  }

  public async asyncExecute(): Promise<any> {
    const result = await this.dynamicForm.saveRemoteForm( this.dataSet.apiSaveMethod ?? "", this.dataSet.viewId, ["*"], this.clientRouteName)
    if ( result && result.isError)
      return
    return result;
  }
}
