
import { reactive, ref } from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
export default {
  name: "RegistrationWaitForConfirmation",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "api/anonymousForm/saveForm",
      form: "RegistrierungBenutzer",
      page: "RegistrierungBestaetigen",
      remoteId: "",
      waitForResponse: true
      
    });

   return {
      request,
    };
  }
};
