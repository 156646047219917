import { AsyncCommand } from "../Command";
import { DynamicDataSet,DynamicFormData,} from "@/components/Shared/Dynamicform/DynamicFormData";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { UserRegistration } from "@/components/registration/userRegistration";

export class RuleRegisterFormUserCheck extends AsyncCommand {
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  private store: any;

  constructor(
    dynamicForm: DynamicFormData,
    dataSet: DynamicDataSet,
    store: any
  ) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;
    this.store = store;
  }

  public async asyncExecute(): Promise<any> {
    const userProfile: UserProfile = new UserProfile()
    const userRegistration: UserRegistration = new UserRegistration()
    const email = this.dynamicForm.getValue("Email")
    const password = this.dynamicForm.getValue("Password")
    const vorname = this.dynamicForm.getValue("Vorname")
    const nachname = this.dynamicForm.getValue("Nachname")
    const geburtsDatum = this.dynamicForm.getValue("Geburtsdatum")
    const benutzerName = this.dynamicForm.getValue("EmailAdresse")
    
    
    const result = await userRegistration.AccountMigration(email,password,vorname,nachname,geburtsDatum, benutzerName)        
    return result;  
  }
}
