
import iconbutton from "./IconNavigation.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { reactive } from "vue";
import { Out } from "@/infrastructure/frontent/clientMessage";
import { disableButtonTime } from "@/business/AppConst";
import { DynamicNavigation } from "@/components/Shared/dynamicNavigation/DynamicNavigation";
import { NotifyIconBar } from "./dynamicNavigation/Hooks/NotifyIconBar";
import { NotifyDynamicNavigation } from "./dynamicNavigation/Hooks/NotifyDynamicNavigation";
import { StateResult } from "@/business/application/statemachine/StateInterfaces";
import { Resource } from "@/infrastructure/resource/resource";

export default {
  name: "IconNavigationBar",
  inheritAttrs: false,
  components: {
    iconbutton,
  },

  setup() {
    const store = useStore();
    let unsubscribe:any = {};

    const disabledNavigationButton = ref<boolean>(false)
    let navigationDiasabledTimer = 10
    
    const notifyDynamicNavigation = NotifyDynamicNavigation.getService()
    const notifyIconBarService =  NotifyIconBar.getService()
    const onNotificationIconBar = notifyIconBarService.subscription().subscribe({
      next: (value) => {
        if ( ! value.NavigationItem ) return
          const t = store.getters["uistatestore/getStateSet"] as StateResult;
          if ( t.navigation) {

            t.navigation.nav1.command = value.NavigationItem.navigationButtons[0].command
            t.navigation.nav1.displayName = value.NavigationItem.navigationButtons[0].displayName
            t.navigation.nav1.enabled = value.NavigationItem.navigationButtons[0].enabled
            t.navigation.nav1.existent = value.NavigationItem.navigationButtons[0].existent
            t.navigation.nav1.imageUrl = value.NavigationItem.navigationButtons[0].imageUrl
            t.navigation.nav1.visible = value.NavigationItem.navigationButtons[0].visible

            t.navigation.nav2.command = value.NavigationItem.navigationButtons[1].command
            t.navigation.nav2.displayName = value.NavigationItem.navigationButtons[1].displayName
            t.navigation.nav2.enabled = value.NavigationItem.navigationButtons[1].enabled
            t.navigation.nav2.existent = value.NavigationItem.navigationButtons[1].existent
            t.navigation.nav2.imageUrl = value.NavigationItem.navigationButtons[1].imageUrl
            t.navigation.nav2.visible = value.NavigationItem.navigationButtons[1].visible
          
            t.navigation.nav3.command = value.NavigationItem.navigationButtons[2].command
            t.navigation.nav3.displayName = value.NavigationItem.navigationButtons[2].displayName
            t.navigation.nav3.enabled = value.NavigationItem.navigationButtons[2].enabled
            t.navigation.nav3.existent = value.NavigationItem.navigationButtons[2].existent
            t.navigation.nav3.imageUrl = value.NavigationItem.navigationButtons[2].imageUrl
            t.navigation.nav3.visible = value.NavigationItem.navigationButtons[2].visible
            

            t.navigation.nav4.command = value.NavigationItem.navigationButtons[3].command
            t.navigation.nav4.displayName = value.NavigationItem.navigationButtons[3].displayName
            t.navigation.nav4.enabled = value.NavigationItem.navigationButtons[3].enabled
            t.navigation.nav4.existent = value.NavigationItem.navigationButtons[3].existent
            t.navigation.nav4.imageUrl = value.NavigationItem.navigationButtons[3].imageUrl
            t.navigation.nav4.visible = value.NavigationItem.navigationButtons[3].visible
          }

          store.commit("uistatestore/setStateSet", t);
      }
    })

    
    let buttonState1 = reactive(
      store.getters["uistatestore/getStateSet"].navigation.nav1
    );
    let buttonState2 = reactive(
      store.getters["uistatestore/getStateSet"].navigation.nav2
    );
    let buttonState3 = reactive(
      store.getters["uistatestore/getStateSet"].navigation.nav3
    );
    let buttonState4 = reactive(
      store.getters["uistatestore/getStateSet"].navigation.nav4
    );

    onMounted(() => {

      disabledNavigationButton.value = true
          navigationDiasabledTimer = setTimeout( ()=> {
            disabledNavigationButton.value = false
          }, disableButtonTime)

      unsubscribe = store.subscribe((mutation) => {
        if ( !DynamicNavigation.isNavigationEnabled()) {
          DynamicNavigation.saveLastStaticNavigationState([buttonState1,buttonState2,buttonState3,buttonState4])
        }

        if (!DynamicNavigation.isAssigned("IconNavigationBar")) return

        if (mutation.type === "uistatestore/setStateSet") {
          buttonState1 =
            store.getters["uistatestore/getStateSet"].navigation.nav1;
          buttonState2 =
            store.getters["uistatestore/getStateSet"].navigation.nav2;
          buttonState3 =
            store.getters["uistatestore/getStateSet"].navigation.nav3;
          buttonState4 =
            store.getters["uistatestore/getStateSet"].navigation.nav4;

        }
      });
    });

    onUnmounted(() => {
      unsubscribe()
      onNotificationIconBar.unsubscribe()
      try {
        clearTimeout( navigationDiasabledTimer )
      } catch(e) {
        Out.noOperation("");
      }
    });

    function buttonState1Command() {
      if (
        buttonState1.existent &&
        buttonState1.enabled &&
        buttonState1.routeName !== ""
      ) {
          disabledNavigationButton.value = true
          navigationDiasabledTimer = setTimeout( ()=> {
            disabledNavigationButton.value = false
          }, disableButtonTime)

        if ( DynamicNavigation.isNavigationEnabled()) {
          notifyDynamicNavigation.buttonClicked({ButtonClicked: 1})
          notifyDynamicNavigation.reset()
          return
        }

        store.commit("navigationState/clickNavigationItem1", { clicked: true });
      }
    }
    function buttonState2Command() {
      if (
        buttonState2.existent &&
        buttonState2.enabled &&
        buttonState2.routeName !== ""
      ) {
        

          if ( DynamicNavigation.isNavigationEnabled()) {
            notifyDynamicNavigation.buttonClicked({ButtonClicked: 2})
            notifyDynamicNavigation.reset()
          return
        }


        store.commit("navigationState/clickNavigationItem2", { clicked: true });
      }
    }
    function buttonState3Command() {
      if (
        buttonState3.existent &&
        buttonState3.enabled &&
        buttonState3.routeName !== ""
      ) {
       

          if ( DynamicNavigation.isNavigationEnabled()) {
            notifyDynamicNavigation.buttonClicked({ButtonClicked: 3})
            notifyDynamicNavigation.reset()
          return
        }

        store.commit("navigationState/clickNavigationItem3", { clicked: true });
      }
    }
    function buttonState4Command() {
      if (
        buttonState4.existent &&
        buttonState4.enabled &&
        buttonState4.routeName !== ""
      ) {
        
          if ( DynamicNavigation.isNavigationEnabled()) {
            notifyDynamicNavigation.buttonClicked({ButtonClicked: 4})
            notifyDynamicNavigation.reset()
          return
        }

        store.commit("navigationState/clickNavigationItem4", { clicked: true });
      }
    }

    return {
      buttonState1,
      buttonState2,
      buttonState3,
      buttonState4,
      buttonState1Command,
      buttonState2Command,
      buttonState3Command,
      buttonState4Command,
      disabledNavigationButton,
     
    };
  },
};
