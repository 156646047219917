import { DynamicGridRowParentChild } from "../dynamicList/DynamicListTypes"
import { DynamicFormBusinessCasesFishing } from "./DynamicFormBusinessCasesFishing"
import { DynamicFormBusinessCasesHunting } from "./DynamicFormBusinessCasesHunting"
import { DynamicDataSet } from "./DynamicFormData"

export class DynamicFormBusinessCases {

    private fishing: DynamicFormBusinessCasesFishing = new DynamicFormBusinessCasesFishing()
    private hunting: DynamicFormBusinessCasesHunting = new DynamicFormBusinessCasesHunting()

    public async getRemoteFormOverwriteFormWithBusinessCase(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<any> {
        if ( this.isFishBook(reactiveData.viewId))
            return await this.fishing.getRemoteFormOverwriteFormWithBusinessCase(reactiveData,clientRouteName)
        if ( this.isWildBook(reactiveData.viewId))
            return await this.hunting.getRemoteFormOverwriteFormWithBusinessCase(reactiveData,clientRouteName)            
    }

    public async deleteDynamicGridRow(reactiveData: DynamicDataSet, clientRouteName: string, rowId: string) : Promise<DynamicGridRowParentChild | undefined> {
        if ( this.isFishBook(reactiveData.viewId))
            return await this.fishing.deleteDynamicGridRow(reactiveData,clientRouteName, rowId)
         if ( this.isWildBook(reactiveData.viewId))            
            return await this.hunting.deleteDynamicGridRow(reactiveData,clientRouteName, rowId)
        }

    public async saveRemoteFormOverwriteFormWithBusinessCase(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<DynamicGridRowParentChild | undefined> {
        if ( this.isFishBook(reactiveData.viewId))
            return await this.fishing.saveRemoteFormOverwriteFormWithBusinessCase(reactiveData,clientRouteName)
        if ( this.isWildBook(reactiveData.viewId))            
            return await this.hunting.saveRemoteFormOverwriteFormWithBusinessCase(reactiveData,clientRouteName)
    }


    private isFishBook(viewId: string) : boolean {
        switch( viewId) {
            case "HomeFischerei":
                return true
        
            case "FischbuchEintragPachtrevier":
                return true
        
            case "FischbuchEintragPatentrevier":
                return true
            case "FischbuchEintragLinthkanal":
                return true
           }
           return false
    }

    private isWildBook(viewId: string) : boolean {
        switch( viewId) {
            case "WildbuchEintragJagd":
                return true
            case "WildbuchEintragFallwild":
                return true
            case  "WildbuchEintragBeobachtung": 
                return true
            case  "WildbuchEintragBeobachtungSpez": 
                return true
            }
           return false
    }


}