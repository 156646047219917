import { modalddialogstore, ModalDialogParameter } from "@/store/modules/modaldialogstore"

export enum ModalDialogType {
  VersionUpdate = "versionupdate"
}

export function showError(store: any, titel: string,  message: string ) {

  message = "Es ist ein Fehler entstanden. Bitte versuchen Sie es erneut."

  const parameter: ModalDialogParameter = {    
    isCancel: false,
    isOk: true,
    name: "error",
    open: true,
    text: message,
    titel: titel,
    type: "error"
  }  
  store.commit("modalddialogstore/openDialog", parameter);
}

export function getParameter(store: any) : ModalDialogParameter {
  return store.getters["modalddialogstore/getParameter"]
}


export function openDialog(store: any, parameter: ModalDialogParameter ) {
  store.commit("modalddialogstore/openDialog", parameter);
}

export function closeDialog(store: any ) {
  store.commit("modalddialogstore/closeDialog", true);
}

export function click(store: any , val: number ) {
  store.commit("modalddialogstore/click", true);
}

export function reset(store: any , val: number ) {
  store.commit("modalddialogstore/reset");
}

