
  import { reactive , defineComponent} from "vue";
  import {sanitize} from "@/infrastructure/functional/sanitize"
  import { getApplicationConstText } from "@/business/AppConst";
import { MessageBusServiceForRoot } from "@/infrastructure/observables/messageBus";
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
  
  export default defineComponent ({
    name: "NotificationPopup",
    emits: {
        closed: () => true
      },
    props: {  
      isNotification: {
        type: Boolean,
         default: null,
        require: true
      },
    title: {
        type: String,
        default: null,
        require: false
      },
        text: {
        type: String,
        default: null,
        require: false
      }
    },
    inheritAttrs: false,
    components: {},
    setup( props, {emit}) {
  
     

      const navigationState = NotificationStateServiceForRoot.getNotificationStateService()
  
      const notificationParameter: any = reactive({
        isNotification: props.isNotification,        
        title: props.title,
        text: props.text,
        })
    
      function applyOk() {
        notificationParameter.isNotification=false;
        navigationState.resetNotificationState(); 
        emit("closed");
      }
  
      function getText() {
          return sanitize(notificationParameter.text)
      }
  
      function setUserFriendlyText() {      
        notificationParameter.text = `${notificationParameter.text}` 
        }
  
      setUserFriendlyText()
  
      return {    
        applyOk,
        notificationParameter,
        getText
      };
    }
  });
  