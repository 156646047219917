import { UserRegistration } from '../../../../components/registration/userRegistration';
import { UserProfile } from './../../../../infrastructure/backend/userProfile';
import { AsyncCommand } from "../Command";
import {
  DynamicDataSet,
  DynamicFormData,
} from "@/components/Shared/Dynamicform/DynamicFormData";

export class RuleSendMailActivateNewUser extends AsyncCommand {
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;

  constructor(
    dynamicForm: DynamicFormData,
    dataSet: DynamicDataSet,    
  ) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;    
  }

  public async asyncExecute(): Promise<any> {    
    const userRegistration: UserRegistration = new UserRegistration()
    const email = this.dynamicForm.getValue("Email")
    const result = await userRegistration.CreateUserActivationTicket(email)    
    return result;
  }
}
