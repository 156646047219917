
  import { reactive } from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  
  export default {
    name: "password-with-current-email",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
      setup() {    
      const request = reactive({
        apiLoadMethod: "api/anonymousForm/loadForm",
        apiSaveMethod: "",
        form: "PasswordWithCurrentEmail",
        page: "PasswordSaveEmail",
        remoteId: "",
        waitForResponse: true
      });  
      return {
        request,
      };
    }
  };
  