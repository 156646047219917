import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-598fe39a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row g-0" }
const _hoisted_3 = { class: "col d-flex justify-content-center" }
const _hoisted_4 = { class: "row g-0 eFj-list-content" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "row g-0" }
const _hoisted_8 = { class: "col-2 eFj-list-card-image" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "col-3" }
const _hoisted_12 = { class: "row g-0 pr-1" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "row g-0 pr-1" }
const _hoisted_15 = { class: "col eFj-list-card-gueltig" }
const _hoisted_16 = { class: "row g-0" }
const _hoisted_17 = { class: "col eFj-list-card-gueltig" }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dynamic_form = _resolveComponent("dynamic-form")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onClosed()), ["prevent"])),
    class: "eFj-list"
  }, [
    (_ctx.isListVisible())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamic.parentRow.rows, (record, id) => {
                    return (_openBlock(), _createElementBlock("div", { key: id }, [
                      (_ctx.isGridRowVisible(record))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            onClick: _withModifiers(($event: any) => (_ctx.openDetail(record)), ["stop","prevent"]),
                            class: _normalizeClass(["eFj-list-card", _ctx.rowDeleted(record)])
                          }, [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("img", {
                                  class: "eFj-image",
                                  src: (_ctx.getImagePath(record))
                                }, null, 8, _hoisted_9)
                              ]),
                              _createElementVNode("div", {
                                innerHTML: _ctx.getCellTitle(record),
                                class: "col-7 eFj-list-card-text"
                              }, null, 8, _hoisted_10),
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("div", {
                                    innerHTML: _ctx.getCellSubTitle(record),
                                    class: _normalizeClass(["col eFj-list-card-status", record.cell.image.imageState])
                                  }, null, 10, _hoisted_13)
                                ]),
                                _createElementVNode("div", _hoisted_14, [
                                  _createElementVNode("div", _hoisted_15, _toDisplayString(record.cell.additional.gueltigVon), 1)
                                ]),
                                _createElementVNode("div", _hoisted_16, [
                                  _createElementVNode("div", _hoisted_17, _toDisplayString(record.cell.additional.gueltigBis), 1)
                                ])
                              ])
                            ])
                          ], 10, _hoisted_6))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.doNothing && _ctx.doNothing(...args)), ["stop","prevent"])),
            class: "row g-0"
          }, [
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_dynamic_form, _mergeProps({ name: "formDetail" }, _ctx.dynamicDetail), null, 16)
            ])
          ])
        ]))
  ]))
}