import { DynamicDataSet } from "./DynamicFormData";
import { OnlineSearchDataSource } from "./OnlineSearchDataSource";
import { Out } from "@/infrastructure/frontent/clientMessage";

export class DynamicFormOnlineSearchComboBox {
    private search: OnlineSearchDataSource
    
    constructor() {
        this.search = new OnlineSearchDataSource()
    }
    
    public async onlineFilterChanged(ereignis: any, dynamicData: DynamicDataSet): Promise<void> {
        try {   

            let searchAt = 3
            if (ereignis.component.id.toLowerCase().trim() === "land") {
            searchAt = 2
            }

            const datasourceName = ereignis.component.id
            if ( ereignis.filter.value.length < searchAt) {
            for (const row of  dynamicData.view ) {
                if ( row.namedValueListDataSourceName === datasourceName) {                                            
                row.value = ereignis.filter.value   
                }         
            }                
            return        
            }
            
            for (const row of  dynamicData.view ) {
            if ( row.namedValueListDataSourceName === datasourceName) {         
                row.namedValueList = await this.search.SearchListPlzOrt(datasourceName,ereignis.filter.value)
                if (row.namedValueList  ) {              
                row.list = []
                for( const element of row.namedValueList)              
                    row.list.push(element.value)
                }            
                
                if (! row.namedValueList || row.namedValueList.length == 0 ) {              
                row.list = [ereignis.filter.value]
                row.value = ereignis.filter.value   
                }
            }
        }
        } catch(e) {
            Out.error(e)
        }
    }

    public onlineSelectionChanged(dataSourceName: string, dynamicData: DynamicDataSet, value: string ) {
        if( !dataSourceName || dataSourceName.length < 1)
            return

        if ( !dynamicData || !dynamicData.view)
            return

        for (const row of  dynamicData.view ) {   
            if(row.formularFeldname.toLowerCase().trim() === "plz" && dataSourceName.toLowerCase().trim() == "plz" ) {
            this.setOrtsdaten(dynamicData, value)
            }
            if(row.formularFeldname.toLowerCase().trim() === "ort" && dataSourceName.toLowerCase().trim() == "ort" ) {
            this.setOrtsdaten(dynamicData, value)
            }
        }
    }

    private setOrtsdaten(dynamicData: DynamicDataSet, value: string): void {
        if ( ! value)
            return

        const splittedValue = value.split(" ")
        if ( !splittedValue || splittedValue.length < 3)
            return

        if ( splittedValue.length > 3 ) {
            for (let i=2; i < splittedValue.length -1; i++) {
            splittedValue[1] += " " + splittedValue[i]
            }
            splittedValue[2] = splittedValue[splittedValue.length-1]
        }

        for (const row of  dynamicData.view ) {   
            if( row.formularFeldname.toLowerCase().trim() === "plz" )  
            row.value = splittedValue[0] 
            if( row.formularFeldname.toLowerCase().trim() === "ort" )  
            row.value = (splittedValue[1] ?? "").replace("#", " ")
            if( row.formularFeldname.toLowerCase().trim() === "land" )  
            row.value = splittedValue[2] 
        }     
    }
}