import { ObjectListItems } from "@/infrastructure/generics/namedListItem";
import { DynamicDataSet } from "./DynamicFormData";
import { ObjectSearchDataSource } from "./ObjectSearchDataSource";
import { Resource } from "@/infrastructure/resource/resource";
//import { FishingSelected } from "@/components/fishing/business/FishingSelected";
import { Out } from "@/infrastructure/frontent/clientMessage";
import { GeoState, GeoStateAction, GeoView } from "../geo/hooks/ChangeGeoMap";

export class DynamicFormObjectSearchComboBox {
    private objectSearchDataSource: ObjectSearchDataSource
    private parentCascadeId : string 
    private kategorie: string
    private static patentChanged = false
    private geoViewActionService: GeoView
    
    constructor() {
        this.objectSearchDataSource = new ObjectSearchDataSource()
        this.parentCascadeId = ""
        this.kategorie = ""
        this.geoViewActionService = GeoView.getService()
    }
    
    public async selectObjectListSelectionChanged(dataSourceName: string, dynamicData: DynamicDataSet, value: any ) {
        if( !dataSourceName || dataSourceName.length < 1)
          return
    
        if ( !dynamicData || !dynamicData.view)
          return
    
        for (const row of  dynamicData.view ) {   
          if ( row.namedValueListDataSourceName == "Patent") {
            if ( dataSourceName === "Patent" ) {
              this.parentCascadeId = value.key
              this.kategorie = value.kategorie     
              DynamicFormObjectSearchComboBox.patentChanged = true   
            }
          }


          if(row.namedValueListDataSourceName === dataSourceName) {
            row.value = value            
            row.infoResourceId = value.resourceId
            row.infoTextTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`)
            row.infoTextContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`)
          }    

          if ( row.namedValueListDataSourceName === "JagdRevier" ) {
            row.value = value            
            row.infoResourceId = value.resourceId
            row.infoTextTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`)
            row.infoTextContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`)
            if (row.value &&  row.value.key && row.value.key.length > 0) {
              const state: GeoState = {
                action: GeoStateAction.ChangeRevierAndType,
                wgs84X: -1,
                wgs84Y: -1,
                revierNumber: row.value.key,
                typ: "jagdrevier",
                lastState: false,
                isEditable: undefined

              }
              this.geoViewActionService.changeGeoState(state)
          }
        }
          
          if ( row.namedValueListDataSourceName === "FischereiRevier" ) {
            if ( this.parentCascadeId !== undefined && this.parentCascadeId !== '') {
              row.parentCascadeId = this.parentCascadeId
              row.kategorie = this.kategorie
              }
              if ( !DynamicFormObjectSearchComboBox.patentChanged) {
                if (row.value &&  row.value.key && row.value.key.length > 0) {
                  const state: GeoState = {
                    action: GeoStateAction.ChangeRevierAndType,
                    wgs84X: undefined,
                    wgs84Y: undefined,
                    revierNumber: row.value.key,
                    typ: "fischereirevier",
                    lastState: false,
                    isEditable: undefined

                  }
                  this.geoViewActionService.changeGeoState(state)
                }
              }
            
            if ( DynamicFormObjectSearchComboBox.patentChanged) {
              DynamicFormObjectSearchComboBox.patentChanged = false
              row.namedValueList = await this.objectSearchDataSource.search(row.namedValueListDataSourceName, row.parentCascadeId, row.kategorie ?? "", "")
                if ( row.namedValueList !== undefined) {
                  row.value = row.namedValueList[0]   
                  row.infoResourceId = row.value.resourceId
                  row.infoTextTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`)
                  row.infoTextContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`)   
                  
                  if ( row.namedValueListDataSourceName === "FischereiRevier") {
                    const state: GeoState = {
                      action: GeoStateAction.ChangeRevierAndType,
                      wgs84X: undefined,
                      wgs84Y: undefined,
                      revierNumber: row.value.key,
                      typ: "fischereirevier",
                      lastState: false,
                      isEditable: undefined

                    }
                    this.geoViewActionService.changeGeoState(state)
                  }
                  
                }
              }
            }
        }    
    }
    
      
    
   
    
    public async onObjectListFilterChanged(ereignis: any, dynamicData: DynamicDataSet): Promise<void> {
        const searchAt = 0
        const datasourceName = ereignis.component.id
    
          if ( ereignis.filter !== undefined && ereignis.filter.value.length < searchAt) {
            for (const row of  dynamicData.view ) {
              if ( row.namedValueListDataSourceName === datasourceName) {                                            
                row.value = ereignis.filter.value   
              }         
            }                
            return        
          }
      
          for (const row of  dynamicData.view ) {
            if ( row.namedValueListDataSourceName === datasourceName) {         
              if ( ereignis.filter !== undefined) {
                row.namedValueList = await this.objectSearchDataSource.search(datasourceName, row.parentCascadeId, row.kategorie ?? "", ereignis.filter.value)
              } else {
                row.namedValueList = await this.objectSearchDataSource.search(datasourceName, row.parentCascadeId, row.kategorie ?? "",row.namedValueListFilterTyp ?? "")
    
              }
              if (row.namedValueList  ) {              
                row.list = []
                for( const element of row.namedValueList)              
                  row.list.push(element)
              }            
              
              if (! row.namedValueList || row.namedValueList.length == 0 ) {              
                row.list = {}
              }
            }
          }
      }
}