import { Result } from "@/infrastructure/functional/result";
import { getCookie, getCookies } from "typescript-cookie";
import { LogoutChannelForRoot } from "../observables/logoutChannel";
import { Out } from "../frontent/clientMessage";

export interface UserIdentity {
  UserId: string;
  Roles: string; 
}

export class Identity {

  public static getIdentity(): Result<UserIdentity> {
    return this.getInternalIdentity(false);
  }

  public static printCookies1() {
    try {
     console.error( "document.Cookies: " + document.cookie );
    } catch(e) {
      Out.noOperation("")
    }
      
  }

  public static printCookies() {

    try {
      const c = getCookies()
      console.error("getCookies: " , c)
     } catch(e) {
       Out.noOperation("")
     }
 }



  

  public static getCookieByName1(name: string) {
      const match = document.cookie.match(new RegExp(name + '=([^;]+)'));
      return match ? match[1] : undefined;
  }

  
  public static getCookieByName2(name: string) {
    return getCookie(name)
}

  public static isUserRole() {
    try {
      const identity =  this.getInternalIdentity(false)
      if (identity && identity.getValue()) {
        const roles = identity.getValue().Roles
        if( roles && roles === "User") return true
         

      }

    } catch(e) {
      Out.noOperation("")
    }
    return false
  }


  public static getIdentityViaCookie(): Result<UserIdentity> {
    return this.getInternalIdentity(false);
  }

  public static identityExists() : boolean {
    let content: string | undefined = undefined
    try {
        content = getCookie("X-UserClaim-Names");
        if (!content || content.length < 1) {    
          return false
        }    
        return true
      } catch (e) {
       return false
      }
  }

  public static getInternalIdentity(reactOnTimedOutCookie: boolean): Result<UserIdentity> {
    const identity: UserIdentity = {
      UserId: "",
      Roles: "",
    };


    try {
      const content = getCookie("X-UserClaim-Names");
      if (content === undefined) {        
        // if ( reactOnTimedOutCookie) {
        //   const logout = LogoutChannelForRoot.getService()
        //   if (! logout.logoutState.cleanUp ) logout.changeLogoutState({cleanUp: true, login: false})  
        // }
        return Result.fail<UserIdentity>(
          `"UserIdentity.getIdentity cookie:X-Claim-Names not found"`
        );
      }

      

      const temp = content.split(",");
      if (temp.length < 1) {
        return Result.fail<UserIdentity>(
          `"UserIdentity.getIdentity cookie:X-Claim-Names has wrong content"`
        );
      }
      if (temp.length < 2) {
        if (temp[0]) {
          identity.UserId = temp[0].replace("nameidentifier=", "");
          identity.Roles = "User";
          return Result.ok<UserIdentity>(identity);
        }
      }
      identity.UserId = temp[0].replace("nameidentifier=", "");
      identity.Roles = temp[1].replace("Roles=", "");
    } catch (e) {
      return Result.fail<UserIdentity>(`"UserIdentity.getIdentity.Error ${e}"`);
    }
    return Result.ok<UserIdentity>(identity);
  }
}
