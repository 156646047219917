import {
    StateRule,
    StateTransitionOptions,
    StateResult,
    StateNavigationAction,
    Transition,
    addNavigationElement,    
    addPreviousNext,
    addPrevious,
  } from "../StateInterfaces";
  
  import { BaseState }  from "../StateMachine";  
  import { NavigatonIcons } from "@/business/application/NavConst";
  import {Commands} from "@/business/application/commands/Command";
  import { StateFishing } from "./StateFishing";
import { Resource } from "@/infrastructure/resource/resource";
  
  
  export class StateFishbookAdd extends BaseState {
    private static mainState = "FishbookAdd";
    private static transitions: Transition[] = []; 
    private isForwardClicked = true
    private static formName = "FischbuchEintragPachtrevier"
    
    public constructor() {
      super()    
    }
  
    public getStartTransition(form: string) : StateResult {     
      StateFishbookAdd.transitions = [] 
      this.getTransitions()
      StateFishbookAdd.formName = form
      if ( form === "FischbuchEintragPachtrevier" ){
            return this.getTransition(this.getTransitions()[0]);
      }
      if ( form === "FischbuchEintragPatentrevier" ){
        return this.getTransition(this.getTransitions()[1]);
      }
      if ( form === "FischbuchEintragLinthkanal" ){
        return this.getTransition(this.getTransitions()[2]);
      }
      return this.getTransition(this.getTransitions()[0]);
    }
  
  
    public next(rule: StateRule, options: StateTransitionOptions): StateResult {
      return this.calculateState(
        rule,
        options,
        false,
        StateFishbookAdd.mainState
      );
    }
  
    public previous(rule: StateRule,options: StateTransitionOptions): StateResult {
      return this.calculateState(rule,options,true,StateFishbookAdd.mainState);
    }
  
    protected checkState(transition: Transition,options: StateTransitionOptions): StateResult {
      const context = this.getContext()
      let newResultState = this.getTransition(transition);      
  
      let newState: any
      if (!options.lookahead && transition.newState != "") {
        switch (newResultState.newMainState) {
            case "Fishing":
                newState = new StateFishing();
                newResultState = newState.getStartTransition("Fishing")
                this.getContext().transitionTo(newState,newResultState);
                break;         
          default:
            context.transitionTo(
              new StateFishbookAdd(),
              newResultState
            );
        }
      }
      return newResultState;
    }
  
    protected calculateState( rule: StateRule,options: StateTransitionOptions,isPrevious: boolean, _mainState: string): StateResult {    
      const context = this.getContext()
      const currentState = context.getCurrentState()
      this.isForwardClicked = ! isPrevious
      const transition = this.getTransitions().filter(row => {
        if (row.nav === StateNavigationAction.Next && this.isForwardClicked) {        
          if(currentState.newState === StateFishbookAdd.formName  &&  row.oldState === StateFishbookAdd.formName) {
            if (  row.condition === StateFishbookAdd.formName ) return true;    
          }
        } 
        
        if (row.nav === StateNavigationAction.Previous && !this.isForwardClicked) {                       
          if(currentState.newState === StateFishbookAdd.formName &&  row.oldState === StateFishbookAdd.formName) {
            if (  row.condition === StateFishbookAdd.formName ) return true;    
          }
  
        }
  
  
  
      });            
      return this.checkState(transition[0], options);
    }
  
    protected calculateBusinessConditions(rule: StateRule, _resultState: StateResult): string {
      if ( rule.conditions === undefined || rule.conditions === null || rule.conditions.length <= 0 ) {
        return "";
      }
  
      if (rule.conditions.length == 1) {
        if ( rule.conditions[0].value === undefined ) {
          return ""
        }
  
        //StateFishbookAdd.formName = rule.conditions[0].value
        return rule.conditions[0].value;
      }
      return "";
    }
  
    public getTransitions(): Transition[] {
      if (StateFishbookAdd.transitions.length === 0) {
  
        //*****************************************
        //--> Wizzard Start
        //*****************************************        
        let t: Transition = { mainState: "Fishing", newMainState: StateFishbookAdd.mainState, nav: StateNavigationAction.Next, oldState: "Fishing", newState: "FischbuchEintragPachtrevier", condition: StateFishbookAdd.formName};      
        //t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleDynamicSave);
        t = addPrevious(t,Commands.PreviousPage)
        StateFishbookAdd.transitions.push(t);

        t = { mainState: "Fishing", newMainState: StateFishbookAdd.mainState, nav: StateNavigationAction.Next, oldState: "Fishing", newState: "FischbuchEintragPatentrevier", condition: StateFishbookAdd.formName};      
        //t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleDynamicSave);
        t = addPrevious(t,Commands.PreviousPage)
        StateFishbookAdd.transitions.push(t);

        t = { mainState: "Fishing", newMainState: StateFishbookAdd.mainState, nav: StateNavigationAction.Next, oldState: "Fishing", newState: "FischbuchEintragLinthkanal", condition: StateFishbookAdd.formName};      
        //t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleDynamicSave);
        t = addPrevious(t,Commands.PreviousPage)
        StateFishbookAdd.transitions.push(t);
  
        //*****************************************
        //--> Wizzard vorward
        //*****************************************
        t = {mainState:  StateFishbookAdd.mainState,newMainState: "Fishing", oldState:  "FischbuchEintragPachtrevier", newState: "Fishing",condition: "FischbuchEintragPachtrevier", nav: StateNavigationAction.Next}
        //t = addPreviousNext(t,Commands.PreviousPage, Commands.RuleDynamicSave);
        t = addPrevious(t,Commands.PreviousPage)
        StateFishbookAdd.transitions.push(t);

        t = {mainState:  StateFishbookAdd.mainState,newMainState: "Fishing", oldState:  "FischbuchEintragPatentrevier", newState: "Fishing",condition: "FischbuchEintragPatentrevier", nav: StateNavigationAction.Next}
        //t = addPreviousNext(t,Commands.PreviousPage, Commands.RuleDynamicSave);
        t = addPrevious(t,Commands.PreviousPage)
        StateFishbookAdd.transitions.push(t);

        t = {mainState:  StateFishbookAdd.mainState,newMainState: "Fishing", oldState:  "FischbuchEintragLinthkanal", newState: "Fishing",condition: "FischbuchEintragLinthkanal", nav: StateNavigationAction.Next}
        //t = addPreviousNext(t,Commands.PreviousPage, Commands.RuleDynamicSave);
        t = addPrevious(t,Commands.PreviousPage)
        StateFishbookAdd.transitions.push(t);
        
        //*****************************************
        //--> Wizzard backward
        //*****************************************
  
        t = {mainState: "FishbookAddOnly",newMainState: "Fishing", oldState:"FischbuchEintragPachtrevier" , newState: "Fishing" ,condition:"FischbuchEintragPachtrevier", nav: StateNavigationAction.Previous}
  
        t = addNavigationElement(t,1,{command: Commands.PreviousPage, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Home",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Home });
        t = addNavigationElement(t,2,{command: Commands.Products, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Produkte",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Products });
        t = addNavigationElement(t,3,{command: Commands.Wildbuch, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Fischbuch",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Fischbuch });
        t = addNavigationElement(t,4,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
        StateFishbookAdd.transitions.push(t);

        t = {mainState: "FishbookAddOnly",newMainState: "Fishing", oldState:"FischbuchEintragPatentrevier" , newState: "Fishing" ,condition: "FischbuchEintragPatentrevier", nav: StateNavigationAction.Previous}
  
        t = addNavigationElement(t,1,{command: Commands.PreviousPage, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Home",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Home });
        t = addNavigationElement(t,2,{command: Commands.Products, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Produkte",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Products });
        t = addNavigationElement(t,3,{command: Commands.Wildbuch, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Fischbuch",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Fischbuch });
        t = addNavigationElement(t,4,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
        StateFishbookAdd.transitions.push(t);
        
        t = {mainState: "FishbookAddOnly",newMainState: "Fishing", oldState:"FischbuchEintragLinthkanal" , newState: "Fishing" ,condition: "FischbuchEintragLinthkanal", nav: StateNavigationAction.Previous}
  
        t = addNavigationElement(t,1,{command: Commands.PreviousPage, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Home",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Home });
        t = addNavigationElement(t,2,{command: Commands.Products, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Produkte",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Products });
        t = addNavigationElement(t,3,{command: Commands.Wildbuch, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Fischbuch",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Fischbuch });
        t = addNavigationElement(t,4,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
        StateFishbookAdd.transitions.push(t);

      }
      return StateFishbookAdd.transitions;
    }
  }
  