
import { Resource } from "@/infrastructure/resource/resource";
import {
  StateRule,
  StateTransitionOptions,
  StateResult,
  StateNavigationAction,
  Transition,
  addNavigationElement,
  addEmptyNavigationElement,
  addPreviousNext,  
  newTransitionCondition,
} from "../StateInterfaces";

import { BaseState }  from "../StateMachine";
import { StateHome } from "./StateHome";
import { StateLogin } from "./StateLogin";
import { NavigatonIcons } from "@/business/application/NavConst";
import {Commands} from "@/business/application/commands/Command";


export class StateUserProfile extends BaseState {
  private static mainState = "UserProfile";
  private static transitions: Transition[] = []; 
  private isFishingRule = true
  private isHuntingRule = true
  private isForwardClicked = true
  private static isRefreshCondition = "IsUserProfile"
  private static callerState : StateResult = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''}

  public constructor() {
    super()    
  }

  public getStartTransition(condition: string) : StateResult {
    StateUserProfile.transitions = []
    this.getTransitions()
    StateUserProfile.isRefreshCondition = "IsUserProfile"
    if ( condition === "Login") {
      StateUserProfile.isRefreshCondition = "IsVerification"
    }

    this.setCallerState(condition)    
    return this.getTransition(this.getTransitions()[0]);
  }

  private setCallerState(callerState: string) : StateResult {
    if (callerState === "Login" || callerState === "Home") {      
      StateUserProfile.callerState.newMainState = "UserProfile"
      StateUserProfile.callerState.newState = "UserPersonalData"
      StateUserProfile.callerState.routeName = callerState
      StateUserProfile.callerState.oldMainState = callerState
      StateUserProfile.callerState.oldState = callerState
    }    
    return StateUserProfile.callerState;
  }

  private callerState() : StateResult {
    return StateUserProfile.callerState
  }



  public next(rule: StateRule, options: StateTransitionOptions): StateResult {
    return this.calculateState(
      rule,
      options,
      false,
      StateUserProfile.mainState
    );
  }

  public previous(rule: StateRule,options: StateTransitionOptions): StateResult {
    return this.calculateState(rule,options,true,StateUserProfile.mainState);
  }

  protected checkState(transition: Transition,options: StateTransitionOptions): StateResult {
    const context = this.getContext()
    let newResultState = this.getTransition(transition);
    // if ( newResultState.newMainState === "@newMainState@") newResultState.newMainState = this.callerState().oldMainState
    // if ( newResultState.newState === "@newState@") newResultState.newState = this.callerState().oldState

    let newState: any
    if (!options.lookahead && transition.newState != "") {
      switch (newResultState.newMainState) {
        case "Login":
          newState = new StateLogin();
          newResultState = newState.getStartTransition("Login")
          this.getContext().transitionTo(newState,newResultState)                  
          break;
        case "Home":
          newState = new StateHome();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState)                  
          break;        
        default:
          context.transitionTo(
            new StateUserProfile(),
            newResultState
          );
      }
    }
    return newResultState;
  }

  protected calculateState( rule: StateRule,options: StateTransitionOptions,isPrevious: boolean, _mainState: string): StateResult {    
    const context = this.getContext()
    const currentState = context.getCurrentState()
    this.isForwardClicked = ! isPrevious
    if (StateUserProfile.isRefreshCondition === "IsUserProfile") {
      return this.CalculateUserProfileState(currentState, options)
    }
    return this.CalculateUserProfileConfirmState(currentState, options)

  }
  protected CalculateUserProfileConfirmState(currentState: StateResult, options: StateTransitionOptions) : StateResult {
    const transition = this.getTransitions().filter(row => {
      if (row.nav === StateNavigationAction.Next && this.isForwardClicked ) {        
        if(currentState.newState == "UserPersonalData" &&  row.oldState == "UserPersonalData") {
          if (  row.condition == "isAddress" )
          return true;
        }
        if(currentState.newState == "UserAddress" &&  row.oldState == "UserAddress") {
          return true;
        }
        if (currentState.newState == "UserCommunication" &&   row.oldState == "UserCommunication") {
          if (  this.isFishingRule && row.condition == "isFishing" ) return true;                                  
          if (  this.isHuntingRule && row.condition == "isHunting" ) return true;
          if (  row.condition == "isPrivacyByCode" ) return true;                                  
        }

        if (currentState.newState == "UserFishing" &&   row.oldState == "UserFishing") {         
          if (  this.isHuntingRule && row.condition == "isHunting" ) return true;                        
          if (  row.condition == "isPrivacyByCode" ) return true;                                  
        }

        if (currentState.newState == "UserHunting" &&   row.oldState == "UserHunting") {                                
          if (  row.condition == "isPrivacyByCode" ) return true;                                  
        }

        if (currentState.newState == "UserPrivacyByCode" &&   row.oldState == "UserPrivacyByCode") {                                
          return true;                                  
        }          
        if (currentState.newState == "UserRegistrationByCode" &&   row.oldState == "UserRegistrationByCode") {                                
          return true;                                  
        }    
      } 
      
      if (row.nav === StateNavigationAction.Previous && !this.isForwardClicked ) {  
        if (currentState.newState == "UserRegistrationByCode" &&   row.oldState == "UserRegistrationByCode") {                                       
          return true;                                  
        }                       
        if (currentState.newState == "UserPrivacyByCode" &&   row.oldState == "UserPrivacyByCode") {
          if (  this.isHuntingRule && row.condition == "isHunting" ) return true;
          if (  this.isFishingRule && row.condition == "isFishing" ) return true;                                  
          if (  row.condition == "isCommunication" ) return true;                                  
        }    
        if (currentState.newState == "UserHunting" &&   row.oldState == "UserHunting") {
          if (  this.isFishingRule && row.condition == "isFishing" ) return true;                                  
          if (  row.condition == "isCommunication" ) return true;                                  
        }       
        if (currentState.newState == "UserFishing" &&   row.oldState == "UserFishing") {                                       
          if (  row.condition == "isCommunication" ) return true;                                  
        }       
        if (currentState.newState == "UserCommunication" &&   row.oldState == "UserCommunication") {                                
          if (  row.condition == "isAddress" ) return true;                                  
        }
        if (currentState.newState == "UserAddress" &&   row.oldState == "UserAddress") {                                
          if (  row.condition == "isPersonal" ) return true;                                  
        }
        if (currentState.newState == "UserPersonalData" &&   row.oldState == "UserPersonalData") {                                
          if (  row.condition == "isFinishByCode" ) return true;                                  
        }

      }

    });            
    return this.checkState(transition[0], options);
  }


  protected CalculateUserProfileState(currentState: StateResult, options: StateTransitionOptions) : StateResult {
    const transition = this.getTransitions().filter(row => {
      if (row.nav === StateNavigationAction.Next && this.isForwardClicked ) {        
        if(currentState.newState == "UserPersonalData" &&  row.oldState == "UserPersonalData") {
          if (  row.condition == "isAddress" )
          return true;
        }
        if(currentState.newState == "UserAddress" &&  row.oldState == "UserAddress") {
          return true;
        }
        if (currentState.newState == "UserCommunication" &&   row.oldState == "UserCommunication") {
          if (  this.isFishingRule && row.condition == "isFishing" ) return true;                                  
          if (  this.isHuntingRule && row.condition == "isHunting" ) return true;
          if (  row.condition == "isPrivacy" ) return true;                                  
        }

        if (currentState.newState == "UserFishing" &&   row.oldState == "UserFishing") {         
          if (  this.isHuntingRule && row.condition == "isHunting" ) return true;                        
          if (  row.condition == "isPrivacy" ) return true;                                  
        }

        if (currentState.newState == "UserHunting" &&   row.oldState == "UserHunting") {                                
          if (  row.condition == "isPrivacy" ) return true;                                  
        }

        if (currentState.newState == "UserPrivacy" &&   row.oldState == "UserPrivacy") {                                
          return true;                                  
        }        
      } 
      
      if (row.nav === StateNavigationAction.Previous && !this.isForwardClicked ) {              
        if (currentState.newState == "UserPrivacy" &&   row.oldState == "UserPrivacy") {
          if (  this.isHuntingRule && row.condition == "isHunting" ) return true;
          if (  this.isFishingRule && row.condition == "isFishing" ) return true;                                  
          if (  row.condition == "isCommunication" ) return true;                                  
        }    
        if (currentState.newState == "UserHunting" &&   row.oldState == "UserHunting") {
          if (  this.isFishingRule && row.condition == "isFishing" ) return true;                                  
          if (  row.condition == "isCommunication" ) return true;                                  
        }       
        if (currentState.newState == "UserFishing" &&   row.oldState == "UserFishing") {                                       
          if (  row.condition == "isCommunication" ) return true;                                  
        }       
        if (currentState.newState == "UserCommunication" &&   row.oldState == "UserCommunication") {                                
          if (  row.condition == "isAddress" ) return true;                                  
        }
        if (currentState.newState == "UserAddress" &&   row.oldState == "UserAddress") {                                
          if (  row.condition == "isPersonal" ) return true;                                  
        }
        if (currentState.newState == "UserPersonalData" &&   row.oldState == "UserPersonalData") {                                
          if (  row.condition == "isFinish" ) return true;                                  
        }

      }

    });            
    return this.checkState(transition[0], options);
  }

  protected calculateBusinessConditions(rule: StateRule, _resultState: StateResult): string {
    if (
      rule.conditions === undefined ||
      rule.conditions === null ||
      rule.conditions.length <= 0
    ) {
      return "";
    }

    if (rule.conditions.length == 1) {
      if ( rule.conditions[0].value === undefined ) {
        return ""
      }

      if( rule.conditions[0].nameId.toLowerCase().trim() === "isFishing".toLowerCase()) {
        this.isFishingRule = true;
      }

      if( rule.conditions[0].nameId.toLowerCase().trim() === "isHunting".toLowerCase()) {
        this.isHuntingRule = true;
      }


      return rule.conditions[0].value;
    }
    return "";
  }

  public getTransitions(): Transition[] {
    if (StateUserProfile.transitions.length === 0) {

      

      let t: Transition = { mainState: "Login", newMainState: "UserProfile", nav: StateNavigationAction.Next, oldState: "Login", newState: "UserPersonalData", condition: ""};      
      //let t: Transition = { mainState: this.callerState().oldMainState, newMainState: this.callerState().newMainState, nav: StateNavigationAction.Next, oldState: this.callerState().oldState, newState: this.callerState().newState	, condition: ""};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      //*****************************************
      //--> Wizzard vorward
      //*****************************************
      t = newTransitionCondition("UserProfile","UserPersonalData", "UserAddress" , "isAddress", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);


      t = newTransitionCondition("UserProfile","UserAddress", "UserCommunication" , "isCommunication", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserCommunication", "UserFishing", "isFishing", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserFishing", "UserHunting", "isHunting", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserCommunication", "UserHunting", "isHunting", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserCommunication", "UserPrivacy", "isPrivacy", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.SaveAndJump);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserHunting", "UserPrivacy", "isPrivacy", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.SaveAndJump);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserFishing", "UserPrivacy", "isPrivacy", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.SaveAndJump);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserCommunication", "UserPrivacy" , "isPrivacy", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.SaveAndJump);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserHunting", "UserPrivacyByCode", "isPrivacyByCode", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleSendEmailActivateNewUser);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserFishing", "UserPrivacyByCode", "isPrivacyByCode", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleSendEmailActivateNewUser);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserCommunication", "UserPrivacyByCode" , "isPrivacyByCode", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleSendEmailActivateNewUser);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserCommunication", "UserPrivacyByCode" , "isPrivacyByCode", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleSendEmailActivateNewUser);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserPrivacyByCode", "UserRegistrationByCode" , "isRegistrationByCode", StateNavigationAction.Next)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleActivateNewUser);
      StateUserProfile.transitions.push(t);

      t = {mainState: "UserProfile",newMainState: "Home", oldState: "UserPrivacy", newState: "Home",condition: "isFinish", nav: StateNavigationAction.Next}
      t = addPreviousNext(t,Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);


      t = {mainState: "UserProfile",newMainState: "Home", oldState: "UserRegistrationByCode", newState: "Home",condition: "isFinish", nav: StateNavigationAction.Next}
      t = addPreviousNext(t,Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      //*****************************************
      //--> Wizzard backward
      //*****************************************

      t = newTransitionCondition("UserProfile","UserRegistrationByCode", "UserPrivacyByCode", "isPrivacyByCode", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleSendEmailActivateNewUser);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserPrivacyByCode", "UserHunting", "isHunting", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);


      t = newTransitionCondition("UserProfile","UserPrivacy", "UserHunting", "isHunting", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserPrivacy", "UserFishing", "isFishing", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserHunting", "UserFishing", "isFishing", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserFishing", "UserCommunication", "isCommunication", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserHunting", "UserCommunication", "isCommunication", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = newTransitionCondition("UserProfile","UserPrivacy", "UserCommunication" , "isCommunication", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);


      t = newTransitionCondition("UserProfile","UserCommunication", "UserAddress", "isAddress" , StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);


      t = newTransitionCondition("UserProfile","UserAddress", "UserPersonalData", "isPersonal" , StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateUserProfile.transitions.push(t);

      t = {mainState: "UserProfile",newMainState: "Home", oldState: "UserPersonalData", newState: "Home" ,condition: "isFinish", nav: StateNavigationAction.Previous}
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateUserProfile.transitions.push(t);


      t = {mainState: "UserProfile",newMainState: "Login", oldState: "UserPersonalData", newState: "Login" ,condition: "isFinishByCode", nav: StateNavigationAction.Previous}
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateUserProfile.transitions.push(t);
    
    }
    return StateUserProfile.transitions;
  }
}
