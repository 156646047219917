import { Resource } from "@/infrastructure/resource/resource";
import {
    StateRule,
    StateTransitionOptions,
    StateResult,
    StateNavigationAction,
    Transition,
    addNavigationElement,
    addEmptyNavigationElement,
    addPreviousNext,
    newTransitionCondition,
  } from "../StateInterfaces";
  
  import { BaseState }  from "../StateMachine";
  import { StateHome } from "./StateHome";
  import { StateLogin } from "./StateLogin";
  import { NavigatonIcons } from "@/business/application/NavConst";
  import {Commands} from "@/business/application/commands/Command";
  
  
  export class StateEmailVerification extends BaseState {
    private static mainState = "PrivacyPolicy";
    private static transitions: Transition[] = []; 
    private isForwardClicked = true
    private static callerState : StateResult = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''}
  
    public constructor() {
      super()    
      
    }
  
    public getStartTransition(condition: string) : StateResult {
      StateEmailVerification.transitions = []
      this.getTransitions()
      this.setCallerState(condition)    
      return this.getTransition(this.getTransitions()[0]);
    }
  
    private setCallerState(callerState: string) : StateResult {
      if (callerState === "Login" || callerState === "Home") {      
        StateEmailVerification.callerState.newMainState = "PrivacyPolicy"
        StateEmailVerification.callerState.newState = "PrivacyPolicy"
        StateEmailVerification.callerState.routeName = callerState
        StateEmailVerification.callerState.oldMainState = callerState
        StateEmailVerification.callerState.oldState = callerState
      }    
      return StateEmailVerification.callerState;
    }
  
    private callerState() : StateResult {
      return StateEmailVerification.callerState
    }
  
    public next(rule: StateRule, options: StateTransitionOptions): StateResult {
      return this.calculateState(
        rule,
        options,
        false,
        StateEmailVerification.mainState
      );
    }
  
    public previous(rule: StateRule,options: StateTransitionOptions): StateResult {
      return this.calculateState(rule,options,true,StateEmailVerification.mainState);
    }
  
    protected checkState(transition: Transition,options: StateTransitionOptions): StateResult {
      const context = this.getContext()
      let newResultState = this.getTransition(transition);
      //if ( newResultState.newMainState === "@newMainState@") newResultState.newMainState = this.callerState().oldMainState
      //if ( newResultState.newState === "@newState@") newResultState.newState = this.callerState().oldState
  
      let newState: any
      if (!options.lookahead && transition.newState != "") {
        switch (newResultState.newMainState) {
          case "Login":
            newState = new StateLogin();
            newResultState = newState.getStartTransition("Login")
            this.getContext().transitionTo(newState,newResultState)                  
            break;
          case "Home":
            newState = new StateHome();
            newResultState = newState.getStartTransition("Home")
            this.getContext().transitionTo(newState,newResultState)                  
            break;        
          default:
            context.transitionTo(
              new StateEmailVerification(),
              newResultState
            );
        }
      }
      return newResultState;
    }
  
    protected calculateState( rule: StateRule,options: StateTransitionOptions,isPrevious: boolean, _mainState: string): StateResult {    
      const context = this.getContext()
      const currentState = context.getCurrentState()
      this.isForwardClicked = ! isPrevious
      const transition = this.getTransitions().filter(row => {
        if (row.nav === StateNavigationAction.Next && this.isForwardClicked) {                 
          if(currentState.newState == "EmailInput" &&  row.oldState == "EmailInput") {
            if (  row.condition == "isInput" ) return true;    
          }
          if(currentState.newState == "EmailVerification" &&  row.oldState == "EmailVerification") {
            if (  row.condition == "isFinish" ) return true;    
          }
        } 
        
        if (row.nav === StateNavigationAction.Previous && !this.isForwardClicked) {              
          if(currentState.newState == "EmailVerification" &&  row.oldState == "EmailVerification") {
            if (  row.condition == "isInput" ) return true;    
          }
          if(currentState.newState == "EmailInput" &&  row.oldState == "EmailInput") {
            if (  row.condition == "isFinish" ) return true;    
          }
  
        }
  
      });            
      return this.checkState(transition[0], options);
    }
  
    protected calculateBusinessConditions(rule: StateRule, _resultState: StateResult): string {
      if (
        rule.conditions === undefined ||
        rule.conditions === null ||
        rule.conditions.length <= 0
      ) {
        return "";
      }
  
      if (rule.conditions.length == 1) {
        if ( rule.conditions[0].value === undefined ) {
          return ""
        }
  
        return rule.conditions[0].value;
      }
      return "";
    }
  
    public getTransitions(): Transition[] {
      if (StateEmailVerification.transitions.length === 0) {
  
        //*****************************************
        //--> Wizzard Start
        //*****************************************        
        let t: Transition = { mainState: "Login", newMainState: "StateEmailVerification", nav: StateNavigationAction.Next, oldState: "Login", newState: "EmailInput", condition: "isStart"};      
        t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleEmailVerificationRequest);
        StateEmailVerification.transitions.push(t);
  
        //*****************************************
        //--> Wizzard vorward
        //*****************************************
        t = {mainState: "StateEmailVerification",newMainState: "StateEmailVerification", oldState: "EmailInput", newState: "EmailVerification",condition: "isInput", nav: StateNavigationAction.Next}
        t = addPreviousNext(t,Commands.PreviousPage, Commands.RuleEmailVerification);
        StateEmailVerification.transitions.push(t);


        t = {mainState: "StateEmailVerification",newMainState: "StateUserProfile", oldState: "EmailVerification", newState: "UserPersonalData",condition: "isFinish", nav: StateNavigationAction.Next}
        t = addPreviousNext(t,Commands.PreviousPage, Commands.NextPage);
        StateEmailVerification.transitions.push(t);
  
        //*****************************************
        //--> Wizzard backward
        //*****************************************
        t = newTransitionCondition("StateEmailVerification","EmailVerification", "EmailInput", "isInput", StateNavigationAction.Previous)
        t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleEmailVerificationRequest);
        StateEmailVerification.transitions.push(t);


        t = {mainState: "StateEmailVerification",newMainState: "Login", oldState: "EmailInput", newState: "Login" ,condition: "isFinish", nav: StateNavigationAction.Previous}  
        t = addEmptyNavigationElement(t,1);
        t = addEmptyNavigationElement(t,2);
        t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
        t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
        StateEmailVerification.transitions.push(t);
      
      }
      return StateEmailVerification.transitions;
    }
  }
  