import { Dictionary } from "@/infrastructure/generics/dictionary"
import { NavigationButton, NavigationChain, NavigationItem, action } from "./DynamicNavigationTypes"
import { NotifyDynamicNavigation } from "./Hooks/NotifyDynamicNavigation"
import { NotifyIconBar } from "./Hooks/NotifyIconBar"
import { Out } from "@/infrastructure/frontent/clientMessage";
import { NavigateDynamicForm } from "./Hooks/NavigateDynamicForm";
import { NotifyDynamicList } from "./Hooks/NotifyDynamicList";
import { GenerateWildBookNavigationChains } from "./WildBookNavigationChain";
import { GenerateWebShopNavigationChains } from "./WebShopNavigationChain";




export class DynamicNavigation {

    private static _isNavigation = false
    private static assignedTo: string | undefined = undefined
    private static _navigationChains: Dictionary<NavigationChain> = {}    
    private static _currentNavigationChain: NavigationChain | undefined
    private static _lastStaticNavigationState: NavigationButton[]
    private static _lastClientFormId: Dictionary<string> = {}
    private static _actionCounter = 0
    private static _chainName: string | undefined

    public static setClientFormId( formName: string , clientId: string ) {
        this._lastClientFormId[formName] = clientId
    }

    public static isClientFormId (formName: string , clientId: string) : boolean {
        if (this._lastClientFormId[formName] === clientId) {
            return true
        }
        return false
    }



    private static formNavigation = NavigateDynamicForm.getService()
    private static onFormFeedback = this.formNavigation.subscription().subscribe({
        next: (value) => {
            if ( !value || !value.direction || !value.action) return
            if ( value.direction === "to") return

            if ( value.result) {

                if ( value.action &&  (value.action === "RuleDynamicSave" || value.action === "RuleDynamicDelete")) DynamicNavigation._actionCounter = 0

                if ( DynamicNavigation._actionCounter > 0) return
                DynamicNavigation._actionCounter ++

                if ( value.action === "next-validate" && DynamicNavigation._currentNavigationChain) {
                    DynamicNavigation._currentNavigationChain.currentIndex ++                                  
                    
                    DynamicNavigation.notifyIconBarService.navigationChanged({NavigationItem: DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex], reset: undefined})
                    DynamicNavigation.notifyIconBarService.reset()

                    const page = DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex].page                                                     
                    this.formNavigation.navigate({direction: "to"  ,action: "load-page",  result: undefined, page: page})
                    this.formNavigation.reset()
                }

                if ( value.action === "previous-notify" && DynamicNavigation._currentNavigationChain) {
                    DynamicNavigation._currentNavigationChain.currentIndex --
                   
                    DynamicNavigation.notifyIconBarService.navigationChanged({NavigationItem: DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex], reset: undefined})
                    DynamicNavigation.notifyIconBarService.reset()
    

                    const page = DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex].page                                                          
                    this.formNavigation.navigate({direction: "to"  ,action: "load-page",  result: undefined, page: page})
                    this.formNavigation.reset()
                }

                if ( (value.action === "RuleDynamicSave" || value.action === "RuleDynamicDelete") && DynamicNavigation._currentNavigationChain) {                    
                    const page = DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex].page                                  
                    const lastNavigationItem: NavigationItem = {
                        page: page,
                        navigationButtons: DynamicNavigation._lastStaticNavigationState
                    }
                    DynamicNavigation.notifyIconBarService.navigationChanged({NavigationItem: lastNavigationItem, reset: true})
                    DynamicNavigation.notifyIconBarService.reset()                                  
                    DynamicNavigation.reset()
                    DynamicNavigation.notifyDynamicList.visibilityChanged({isClosed: true})
                    DynamicNavigation.notifyDynamicList.reset()

                    DynamicNavigation._currentNavigationChain.currentIndex = 0

                    return
                }

            }

        }
    })

    private static notifyDynamicList = NotifyDynamicList.getService()
    private static notifyIconBarService =  NotifyIconBar.getService()

    private static notifyDynamicNavigation = NotifyDynamicNavigation.getService()
    private static onNotifyDynamicNavigation = DynamicNavigation.notifyDynamicNavigation.subscription().subscribe({
        next: (value) => {
            if (!value.ButtonClicked) return

        

            let page = ""
            if (  DynamicNavigation._currentNavigationChain) {
                try {
                    page = DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex].page
                } catch(e) {
                    Out.noOperation("")
                }
            }

            if( DynamicNavigation._currentNavigationChain?.currentIndex === 0 && value.ButtonClicked === 1) {
                
               
                 
                const lastNavigationItem: NavigationItem = {
                    page: page,
                    navigationButtons: DynamicNavigation._lastStaticNavigationState
                }

                DynamicNavigation.notifyIconBarService.navigationChanged({NavigationItem: lastNavigationItem, reset: true})
                DynamicNavigation.notifyIconBarService.reset()                              
                DynamicNavigation.reset()
                DynamicNavigation.notifyDynamicList.visibilityChanged({isClosed: true})
                DynamicNavigation.notifyDynamicList.reset()
                return

            }

          
           

            const buttonItem = this.getButtonAction(value.ButtonClicked)
            if ( !buttonItem ) return

            if ( buttonItem.command === "next") {
                DynamicNavigation._actionCounter = 0              
                this.formNavigation.navigate({direction: "to"  ,action: "next-validate",  result: undefined, page: undefined})
                this.formNavigation.reset()
            }

            if ( buttonItem.command === "previous") {
                DynamicNavigation._actionCounter = 0
                this.formNavigation.navigate({direction: "to"  ,action: "previous-notify", result: undefined, page: undefined})
                this.formNavigation.reset()
            }

            if ( buttonItem.command !== "next" && buttonItem.command !== "previous") {
                DynamicNavigation._actionCounter = 0
                this.formNavigation.navigate({direction: "to"  ,action: buttonItem.command + "-validate" as action, result: undefined, page: undefined})
                this.formNavigation.reset()
            }

        }
    })

    public static create() : void {
        this.ensureChains()
    }

    private static getButtonAction(buttonClicked: number | undefined) : NavigationButton | undefined {
        if ( ! buttonClicked ) return
        if (  !DynamicNavigation._currentNavigationChain ) return
        if ( buttonClicked < 1 || buttonClicked > 4) return

        const currentItem = DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex]
        if ( ! currentItem ) return

        return currentItem.navigationButtons[buttonClicked-1]
    }
    
    private static ensureChains() : void {
        if ( (!DynamicNavigation._navigationChains  ||  Object.keys(DynamicNavigation._navigationChains).length === 0  ) && DynamicNavigation._chainName  === "WildBook") {
            GenerateWildBookNavigationChains.generateChains(DynamicNavigation._navigationChains)
            try {
                const test = DynamicNavigation._navigationChains["WildBookEntry"] 
                if (!test) 
                GenerateWildBookNavigationChains.generateChains(DynamicNavigation._navigationChains)
            } catch(e) {
                GenerateWildBookNavigationChains.generateChains(DynamicNavigation._navigationChains)
            }
        }

        if ( (! DynamicNavigation._navigationChains ||  Object.keys(DynamicNavigation._navigationChains).length === 0 ) && DynamicNavigation._chainName === "WebShop") {
            GenerateWebShopNavigationChains.generateChains(DynamicNavigation._navigationChains)
            try {
                const test = DynamicNavigation._navigationChains["WebShopDaten"] 
                if (!test) 
                GenerateWebShopNavigationChains.generateChains(DynamicNavigation._navigationChains)
            } catch(e) {
                GenerateWebShopNavigationChains.generateChains(DynamicNavigation._navigationChains)
            }
        }
      
    }

    public static isNavigationEnabled() : boolean {
        DynamicNavigation.ensureChains()
        return DynamicNavigation._isNavigation
    }

    public static isAssigned(assigned: string) : boolean {
        DynamicNavigation.ensureChains()
        if (!DynamicNavigation._isNavigation) return true
        if( DynamicNavigation.assignedTo === assigned) return true
        return false
    }

    public static start(chainName: string, navigationChainName: string, assignedTo: string) : void {
        DynamicNavigation._chainName = chainName
        DynamicNavigation.assignedTo = assignedTo
        DynamicNavigation._navigationChains = {}
        DynamicNavigation.ensureChains()
        DynamicNavigation._currentNavigationChain = DynamicNavigation._navigationChains[navigationChainName]
        DynamicNavigation._currentNavigationChain.currentIndex = 0
        DynamicNavigation._isNavigation = true

        DynamicNavigation.notifyIconBarService.navigationChanged({NavigationItem: DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex], reset: undefined})
        DynamicNavigation.notifyIconBarService.reset()
    }

    public static reset() : void {
        DynamicNavigation.ensureChains()
        DynamicNavigation.assignedTo = undefined
        DynamicNavigation._isNavigation = false

        if ( DynamicNavigation._currentNavigationChain) {
            DynamicNavigation._currentNavigationChain.currentIndex = 0
        }
    }

    public static action(actionName: string) {
        DynamicNavigation.ensureChains()
        if ( actionName.toLowerCase() === "next") {
            if ( DynamicNavigation._currentNavigationChain) {
                DynamicNavigation._currentNavigationChain.currentIndex += 1      

                DynamicNavigation.notifyIconBarService.navigationChanged({NavigationItem: DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex], reset: undefined})
                DynamicNavigation.notifyIconBarService.reset()
        
                return
            }
        }
        if ( actionName.toLowerCase() === "previous") {
            if ( DynamicNavigation._currentNavigationChain) {
                DynamicNavigation._currentNavigationChain.currentIndex -= 1

                DynamicNavigation.notifyIconBarService.navigationChanged({NavigationItem: DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex], reset: undefined})
                DynamicNavigation.notifyIconBarService.reset()
        
                return
            }
        }
    }

    public static getNavigationItem() : NavigationItem | undefined {
        DynamicNavigation.ensureChains()
        if ( !DynamicNavigation._currentNavigationChain)  return undefined
        return DynamicNavigation._currentNavigationChain.navigationItems[DynamicNavigation._currentNavigationChain.currentIndex]
    }

    public static saveLastStaticNavigationState(navigationButtons: NavigationButton[]) {
        if ( !navigationButtons) return
        if ( !navigationButtons[0] || !navigationButtons[1] || !navigationButtons[2] || !navigationButtons[3]) return
        if ( !navigationButtons[0].command && !navigationButtons[1].command && !navigationButtons[2].command && !navigationButtons[3].command) return
        if ( navigationButtons[0].command.length < 1 && navigationButtons[1].command.length < 1 && navigationButtons[2].command.length < 1 && navigationButtons[3].command.length < 1) return
        
        
        
        DynamicNavigation._lastStaticNavigationState = []

        DynamicNavigation._lastStaticNavigationState.push(DynamicNavigation.cloneButton(navigationButtons[0]))
        DynamicNavigation._lastStaticNavigationState.push(DynamicNavigation.cloneButton(navigationButtons[1]))
        DynamicNavigation._lastStaticNavigationState.push(DynamicNavigation.cloneButton(navigationButtons[2]))
        DynamicNavigation._lastStaticNavigationState.push(DynamicNavigation.cloneButton(navigationButtons[3]))


    }

    private static cloneButton(from: NavigationButton) : NavigationButton {
        const to: NavigationButton = {
            displayName: from.displayName,
            imageUrl: from.imageUrl,
            enabled: from.enabled,
            visible: from.visible,
            existent: from.existent,
            command: from.command
        }
        return to
    }


    public static destroy() {
        try {
            DynamicNavigation.onNotifyDynamicNavigation.unsubscribe()
        } catch(e) {
            Out.noOperation("")
        }        

        try {
            DynamicNavigation.onFormFeedback.unsubscribe()
        } catch(e) {
            Out.noOperation("")
        }        
    }
}

