
import { reactive} from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
import { Resource, ResourceText } from "@/infrastructure/resource/resource";
import { Identity } from "@/infrastructure/backend/userIdentity"
import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";

export default {
  name: "PasswordRequestEmail",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {    
    const request = reactive({
      apiLoadMethod: "api/anonymousForm/loadForm",
      apiSaveMethod: "",
      form: "PasswordEmail",
      page: "PasswordRequestEmail",
      remoteId: "",
      waitForResponse: true
      
    });  

 
    loadResource()

    async function loadResource() {
      await Resource.loadResources("PasswordRequest")
    }


    return {
      request,
    };
  }
};
