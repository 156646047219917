import { Observable } from "rxjs";
import { StateService } from "./stateService";

export interface ErrorState {
    message: string;
    errorTitleResourceId: string
    errorContentResourceId: string
    isError: boolean;
  }
  
const initialState: ErrorState = {
    message: "",
    errorTitleResourceId: "",
    errorContentResourceId: "",
    isError: false,
  };

export function CreateUserError(errorTitleResourceId: string, errorContentResourceId: string, message: string) : ErrorState {
    return {
        errorTitleResourceId: errorTitleResourceId,
        errorContentResourceId: errorContentResourceId,
        message: message,
        isError: true
    }
}




export class ErrorStateServiceForRoot extends StateService<ErrorState>{
    public static service: ErrorStateServiceForRoot;

    public static getErrorStateService() : ErrorStateServiceForRoot {
        if ( ErrorStateServiceForRoot.service === undefined) {
            ErrorStateServiceForRoot.service = new ErrorStateServiceForRoot();
            this.service = ErrorStateServiceForRoot.service
        }
        return ErrorStateServiceForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<ErrorState> {
        return this.subscribe();
    }

    public changeErrorState(error: ErrorState) : void {
        this.changeState(error);
    }

    public resetErrorState() : void {
        const error = {
            message: "",
            errorTitleResourceId: "",
            errorContentResourceId: "",
            isError: false,
        }

        this.changeState(error);
    }

    public get state(): ErrorState {
        return this.state;
    }
}