import { DynamicRecord } from '@/components/Shared/Dynamicform/DynamicFormData';
import { DynamicCloseForm } from './CloseForm';

export interface DynamicCloseFormState {
    close: boolean
    records?: DynamicRecord[]
}

export function createIntitialCloseFormState() : DynamicCloseFormState {
    return {
        close: false
    }
}

export interface DynamicFormState {  
    formName: string
    teilformularName?: string | undefined
    rows: DynamicRecord[] | undefined
    attachTo: string | undefined    
    id?: string | undefined
}

export const  record: DynamicRecord = {
    teilFormularName: '',
    formularFeldname: '',
    feldTyp: '',
    istEditierbar: false,
    istPflichtfeld: false,
    istSichtbar: false,
    maxLaenge: 0,
    resourceId: '',
    teilFormularReihenfolge: 0,
    regulaererAusdruck: '',
    resourceIdErrorMeldungPflichtfeld: '',
    resourceIdErrorMeldungValidierung: '',
    resourceIdErrorMeldungMaxLaenge: '',
    layoutKlasse: '',
    isValidationError: false,
    validationMessage: '',
    value: undefined,
    tabellenFeldname: '',
    tabellenName: '',
    infoTextTitle: undefined,
    infoTextContent: undefined,
    infoResourceId: undefined,
    parentCascadeId: undefined
}

export function createIntitialFormState(attachTo: string | undefined) : DynamicFormState {
    return {
        formName: "",
        rows: [record],
        attachTo: attachTo,
      };
}