import { CreateUserError, ErrorStateServiceForRoot } from "@/infrastructure/observables/errorState";
import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
import { Resource } from "@/infrastructure/resource/resource";


enum ErrorMessageType {
    ShowError,
    LogError
}

type ErrorMessageUser = {
    Title: string
    Content: string
    Type: ErrorMessageType
}

export class HandleErrorMessages {

    private static errorOff = false

    public static async PresentError(endpoint: string , errorMessage: string) : Promise<void> {
        const errorState = ErrorStateServiceForRoot.getErrorStateService()
        errorState.resetErrorState();

        const error = await HandleErrorMessages.AnalyseError(endpoint, errorMessage)
        if ( error.Type == ErrorMessageType.ShowError) {
            if( errorMessage && errorMessage.length > 0) {
                errorState.changeErrorState(CreateUserError(error.Title , error.Content, "api"))
                return     
            }
            errorState.changeErrorState(CreateUserError(error.Title , error.Content, "api"))
            return 
        }

        console.error(`Error on endpoint: ${endpoint} `)

    }

    public static SuppressError( onOff: boolean) {
        HandleErrorMessages.errorOff = onOff
    }

    // public static ResetErrorCounter(endpoint: string) {

    //     if ( !endpoint) return

    //     const controlerMethod = HandleErrorMessages.GetControlerMethod(endpoint)
    //     if ( ! controlerMethod ) {
    //         return 
    //     }

    //     switch (controlerMethod) {
    //         case "getProducts": 
    //             HandleErrorMessages.errorCount = 0
    //             return
    //     }

       
    // }

    private static async AnalyseError(endpoint: string , resourceId: string) : Promise<ErrorMessageUser> {
        const errorMessage: ErrorMessageUser = {
            Title: "",
            Content: "",
            Type: ErrorMessageType.LogError
        }

        const controlerMethod = HandleErrorMessages.GetControlerMethod(endpoint)
        if ( ! controlerMethod ) {
            return errorMessage
        }

        switch (controlerMethod) {
            case "cleanUpWebShopProducts":
                errorMessage.Type = ErrorMessageType.LogError
                return errorMessage;
            case "getJahreswechsel": 
                errorMessage.Type = ErrorMessageType.LogError
                return errorMessage;       
            case "getProducts": 
                if ( HandleErrorMessages.errorOff) {
                    errorMessage.Type = ErrorMessageType.LogError
                    return errorMessage;
                }
            }

        errorMessage.Title = await Resource.getResourceExchangeText(`${resourceId}.Title`)
        errorMessage.Content = await Resource.getResourceExchangeText(`${resourceId}.Content`)
        if (( errorMessage.Title && errorMessage.Title.length > 0) || ( errorMessage.Content && errorMessage.Content.length > 0)) {
            errorMessage.Type = ErrorMessageType.ShowError
            return errorMessage
        }
        

        errorMessage.Title = await Resource.getResourceExchangeText(`ApiError.${controlerMethod}.Title`)
        errorMessage.Content = await Resource.getResourceExchangeText(`ApiError.${controlerMethod}.Content`)
        errorMessage.Type = ErrorMessageType.ShowError

        if ( errorMessage.Content && errorMessage.Title && errorMessage.Title.length > 0 && errorMessage.Content.length > 0) {
            return errorMessage
        }

        errorMessage.Title = await Resource.getResourceExchangeText(`ApiError.Standard.Title`)
        errorMessage.Content = controlerMethod
        errorMessage.Type = ErrorMessageType.ShowError


        return errorMessage
    }


    private static GetControlerMethod(endpoint: string) : string | undefined {
        const method: string | undefined = undefined

        const temp = endpoint.split("/")
        if ( !temp || temp.length < 1 ) return undefined

        return temp[temp.length-1]
        return method
    }

}