import { StateManualRegistration } from './StateManualRegistration';
import { StateEmailVerification } from './StateEmailVerification';
import { StateUserProfile } from './StateUserProfile';
import {
  StateRule,
  StateTransitionOptions,
  StateResult,
  StateNavigationAction,
  Transition,
  // eslint-disable-next-line
  addNavigationElement,
  // eslint-disable-next-line
  addEmptyNavigationElement,
  addPreviousNext,
  // eslint-disable-next-line
  addPrevious,
  // eslint-disable-next-line
  addNext,  
  newTransition,
  newMainTransition,
  newTransitionCondition,
  newMainTransitionCondition
} from "../StateInterfaces";
import { NavigatonIcons } from "@/business/application/NavConst";
import {Commands} from "@/business/application/commands/Command";
import { BaseState } from "../StateMachine";
import { StateHome } from "./StateHome";
import { StateRegisterUser } from "./StateRegisterUser";
import { StatePasswordEmail } from "./StatePasswordEmail"
import { StateKarte } from "./StateKarte"
import { StateInfo } from "./StateInfo";
import { StatePrivacyPolicy } from "./StatePrivacyPolicy";
import { LightLog, LogTyp } from "@/business/log/LightLog"
import { StatePassword } from './StatePassword';
import { StatePasswordForAuthenticatedUser } from './StatePasswordForAuthenticatedUser';
import { StateVerification } from './StateVerification';
import { StatePasswordWithCurrentEmail } from './StatePasswordWithCurrentEmail';
import { StateGeneralSettings } from './StateGeneralSettings';
import { Resource } from '@/infrastructure/resource/resource';

export class StateLogin extends BaseState {
  private static mainState = "Login";
  private static transitions: Transition[] = [];

  public constructor() {
    super()    
    
  }


  public getStartTransition(condition: string) : StateResult {
    StateLogin.transitions = []
    this.getTransitions()
    const t = this.getTransitions()[0];
    return this.getTransition(t);
  }

 

  public next(rule: StateRule, options: StateTransitionOptions): StateResult {   
    return this.calculateState(rule, options, false, StateLogin.mainState);
  }

  public previous(
    rule: StateRule,
    options: StateTransitionOptions
  ): StateResult {
    return this.calculateState(rule, options, true, StateLogin.mainState);
  }

  protected calculateState(    
    rule: StateRule,
    options: StateTransitionOptions,
    isPrevious: boolean,
    // eslint-disable-next-line
    mainState: string
  ): StateResult {
    try {
      const context = this.getContext()
      const newState = this.calculateBusinessConditions(rule,this.getContext().getCurrentState());
      const transition = this.getTransitions().filter((row) => {
        if (row.newState === newState && row.nav === StateNavigationAction.Previous && isPrevious) {
          return true;
        }
        if (row.newState === newState && row.nav !== StateNavigationAction.Previous && !isPrevious) {
          return true;
        }

        if ( row.condition === newState && row.nav === StateNavigationAction.Decision ) {
          return true;
        }


      });  
      return this.checkState(transition[0], options);
  } catch( e ) {
    LightLog.add("Navigationsfehler bei: " , mainState , LogTyp.Fehler)
  }
  return this.checkState(this.getTransitions()[0], options);
}

  protected checkState(
    transition: Transition,
    options: StateTransitionOptions
  ): StateResult {
    let newResultState = this.getTransition(transition);   
    let newState: any
    if (!options.lookahead && transition.newState != "") {
      switch (transition.newMainState) {
        case "Info":
          newState = new StateInfo();
          newResultState = newState.getStartTransition("Login")
          this.getContext().transitionTo(newState,newResultState);
          break;
        case "GeneralSettings":
          newState = new StateGeneralSettings();
          newResultState = newState.getStartTransition("Login")
          this.getContext().transitionTo(newState,newResultState);
          break;
        case "PrivacyPolicy":
            newState = new StatePrivacyPolicy();
            newResultState = newState.getStartTransition("Login")
            this.getContext().transitionTo(newState,newResultState);
            break;
        case "PasswordForAuthenticatedUser":
              newState = new StatePasswordForAuthenticatedUser();
              newResultState = newState.getStartTransition("Login")
              this.getContext().transitionTo(newState,newResultState);
              break;                          
          case "UserProfile":
              newState = new StateUserProfile();
              newResultState = newState.getStartTransition("Login")                        
              this.getContext().transitionTo(newState,newResultState);
              break;            
          case "Karte":
            newState = new StateKarte();
            newResultState = newState.getStartTransition("Login")
            this.getContext().transitionTo(newState,newResultState);
            break;
          case "PasswordEmail":
            newState = new StatePasswordEmail();
            newResultState = newState.getStartTransition("Login")
            this.getContext().transitionTo(newState,newResultState);
            break;
            case "Password":
              newState = new StatePassword();
              newResultState = newState.getStartTransition("Login")
              this.getContext().transitionTo(newState,newResultState);
              break;      
          case "PasswordWithCurrentEmail":
              newState = new StatePasswordWithCurrentEmail();
              newResultState = newState.getStartTransition("PasswordWithCurrentEmail")
              this.getContext().transitionTo(newState,newResultState)                  
              break;      
          case "RegisterUser":
            newState = new StateRegisterUser()
            newResultState = newState.getStartTransition("Login")
            this.getContext().transitionTo(newState,newResultState)                      
            break;
          case "Home":
            newState = new StateHome();
            newResultState = newState.getStartTransition("Login")
            this.getContext().transitionTo(newState,newResultState)                     
            break;
          case "Verification":
              newState = new StateVerification();
              newResultState = newState.getStartTransition("Login")
              this.getContext().transitionTo(newState,newResultState)                     
              break;       
          case "ManualRegistration":
              newState = new StateManualRegistration();
              newResultState = newState.getStartTransition("Login")
              this.getContext().transitionTo(newState,newResultState)                     
              break;                                                
          case "EmailVerification":
              newState = new StateEmailVerification();
              newResultState = newState.getStartTransition("Login")
              this.getContext().transitionTo(newState,newResultState)                     
              break;          
          default:
            if ( transition.condition === "UpdateApp") {
              newState = new StateLogin()
              newResultState = newState.getStartTransition("Login")
              this.getContext().transitionTo(newState,newResultState)
            } else {
              newState = new StateLogin()
              newResultState = newState.getStartTransition("Login")
              this.getContext().transitionTo(newState,newResultState)
            }
        }
    }
    return newResultState;
  }

  protected calculateBusinessConditions(rule: StateRule, resultState: StateResult): string {
    if (rule.conditions === undefined || rule.conditions === null || rule.conditions.length <= 0) {
      return "";
    }

    if (rule.conditions.length == 1) { 
      if ( rule.conditions[0].value === undefined ) {
        return ""
      }
      return rule.conditions[0].value;
    }




    // if ( resultState.newState === "RegistrationPersonal") {            
    //     return "isCommunication";            
    // }

    // if ( resultState.newState === "RegistrationCommunication" && rule.conditions[0].nameId.toLowerCase().trim() === "isFishing".toLowerCase()
    // ) {
    //   if (rule.conditions[0].value == "true") {
    //     return "isFishing";
    //   }
    // }
    // if (
    //   resultState.newState === "RegistrationCommunication" &&
    //   rule.conditions[1].nameId.toLowerCase().trim() === "isHunting".toLowerCase()
    // ) {
    //   if (rule.conditions[1].value == "true") {
    //     return "isHunting";
    //   }
    // }
    // if (resultState.newState === "RegistrationCommunication") {
    //     return "isPrivacy";
    // }
    // if (
    //   resultState.newState === "RegistrationFishing" &&
    //   rule.conditions[1].nameId.toLowerCase().trim() === "isHunting".toLowerCase()
    // ) {
    //   if (rule.conditions[1].value == "true") {
    //     return "isHunting";
    //   }
    // }
    // if (resultState.newState === "RegistrationFishing") {
    //     return "isPrivacy";
    // }
    // if (resultState.newState === "RegistrationHunting") {            
    //     return "isPrivacy";            
    // }        
    

    return "";
  }

  public getTransitions(): Transition[] {
    if (StateLogin.transitions.length === 0) {

      let t: Transition = { mainState: "Login", newMainState: "Login", nav: StateNavigationAction.Next, oldState: "Login", newState: "Login", condition: ""};      
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateLogin.transitions.push(t);

      


      //*****************************************
      //--> End: Rules for user checks
      //*****************************************

      t = { mainState: "Start", newMainState: "Login", nav: StateNavigationAction.Next, oldState: "Start", newState: "Login", condition: "Login"};      
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateLogin.transitions.push(t);

      t = { mainState: "Login", newMainState: "Karte", nav: StateNavigationAction.Next, oldState: "Login", newState: "Karte", condition: ""};      
      t = addPrevious(t, Commands.PreviousPage);
      StateLogin.transitions.push(t);

      t = { mainState: "Login", newMainState: "GeneralSettings", nav: StateNavigationAction.Next, oldState: "Login", newState: "GeneralSettings", condition: ""};      
      t = addPrevious(t, Commands.PreviousPage);
      StateLogin.transitions.push(t);

      t = { mainState: "Login", newMainState: "PrivacyPolicy", nav: StateNavigationAction.Next, oldState: "Login", newState: "PrivacyPolicy", condition: ""};      
      t = addPrevious(t, Commands.PreviousPage);
      StateLogin.transitions.push(t);

      t = { mainState: "Login", newMainState: "UserProfile", nav: StateNavigationAction.Next, oldState: "Login", newState: "UserProfile", condition: ""};      
      t = addPrevious(t, Commands.PreviousPage);
      StateLogin.transitions.push(t);

      t = { mainState: "Login", newMainState: "Info", nav: StateNavigationAction.Next, oldState: "Login", newState: "Info", condition: ""};      
      t = addPrevious(t, Commands.PreviousPage);
      StateLogin.transitions.push(t);

      t = { mainState: "Login", newMainState: "PasswordEmail", nav: StateNavigationAction.Next, oldState: "Login", newState: "WaitForEmailConfirm", condition: ""};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleRegisterLogin);
      StateLogin.transitions.push(t);

      t = { mainState: "Login", newMainState: "RegisterUser", nav: StateNavigationAction.Next, oldState: "Login", newState: "RegistrationLogin", condition: ""};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleRegisterLogin);
      StateLogin.transitions.push(t);

      t = { mainState: "Login", newMainState: "Home", nav: StateNavigationAction.Jump, oldState: "Login", newState: "Home", condition: "Home"} 
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });     
      StateLogin.transitions.push(t);

      t = { mainState: "Login", newMainState: "Login", nav: StateNavigationAction.Next, oldState: "Login", newState: "Login", condition: "UpdateApp"};      
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateLogin.transitions.push(t);      

      //*****************************************
      //--> Start: Rules for user checks
      //*****************************************
      t = newMainTransitionCondition("Login","Login", "PasswordWithCurrentEmail", "PasswordWithCurrentEmail", "UserExistence.ValidButWrongPassword" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateLogin.transitions.push(t);

      t = newMainTransitionCondition("Login","Login", "PasswordEmail", "WaitForConfirm", "UserExistence.KnownButHasNoEmail" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateLogin.transitions.push(t);

      t = newMainTransitionCondition("Login","Login", "PrivacyPolicy", "PrivacyPolicy", "UserExistence.PrivacyPolicy" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateLogin.transitions.push(t);

      t = newMainTransitionCondition("Login","Login", "RegisterUser", "RegistrationLogin", "UserExistence.RegistrationLogin" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateLogin.transitions.push(t);
      
      t = newMainTransitionCondition("Login","Login", "UserProfile", "UserPersonalData", "UserExistence.RefreshUserData" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateLogin.transitions.push(t);

      t = newMainTransitionCondition("Login","Login", "UserProfile", "UserPersonalData", "UserExistence.RefreshUserData" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateLogin.transitions.push(t);

      t = newMainTransitionCondition("Login","Login", "Verification", "WaitOfUserActivation", "UserExistence.Activation" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateLogin.transitions.push(t);      

      // t = newMainTransitionCondition("Login","Login", "Verification", "WaitOfUserActivation", "UserExistence.Known" ,  StateNavigationAction.Decision)
      // t = addNext(t, Commands.NextPage);
      // StateLogin.transitions.push(t);      


      t = newMainTransitionCondition("Login","Login", "EmailVerification", "EmailInput", "UserExistence.ActivationByEmailInput" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateLogin.transitions.push(t);      

      t = newMainTransitionCondition("Login","Login", "ManualRegistration", "ShowInformation", "UserExistence.ExistsButNoAccess" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateLogin.transitions.push(t);      

      

    }
    return StateLogin.transitions;
  }
}
