import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import RegistrationLoginData from "../components/registration/RegistrationLoginData.vue";
import RegistrationPersonData from "../components/registration/RegistrationPersonData.vue";

import RegistrationCommunicationData from "../components/registration/RegistrationCommunicationData.vue";
import RegistrationFishingData from "../components/registration/RegistrationFishingData.vue";
import RegistrationHuntingData from "../components/registration/RegistrationHuntingData.vue";
import RegistrationPrivacyPolicyData from "../components/registration/RegistrationPrivacyPolicyData.vue";
import RegistrationWaitForConfirmation from "../components/registration/RegistrationWaitForConfirmation.vue";
import PasswordRequestEmail from "../components/password/PasswordRequestEmail.vue";
import PasswordRequest from "../components/password/PasswordRequest.vue";
import PasswordEmail from "../components/password/PasswordEmail.vue";
import Password from "../components/password/Password.vue";
import PersonProducts from "@/components/person-products/PersonProducts.vue"
import PersonProduct from "@/components/person-products/PersonProduct.vue"
import Settings from "../components/general/settings.vue"
import generalSettings from "../components/general/GeneralSettings.vue"

import PasswordForAuthenticatedUser from "../components/password/PasswordForAuthenticatedUser.vue"
import HomeInfo from "../components/general/HomeInfo.vue"
import Hunting from "../views/Hunting.vue"

import WildBook from "../components/hunting/wildbook/WildBook.vue"
import WildBookFilter from "../components/hunting/wildbook/WildBookFilter.vue"


import WildBookEntryCoordinates from "../components/hunting/wildbookentry/WildBookEntryCoordinates.vue"
import WildBookEntryLocation from "../components/hunting/wildbookentry/WildBookEntryLocation.vue"
import WildBookEntryHunting from "../components/hunting/wildbookentry/WildBookEntryHunting.vue"
import WildBookEntryAnimal from "../components/hunting/wildbookentry/WildBookEntryAnimal.vue"
import WildBookEntryAnimalExtension from "../components/hunting/wildbookentry/WildBookEntryAnimalExtension.vue"

import WildBookFallData from "../components/hunting/wildbookfall/WildBookFallData.vue"
import WildBookFallLocation from "../components/hunting/wildbookfall/WildBookFallLocation.vue"
import WildBookFallAnimal from "../components/hunting/wildbookfall/WildBookFallAnimal.vue"
import WildBookFallAnimalExtension from "../components/hunting/wildbookfall/WildBookFallAnimalExtension.vue"
import WildBookFallCoordinates from "../components/hunting/wildbookfall/WildBookFallCoordinates.vue"

import WildBookObserveData from "../components/hunting/wildbookobserve/WildBookObserveData.vue"
import WildBookObserveLocation from "../components/hunting/wildbookobserve/WildBookObserveLocation.vue"
import WildBookObserveAnimal from "../components/hunting/wildbookobserve/WildBookObserveAnimal.vue"
import WildBookObserveCoordinates from "../components/hunting/wildbookobserve/WildBookObserveCoordinates.vue"


import WildBookObserveSpecData from "../components/hunting/wildbookobservespec/WildBookObserveSpecData.vue"
import WildBookObserveSpecLocation from "../components/hunting/wildbookobservespec/WildBookObserveSpecLocation.vue"
import WildBookObserveSpecCoordinates from "../components/hunting/wildbookobservespec/WildBookObserveSpecCoordinates.vue"

import Fishing from "@/components/fishing/Fishing.vue"
import FishBook from "../components/fishing/fishbook/FishBook.vue"
import FishBookFilter from "../components/fishing/fishbook/FishBookFilter.vue"

import FishBookEntryLeasing from "../components/fishing/fishbookleasing/FishBookEntryLeasing.vue"


import UserPersonalData from "../components/profile/UserPersonalData.vue"
import UserAddress from "../components/profile/UserAddress.vue"
import UserCommunication from "../components/profile/UserCommunication.vue"
import UserHunting from "../components/profile/UserHunting.vue"
import UserFishing from "../components/profile/UserFishing.vue"
import UserPrivacy from "../components/profile/UserPrivacy.vue"

import WebShopKasse  from "../components/webshop/WebShopKasse.vue"
import WebShopWarenKorb from "../components/webshop/WebShopWarenKorb.vue"

import WebShopList from "@/components/webshop/WebShopList.vue"
import WebShopFilter from "../components/webshop/WebShopFilter.vue"
import WebShopDaten from "@/components/webshop/WebShopDaten.vue"

import FilterSwitch from "@/components/Shared/dynamicList/filter/FilterSwitch.vue"


import EmailVerificationRequest from "@/components/verification/EmailVerificationRequest.vue";
import EmailVerification from "@/components/verification/EmailVerificationRequest.vue"
import UserActivation from "@/components/verification/UserActivation.vue"
import ManualRegistration from "@/components/registration/ManualRegistration.vue"
import PasswordWithCurrentEmail from "@/components/password/PasswordWithCurrentEmail.vue"
import EmailWithCurrentPassword from "@/components/email/EmailWithCurrentPassword.vue"
import FailedLoginRegistration from "@/components/registration/FailedLoginRegistration.vue"

import UserPrivacyOnly from "@/components/profile/UserPrivacyOnly.vue"
import UserPrivacyOnlyRegistrationByCode from "@/components/profile/UserPrivacyOnlyRegistrationByCode.vue"

import UserPrivacyByCode from "@/components/profile/UserPrivacyByCode.vue"
import UserRegistrationByCode from "@/components/profile/UserRegistrationByCode.vue"
import VirtualForm from "@/components/Shared/virtualform/VirtualForm.vue"
import FischbuchEintragPatentrevier from "@/components/fishing/fishbook/FischBuchEintragPatentrevier.vue"
import FischbuchEintragPachtrevier from "@/components/fishing/fishbook/FischbuchEintragPachtrevier.vue"
import FischbuchEintragLinthKanal from "@/components/fishing/fishbook/FischbuchEintragLinthkanal.vue"

import DynamicDetailList from "@/components/Shared/dynamicListDetailList/DynamicDetailList.vue";
import KnownRegistration from "@/components/registration/KnownRegistration.vue"
import WebshopFailed from "@/components/webshop/WebShopFailed.vue"
import WebshopSuccess from "@/components/webshop/WebShopSuccess.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: Login,
    props: true
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/email-verification-request",
    name: "EmailInput",
    component: EmailVerificationRequest
  },
  {
    path: "/email-verification",
    name: "EmailVerification",
    component: EmailVerification
  },  
  {  
    path: "/registration-login",
    name: "RegistrationLogin",
    component: RegistrationLoginData
    //component: VirtualForm
  },
  {
    path: "/registration-personal",
    name: "RegistrationPersonal",
    component: RegistrationPersonData
    //component: VirtualForm
  },
  {
    path: "/registration-communication",
    name: "RegistrationCommunication",
    component: RegistrationCommunicationData
  },
  {
    path: "/registration-fishing",
    name: "RegistrationFishing",
    component: RegistrationFishingData,
    props: true
  },
  {
    path: "/registration-hunting",
    name: "RegistrationHunting",
    component: RegistrationHuntingData
  },
  {
    path: "/registration-privacy",
    name: "RegistrationPrivacy",
    component: RegistrationPrivacyPolicyData
  },
  {
    path: "/registration-confirmation",
    name: "RegistrationWaitForConfirm",
    component: RegistrationWaitForConfirmation
  },
  {
    path: "/password-email-confirmation",
    name: "WaitForEmailConfirm",
    component: PasswordRequestEmail
  },
  {
    path: "/password-email",
    name: "PasswordEmail",
    component: PasswordEmail
  },
  {
    path: "/password-confirm",
    name: "WaitForConfirm",
    component: PasswordRequest
  },
  {
    path: "/password",
    name: "Password",
    component: Password
  },
  {
    path: "/password-current-email",
    name: "PasswordWithCurrentEmail",
    component: PasswordWithCurrentEmail
  },
  {
    path: "/email-current-password",
    name: "EmailWithCurrentPassword",
    component: EmailWithCurrentPassword
  },
  {
    path: "/passwordForAuthenticatedUser",
    name: "PasswordForAuthenticatedUser",
    component: PasswordForAuthenticatedUser
  },
  {
    path: "/user-activation",
    name: "WaitOfUserActivation",
    component: UserActivation
  },
  {
    path: "/show-registration-information",
    name: "ShowValidFailedLoginInformation",
    component: FailedLoginRegistration
  },  
  {
    path: "/show-registration-login-problem-information",
    name: "ShowInformation",
    component: ManualRegistration
  },  
  {
    path: "/show-registration-login-information",
    name: "ShowKnownInformation",
    component: KnownRegistration
  },  
  {
    path: "/personProducts",
    name: "Products",
    component: PersonProducts,
    children: [
      {
        path: "personProduct",
        name: "PersonProduct",
        components: {                    
          listDetail: PersonProduct
        },
      },
      {
        path: "filter",
        name: "DynamicOnOffFilter",
        components: {          
          filterDetail: FilterSwitch,          
        },
      },
    ]
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: "/general-settings",
    name: "GeneralSettings",
    component: generalSettings
  },
  {
    path: "/info",
    name: "Info",
    component: HomeInfo
  },
  {
    path: "/hunting",
    name: "Hunting",
    component: Hunting
  },
  {
    path: "/fishing",
    name: "Fishing",
    component: Fishing
  },
  {         
    path: "/wildbookfall",
    name: "WildBookFall",
    component: WildBookFallCoordinates
  
  },  
  {
    path: "/wildbook",
    name: "WildBook",
    component: WildBook,
    children: [      
      {
          path: "/wildbookfilter",
          name: "WildBookFilter",
          components: {          
            filterDetail: WildBookFilter,          
          },          
      },
      {
        path: "/wildbookfall-map",
        name: "WildBookFallMap",
        components: {
          formDetail: WildBookFallCoordinates }
      },
      {
        path: "/wildbookentry-map",
        name: "WildBookEntryMap",
        components: {
          formDetail: WildBookEntryCoordinates }
      },
      {
        path: "/wildbookentry-map",
        name: "WildBookObserveMap",
        components: {
          formDetail: WildBookObserveCoordinates }
      },
      {
        path: "/wildbookentry-map",
        name: "WildBookObserveSpecMap",
        components: {
          formDetail: WildBookObserveSpecCoordinates }
      },

      
    ]    
  },

 

  {         
    path: "/wildbookfallLocation",
    name: "WildBookFallLocation",
    component: WildBookFallLocation
  
  },  
  {
    path: "/wildbookfalldata",
    name: "WildBookFallData",
    component: WildBookFallData     
  },

  {
    path: "/wildbookfallanimal",
    name: "WildBookFallAnimal",
    component: WildBookFallAnimal     
  },
  {
    path: "/wildbookfallanimalextension",
    name: "WildBookFallAnimalExtension",
    component: WildBookFallAnimalExtension 
  },       
  {
    path: "/wildbookentry",
    name: "WildBookEntry",
    component: WildBookEntryCoordinates
  },
  {
    path: "/wildbookentrylocation",
    name: "WildBookEntryLocation",
    component: WildBookEntryLocation
  },
  {
    path: "/wildbookentryhunting",
    name: "WildBookEntryHunting",
    component: WildBookEntryHunting
  },
  {
    path: "/wildbookentryanimal",
    name: "WildBookEntryAnimal",
    component: WildBookEntryAnimal
  },
  {
    path: "/wildbookentryanimalextension",
    name: "WildBookEntryAnimalExtension",
    component: WildBookEntryAnimalExtension
  },
 
  
  {
    path: "/wildbookobservedata",
    name: "WildBookObserveData",
    component: WildBookObserveData
  },
  {
    path: "/wildbookobservelocation",
    name: "WildBookObserveLocation",
    component: WildBookObserveLocation 
  },
  {
    path: "/wildbookobserveanimal",
    name: "WildBookObserveAnimal",
    component: WildBookObserveAnimal 
  },
  {
    path: "/wildbookobserve",
    name: "WildBookObserve",
    component: WildBookObserveCoordinates
  },

  {
    path: "/wildbookobservespecdata",
    name: "WildBookObserveSpecData",
    component: WildBookObserveSpecData
  },
  {
    path: "/wildbookobservelocationspec",
    name: "WildBookObserveSpecLocation",
    component: WildBookObserveSpecLocation
  },
  {
    path: "/ wildbookobservespec",
    name: "WildBookObserveSpec",
    component: WildBookObserveSpecCoordinates
  },
  {
    path: "/fishbook",
    name: "FishBook",
    component: FishBook,
    children: [
      {
        path: "detail-liste",
        name: "FishbookDetailRecord",
        components: {                    
          listDetail: DynamicDetailList
        },
      },
      {
          path: "/fishbookfilter",
          name: "FishBookFilter",
          components: {          
            filterDetail: FishBookFilter,          
          },
          
      },
    ]    
  },
  {
    path: "/fishbook-entry-patent-revier",
    name: "FischbuchEintragPatentrevier",
    component: FischbuchEintragPatentrevier
  },
  {
    path: "/fishbook-entry-pacht-revier",
    name: "FischbuchEintragPachtrevier",
    component: FischbuchEintragPachtrevier
  },
  {
    path: "/fishbook-entry-linthkanal",
    name: "FischbuchEintragLinthkanal",
    component: FischbuchEintragLinthKanal
  },
  {
    path: "/fishbookentryleasing",
    name: "FishBookEntryLeasing",
    component: FishBookEntryLeasing
  },
  {
    path: "/user-personal-data",
    name: "UserPersonalData",
    component: UserPersonalData
  },
 
  {
    path: "/useraddress",
    name: "UserAddress",
    component: UserAddress
  },
 
  {
    path: "/usercommunication",
    name: "UserCommunication",
    component: UserCommunication
  },
 
  {
    path: "/userhunting",
    name: "UserHunting",
    component: UserHunting
  },
  {
    path: "/userfishing",
    name: "UserFishing",
    component: UserFishing
  },
  {
    path: "/userprivacy",
    name: "UserPrivacy",
    component: UserPrivacy
  },
  {
    path: "/userprivacy-registration",
    name: "UserPrivacyByCode",
    component: UserPrivacyByCode
  },
  {
    path: "/userprivacy-code",
    name: "UserRegistrationByCode",
    component: UserRegistrationByCode
  },
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicyOnly",
    component: UserPrivacyOnly
  },
  {
    path: "/userprivacy-policy-code",
    name: "PrivacyPolicyRegistrationBycode",
    component: UserPrivacyOnlyRegistrationByCode
  },
  {
    path: "/payment",
    name: "WebShopKasse",
    component: WebShopKasse
  },
  {
    path: "/shoppingcard",
    name: "WebShopWarenKorb",
    component: WebShopWarenKorb
  },
  {
    path: "/webshop-products",
    name: "WebShop",
    component: WebShopList,
    children: [
      {
        path: "/webshop-filter",
        name: "WebShopFilter",
        components: {          
          filterDetail: WebShopFilter,          
        },
      },
      {
        path: "/webshop-product",
        name: "WebShopDaten",
        components: {
          formDetail: WebShopDaten }
      },
    ]
  },
  {
    path: "/web-shop-cancel",
    name: "WebshopFailed",
    component: WebshopFailed
    
  },
  {
    path: "/web-shop-success",
    name: "WebshopSuccess",
    component: WebshopSuccess
    
  }
  

  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const isAuthenticated: boolean = true
//   if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
//   else next()
// })

// router.afterEach((to, from, failure) => {
//   if (!failure) sendToAnalytics(to.fullPath)
// })

export default router;
