import { Resource } from "@/infrastructure/resource/resource";
import { DynamicGridRow } from "./DynamicListTypes";
import { ComboBoxDataSource } from "../Dynamicform/ComboBoxDataSource";
import { DynamicRecord } from "../Dynamicform/DynamicFormData";

export async function resolveMainListResourceTitel( viewId: string , resourceId: string, row: DynamicGridRow) : Promise<string> {
    if ( row && viewId === "fishBookEntriesWithDetail") {
        if ( row.rows && row.rows.length > 0) {
            let anzahlTotal = 0
            for( const subRow of row.rows) {
                if (subRow.syncState && subRow.syncState.stateResourceId && subRow.syncState.stateResourceId !== "Sync.State.Delete" && subRow.detailForm && subRow.detailForm.view && subRow.detailForm.view.length > 0) {
                    for( const formRow of subRow.detailForm.view) {
                        if ( formRow.formularFeldname === "Anzahl") {
                            const temp = +formRow.value
                            if ( !Number.isNaN(temp)) {
                                anzahlTotal += temp
                            }
                        }
                    }
                }
            }
            if ( anzahlTotal === 0) {
                return await Resource.getDynamicResourceText(resourceId, [""]);
            }
            return await Resource.getDynamicResourceText(resourceId, [anzahlTotal.toString()]);

        }
    }

    if ( row && viewId === "wildBookEntriesWithDetail") {        
        let anzahlTotal = 0           
            if (row.syncState && row.syncState.stateResourceId && row.syncState.stateResourceId !== "Sync.State.Delete" && row.detailForm && row.detailForm.view && row.detailForm.view.length > 0) {
                for( const formRow of row.detailForm.view) {
                    if ( formRow.formularFeldname === "Anzahl") {
                        const temp = +formRow.value
                        if ( !Number.isNaN(temp)) {
                            anzahlTotal += temp
                        }
                    }
                }
            }
        
        if ( anzahlTotal === 0) {
            return await Resource.getDynamicResourceText(resourceId, [""]);
        }
        return await Resource.getDynamicResourceText(resourceId, [anzahlTotal.toString()]);
    }

    return await Resource.getResourceText(resourceId)
}

export async function resolveSubListResourceTitel(  resourceId: string | undefined, row: DynamicGridRow) : Promise<string> {       
    let anzahlTotal = 0
    let gewicht = ""
    let laenge = ""    
    let fischart = ""
    let dauer = ""
    if ( row ) {
        if (row.detailForm && row.detailForm.view && row.detailForm.view.length > 0) {          
            for( const formRow of row.detailForm.view) {
                if ( formRow.formularFeldname === "Anzahl") {
                    const temp = +formRow.value
                    if ( !Number.isNaN(temp)) {
                        anzahlTotal += temp
                    }
                }
                if ( formRow.formularFeldname === "Dauer") {
                    dauer = formRow.value
                }
                if ( formRow.formularFeldname === "Gewicht") {
                    gewicht = formRow.value
                }
                if ( formRow.formularFeldname === "Laenge") {
                    laenge = formRow.value
                }
                if ( formRow.formularFeldname === "Fischart") {
                    const tempValue = await ComboBoxDataSource.GetValue( "Fischart", formRow.value)
                     if ( tempValue ) fischart = tempValue ?? ""
                }                
            }
        }

        const params: string[] = []
        if ( anzahlTotal === 0) {
            params.push("")
        } else {
            params.push(anzahlTotal.toString())
        }
        params.push(gewicht.toString())
        params.push(laenge.toString())

        row.sort = fischart

        row.cell.content.title = ""
        row.cell.content.titleResourceId = `@[0]  @Fischfang.Subtitel.AnzahlFische@-${fischart}`
        row.cell.content.titleResourceId += `@<br />@[1]  @Fischfang.Subtitel.Gewicht`
        row.cell.content.titleResourceId += `@<br />@[2]  @Fischfang.Subtitel.Laenge`			   

        row.cell.additional.textResourceId = ""
        if (dauer && dauer.length >= 0) {
            row.cell.additional.textResourceId = `@[0]  @Fischfang.Subtitel.Dauer@-${dauer}`
            const result = await Resource.getDynamicResourceText( row.cell.additional.textResourceId, [dauer])
            if( result) row.cell.additional.text = result
            if ( row.cell.additional.text && row.cell.additional.text.indexOf("@") >= 0) {
                row.cell.additional.text = ""
            }
        }

        return await Resource.getDynamicResourceText( row.cell.content.titleResourceId, params);



    }
    return ""
}
   

export async function resolveSubListResourceTitelFromForm(  row: DynamicGridRow , form: DynamicRecord[]) : Promise<string> {       
    let anzahlTotal = 0
    let gewicht = ""
    let laenge = ""    
    let fischart = ""
    let dauer = ""
    if ( row ) {
                 
            for( const formRow of form) {
                if ( formRow.formularFeldname === "Anzahl") {
                    const temp = +formRow.value
                    if ( !Number.isNaN(temp)) {
                        anzahlTotal += temp
                    }
                }
                if ( formRow.formularFeldname === "Dauer") {
                    dauer = formRow.value
                }
                if ( formRow.formularFeldname === "Gewicht") {
                    gewicht = formRow.value
                }
                if ( formRow.formularFeldname === "Laenge") {
                    laenge = formRow.value
                }
                if ( formRow.formularFeldname === "Fischart") {
                    const tempValue = await ComboBoxDataSource.GetValue( "Fischart", formRow.value)
                     if ( tempValue ) fischart = tempValue ?? ""
                }                
            }
        

        const params: string[] = []
        if ( anzahlTotal === 0) {
            params.push("")
        } else {
            params.push(anzahlTotal.toString())
        }
        params.push(gewicht.toString())
        params.push(laenge.toString())

        row.sort = fischart

        row.cell.content.title = ""
        row.cell.content.titleResourceId = `@[0]  @Fischfang.Subtitel.AnzahlFische@-${fischart}`
        row.cell.content.titleResourceId += `@<br />@[1]  @Fischfang.Subtitel.Gewicht`
        row.cell.content.titleResourceId += `@<br />@[2]  @Fischfang.Subtitel.Laenge`			   

        row.cell.additional.textResourceId = ""
        if (dauer && dauer.length >= 0) {
            row.cell.additional.textResourceId = `@[0]  @Fischfang.Subtitel.Dauer@-${dauer}`
            const result = await Resource.getDynamicResourceText( row.cell.additional.textResourceId, [dauer])
            if( result) row.cell.additional.text = result
            if ( row.cell.additional.text && row.cell.additional.text.indexOf("@") >= 0) {
                row.cell.additional.text = ""
            }
        }

        return await Resource.getDynamicResourceText( row.cell.content.titleResourceId, params);



    }
    return ""
}