import { StateService } from '../../../../infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { DynamicGrid , DynamicGridRow, DynamicGridRequest} from "@/components/Shared/dynamicList/DynamicListTypes"
import { SupportedDetailLists } from '../DynamicDetailListTypes';
import { RefreshServerObjects } from '@/infrastructure/resource/RefreshServerObjects';



export interface DynamicDetailListState {     
    row: DynamicGridRow    
    request: DynamicGridRequest 
    isNotification: boolean;
    isClosed: boolean
  }
  
const initialState: DynamicDetailListState = {   
    isNotification: false,
    isClosed: false,
    row: {
        cell: {
          image:{},
          content: {},
          additional: {}
        }
    },
    request: {
        userId: "" ,  
        viewId: "",
        detailId: "",
        detailList: false,
        allRecords: false,
        lastRefreshDate: RefreshServerObjects.getLastGridFormRefreshDateSync(),
        lastViewIdRefreshState: RefreshServerObjects.getLastGridFormRefreshDateSync()
    }
  };

export function createIntitialListDetailState() : DynamicDetailListState {
    return initialState;
}

export async function CreateDetailListState(request: DynamicGridRequest, parentRow: DynamicGridRow, isNotification: boolean, isClosed: boolean) : Promise<DynamicDetailListState> {
    return {             
        row: parentRow,
        request: request,
        isNotification: isNotification,
        isClosed: isClosed
    }
}

export class DynamicDetailListChannelForRoot extends StateService<DynamicDetailListState>{
    public static service: DynamicDetailListChannelForRoot;
    private static attachetTo: SupportedDetailLists = undefined
    private static firstAttached = true

    public static getService() : DynamicDetailListChannelForRoot {
        if ( DynamicDetailListChannelForRoot.service === undefined) {
            DynamicDetailListChannelForRoot.service = new DynamicDetailListChannelForRoot();
            this.service = DynamicDetailListChannelForRoot.service
        }
        return DynamicDetailListChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<DynamicDetailListState> {
        return this.subscribe();
    }

    public changeDetailState(state: DynamicDetailListState) : void {
        DynamicDetailListChannelForRoot.firstAttached = false
        if ( DynamicDetailListChannelForRoot.attachetTo !== state.request.detailId as SupportedDetailLists ) {
            DynamicDetailListChannelForRoot.attachetTo = state.request.detailId as SupportedDetailLists 
            DynamicDetailListChannelForRoot.firstAttached = true
        }
        this.changeState(state);
    }

    public isAttached(detailListName: SupportedDetailLists): boolean {
        if ( detailListName === undefined ) {
            return false
        }
        
        if ( DynamicDetailListChannelForRoot.attachetTo == detailListName && DynamicDetailListChannelForRoot.attachetTo !== undefined && detailListName !== undefined) {
            return true
        }
        
        return false
    }

    public isFirstAttached() {
        return DynamicDetailListChannelForRoot.firstAttached
    }


    public get detailState(): DynamicDetailListState {
        return this.state;
    }
}