
import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { Anrede, Fischart, FischereiRevierPerson, FischereiRevierTable, JagdRevier, Land, Nachtsichthilfe, Nachtsichtzielhilfe, PatentTable, Sprache, SystemParameter, Tierart, WildbuchBeobachtungMethode, WildbuchBeobachtungSpezialFall, WildbuchErfassungStatus, WildbuchJagdBetriebsart, WildbuchNachsuche, WildbuchOrtsbeschreibung, WildbuchTierGeschlecht, WildbuchTieralter, WildbuchTodesursache, WildbuchTyp, WildbuchZeit, addOrReplaceRecord, deleteStore, getRecord, loadAllRecords, localizationTable, openDatabase, recordCount } from "@/infrastructure/database/asyncDb";
import {sanitize} from "@/infrastructure/functional/sanitize"
import { ConvertUtcStringToDate } from '@/infrastructure/functional/datetimehelper';
import { UserProfile } from "../backend/userProfile";
import { Out } from "../frontent/clientMessage";
import { ComboBoxDataSource } from "@/components/Shared/Dynamicform/ComboBoxDataSource";
import { RefreshServerObjects } from "./RefreshServerObjects";
import { Navigate } from "../backend/async/Navigate";
import { isMultiLanguage } from "../frontent/LanguageBrowserDetection";
import { RefreshLoginWhenResourceLoaded } from "../frontent/RefreshLoginWhenResourceLoaded";
import { RefreshAppLanguage } from "../backend/RefreshAppLanguage";
import { reSyncFishbook } from "../serviceworker-related/ReSyncFishBook";

export interface ResourceItem {
  resourceId: string
  value: string  
}


export enum ResourceText {
  UpdateModalDialogName = "UpdateApp.ModalDialogName",
  UpdateAppVersionTitle = "UpdateApp.VersionTitle",
  UpdateAppVersionText = "UpdateApp.VersionText",
  ServiceWorkerRegisterUndefined = "ServieWorkerRegistration undefined"
}

export class Resource {

  //private static locked = false
  
  private static readonly _resourceSetUri = "api/resource/loadResourceSet"
  private static readonly _resourceSetSaveUri = "api/resource/saveResourceSet"
  
  //private static _language =UserProfile.CurrentProfile.language

  public static getResourceUpdateAppText(resourceId: string) {
        let language = "de"
        try {
          language = UserProfile.GetClientSettingsSync().language
        } catch(e) {
          Out.noOperation("")
        }
       
        if ( language === "fr") {
          switch(resourceId) {
            case ResourceText.UpdateModalDialogName:
              return "fr-updateApp"
            case ResourceText.UpdateAppVersionTitle:
              return "fr-Neue App Version"
            case ResourceText.UpdateAppVersionText:
              return "fr-Es ist eine neue Version verfügbar. Wollen Sie die Version übernehmen ?"
          }
          return ""
        }
        switch(resourceId) {
          case ResourceText.UpdateModalDialogName:
            return "updateApp"
          case ResourceText.UpdateAppVersionTitle:
            return "Neue App Version"
          case ResourceText.UpdateAppVersionText:
            return "Es ist eine neue Version verfügbar. Wollen Sie die Version übernehmen ?"
        }
       
        return ""
  }

  private static SyncWrapperData = {
    offlineNotAllowedTitle: "",
    offlineNotAllowedContent: "",
    registrierungNotSyncedTitle: "",
    registrierungNotSyncedContent: "",
    logoutNotSyncedTitle: "",
    logoutNotSyncedContent: "",

    offlineInformationTitle: "",
    offlineInformationContent: "",
	
    validationMessageZukunft: "",
    validationMessagePasswordEmpty: "",
    validationMessagePasswordDifferent: "",
    validationMessageMandatory: "",
    validationMessageCorrect: "",
    validationMessageFalsch: "",

    logoutFishingStartedContent: "",
    logoutFishingStartedTitle: "",

    Vor: "",
    Zurueck: "",
    Info: "",
    Settings: "",
    Person: "",
    Abmelden: "",
    Home: "",
    Produkte: "",
    Fischbuch: "",
    Shop: "",
    Warenkorb: "",
    Wildbuch: "",
    Pdf: "",
    
    NotInstalledAsPwaTitle: "",
    NotInstalledAsPwaContent: ""

  }

  private static validationMessages:any [] = []
  
  public static async loadSyncWrapper() {
    Resource.SyncWrapperData.offlineNotAllowedTitle = await Resource.getResourceTextOrDefault("Offline.NotAllowed.Title" , "Offline Mode")
    Resource.SyncWrapperData.offlineNotAllowedContent = await Resource.getResourceTextOrDefault("Offline.NotAllowed.Content" , "Diese Aktion ist im Offline Mode nicht möglich.")
    Resource.SyncWrapperData.registrierungNotSyncedTitle = await Resource.getResourceTextOrDefault("Registrierung.NotSynced.Title" , "Synchronisierung steht aus")
    Resource.SyncWrapperData.registrierungNotSyncedContent = await Resource.getResourceTextOrDefault("Registrierung.NotSynced.Content" , "Sie können sich erst wieder Registrieren wenn alle Ihre Daten synchronisiert wurden")
    Resource.SyncWrapperData.logoutNotSyncedTitle = await Resource.getResourceTextOrDefault("Logout.NotSynced.Title" , "Synchronisierung steht aus")
    Resource.SyncWrapperData.logoutNotSyncedContent = await Resource.getResourceTextOrDefault("Logout.NotSynced.Content" , "Sie können sich erst abmelden wenn alle Ihre Daten synchronisiert wurden")

    Resource.SyncWrapperData.offlineInformationTitle = await Resource.getResourceTextOrDefault("Offline.Information.Title" , "Offline Mode")
    Resource.SyncWrapperData.offlineInformationContent = await Resource.getResourceTextOrDefault("Offline.Information.Content" , "Sie befinden sich im Offline Mode")


    Resource.SyncWrapperData.validationMessageFalsch = await Resource.getResourceTextOrDefault("Validation.Message.Falsch" , "Datum ist verkehrt")
    Resource.SyncWrapperData.validationMessageZukunft = await Resource.getResourceTextOrDefault("Validation.Message.Zukunft" , "Datum liegt in der Zukunft")
    Resource.SyncWrapperData.validationMessagePasswordEmpty = await Resource.getResourceTextOrDefault("Validation.Message.PasswordEmpty" , "Die Kennwörter dürfen nicht leer sein")
    Resource.SyncWrapperData.validationMessagePasswordDifferent = await Resource.getResourceTextOrDefault("Validation.Message.PasswordDifferent" , "Die Kennwörter stimmen nicht überein")
    Resource.SyncWrapperData.validationMessageMandatory = await Resource.getResourceTextOrDefault("Validation.Message.Mandatory" , "Das Eingabefeld ist ein Pflichtfeld")
    Resource.SyncWrapperData.validationMessageCorrect = await Resource.getResourceTextOrDefault("Validation.Message.Correct" , "Bitte korrigieren sie das Eingabefeld")
    

    Resource.SyncWrapperData.NotInstalledAsPwaTitle = await Resource.getResourceTextOrDefault("App.NotInstalledAsPwa.Title" , "App Installation")
    Resource.SyncWrapperData.NotInstalledAsPwaContent = await Resource.getResourceTextOrDefault("App.NotInstalledAsPwa.Content" , "Bitte installieren sie die Anwendung als App. Der Browser Mode wird nicht unterstützt!")


    Resource.validationMessages = []

    const records = await loadAllRecords(localizationTable)
    if( records) {
      for(const row of records) {
        const temp = row as any
        if (temp.item.resourceId.indexOf("Validation") >= 0) {
          Resource.validationMessages.push({resourceId: temp.item.resourceId, value: temp.item.value})
        }
      }
    }

   

    let language = "de"
    try {
      language = (await UserProfile.GetClientSettingsAsync()).language
    } catch (e) {
      Out.noOperation("")
    }

    Resource.SyncWrapperData.logoutFishingStartedTitle = await Resource.getResourceTextOrDefault("Logout.FishingStarted.Title" , "Fischen ist gestartet")
    Resource.SyncWrapperData.logoutFishingStartedContent = await Resource.getResourceTextOrDefault("Logout.FishingStarted.Content" , "Logout nicht möglich. Bitte stoppen Sie das Fischen")
 


    // Resource.SyncWrapperData.Vor = "Vorwärts"
    // Resource.SyncWrapperData.Zurueck = "Zurück"
    // Resource.SyncWrapperData.Info = "Info"
    // Resource.SyncWrapperData.Settings = "Settings"
    // Resource.SyncWrapperData.Person = "Person"
    // Resource.SyncWrapperData.Abmelden = "Abmelden"
    // Resource.SyncWrapperData.Home = "Home"
    // Resource.SyncWrapperData.Produkte = "Produkte"
    // Resource.SyncWrapperData.Fischbuch = "Fischbuch"
    // Resource.SyncWrapperData.Shop = "Shop"
    // Resource.SyncWrapperData.Warenkorb = "Warenkorb"
    // Resource.SyncWrapperData.Wildbuch = "Wildbuch"
    // Resource.SyncWrapperData.Pdf = "Pdf"
    // if ( language === "fr") {
    //   Resource.SyncWrapperData.Vor = "fr-Vorwärts"
    //   Resource.SyncWrapperData.Zurueck = "fr-Zurück"
    //   Resource.SyncWrapperData.Info = "fr-Info"
    //   Resource.SyncWrapperData.Settings = "fr-Settings"
    //   Resource.SyncWrapperData.Person = "fr-Person"
    //   Resource.SyncWrapperData.Abmelden = "fr-Abmelden"
    //   Resource.SyncWrapperData.Home = "fr-Home"
    //   Resource.SyncWrapperData.Produkte = "fr-Produkte"
    //   Resource.SyncWrapperData.Fischbuch = "fr-Fischbuch"
    //   Resource.SyncWrapperData.Shop = "fr-Shop"
    //   Resource.SyncWrapperData.Warenkorb = "fr-Warenkorb"
    //   Resource.SyncWrapperData.Wildbuch = "fr-Wildbuch"
    //   Resource.SyncWrapperData.Pdf = "fr-Pdf"
    // }

   

    Resource.SyncWrapperData.Vor = await Resource.getResourceTextOrDefault("Navigation.Icon.Vor" , "Vorwärts")
    Resource.SyncWrapperData.Zurueck = await Resource.getResourceTextOrDefault("Navigation.Icon.Zurueck" , "Zurück")
    Resource.SyncWrapperData.Info = await Resource.getResourceTextOrDefault("Navigation.Icon.Info" , "Info")
    Resource.SyncWrapperData.Settings = await Resource.getResourceTextOrDefault("Navigation.Icon.Settings" , "Settings")
    Resource.SyncWrapperData.Person = await Resource.getResourceTextOrDefault("Navigation.Icon.Person" , "Person")
    Resource.SyncWrapperData.Abmelden = await Resource.getResourceTextOrDefault("Navigation.Icon.Abmelden" , "Abmelden")
    Resource.SyncWrapperData.Home = await Resource.getResourceTextOrDefault("Navigation.Icon.Home" , "Home")
    Resource.SyncWrapperData.Produkte = await Resource.getResourceTextOrDefault("Navigation.Icon.Produkte" , "Produkte")
    Resource.SyncWrapperData.Fischbuch = await Resource.getResourceTextOrDefault("Navigation.Icon.Fischbuch" , "Fischbuch")
    Resource.SyncWrapperData.Shop = await Resource.getResourceTextOrDefault("Navigation.Icon.Shop" , "Shop")
    Resource.SyncWrapperData.Warenkorb = await Resource.getResourceTextOrDefault("Navigation.Icon.Warenkorb" , "Warenkorb")
    Resource.SyncWrapperData.Wildbuch = await Resource.getResourceTextOrDefault("Navigation.Icon.Wildbuch" , "Wildbuch")
    Resource.SyncWrapperData.Pdf = await Resource.getResourceTextOrDefault("Navigation.Icon.Pdf" , "Pdf")

  }

  public static getValidationMessage(resourceId: string) : string {
    try {
      if( !resourceId ) return resourceId
      if (Resource.validationMessages && Resource.validationMessages.length > 0) {
        for( const row of Resource.validationMessages) {
          if( resourceId === row.resourceId) {
            return row.value
          }
        }
      } 
      return resourceId
    } catch(e) {
      return resourceId
    }
  }

  public static getResourceTextSyncMode(resourceId: string, defaultValue: string) : string {
    switch (resourceId) {
      case "Offline.NotAllowed.Title": 
        return Resource.SyncWrapperData.offlineNotAllowedTitle
      case "Offline.NotAllowed.Content": 
        return Resource.SyncWrapperData.offlineNotAllowedContent
      case "Registrierung.NotSynced.Title":
        return Resource.SyncWrapperData.registrierungNotSyncedTitle
      case "Registrierung.NotSynced.Content":
        return Resource.SyncWrapperData.registrierungNotSyncedContent
      case "Logout.NotSynced.Title":
        return Resource.SyncWrapperData.logoutNotSyncedTitle
      case "Logout.NotSynced.Content":
        return Resource.SyncWrapperData.logoutNotSyncedContent
      case "Offline.Information.Content":
        return Resource.SyncWrapperData.offlineInformationContent
        case "Offline.Information.Title":
          return Resource.SyncWrapperData.offlineInformationTitle

      case "Logout.FishingStarted.Title":
        return Resource.SyncWrapperData.logoutFishingStartedTitle
      case "Logout.FishingStarted.Content":
        return Resource.SyncWrapperData.logoutFishingStartedContent
  

      case "Validation.Message.Falsch":
        return Resource.SyncWrapperData.validationMessageFalsch
      case "Validation.Message.Zukunft":
        return Resource.SyncWrapperData.validationMessageZukunft
      case "Validation.Message.PasswordEmpty":
        return  Resource.SyncWrapperData.validationMessagePasswordEmpty
      case "Validation.Message.PasswordDifferent":
        return  Resource.SyncWrapperData.validationMessagePasswordDifferent
      case "Validation.Message.Mandatory":
        return  Resource.SyncWrapperData.validationMessageMandatory
      case "Validation.Message.Correct":
        return  Resource.SyncWrapperData.validationMessageCorrect     
        

      case "Navigation.Icon.Vor":
        return Resource.SyncWrapperData.Vor
      case "Navigation.Icon.Zurueck":
        return Resource.SyncWrapperData.Zurueck
      case "Navigation.Icon.Info":
        return Resource.SyncWrapperData.Info
      case "Navigation.Icon.Settings":
        return Resource.SyncWrapperData.Settings
      case "Navigation.Icon.Person":
        return Resource.SyncWrapperData.Person
      case "Navigation.Icon.Abmelden":
        return Resource.SyncWrapperData.Abmelden
      case "Navigation.Icon.Home":
        return Resource.SyncWrapperData.Home
      case "Navigation.Icon.Fischbuch":
        return Resource.SyncWrapperData.Fischbuch
      case "Navigation.Icon.Wildbuch":
        return Resource.SyncWrapperData.Wildbuch
      case "Navigation.Icon.Shop":
        return Resource.SyncWrapperData.Shop
      case "Navigation.Icon.Warenkorb":
        return Resource.SyncWrapperData.Warenkorb
      case "Navigation.Icon.Pdf":
        return Resource.SyncWrapperData.Pdf
      case "Navigation.Icon.Produkte":
        return Resource.SyncWrapperData.Produkte
                                                                            
      case "App.NotInstalledAsPwa.Title" :
        return Resource.SyncWrapperData.NotInstalledAsPwaTitle
      case "App.NotInstalledAsPwa.Content" :
        return Resource.SyncWrapperData.NotInstalledAsPwaContent

    }
      
    
    return "";

  }

  public static async updateResourceText(resourceItems: ResourceItem[]) {

    if( Navigate.IsLocked() ) {
      return undefined
    }


    const client = AsyncRestClient.Create(true);
    if (client.isFailure) return false;

    const result = await client
        .getValue()
        .exchangeForComponents<string, object>(process.env.VUE_APP_API + Resource._resourceSetSaveUri , JSON.stringify({language: await (await UserProfile.GetClientSettingsAsync()).language, resources: resourceItems, resourceSet: "app"}));  

    if (result.isFailure) return false;    
    const resources:ResourceItem[]  = result.getValue() as ResourceItem[]    
    for (const item of resources) {
      await addOrReplaceRecord(localizationTable, {id:item.resourceId, item})
    }
  }

  public static async getResourceText( resourceId: string ) : Promise<string> {
    if( resourceId === undefined || resourceId === null) {
      return ""
    }
    const response = await getRecord(localizationTable, resourceId)
    if ( !response) {      
      return resourceId
    }

    const result = response.item

    return (result as ResourceItem).value
    //return sanitize((result as ResourceItem).value)
  }

  public static async getResourceTextOrDefault( resourceId: string , defaultValue: string ) : Promise<string> {
    if( resourceId === undefined || resourceId === null) {
      return ""
    }
    const response = await getRecord(localizationTable, resourceId)
    if ( !response) {      
      return defaultValue
    }

    const result = response.item

    return (result as ResourceItem).value
    //return sanitize((result as ResourceItem).value)
  }

  public static async getResourceTextOrUndefined( resourceId: string ) : Promise<string | undefined> {
    if( !resourceId || resourceId.length < 1) {
      return undefined
    }
    const response = await getRecord(localizationTable, resourceId)
    if ( !response) {      
      return undefined
    }

    const result = response.item
    return (result as ResourceItem).value
    //return sanitize((result as ResourceItem).value)
  }

  public static async getDynamicResourceText(resourceIdWithParameter: string, parameter: string[] | undefined) : Promise<string>
  {
    let result = ""
    if(!resourceIdWithParameter) return ""
    const parts:string[] =  resourceIdWithParameter.split("@")
    let i = 0
    let skip = false

    for(const part of parts) {
      if (!part) continue
      if (part.indexOf("[") >= 0) {
        if ( parameter && parameter[i]) {
          result += part.replace(`[${i}]`, parameter[i])          
        } else {
          result += part.replace(`[${i}]`, "")
        }
        if (parameter && ( !parameter[i] || parameter[i] === "")) {
          skip = true
        }
        i++
        continue
      }
      
      if ( ! skip) result += await this.getResourceText(part)
      skip = false
    }

    return result;
  }

  public static async getResourceInfoText( resourceId: string ) : Promise<string | undefined> {
    if( resourceId === undefined || resourceId === null) {
      return ""
    }


    const response = await getRecord(localizationTable, resourceId)
    if ( !response || response === undefined || response === "") 
      return""
            

    const result = response.item
    return (result as ResourceItem).value
    //return sanitize((result as ResourceItem).value)
  }


  public static async getResourceExchangeText( resourceId: string ) : Promise<string> {
    if( resourceId === undefined || resourceId === null) {
      return ""
    }


    const response = await getRecord(localizationTable, resourceId)
    if ( !response || response === undefined || response === "") 
      return""
            

    const result = response.item
    return (result as ResourceItem).value
    //return sanitize((result as ResourceItem).value)
  }



  public static async loadResources(caller: string) : Promise<boolean> {    
    
   

    //if ( Resource.locked) return false

    if( Navigate.IsLocked() ) {
      //Resource.locked = false
      return false
    }

    //Resource.locked = true

    try {

      await RefreshServerObjects.refreshSyncDates()

      const client = AsyncRestClient.Create(true);
      if (client.isFailure) return false;

      const result = await client
          .getValue()
          .exchangeForComponents<string, object>(process.env.VUE_APP_API + Resource._resourceSetUri , JSON.stringify({
            language: (await UserProfile.GetClientSettingsAsync()).language, 
            resourceSet: "app",
            lastRefreshDate: await RefreshServerObjects.getLastResourceRefreshDate()
          }));  

      if (result.isFailure) return false;    
      const response = result.getValue() as any

      if(response && response.offline) {
        //Resource.locked = false
        return true;
      } 

      if( response.resources && response.resources.length > 0 ) {

        const resources:ResourceItem[]  = response.resources as ResourceItem[]
        
        if ( resources && resources.length > 0) {
          for (const item of resources) {
            try {
              await addOrReplaceRecord(localizationTable, {id:item.resourceId, item})
            } catch(e) {
              Out.noOperation()
            }
          }

          await Resource.loadSyncWrapper()
          await RefreshServerObjects.addLastResourceRefreshDate(response.lastRefreshDate ?? RefreshServerObjects.getCurrentLastRefreshDate())
        }
      }

      if ( caller &&  caller === "App" && isMultiLanguage()) {
        const service = RefreshLoginWhenResourceLoaded.getRefreshLoginService() 
        service.refreshLoginState({refresh: true})
        service.refreshLoginState({refresh: false})
      }

      if ( caller &&  caller === "App" ) {
        const refreshAppServvice = RefreshAppLanguage.getRefreshAppService()
        refreshAppServvice.refreshAppLanguageState({refresh: true})
        refreshAppServvice.refreshAppLanguageState({refresh: false})
      }

      //Resource.locked = false
      return true;

    } catch(e) {
      //Resource.locked = false
      return false
    }
  }

}


