import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60f0ac54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row align-items-center h-100" }
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = { class: "mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.content), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.stopPayment()), ["prevent"])),
        class: "mx-3 btn button_danger align-self-center",
        style: {"margin-bottom":"20px"}
      }, _toDisplayString($setup.buttonText), 1)
    ])
  ]))
}