import { RouteRecordName, RouteRecordNormalized } from "vue-router";

export class RouterOfflineGuard
{
    public static onlineRoutes = ["Info","Test", "UserPersonalData", "Login","RegistrationLogin","WaitForEmailConfirm","Settings","GeneralSettings"]
    
    public static RouteTo: string | undefined
    
    
    
    public static IsAllowed( routeFrom: RouteRecordName | null | undefined,  routeTo: RouteRecordName | null | undefined) : boolean {

        if ( !routeTo ) return true

        RouterOfflineGuard.RouteTo = routeTo.toString()
        if ( !routeFrom && routeTo === "Login") return true

        
        if (routeFrom && routeFrom === "DynamicOnOffFilter" && routeTo && routeTo === "WebShop") {
            return false
        }

        if (routeFrom && routeFrom === "WebShop" && routeTo && routeTo === "WebShopFilter") {
            return false
        }

        if (routeFrom && routeFrom === "WebShopFilter" && routeTo && routeTo === "WebShopWarenKorb") {
            return false
        }

        if (routeFrom && routeFrom === "WebShopFilter" && routeTo && routeTo === "WebShopDaten") {
            return false
        }

        if (routeFrom && routeFrom === "WebShopWarenKorb" && routeTo && routeTo === "WebShopKasse") {
            return false
        }


        if (routeFrom && routeFrom === "Products" && routeTo && routeTo === "WebShop") {
            return false
        }


        if ( this.onlineRoutes.indexOf(routeTo?.toString()) >-1 ) {
            return false
        }

        return true;
    }


}

