import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";


export interface RouterState {
    routeName: string | undefined
    routeFromName: string | undefined
}

export function createIntitialRouterState() : RouterState {
    return {
        routeName: undefined,
        routeFromName: undefined
    }
}


export class RouterChangedNotification extends StateService<RouterState>{
    public static service: RouterChangedNotification;

    public static getService() : RouterChangedNotification {
        if (!RouterChangedNotification.service) {
            RouterChangedNotification.service = new RouterChangedNotification();
            this.service = RouterChangedNotification.service
        }
        return RouterChangedNotification.service;
    }

    private constructor() {
        super(createIntitialRouterState());
       
    }
    
    public subscription(): Observable<RouterState> {
        return this.subscribe();
    }

    public changeRouterState(routerState: RouterState) : void {               
        this.changeState(routerState);
    }

    public get RouterState(): RouterState {
        return this.state;
    }
}