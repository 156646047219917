import { AsyncRestClient } from '@/infrastructure/backend/async/AsyncRestClient';
import { Identity } from '../backend/userIdentity';
import { addOrReplaceRecord, deleteStore, fishbookTable, getRecord, loadAllRecords, personProductsTable } from '../database/asyncDb';
import { DynamicGrid, DynamicGridRequest, DynamicGridRow } from '@/components/Shared/dynamicList/DynamicListTypes';
import { Result } from '../functional/result';
import { UserProfile } from '../backend/userProfile';
import { ConvertCanonicalToDate, ConvertCanonicalToDayDate, ConvertEuropeanToDate, ConvertUtcStringToDate, ConvertUtcStringToDateOrUndefined, makeValidUtcString } from '../functional/datetimehelper';
import { Resource } from '../resource/resource';
import { getWildBookEntriesWithDetail } from './DynamicGridHuntingService';
import { RefreshServerObjects } from '../resource/RefreshServerObjects';
import { Navigate } from '../backend/async/Navigate';
import { LoaderStateServiceForRoot } from '../observables/loaderState';
import { Out } from '../frontent/clientMessage';
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";


export async function getDynamicPdfFromApiOld(request: DynamicGridRequest) : Promise<any> {

    if( Navigate.IsLocked() ) {
        return undefined
    }

    const client = AsyncRestClient.Create(true);
    if (client.isFailure) throw new Error("Error.getDynamicGridData");
    const result = await client
        .getValue()
        .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/Pdf/getFishBookPdf", JSON.stringify(request));  

    if (result.isFailure) throw new Error(`${result.error}`)            
    const content = JSON.parse( result.getValue() ) as any 
    //console.table(content.report.columns)

    if ( content && content.title) {
        content.title = await Resource.getResourceText(content.title)
        for( const column of content.report.columns) {
            column.resourceId = await Resource.getResourceText(column.resourceId)
        }
        return content
    } 
    
    return undefined
    
}

// testAuftragBestaetigungPdf
export async function getDynamicPdfFromApiTest(request: DynamicGridRequest) : Promise<any> {

    if( Navigate.IsLocked() ) {
        return undefined
    }

    const url = "api/Pdf/testAuftragBestaetigungPdf"
    const testRequest = {
        userId: Identity.getIdentity().getValue().UserId,
        language: (await UserProfile.GetClientSettingsAsync()).language
    }
    
    //testRequest.language = "fr"

    const client = AsyncRestClient.Create(true);
    if (client.isFailure) throw new Error("Error.testAuftragBestaetigungPdf");
    const result = await client
        .getValue()
        .exchangeForComponents<string, string>(process.env.VUE_APP_API + url, JSON.stringify(testRequest));  

        const loaderState = LoaderStateServiceForRoot.getLoaderStateService()

        loaderState.changeLoaderState({isInProgress: false})
    
    return undefined
}



export async function getDynamicPdfFromApi(request: DynamicGridRequest) : Promise<any> {

    if( Navigate.IsLocked() ) {
        return undefined
    }

    let content =  await Resource.getResourceText(`Report.Fischbuch.Email.Content`)
    let title =  await Resource.getResourceText(`Report.Fischbuch.Email.Titel`)

    let url = ""
    let name = ""
    if (request.viewId === "wildBookEntriesWithDetail") {
        url = "api/Pdf/downLoadHuntBookPdf"
        name = "Fischbuch"
        content =  await Resource.getResourceText(`Report.Wildbuch.Email.Content`)
        title =  await Resource.getResourceText(`Report.Wildbuch.Email.Titel`)
    
    }

    if (request.viewId === "fishBookEntriesWithDetail") {
        url = "api/Pdf/downLoadFishBookPdf"
        name = "WildBuch"
    }

    if ( url === "") return undefined

    if ( request.dynamicFilterItems && request.dynamicFilterItems.length > 0) {
        for( const row of request.dynamicFilterItems) {
            if ( row.value && typeof(row.value) !== "string" && row.value.key) {
                row.value = row.value.key
            }
        }
    }


    const client = AsyncRestClient.Create(true);
    if (client.isFailure) throw new Error("Error.getDynamicGridData");
    const result = await client
        .getValue()
        //.downLoadFile<string>(process.env.VUE_APP_API + url, JSON.stringify(request), name )
        .exchangeForComponents<string, string>(process.env.VUE_APP_API + url, JSON.stringify(request));  

        const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        loaderState.changeLoaderState({isInProgress: false})

      

        try {
            const resultContent = result.getValue() as any
            if ( resultContent.errorResourceId && resultContent.errorResourceId.length > 0) {
                content =  await Resource.getResourceText(`${resultContent.errorResourceId}.Content`)
                title =  await Resource.getResourceText(`${resultContent.errorResourceId}.Titel`)
            }
        } catch(e) {
            Out.noOperation("")
        }

        const notficationState = NotificationStateServiceForRoot.getNotificationStateService()  
        notficationState.resetNotificationState()  
        notficationState.changeNotificationState({isNotification: true, text:  content, title: title})
    
    return undefined
}




export async function getDynamicGridFromApi(request: DynamicGridRequest, allRecords: boolean) : Promise<DynamicGrid | undefined> {
    let gridObject : DynamicGrid = {userId: request.userId , viewId: request.viewId}
    request.lastRefreshDate = await RefreshServerObjects.getLastGridFormRefreshDate()

    if (request.viewId === "personProductsWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridProductsRefreshDate()
    }
    if (request.viewId === "wildBookEntriesWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridWildBookRefreshDate()
    }
    if (request.viewId === "fishBookEntriesWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridFishBookRefreshDate()
    }

    if( Navigate.IsLocked() ) {
        return undefined
    }
    const client = AsyncRestClient.Create(true);
    if (client.isFailure) throw new Error("Error.getDynamicGridData");
    const result = await client
        .getValue()
        .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/grid/loadDynamicGridData", JSON.stringify(request));  

    if (result.isFailure) throw new Error(`${result.error}`)            
    gridObject = JSON.parse(JSON.stringify(result.getValue())) as unknown as DynamicGrid 

    if ( gridObject.viewId === "personProductsWithDetail" && gridObject.rows) {
        
        if ((gridObject.isModified ?? true)) {
            await deleteDatabaseStore(personProductsTable)
            for (const row of gridObject.rows) {
                if ( row.cell.readableIdentification?.startDate && row.cell.readableIdentification?.startDate.length > 0 ) {
                    row.cell.readableIdentification.startDate = makeValidUtcString(row.cell.readableIdentification?.startDate)
                }    
                if ( row.cell.readableIdentification?.stopDate && row.cell.readableIdentification?.stopDate.length > 0 ) {
                    row.cell.readableIdentification.stopDate = makeValidUtcString(row.cell.readableIdentification?.stopDate)
                }          
                //await addRecordsToDatabaseWhenNotExists(row, personProductsTable)
                await addDatabaseRecord(row, personProductsTable)
            }

            if(gridObject && gridObject.rows && gridObject.rows.length > 0)
                await RefreshServerObjects.addLastGridProductsRefreshDate(gridObject.lastRefreshDate ?? RefreshServerObjects.getCurrentLastRefreshDate())

        }

        const tempGridObject = await getFishBookPersonProducts(request.viewId, allRecords, request)
        if ( ! tempGridObject) return
        if( tempGridObject.getValue() === undefined) return
        gridObject = tempGridObject.getValue()

 
    }

    if ( gridObject.viewId === "fishBookEntriesWithDetail" && gridObject.rows) {
        for (const row of gridObject.rows) {      
            if ( row.cell.readableIdentification?.startDate && row.cell.readableIdentification?.startDate.length > 0 ) {
                row.cell.readableIdentification.startDate = makeValidUtcString(row.cell.readableIdentification?.startDate)
            }    
            if ( row.cell.readableIdentification?.stopDate && row.cell.readableIdentification?.stopDate.length > 0 ) {
                row.cell.readableIdentification.stopDate = makeValidUtcString(row.cell.readableIdentification?.stopDate)
            } 
            if (row.cell.readableIdentification?.startDateForNextDetail && row.cell.readableIdentification?.startDateForNextDetail.length > 0) {
                row.cell.readableIdentification.startDateForNextDetail = makeValidUtcString(row.cell.readableIdentification?.startDateForNextDetail);
            }
            if ( row.rows && row.rows.length > 0) {
                for ( const detailRow of row.rows) {
                    if (detailRow.cell.readableIdentification?.startDate && detailRow.cell.readableIdentification?.startDate.length > 0) {
                        detailRow.cell.readableIdentification.startDate = makeValidUtcString(detailRow.cell.readableIdentification?.startDate);
                    }
                    if (detailRow.cell.readableIdentification?.stopDate && detailRow.cell.readableIdentification?.stopDate.length > 0) {
                        detailRow.cell.readableIdentification.stopDate = makeValidUtcString(detailRow.cell.readableIdentification?.stopDate);
                    }
                }
            }          
        }
        const tempGridObject = await getFishBookEntriesWithDetail(request.viewId, allRecords, request)
        if ( ! tempGridObject) return
        if( tempGridObject.getValue() === undefined) return
        gridObject = tempGridObject.getValue()

        if(gridObject && gridObject.rows && gridObject.rows.length > 0)
            await RefreshServerObjects.addLastGridFishBookRefreshDate(RefreshServerObjects.getCurrentLastRefreshDate())

    }

    if ( gridObject.viewId === "wildBookEntriesWithDetail" && gridObject.rows ) {
        for (const row of gridObject.rows) {      
            if ( row.cell.readableIdentification?.startDate && row.cell.readableIdentification?.startDate.length > 0 ) {
                row.cell.readableIdentification.startDate = makeValidUtcString(row.cell.readableIdentification?.startDate)
            }    
            if ( row.cell.readableIdentification?.stopDate && row.cell.readableIdentification?.stopDate.length > 0 ) {
                row.cell.readableIdentification.stopDate = makeValidUtcString(row.cell.readableIdentification?.stopDate)
            } 
            if (row.cell.readableIdentification?.startDateForNextDetail && row.cell.readableIdentification?.startDateForNextDetail.length > 0) {
                row.cell.readableIdentification.startDateForNextDetail = makeValidUtcString(row.cell.readableIdentification?.startDateForNextDetail);
            }         
        }
        const tempGridObject = await getWildBookEntriesWithDetail(request.viewId, allRecords, request)
        if ( ! tempGridObject) return
        if( tempGridObject.getValue() === undefined) return
        gridObject = tempGridObject.getValue()

        if(gridObject && gridObject.rows && gridObject.rows.length > 0)
            await RefreshServerObjects.addLastGridWildBookRefreshDate(RefreshServerObjects.getCurrentLastRefreshDate())

    }


    return gridObject
}

function isFishBookFilter(request: DynamicGridRequest) :boolean {
    if( request?.dynamicFilterItems ) {
        for( const filterItem of request.dynamicFilterItems ) {
            if( filterItem.value && filterItem.value !== "") {
                return true
            }
        }
    }
    return false
}

export function clearFilter(parentRow: DynamicGridRow) {
    parentRow.isVisible = true
    if ( ! parentRow.rows) return
    for( const row of parentRow.rows ) {
        row.isVisible = true
    }
}

function fishBookFilter(request: DynamicGridRequest, parentRow: DynamicGridRow) :boolean {
  
    if( ! parentRow.rows || parentRow.rows.length === 0 ) {
        return true
    }

    if( request?.dynamicFilterItems ) {
        for( const filterItem of request.dynamicFilterItems ) {
            if( !filterItem.value || filterItem.value === "") {
                continue
            }


            for ( const row of parentRow.rows ) {
                if( ! row.detailForm || ! row.detailForm.view || row.detailForm.view.length === 0 ) {
                    continue
                }

                if ( !row.isVisible ) {
                    continue
                }

                
                for (const item of row.detailForm?.view) {
                    if ( item.formularFeldname.toLowerCase() === filterItem.formularFeldname.toLocaleLowerCase() 
                        || item.formularFeldname.toLowerCase() === "datum" && filterItem.formularFeldname.toLocaleLowerCase() === "datumvon"
                        || item.formularFeldname.toLowerCase() === "datum" && filterItem.formularFeldname.toLocaleLowerCase() === "datumbis"
                    ) {
                        if ( filterItem.formularFeldname.toLocaleLowerCase() == "fischereirevier") {
                            if( filterItem.value !== (item.value ?? "")) {
                                if (filterItem.namedValueList && filterItem.namedValueList.length > 0) {
                                    let found = false
                                    for(const listItem  of filterItem.namedValueList) {
                                        if ( listItem.key === filterItem.value) {
                                            if(listItem.value === item.value) {
                                                found = true                                                
                                            }
                                        }
                                    }
                                    if ( !found ) {                                   
                                        row.isVisible = false
                                        break                              
                                    }
                                     
                                }                               
                            }                             
                        }
                        if ( filterItem.formularFeldname === "Fischart" ) {
                            if( filterItem.value !== item.value) {
                                row.isVisible = false                               
                                break                              
                            }
                        }
                        if ( filterItem.formularFeldname == "Gewicht" ) {
                            if ( isNaN(filterItem.value) || isNaN(item.value)) {
                                row.isVisible = false
                                break                              
                            }
                            if( ""+filterItem.value !== ""+item.value ) {
                                row.isVisible = false
                                break                              
                            }
                        }
                        if ( filterItem.formularFeldname == "Laenge" ) {
                            if ( isNaN(filterItem.value) || isNaN(item.value)) {
                                row.isVisible = false
                                break                              
                            }
                            if( ""+filterItem.value !== ""+item.value ) {
                                row.isVisible = false
                                break                              
                            }
                        }
                        if ( filterItem.formularFeldname == "Dauer" ) {
                            if ( isNaN(filterItem.value) || isNaN(item.value)) {
                                row.isVisible = false
                                break                              
                            }
                            if( ""+filterItem.value !== ""+item.value ) {
                                row.isVisible = false
                                break                              
                            }
                        }                        
                        if ( filterItem.formularFeldname === "DatumVon" ) {
                            const filterValue = ConvertCanonicalToDayDate(filterItem.value, false)
                            const itemValue = ConvertCanonicalToDayDate(item.value, false)
                            if ( !filterValue || !itemValue ) {
                                row.isVisible = false
                                break                              
                            } else {
                                if ( itemValue.getTime() < filterValue.getTime() ) {
                                    row.isVisible = false                                  
                                    break                              
                                }
                            }
                        }
                        if ( filterItem.formularFeldname === "DatumBis" ) {
                            const filterValue = ConvertCanonicalToDayDate(filterItem.value, false)
                            const itemValue = ConvertCanonicalToDayDate(item.value, false)
                            if ( !filterValue || !itemValue ) {
                                row.isVisible = false
                                break                              
                            } else {
                                if ( itemValue.getTime() > filterValue.getTime() ) {
                                    row.isVisible = false
                                    break                              
                                }    
                            }
                        }
                    } 
                }                
            }
        }
    }

    if ( parentRow.rows ) {
        let cnt = 0
        for( const row of parentRow.rows) {
            if ( row.isVisible ?? true ) {
                cnt ++
            }
        }
        if ( cnt === 0) {
            parentRow.isVisible = false
            return false        
        }
    }

    return true
}


export async function getFishBookEntriesWithDetail( viewId: string, allRows: boolean, request?: DynamicGridRequest ) : Promise<Result<DynamicGrid> | undefined> {
    const dataRows = await loadAllRecords(fishbookTable)
    if ( dataRows === undefined ) return undefined

    const rows: DynamicGridRow[] = []
    for ( const item of dataRows as any ) {
        
        if ((!item.row || !item.row.rows || item.row.rows.length === 0) && !allRows) {
            continue
        } 

        clearFilter(item.row)


        if ( ! request) {
            rows.push( item.row )
            continue
        }

        if (! isFishBookFilter(request)) {
            rows.push( item.row )
            continue
        } 

        if( fishBookFilter(request,item.row)) {
            rows.push(item.row)
        }
    }

    if ( ! allRows) {
    rows.sort(function(a: DynamicGridRow,b: DynamicGridRow){        
        //return new Date(b.cell.readableIdentification!.startDate ?? "").getTime() - new Date(a.cell.readableIdentification!.startDate ?? "").getTime();
        return (b.cell.readableIdentification!.startDate ?? "").localeCompare(a.cell.readableIdentification!.startDate ?? "");
      });

      for( const row of rows) {
        if( row.rows && row.rows.length >0) {
            row.rows.sort((a: DynamicGridRow,b: DynamicGridRow) => ( (a.sort ?? "") > (b.sort ?? "")) ? 1 : ((b.sort ?? "") > (a.sort ?? "") ? -1 : 0))
        }
      }      
    }    

    const grid: DynamicGrid = {
        userId: Identity.getIdentity().getValue().UserId,
        viewId: viewId,
        pages: 1,
        pageSize: 6,
        rows: rows as unknown as DynamicGridRow[],
        language: (await UserProfile.GetClientSettingsAsync()).language,
        isOffline: true     
    }   
    return Result.ok<DynamicGrid>(grid)
}

export async function getFishBookPersonProducts( viewId: string, allRows: boolean, request?: DynamicGridRequest) : Promise<Result<DynamicGrid> | undefined> {
    const dataRows = await loadAllRecords(personProductsTable)
    if ( dataRows === undefined ) return undefined

    const rows: DynamicGridRow[] = []
    for ( const item of dataRows as any ) {
        if ( request ) {
            if ( item.row.activeData === request.activeData)
                rows.push( item.row )
        } else {
            rows.push( item.row )
        }
        
    }

    if ( ! allRows) {
    rows.sort(function(a: DynamicGridRow,b: DynamicGridRow){        
        //return new Date(b.cell.readableIdentification!.startDate ?? "").getTime() - new Date(a.cell.readableIdentification!.startDate ?? "").getTime();
        return (b.cell.readableIdentification!.startDate ?? "").localeCompare(a.cell.readableIdentification!.startDate ?? "");  
    });

      for( const row of rows) {
        if( row.rows && row.rows.length >0) {
            row.rows.sort((a: DynamicGridRow,b: DynamicGridRow) => ( (a.sort ?? "") > (b.sort ?? "")) ? 1 : ((b.sort ?? "") > (a.sort ?? "") ? -1 : 0))
        }
      }      
    }

    

    const grid: DynamicGrid = {
        userId: Identity.getIdentity().getValue().UserId,
        viewId: viewId,
        pages: 1,
        pageSize: 6,
        rows: rows as unknown as DynamicGridRow[],
        language: (await UserProfile.GetClientSettingsAsync()).language,
        isOffline: true     
    }   
    return Result.ok<DynamicGrid>(grid)
}

const addRecordsToDatabaseWhenPossible = async (row: DynamicGridRow, table: string) => {
    const result = await getRecord(table, row.rowId ?? "-")
    if ( result === undefined) {
        await addOrReplaceRecord(table, {id: row.rowId, row})                
    }
}

const deleteDatabaseStore = async ( table: string) => {
    await deleteStore(table)
}

const addDatabaseRecord = async (row: DynamicGridRow, table: string) => {
        await addOrReplaceRecord(table, {id: row.rowId, row})                
}


