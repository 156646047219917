import { createStore } from "vuex";
import { navigationState } from "./modules/navigation"
import { dynamicformstore } from "@/store/modules/dynamicformstore"
import { uistatestore } from "@/store/modules/uistatestore"
import { modalddialogstore} from "@/store/modules/modaldialogstore"

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    dynamicformstore,
    navigationState,    
    uistatestore,
    modalddialogstore,
  }
});
