import { UserRegistration } from "@/components/registration/userRegistration";
import { AsyncCommand } from "../Command";
import { DynamicDataSet, DynamicFormData} from "@/components/Shared/Dynamicform/DynamicFormData";


export class RuleUserProfileSavePasswordEmail extends AsyncCommand {
  
  private dynamicForm: DynamicFormData;  
  private dataSet: DynamicDataSet;
  

  constructor(dynamicForm: DynamicFormData, dataSet: DynamicDataSet ) {
    super();
    this.dynamicForm = dynamicForm;   
    this.dataSet = dataSet;
  }

  public async asyncExecute(): Promise<any> {

    const userRegistration: UserRegistration = new UserRegistration()

    //const test = this.dynamicForm.getValue("Geburtsdatum")

    const password = this.dynamicForm.getValue("Password") as string
    const validationPassword = this.dynamicForm.getValue("PasswortBestaetigung") as string

    if ( !password || password.length < 1 || !validationPassword || validationPassword.length < 1 ) {
      this.dataSet.view.forEach(row => {
        if (row.feldTyp === "PasswordValidation") {
          row.validationMessage = "Die Kennwörter dürfen nicht leer sein";
          row.isValidationError = true;
        }
      });
      return false;
    }


    if ( password !== validationPassword) {      
          this.dataSet.view.forEach(row => {
          if (row.feldTyp === "PasswordValidation") {
            row.validationMessage = "Die Kennwörter stimmen nicht überein";
            row.isValidationError = true;
          }
        });
      return false
    }

    const result = await userRegistration.ChangePasswordByProfile(this.dynamicForm.getValue("Password") as string , this.dynamicForm.getValue("Email") as string ) as any
    if (result && result.resourceId === "Success.Password.Changed") {
        const fieldsHidden = ["Space_Password1","Email","Space_Password2","Titel_Password","Space_Password3","Password","PasswortBestaetigung","RuleUserProfileSavePasswordEmail"]
        this.dynamicForm.showHideFields(this.dataSet, fieldsHidden , false);    
    
        const fieldsShown = ["RuleUserProfileShowPasswordEmail", "Anrede", "Geburtsdatum"]
        this.dynamicForm.showHideFields(this.dataSet, fieldsShown , true);    
    
    }
    return result    
  }
}
