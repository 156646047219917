import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-206f5403"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid d-flex row g-0" }
const _hoisted_2 = { class: "col top-level-command" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "icon-command-style col" }
const _hoisted_5 = { class: "row g-0" }
const _hoisted_6 = { class: "icon-text-style col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "icontext", {}, undefined, true)
        ])
      ])
    ])
  ]))
}