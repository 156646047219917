

import { defineComponent, reactive } from 'vue';
import DynamicList from '../Shared/dynamicList/DynamicList.vue';

export default defineComponent ({
    name: "PersonProducts",
    inheritAttrs: false,
    components: {     
      DynamicList
    },
    setup() {
        const request = reactive({
            NavigationState: "Products",
            ViewId: "personProductsWithDetail",
            DetailId: "PersonProduct",
            PageSize: 5,
            TitleResourceId: "PersonProdukte.Liste.Titel",
            Filter: "DynamicOnOffFilter",     
            RouteName: "Products"       
        });

        return {
            request
        }
    }
})
