import { Observable } from "rxjs";
import { StateService } from "./stateService";
import { Out } from "../frontent/clientMessage";

export interface LoaderState {   
    isInProgress: boolean;
  }
  
const initialState: LoaderState = {   
    isInProgress: false
  };


export class LoaderStateServiceForRoot extends StateService<LoaderState>{
    public static service: LoaderStateServiceForRoot;

    public static lock = false

    public static getLoaderStateService() : LoaderStateServiceForRoot {
        if ( LoaderStateServiceForRoot.service === undefined) {
            LoaderStateServiceForRoot.service = new LoaderStateServiceForRoot();
            this.service = LoaderStateServiceForRoot.service
        }
        return LoaderStateServiceForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<LoaderState> {
        return this.subscribe();
    }

    public changeLoaderState(load: LoaderState) : void {
        if ( !load.isInProgress && LoaderStateServiceForRoot.lock) {
            Out.noOperation("")
            return
        }

        this.changeState(load);
    }

    public get state(): LoaderState {
        return this.state;
    }
}