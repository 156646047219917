
export enum StateConstants {
  Login = "Login",
  Home  = "Home",
  Hunting = "Hunting",
  Fishing = "Fishing"
}

export function StringToStateConstants(state: string) : StateConstants {
  switch(state) {
    case "Login":
      return StateConstants.Login
    case "Home":
      return StateConstants.Home
    case "Hunting":
      return StateConstants.Hunting
    case "Fishing":
      return StateConstants.Fishing
    default:
        throw new Error(`Unknown StateConstant: ${state}`)
    }
}

export function StateConstantToString(state: StateConstants) : string {
  switch(state) {
    case StateConstants.Login:
      return "Login"
    case StateConstants.Home:
      return "Home"
    case StateConstants.Hunting:
      return "Hunting"
    case StateConstants.Fishing:
      return "Fishing"
    default:
        throw new Error(`Unknown StateConstant: ${state}`)
    }
}

