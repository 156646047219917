import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { Navigate } from "@/infrastructure/backend/async/Navigate";
import { Identity } from "@/infrastructure/backend/userIdentity";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { NamedListItem } from "@/infrastructure/generics/namedListItem";

export class OnlineSearchDataSource {
   
    public async SearchListPlzOrt( sourceName: string, filter: string) : Promise<NamedListItem[]>  {
        return await this.getData(sourceName, filter) as unknown as NamedListItem[]
    }

    private async getData(sourceName: string, filter: string) : Promise<any> {
        const dataSourceClient = AsyncRestClient.Create(true);
        if (dataSourceClient.isFailure) return;

        if( Navigate.IsLocked() ) {
            return undefined
        }
 

        const result = await dataSourceClient
            .getValue()
            .exchangeForComponents<string, any>(process.env.VUE_APP_API + "api/comboBox/plzOrtLandOnlineSearch", JSON.stringify({
                DataSourceName: sourceName, 
                Filter: filter,
                userId: Identity.getIdentity().getValue().UserId,
                language: (await UserProfile.GetClientSettingsAsync()).language
             }));       
        if (result.isFailure) return;
        return result.getValue()
    }
}