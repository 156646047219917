import { Out } from "./clientMessage";

export function isAppInstalled() {
    const firstCheck = isInstalledPwa()
    if ( firstCheck ) return true

    return isInstalledPwaAndTwa()
}


function isInstalledPwa() {
    try {
        const media = window.matchMedia('(display-mode: standalone)').matches;
        if ( media ) return true;
    } catch(e) {
        Out.noOperation("")
    }

    try {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
        const installed = navigator.standalone;
        if ( installed ) return true;
    } catch(e) {
        Out.noOperation("")
    }
    

    try {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
        const andref = document.referrer.includes('android-app://');
        if ( andref ) return true;
    } catch(e) {
        Out.noOperation("")
    }

    return false;
}

function isInstalledPwaAndTwa() : boolean {
    try {
        const UA = navigator.userAgent;
        const IOS = UA.match(/iPhone|iPad|iPod/);
        const ANDROID = UA.match(/Android/);
        const PLATFORM = IOS ? 'ios' : ANDROID ? 'android' : 'unknown';
        const standalone = window.matchMedia('(display-mode: standalone)').matches;
        const INSTALLED = !!(standalone || (IOS && !UA.match(/Safari/)));
        return INSTALLED
    } catch(e) {
        return false
    }
}