import { DynamicGridRow, DynamicGridRowParentChild, SyncStateRequest, SyncStateResponse, SyncWildbookAction } from "@/components/Shared/dynamicList/DynamicListTypes";
import { AsyncRestClient } from "../backend/async/AsyncRestClient";
import { addOrReplaceRecord, loadAllRecords, wildbookTable } from "../database/asyncDb";
import { Identity } from "../backend/userIdentity";
import { DynamicRecord } from "@/components/Shared/Dynamicform/DynamicFormData";
import { findLocalZusatzDatenRecords } from "./LocalWildBookDataAccess";
import { BusinessChanges } from "@/components/Shared/dynamicList/Hooks/BusinessFormChanges";
import { GenerateAppSynchronId } from "../functional/datetimehelper";
import { Navigate } from "../backend/async/Navigate";




export async function syncWildbook(row: DynamicGridRow, action: SyncWildbookAction) : Promise<SyncStateResponse> {
    
    const request: SyncStateRequest = {
        userId: Identity.getIdentity().getValue().UserId,
        parentRow: row,
        action: action
    }
    
    let response: SyncStateResponse = {
        success: true,
        offline: false,
        errorResourceId: "",
        parentRow: row
    }

    let isDelete = false
    if ( row.syncState && row.syncState.stateResourceId && row.syncState.stateResourceId === "Sync.State.Delete" )
        isDelete = true
 
    let zusatzDaten = await findLocalZusatzDatenRecords(row.id)
    if ( !zusatzDaten )
        zusatzDaten = await findLocalZusatzDatenRecords(row.rowId)
    if ( !zusatzDaten )
        zusatzDaten = await findLocalZusatzDatenRecords(row.cell.readableIdentification?.id)

    row.zusatzTierartRecords = zusatzDaten

    // if ( !row.zusatzTierartRecords || row.zusatzTierartRecords.length == 0 )
    // {
    //     let zusatzDaten = await findLocalZusatzDatenRecords(row.id)
    //     if ( !zusatzDaten )
    //         zusatzDaten = await findLocalZusatzDatenRecords(row.rowId)
    //     if ( !zusatzDaten )
    //         zusatzDaten = await findLocalZusatzDatenRecords(row.cell.readableIdentification?.id)

    //     if ( zusatzDaten ){
    //         row.zusatzTierartRecords = zusatzDaten
    //     }
    // }   
    try {

        if( Navigate.IsLocked() ) {
            response.success = false
            response.errorResourceId = "error.response.server"
            return response
        }

        const client = AsyncRestClient.Create(true);

        if (client.isFailure) throw new Error("Error.syncWildbook");
        const result = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/sync/syncWildbook", JSON.stringify(request));  
    
        if (result.isFailure) {
            response.success = false
            response.errorResourceId = "error.response.server"
            return response
        }           
        const apiResult = JSON.parse(JSON.stringify(result.getValue())) 
        response = apiResult as unknown as SyncStateResponse
        } catch(e) {
            response.success = false
            response.errorResourceId = "error.response.server"
            return response
        }
       
        if ( ! response.success ) {
            return response
        }
       
        if ( response.offline ) {            
            const offlineResponse: SyncStateResponse = {
                success: true,
                offline: true,
                errorResourceId: "",
                parentRow: row
            }
           
            if ( row  ) {
                row.isSynced = false
                if ( row.syncState) {
                    row.syncState.isSynched = false
                    row.syncState.stateResourceId = "Sync.State.NotSynced"
                }
                if ( row.cell.image) {
                    row.cell.image.imageType = "notsynced"
                    row.cell.image.imagePath = "img/icons/notsynced.svg"
                    row.cell.image.imageState = "notsynced"
                }
            }

            return offlineResponse
        }

        const listNotify = BusinessChanges.getService()
        if ( isDelete && response.parentRow.syncState && response.parentRow.syncState.stateResourceId === "Sync.State.Read" )  {
            
            response.parentRow.syncState.stateResourceId = "Sync.State.Hidden"
            await addOrReplaceRecord(wildbookTable, {id: response.parentRow.id, row: response.parentRow}) 
            listNotify.onRowChanged({action: "hided", row: response.parentRow })
            listNotify.reset()
            return response
        }

        await saveResponseToLocalStore(action, response)


        
        listNotify.onRowChanged({action: "change", row: response.parentRow })
        listNotify.reset()

        
    return response
}

async function saveResponseToLocalStore( action: SyncWildbookAction, response: SyncStateResponse) {
    const dataRows = await loadAllRecords(wildbookTable) as any
    if ( dataRows === undefined ) return 
    for(const item of dataRows) {
        const parentRow = item.row as DynamicGridRow
        if ( parentRow.id === response.parentRow.id ) {
            if ( !parentRow.syncState?.isSynched || !response.parentRow.isSynced) {
                if ( !parentRow.rowId || parentRow.rowId.length <= 0) {
                    parentRow.rowId = response.parentRow.rowId
                }
                parentRow.isSynced = response.parentRow.isSynced
                parentRow.cell.image.imageState = response.parentRow.cell.image.imageState
                parentRow.cell.image.imageType = response.parentRow.cell.image.imageState ?? ""
                parentRow.cell.image.imagePath = response.parentRow.cell.image.imagePath

                if ( parentRow.detailForm && parentRow.detailForm.view && response.parentRow.detailForm &&  response.parentRow.detailForm.view ) {
                    assignFormValues(parentRow.detailForm?.view, response.parentRow.detailForm?.view)
                }

                if( parentRow.syncState ) {
                    parentRow.syncState.isSynched = response.parentRow.syncState?.isSynched ?? false
                    parentRow.syncState.stateResourceId = response.parentRow.syncState?.stateResourceId ?? ""
                    parentRow.syncState.errorMessageResourceId = response.parentRow.syncState?.errorMessageResourceId ?? ""
                }
            }

            if (parentRow.rows && parentRow.rows.length > 0  ) {
                let synced = true
                let failed = false
                for(const detailRow of parentRow.rows) {
                    if (!detailRow.isSynced) {
                        synced = false
                       
                    }
                    if ( detailRow.syncState?.stateResourceId === "Sync.State.Failed") {
                        failed = true
                    }
                }
                if ( parentRow.rowId && parentRow.rowId.length <= 0) {
                    parentRow.rowId = response.parentRow.rowId
                }
                if ( synced) {
                    
                    parentRow.isSynced = true
                    parentRow.cell.image.imageState = "synced"
                    parentRow.cell.image.imageType = "synced"
                    parentRow.cell.image.imagePath = "img/icons/synced.svg";
    
                    if( parentRow.syncState ) {
                        parentRow.syncState.isSynched = true
                        parentRow.syncState.stateResourceId = "Sync.State.Read"
                        parentRow.syncState.errorMessageResourceId =  ""
                    }
                } else {

                    if ( failed) {
                        parentRow.isSynced = false
                        parentRow.cell.image.imageState = "failedsync"
                        parentRow.cell.image.imageType = "failedsync"
                        parentRow.cell.image.imagePath = "img/icons/failedsync.svg";
        
                        if( parentRow.syncState ) {
                            parentRow.syncState.isSynched = false
                            parentRow.syncState.stateResourceId = "Sync.State.Failed"
                            parentRow.syncState.errorMessageResourceId =  ""
                        }
                    } else {
                        parentRow.isSynced = false
                        parentRow.cell.image.imageState = "notsynced"
                        parentRow.cell.image.imageType = "notsynced"
                        parentRow.cell.image.imagePath = "img/icons/notsynced.svg";
        
                        if( parentRow.syncState ) {
                            parentRow.syncState.isSynched = false
                            parentRow.syncState.stateResourceId = "Sync.State.NotSynced"
                            parentRow.syncState.errorMessageResourceId =  ""
                        }    
                    }
                }
            }

            await addOrReplaceRecord(wildbookTable, {id: parentRow.id, row: parentRow})            
        }       
    }
    return
}

function assignFormValues( to: DynamicRecord[], from: DynamicRecord[] ) {
    for( const toItem of to) {
        toItem.resourceIdServerValidation = ""
    }

    for( const fromItem of from) {
        for( const toItem of to) {
            if ( fromItem.resourceIdServerValidation && fromItem.resourceIdServerValidation.length > 0) {
                if ( fromItem.formularFeldname === toItem.formularFeldname) {
                    toItem.resourceIdServerValidation = fromItem.resourceIdServerValidation
                }
            }
        }
    }
}


