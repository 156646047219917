import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";
import { NavigationItem } from "../DynamicNavigationTypes";

export interface IconBarState {
    NavigationItem: NavigationItem | undefined
    reset: true | undefined
}

//__ Steuert das aussehen und definiert click events von der IconNavigationBar über die Klasse DynamicNavigation (Proxy)
export class NotifyIconBar extends StateService<IconBarState>{
    public static service: NotifyIconBar;

    public static getService() : NotifyIconBar {
        if (!NotifyIconBar.service) {
            NotifyIconBar.service = new NotifyIconBar();
            this.service = NotifyIconBar.service
        }
        return NotifyIconBar.service;
    }

    private constructor() {
        super({NavigationItem: undefined, reset: undefined});       
    }
    
    public subscription(): Observable<IconBarState> {
        return this.subscribe();
    }

    public navigationChanged(navigation: IconBarState) : void {               
        this.changeState(navigation);
    }

    public reset() : void {
        this.changeState({NavigationItem: undefined, reset: undefined});
    }

    public get navigationState(): IconBarState {
        return this.state;
    }
}