import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withModifiers as _withModifiers, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "container-fluid g-0 map__editXY" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "col-1 map__edit-colTitle" }
const _hoisted_5 = { class: "col-5" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "col-1 map__edit-colTitle" }
const _hoisted_8 = { class: "col-5" }
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      id: _ctx.divId,
      class: _normalizeClass(_ctx.getGeoClass())
    }, null, 10, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isEditMode())
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.geoMapState.LabelX), 1),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "X",
                disabled: !_ctx.geoMapState.editActivated,
                class: _normalizeClass(_ctx.IsInsidePolygon()),
                spellcheck: "false",
                autocorrect: "off",
                autocapitalize: "off",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.geoMapState.X) = $event)),
                onKeyup: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.setMarkerFromCoordinates()), ["prevent"]))
              }, null, 42, _hoisted_6), [
                [_vModelText, _ctx.geoMapState.X]
              ])
            ]),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.geoMapState.LabelY), 1),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "Y",
                disabled: !_ctx.geoMapState.editActivated,
                class: _normalizeClass(_ctx.IsInsidePolygon()),
                spellcheck: "false",
                autocorrect: "off",
                autocapitalize: "off",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.geoMapState.Y) = $event)),
                onKeyup: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.setMarkerFromCoordinates()), ["prevent"]))
              }, null, 42, _hoisted_9), [
                [_vModelText, _ctx.geoMapState.Y]
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}