import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";
import { DynamicGridRow } from "../DynamicListTypes";



export interface BusinessChangesState {   
    action: string | undefined
    row: DynamicGridRow | undefined
}


export class BusinessChanges extends StateService<BusinessChangesState>{
    public static service: BusinessChanges;

    public static getService() : BusinessChanges {
        if (!BusinessChanges.service) {
            BusinessChanges.service = new BusinessChanges();
            this.service = BusinessChanges.service
        }
        return BusinessChanges.service;
    }

    private constructor() {
        super({action: undefined, row: undefined});       
    }
    
    public subscription(): Observable<BusinessChangesState> {
        return this.subscribe();
    }

    public onRowChanged(row: BusinessChangesState) : void {               
        this.changeState(row);
    }

    public reset() : void {
        this.changeState({action: undefined, row: undefined});
    }

    public get rowState(): BusinessChangesState {
        return this.state;
    }
}