import { ObjectListItems } from "@/infrastructure/generics/namedListItem";
import { AsyncCommand } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
import { FishingEdit } from "@/components/fishing/business/FishingEdit";

export class RuleStartFishing extends AsyncCommand {
  
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  
  constructor(
    dynamicForm: DynamicFormData,
    dataSet: DynamicDataSet
  ) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;
  }

  public async asyncExecute(): Promise<any> {

    let patent: ObjectListItems | undefined = undefined
    let revier: ObjectListItems | undefined = undefined

    this.dataSet.view.forEach(row => {
        if ( row.namedValueListDataSourceName === "Patent" ) {
            if ( row.value as ObjectListItems && row.value.key !== undefined) {
                patent = row.value
            }
        }
        if ( row.namedValueListDataSourceName === "FischereiRevier" ) {
            if ( row.value as ObjectListItems && row.value.key !== undefined) {
                revier = row.value
            }
        }
    });

    const success = await FishingEdit.startFishing(patent, revier);
    if ( !success)
        return false

        this.dataSet.view.forEach(row => {
            if (row.formularFeldname === "RuleFischfangEintrag") {
                row.layoutKlasse = "btn action__button_primary btn-block action__button"
                row.istEditierbar = true
                row.istSichtbar = true
            }

            if (row.formularFeldname === "RuleStartFishing") {
                row.istSichtbar = false
            }
            if (row.formularFeldname === "RuleStopFishing") {
                row.istSichtbar = true
            }


            if (row.formularFeldname === "Auswahlpatent") {               
                row.value = patent?.value
                row.istSichtbar = true
            }

            if (row.formularFeldname === "Patent") {               
                row.istSichtbar = false
            }

            if (row.formularFeldname === "Auswahlrevier") {               
                row.value = revier?.value
                row.istSichtbar = true
            }

            if (row.formularFeldname === "Fischereirevier") {               
                row.istSichtbar = false
            }


        })
        const result = this.dynamicForm.saveLocalPage(this.dataSet);
    return true
  }
}

