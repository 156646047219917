import { OfflineForms } from '../../Shared/Dynamicform/DynamicFormPreload';

import fischereiFangJson from '@/components/fishing/business/FischereiFang.json';
import { FishingEdit } from './FishingEdit';
import {v4 as uuidv4} from 'uuid';
import { DynamicGridRow } from '@/components/Shared/dynamicList/DynamicListTypes';
import { ConvertDateToDayString, ConvertDateToIsoString, GenerateAppSynchronId, GetEndOfDayIsoString } from '@/infrastructure/functional/datetimehelper';


export class FishingRecordParent {



    public async CreateFischereiFangRow() : Promise<DynamicGridRow> {        
        const fishingEditState = FishingEdit.getEditFishingState()
        const row : DynamicGridRow = <DynamicGridRow>fischereiFangJson
        row.id = uuidv4()
        row.appSynchronId = GenerateAppSynchronId()
        row.cell.readableIdentification!.id = row.id
        row.cell.readableIdentification!.readableId = fishingEditState.fischereiRevier!.key
        row.cell.readableIdentification!.readableNumber = fishingEditState.fischereiRevier!.key
        row.cell.readableIdentification!.readableName = fishingEditState.fischereiRevier!.value
        row.cell.readableIdentification!.readablePatentId = fishingEditState.patent!.key
        row.cell.readableIdentification!.readablePatentNumber = fishingEditState.patent!.personProductNumber
        row.cell.readableIdentification!.readablePatentName = fishingEditState.patent!.value
        row.cell.readableIdentification!.startDate = ConvertDateToIsoString(fishingEditState.startDate!)
        row.cell.readableIdentification!.stopDate = this.GetStopDate()
        row.cell.readableIdentification!.kategorie = fishingEditState.patent?.kategorie
        
        row.cell.content.title = ""

        row.cell.content.titleResourceId = fishingEditState.fischereiRevier!.value
        row.cell.content.titleResourceId += "<br />" + fishingEditState.patent!.value
        row.cell.content.titleResourceId += "@<br/> @[0]  @Fischfang.Subtitel.AnzahlFische"        

        row.cell.content.content = fishingEditState.patent!.value

        row.cell.additional.text = ConvertDateToDayString( fishingEditState.startDate! )
        //row.cell.additional.gueltigVon = ConvertDateToDayString( fishingEditState.startDate! )

        row.jsonDetail = undefined
        row.detailForm = await OfflineForms.getForm("Fischbuch")
        //row.detailForm.id = row.id
        row.rows = undefined
        return row
    }

    private GetStopDate() : string {
        let stopDate = ""
        const fishingEditState = FishingEdit.getEditFishingState()
        if ( fishingEditState.stoppDate === undefined) {
            stopDate = GetEndOfDayIsoString()
        } else {
            try {
                stopDate = ConvertDateToIsoString(fishingEditState.stoppDate!)
            } catch(e) {
              stopDate = GetEndOfDayIsoString()
            }
        }
        return stopDate
    }

    
}