
  import { reactive } from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  export default {
    name: "UserRegistrationByCode",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
    setup() {
      const request = reactive({
        apiLoadMethod: "",
        apiSaveMethod: "api/Form/saveForm",
        form: "Benutzer",
        page: "BenutzerRegistrierung",
        remoteId: "",
        waitForResponse: true
        
      });
  
     return {
        request,
      };
    }
  };
  