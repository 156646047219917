import { Resource } from '../resource/resource';
import { Observable } from "rxjs";
import { StateService } from "./stateService";

export interface NotificationState {
    text: string;
    title: string;
    isNotification: boolean;
  }
  
const initialState: NotificationState = {
    text: "",
    title: "",    
    isNotification: false,
  };

export async function CreateNotification(textResourceId: string, titleResourceId: string) : Promise<NotificationState> {
    return {
        text: await Resource.getResourceText(textResourceId),
        title: await Resource.getResourceText(titleResourceId),
        isNotification: true
    }
}

export class NotificationStateServiceForRoot extends StateService<NotificationState>{
    public static service: NotificationStateServiceForRoot;

    public static getNotificationStateService() : NotificationStateServiceForRoot {
        if ( NotificationStateServiceForRoot.service === undefined) {
            NotificationStateServiceForRoot.service = new NotificationStateServiceForRoot();
            this.service = NotificationStateServiceForRoot.service
        }
        return NotificationStateServiceForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<NotificationState> {
        return this.subscribe();
    }

    public changeNotificationState(notification: NotificationState) : void {
        this.changeState(notification);
    }

    public resetNotificationState() : void {
        const notification = {
            text: "",
            title: "",    
            isNotification: false,
        }

        this.changeState(notification);
    }

    public get state(): NotificationState {
        return this.state;
    }
}