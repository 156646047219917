
  import { reactive } from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  
  export default {
    name: "EmailVerificationRequest.vue",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
    setup() {
      const request = reactive({
        apiLoadMethod: "api/anonymousForm/loadForm",
        apiSaveMethod: "",
        form: "EmailVerifikationBenutzer",
        page: "EmailVerifikationEmailEingabe",
        remoteId: "",
        
        
      });
  
      return {
        request,
      };
    }
  };
  