
  import { reactive } from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  
  export default {
    name: "UserPrivacyByCode",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
    setup() {    
      const request = reactive({
        apiLoadMethod: "",
        apiSaveMethod: "",
        form: "Benutzer",
        page: "BenutzerDatenschutz",
        remoteId: "",
        hiddenFields: "BenutzerSpeichern"
        
      });
  
      return {
        request
      };
    }
  };
  