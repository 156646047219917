
import { StateService } from '@/infrastructure/observables/stateService';
import { Observable } from "rxjs";

export interface LogoutState {
    cleanUp: boolean
    login: boolean
}

export function createIntitialLogoutState() : LogoutState {
    return {
        cleanUp: false,       
        login: false
      };
}

export class LogoutChannelForRoot extends StateService<LogoutState>{
    public static service: LogoutChannelForRoot;
   

    public static getService() : LogoutChannelForRoot {
        if (!LogoutChannelForRoot.service) {
            LogoutChannelForRoot.service = new LogoutChannelForRoot();
            this.service = LogoutChannelForRoot.service
        }
        return LogoutChannelForRoot.service;
    }

    private constructor() {
        super(createIntitialLogoutState());
    }
    
    public subscription(): Observable<LogoutState> {
        return this.subscribe();
    }

    public changeLogoutState(logoutState: LogoutState) : void {               
        this.changeState(logoutState);
    }

    

    public get logoutState(): LogoutState {
        return this.state;
    }
}