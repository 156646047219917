import { Resource } from "@/infrastructure/resource/resource"
import { AsyncCommand, Command } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
import { StateMachine,StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";

export class ServerLoadDetailForm extends AsyncCommand {
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  private _store: any;
  private stateMachine: StateMachine;

  
  constructor(dynamicForm: DynamicFormData,dataSet: DynamicDataSet,store: any) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;
    this._store = store;
    this.stateMachine = StateMachineServiceLocator.get();
  }

  public async asyncExecute(): Promise<any> {
    try {      
      return this.dataSet;
     } catch (e) {
       const lastState = this.stateMachine.last()
       changeNavigation(this._store)       
     }
  }
}
