import { Out } from "./clientMessage"

export class ManageSubscriptions {

    private static subscriber: any | undefined = undefined

    public static NewSubscription(subscription: any, topic: string, thema: string) {
        if (ManageSubscriptions.subscriber) {
            try {
                ManageSubscriptions.subscriber.unsubscribe()
            } catch(e) {
                Out.noOperation("")
            }

            ManageSubscriptions.subscriber = subscription

        }

    }

}
