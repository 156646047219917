import { StateService } from '@/infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { FilterSupportedLists } from './DynamicFilterTypes';


export interface FilterAttachState {     
    attachTo: FilterSupportedLists
    clientParentRoute: string | undefined
  }
  
const initialState: FilterAttachState = {  
    attachTo: undefined,
    clientParentRoute: undefined
  };

export async function CreateDynamicFilterAttachment() : Promise<FilterAttachState> {   
    return initialState
}




export class AttachDynamicFilterChannelForRoot extends StateService<FilterAttachState>{
    public static service: AttachDynamicFilterChannelForRoot;
    private static attachetTo: FilterSupportedLists = undefined
    private static firstAttached = true
    

    public static getService() : AttachDynamicFilterChannelForRoot {
        if ( AttachDynamicFilterChannelForRoot.service === undefined) {
            AttachDynamicFilterChannelForRoot.service = new AttachDynamicFilterChannelForRoot();
            this.service = AttachDynamicFilterChannelForRoot.service
        }
        return AttachDynamicFilterChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }

    public reset() : void {
        this.changeFilterState(initialState)
    }
    
    public subscription(): Observable<FilterAttachState> {
        return this.subscribe();
    }

    public changeFilterState(filterState: FilterAttachState) : void {
        
        AttachDynamicFilterChannelForRoot.firstAttached = false
        if ( AttachDynamicFilterChannelForRoot.attachetTo !== filterState.attachTo ) {
            AttachDynamicFilterChannelForRoot.attachetTo = filterState.attachTo
            AttachDynamicFilterChannelForRoot.firstAttached = true
        }
        this.changeState(filterState);
    }

   

    public isAttached(): boolean {

        if ( this.state.clientParentRoute === undefined || this.state.attachTo === undefined ) {
            return false
        }

        if ( AttachDynamicFilterChannelForRoot.attachetTo as string === this.state.clientParentRoute) {
            return true
        }

        return false

    
    }

    public isFirstAttached() {
        return AttachDynamicFilterChannelForRoot.firstAttached
    }

    public get filterState(): FilterAttachState {
        return this.state;
    }
}