import { Dictionary } from "@/infrastructure/generics/dictionary"
import { NavigationItem, NavigationButton, NavigationChain } from "./DynamicNavigationTypes"
import { Resource } from "@/infrastructure/resource/resource"

export class GenerateWebShopNavigationChains {
    
    public static generateChains(navigationChains: Dictionary<NavigationChain>) : void {
        GenerateWebShopNavigationChains.webShopDaten(navigationChains)
    }

    private static webShopDaten(navigationChains: Dictionary<NavigationChain>) : void {

        const items: NavigationItem[] = []
        const chain: NavigationChain = {
            navigationItems: items,
            currentIndex: 0,
            sourceRouteName: "WebShop",
            targetRoteName: "WebShop"
        }
        navigationChains["WebShopDaten"] = chain
        
        
        const buttons = [GenerateWebShopNavigationChains.genPrevious(), GenerateWebShopNavigationChains.genEmpty(), GenerateWebShopNavigationChains.genEmpty(), GenerateWebShopNavigationChains.genEmpty()]
        items.push( GenerateWebShopNavigationChains.generateNavigationItem("WebShopDaten", buttons) )
        
       
        
    }

    private static generateNavigationItem(page: string , buttons: NavigationButton[] ) : NavigationItem {
        const item: NavigationItem = {
            page: page,
            navigationButtons: buttons
        }

        return item
    }

    private static genNext() : NavigationButton {
        const button: NavigationButton = {
            displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Vor",""),
            imageUrl: 'img/icons/next_white.svg',
            enabled: true,
            visible: true,
            existent: true,
            command: 'next'
        }
        return button
    }

    private static genPrevious() : NavigationButton {
        const button: NavigationButton = {
            displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Zurueck",""),
            imageUrl: 'img/icons/previous_white.svg',
            enabled: true,
            visible: true,
            existent: true,
            command: 'previous'
        }
        return button
    }


    private static genAction(displayName: string , imageUrl: string , commandName: string) : NavigationButton {
        const button: NavigationButton = {
            displayName: displayName,
            imageUrl: imageUrl,
            enabled: true,
            visible: true,
            existent: true,
            command: commandName
        }
        return button
    }

    private static genEmpty() : NavigationButton {
        const button: NavigationButton = {
            displayName: '',
            imageUrl: '',
            enabled: false,
            visible: false,
            existent: false,
            command: ''
        }
        return button
    }
}