
  import { onUnmounted, ref, reactive, defineComponent, computed } from "vue";
  import { useStore } from "vuex";  
  import { DynamicGridRequest , DynamicGrid, DynamicGridRow} from "@/components/Shared/dynamicList/DynamicListTypes"   
  import { DynamicDetailListParentListChannelForRoot, SupportedDetailLists, createParentListState} from "@/components/Shared/dynamicListDetailList/Hooks/DynamicDetailListParentListToDatailListChannel"
  import { CreateDetailListState, DynamicDetailListChannelForRoot } from "@/components/Shared/dynamicListDetailList/Hooks/DynamicDetailListToParentListChannel";  
  import { useRoute } from "vue-router";

  import { RoutedButtonBarRequestChannelForRoot, RoutedButtonBarRequestState } from "@/components/Shared/routed-button-bar/RoutedButtonBarRequestChannel";
  import { RoutedButtonBarCleanupChannelForRoot } from "@/components/Shared/routed-button-bar/RoutedButtonBarCleanupChannel";
  import { RoutedButtonBarResponseChannelForRoot } from "../routed-button-bar/RoutedButtonResponseChannel";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  import { DynamicRecord } from "../Dynamicform/DynamicFormData";
  import { Out } from "@/infrastructure/frontent/clientMessage";
  import { sanitize } from "@/infrastructure/functional/sanitize";
  import { DynamicFormToDetailListChannelForRoot } from "./Hooks/DynamicDetailFormToDetailListChannel";
  import { resolveMainListResourceTitel, resolveSubListResourceTitel, resolveSubListResourceTitelFromForm } from "../dynamicList/DynamicListBusinessCase";
import { findLocalFishingDetailRecord, findLocalFishingDetailRecordByRowId } from "@/components/fishing/business/FishingAdd";
import { DynamicCloseForm } from "../Dynamicform/hooks/CloseForm";
import { DynamicDetailListStore } from "../dynamicList/DynamicDetailListStore";
import { RefreshServerObjects } from "@/infrastructure/resource/RefreshServerObjects";



  export default defineComponent ({
    name: "DynamicDetailList",
    inheritAttrs: false,
    components: {           
      DynamicForm
    },   
    setup() {
      
      const store = useStore();
      let unsubscribe: any = {};
      
      const route=useRoute();    
      const clientRouteName = computed(() =>route.name?.toString())
      
      const fromParentChannel = DynamicDetailListParentListChannelForRoot.getService();      
      const toParentChannel = DynamicDetailListChannelForRoot.getService();
      
      const dynamicGrid: DynamicGrid = {
          userId: "",
          viewId: ""
      }

     

      const dynamicParentRow: DynamicGridRow = {
        cell: {
        image: {
        imagePath: undefined,
        imageState: undefined,
        imageStateText: undefined,
        imageType: undefined
        },
        content: {
        titleResourceId: undefined,
        contentResourceId: undefined,
        title: undefined,
        content: undefined
        },
        additional: {
        textResourceId: undefined,
        text: undefined,
        gueltigVon: undefined,
        gueltigBis: undefined
        },
        readableIdentification: undefined
        }
      }

      let dynamicDetailRow: DynamicGridRow = {
        cell: {
        image: {
        imagePath: undefined,
        imageState: undefined,
        imageStateText: undefined,
        imageType: undefined
        },
        content: {
        titleResourceId: undefined,
        contentResourceId: undefined,
        title: undefined,
        content: undefined
        },
        additional: {
        textResourceId: undefined,
        text: undefined,
        gueltigVon: undefined,
        gueltigBis: undefined
        },
        readableIdentification: undefined
        }
      }

      const dynamicGridRequest: DynamicGridRequest = {
        userId: "",
        viewId: "",
        detailId: "",
        detailList: false,
        routeName: undefined,
        detailRouteName: undefined,
        form: "",
        page: "",
        allRecords: false,
        lastRefreshDate: RefreshServerObjects.getLastGridFormRefreshDateSync(),
        lastViewIdRefreshState: RefreshServerObjects.getLastGridFormRefreshDateSync()
      }

      const dynamic = reactive({
        grid: dynamicGrid,
        parentRow: dynamicParentRow,
        request: dynamicGridRequest
      })

      const formRecords: DynamicRecord[] = [{
          teilFormularName: "",
          formularFeldname: "",
          feldTyp: "",
          istEditierbar: false,
          istPflichtfeld: false,
          istSichtbar: false,
          maxLaenge: 0,
          resourceId: "",
          teilFormularReihenfolge: 0,
          regulaererAusdruck: "",
          resourceIdErrorMeldungPflichtfeld: "",
          resourceIdErrorMeldungValidierung: "",
          resourceIdErrorMeldungMaxLaenge: "",
          layoutKlasse: "",
          isValidationError: false,
          validationMessage: "",
          value: undefined,
          tabellenFeldname: "",
          tabellenName: "",
          infoTextTitle: undefined,
          infoTextContent: undefined,
          infoResourceId: undefined,
          parentCascadeId: undefined
          }]

      const dynamicDetail = reactive({        
          apiLoadMethod: "",
          apiSaveMethod: "",
          form: "FischbuchEintragPacht",
          page: "FischbuchEintragPachtDaten",
          remoteId: "",
          loadErrorTitle: "",
          loadErrorMessage: "",
          detailData: {},
          hiddenFields: "",
          parentId: "",
          records: formRecords,
          routeName: "",          
      })

      const content = reactive({
        titleResourceId: "",
        title: ""
      })

      
      const detailListVisible = ref(true)

      const closeDetail = () : boolean => { 
          return detailListVisible.value = true
      }

      const doNothing = () => {
        Out.noOperation()
      }

      async function getRows() {
        if ( dynamic.parentRow && dynamic.parentRow.rows)
        for (const row of dynamic.parentRow.rows) {
          const titel = await resolveSubListResourceTitel(row.cell.content.titleResourceId, row)
          row.cell.content.title = titel
        }

        return dynamic.parentRow.rows
      }

      const rowDeleted = ( row: DynamicGridRow) => {
        if ( row.syncState?.stateResourceId === "Sync.State.Delete") {
          return "row__deleted"
        }
        return ""
      }

      const openDetail = (row: DynamicGridRow) : boolean => { 
        if ( row.detailForm?.view === undefined) return true     
        row.localDirty = false
        dynamicDetailRow = row   

        detailListVisible.value = false;  
        dynamicDetail.records = row.detailForm?.view     
        dynamicDetail.remoteId = row.id ?? ""
        DynamicDetailListStore.SetSelectedGridRow(row)
        return false
      }

      function isGridRowVisible(row: DynamicGridRow) {
        if ( row.isVisible === undefined ) return true
        return row.isVisible
      }


      const showList = () : boolean => { 
        return detailListVisible.value = true
      }

      const isListVisible = () : boolean => { 
        if (detailListVisible.value) return true;
        return false;
      }
  
      const routedButtonBarRequest = RoutedButtonBarRequestChannelForRoot.getService()
      const routedButtonBarCleanup = RoutedButtonBarCleanupChannelForRoot.getService()
      const routeButtonBarResponse = RoutedButtonBarResponseChannelForRoot.getService()

  
      const onParentSelect = fromParentChannel.subscription().subscribe({
        next: (value) => {  
            if ( fromParentChannel.isAttached(clientRouteName.value as unknown as SupportedDetailLists )) {
              dynamic.grid = value.grid
              dynamic.parentRow = value.row    
              dynamic.request = value.request
              
              if ( value.row.cell.readableIdentification &&  value.row.cell.readableIdentification.kategorie) {
                dynamicDetail.form = "FischbuchEintrag" + value.row.cell.readableIdentification?.kategorie
                dynamicDetail.page = "FischbuchEintrag" + value.row.cell.readableIdentification?.kategorie + "Daten"
              }
              // if ( value.request.form !== undefined)
              //   dynamicDetail.form = value.request.form
              // if ( value.request.page !== undefined)
              //   dynamicDetail.page = value.request.page
              if ( value.request.detailRouteName !== undefined) {
                dynamicDetail.routeName = value.request.detailRouteName
                dynamicGridRequest.routeName = value.request.routeName
                dynamicGridRequest.detailRouteName = value.request.detailRouteName
              }
              showList()                   
            }
        }
      })


      const backState: RoutedButtonBarRequestState = {
        sourceRoute: clientRouteName.value,
        targetRoutes: {backTargetRoute: dynamicGridRequest.routeName , vorwardTargetRoute: undefined},
        command: "Back",
        topic: "DetailList"
        }

      routedButtonBarRequest.changeRouteState(backState)
      const onRouteButtonBarResponse = routeButtonBarResponse.subscription().subscribe({
        next: (value) => {
          if ( value.targetRoute !== undefined && value.targetRoute === dynamicGridRequest.routeName && value.topic === "DetailList" )
            setTimeout( () => {
              onClosed()
            } , 0)
        }
      })


      
      async function onClosed() {     
        routeButtonBarResponse.changeRouteState( {targetRoute: undefined, command: "Back", topic: "DetailList"})
        toParentChannel.changeDetailState(await CreateDetailListState(dynamic.request, dynamic.parentRow, true, true))
        routedButtonBarCleanup.changeRouteState({targetRoutes: {backTargetRoute: dynamicGridRequest.routeName , vorwardTargetRoute: undefined} })
      }

      const formResultResponse = DynamicFormToDetailListChannelForRoot.getService()
      const onformResultResponse = formResultResponse.subscription().subscribe({
        next: (value) => {
          if ( value.attachTo === dynamicDetail.routeName && value.attachTo !== undefined )
          if ( !value.isSave) {
            detailListVisible.value = true
            setTimeout( () => {        
              if ( ! backState.targetRoutes) {
                backState.targetRoutes = {backTargetRoute: "FishBook" , vorwardTargetRoute: undefined}                
              } else {
                if (backState.targetRoutes.backTargetRoute === undefined) {
                  backState.targetRoutes = {backTargetRoute: "FishBook" , vorwardTargetRoute: undefined}    
                }
              }
              ChangeList(undefined)
            } , 0)
          }
        }
      })

      const saveResponse = DynamicCloseForm.getService()
      const onSaveForm = saveResponse.subscription().subscribe({
        next: (value) => {
          if ( value.close) {
            detailListVisible.value = true
            setTimeout( () => {        
              if ( ! backState.targetRoutes) {
                backState.targetRoutes = {backTargetRoute: "FishBook" , vorwardTargetRoute: undefined}                
              } else {
                if (backState.targetRoutes.backTargetRoute === undefined) {
                  backState.targetRoutes = {backTargetRoute: "FishBook" , vorwardTargetRoute: undefined}    
                }
              }
              if ( value.records ) {
                ChangeList(value.records as DynamicRecord[])
              } else {
                ChangeList(undefined)
              }
              
            } , 0)
          }
        }
      })
     

      async function ChangeList(form: DynamicRecord[] | undefined) {
        if ( ! dynamic.parentRow.rows ) {
           routedButtonBarRequest.changeRouteState(backState)
           return
        }
         let isSynced = false
         let isFailed = false
         const dbRecord = await findLocalFishingDetailRecordByRowId("", dynamicDetailRow.id ?? "") 
          if( dbRecord ) {
           isSynced = true
           for(const row of dynamic.parentRow.rows) {
              if ( row.id === dbRecord.childRow?.id) {
                  row.isSynced = dbRecord.childRow?.isSynced
                  if ( ! row.isSynced) {
                    isSynced = false
                  }
                  if ( form) {
                    row.cell.content.title = await resolveSubListResourceTitelFromForm(row, form)
                  } else {
                    row.cell.content.title = await resolveSubListResourceTitel(dbRecord.childRow?.cell.content.titleResourceId, dbRecord.childRow!)
                  }
                  row.cell.image.imageState = dbRecord.childRow?.cell.image.imageState
                  row.cell.image.imageType = dbRecord.childRow?.cell.image.imageState
                  row.cell.image.imagePath = dbRecord.childRow?.cell.image.imagePath
                  row.isSynced = dbRecord.childRow?.isSynced
                  if ( row.syncState) {
                      row.syncState.stateResourceId = dbRecord.childRow?.syncState?.stateResourceId ?? ""
                      row.syncState.isSynched = dbRecord.childRow?.syncState?.isSynched ?? false
                      row.syncState.errorMessageResourceId = dbRecord.childRow?.syncState?.errorMessageResourceId ?? ""
                      if ( row.syncState.errorMessageResourceId && row.syncState.errorMessageResourceId.length > 0) {
                        isFailed = true
                      }
                  }
                  if ( row.detailForm && row.detailForm.view && dbRecord.childRow && dbRecord.childRow.detailForm &&  dbRecord.childRow.detailForm.view ) {
                    assignDetailFormValues(row.detailForm?.view, dbRecord.childRow.detailForm?.view)
                  }

                  
                }
              }
          }

          
          if( dbRecord ) {

            if ( isFailed) {
              dynamic.parentRow.isSynced = false
              dynamic.parentRow.cell.content.title =  await resolveMainListResourceTitel("fishBookEntriesWithDetail", dbRecord.parentRow.cell.content.titleResourceId ?? "", dbRecord.parentRow )
              dynamic.parentRow.cell.image.imageState = "failedsync" 
              dynamic.parentRow.cell.image.imageType = "failedsync"
              dynamic.parentRow.cell.image.imagePath = "img/icons/failedsync.svg"
              dynamic.parentRow.isSynced = false
              if (  dynamic.parentRow.syncState) {
                    dynamic.parentRow.syncState.stateResourceId = "Sync.State.Failed"
                    dynamic.parentRow.syncState.isSynched = false
                    dynamic.parentRow.syncState.errorMessageResourceId = dbRecord.parentRow?.syncState?.errorMessageResourceId ?? ""
              }    
              routedButtonBarRequest.changeRouteState(backState)            
              return
            }

            if ( ! isSynced ) {
              dynamic.parentRow.isSynced = false
              dynamic.parentRow.cell.content.title =  await resolveMainListResourceTitel("fishBookEntriesWithDetail", dbRecord.parentRow.cell.content.titleResourceId ?? "", dbRecord.parentRow )
              dynamic.parentRow.cell.image.imageState = "notsynced" 
              dynamic.parentRow.cell.image.imageType = "notsynced"
              dynamic.parentRow.cell.image.imagePath = "img/icons/notsynced.svg"
              dynamic.parentRow.isSynced = false
              if (  dynamic.parentRow.syncState) {
                    dynamic.parentRow.syncState.stateResourceId = "Sync.State.NotSynced"
                    dynamic.parentRow.syncState.isSynched = false
                    dynamic.parentRow.syncState.errorMessageResourceId = ""
              }                
              routedButtonBarRequest.changeRouteState(backState)
              return
            }

            dynamic.parentRow.isSynced = dbRecord.parentRow.isSynced
            dynamic.parentRow.cell.content.title =  await resolveMainListResourceTitel("fishBookEntriesWithDetail", dbRecord.parentRow.cell.content.titleResourceId ?? "", dbRecord.parentRow )
            dynamic.parentRow.cell.image.imageState = dbRecord.parentRow?.cell.image.imageState
            dynamic.parentRow.cell.image.imageType = dbRecord.parentRow?.cell.image.imageState
            dynamic.parentRow.cell.image.imagePath = dbRecord.parentRow?.cell.image.imagePath
            dynamic.parentRow.isSynced = dbRecord.parentRow.isSynced
            if (  dynamic.parentRow.syncState) {
                  dynamic.parentRow.syncState.stateResourceId = dbRecord.parentRow?.syncState?.stateResourceId ?? ""
                  dynamic.parentRow.syncState.isSynched = dbRecord.parentRow?.syncState?.isSynched ?? false
                  dynamic.parentRow.syncState.errorMessageResourceId = dbRecord.parentRow?.syncState?.errorMessageResourceId ?? ""
            }                
          }
         
         routedButtonBarRequest.changeRouteState(backState)
      }

      function assignDetailFormValues( to: DynamicRecord[], from: DynamicRecord[] ) {
        for( const toItem of to) {
          toItem.resourceIdServerValidation = ""
        }

        for( const fromItem of from) {
            for( const toItem of to) {
                if ( fromItem.resourceIdServerValidation ) {
                    if ( fromItem.formularFeldname === toItem.formularFeldname) {
                        toItem.resourceIdServerValidation = fromItem.resourceIdServerValidation
                    }
                }
            }
        }
      }


      function getImagePath( record: DynamicGridRow) {
          if ( record.cell && record.cell.image )
              return record.cell.image.imagePath ?? ''
          return ""
      }

      function getCellTitle( record: DynamicGridRow) {
        if (record?.cell?.content?.title )
          return sanitize(record.cell.content.title)
      }

      function getCellSubTitle( record: DynamicGridRow) {
        if (record?.cell?.additional?.text ) {
          if ( record?.cell?.additional?.text.indexOf("@") >= 0 ) {
           return ""
          }
          return sanitize(record?.cell?.additional?.text)
        }
      }

  
      onUnmounted(() => {
        fromParentChannel.changeParentState(createParentListState())
        onRouteButtonBarResponse.unsubscribe()
        onParentSelect.unsubscribe()      
        onformResultResponse.unsubscribe()
        onSaveForm.unsubscribe()
        //unsubscribe();
      });

      getRows()
  
      return {                                  
        content,    
        dynamic,
        dynamicDetail,
        isListVisible,       
        closeDetail,    
        openDetail,   
        onClosed,
        getImagePath,
        doNothing,
        getCellTitle,
        getCellSubTitle,
        getRows,
        rowDeleted,
        isGridRowVisible
      };
    },
  });
