import { StateService } from '../../../../infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { DynamicCloseFormState, DynamicFormState, createIntitialCloseFormState, createIntitialFormState } from './FormHookTypes';


export class DynamicCloseForm extends StateService<DynamicCloseFormState>{
    public static service: DynamicCloseForm;

    public static getService() : DynamicCloseForm {
        if (!DynamicCloseForm.service) {
            DynamicCloseForm.service = new DynamicCloseForm();
            this.service = DynamicCloseForm.service
        }
        return DynamicCloseForm.service;
    }

    private constructor() {
        super(createIntitialCloseFormState());
       
    }
    
    public subscription(): Observable<DynamicCloseFormState> {
        return this.subscribe();
    }

    public changeFormState(formState: DynamicCloseFormState) : void {               
        this.changeState(formState);
    }

    public get formState(): DynamicCloseFormState {
        return this.state;
    }
}