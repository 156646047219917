
import { reactive } from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";

export default {
  name: "RegistrationHuntingData",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {

    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "",
      form: "RegistrierungBenutzer",
      page: "RegistrierungJagd",
      remoteId: "",
      
      
    });
   
    return {
      request,      
    };
  }
};
