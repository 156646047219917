export type geoValidationData = {
    lv95X: number
    lv95Y: number
    revierNumber: string
    insideRevierBorders: boolean
  }

export class GeoValidationStore {
  private static geoStoreData: geoValidationData = {
    lv95X: 0,
    lv95Y: 0,
    revierNumber: "",
    insideRevierBorders: false
  }

  public static Save(geoData: geoValidationData ) {
    GeoValidationStore.geoStoreData = geoData
  }

  public static Load() : geoValidationData {
    return GeoValidationStore.geoStoreData
  }

  public static Reset() : geoValidationData {
    GeoValidationStore.geoStoreData.lv95X = 0
    GeoValidationStore.geoStoreData.lv95Y = 0
    GeoValidationStore.geoStoreData.insideRevierBorders = false
    GeoValidationStore.geoStoreData.revierNumber = ""

    return GeoValidationStore.geoStoreData
  }

}