import {
  StateRule,
  StateTransitionOptions,
  StateResult,

  StateNavigationAction,
  Transition,
  // eslint-disable-next-line
  addNavigationElement,
  // eslint-disable-next-line
  addEmptyNavigationElement,
  addPreviousNext,
  // eslint-disable-next-line
  addPrevious,
  // eslint-disable-next-line
  addNext,  
  newTransition,
  newTransitionCondition
} from "../StateInterfaces";
import { NavigatonIcons } from "@/business/application/NavConst";
import {Commands} from "@/business/application/commands/Command";
import { BaseState } from "../StateMachine";
import { StateLogin } from "./StateLogin";
import { StateRegisterUser } from "./StateRegisterUser";
import { StateProduct } from "./StateProduct";
import { StateHunting} from "./StateHunting"
import { StateFishing} from "./StateFishing"
import { StateSettings } from "./StateSettings";
import { StateInfo } from "./StateInfo";
import { StateUserProfile } from "./StateUserProfile";
import { StatePrivacyPolicy } from "./StatePrivacyPolicy";
import { StatePasswordForAuthenticatedUser } from "./StatePasswordForAuthenticatedUser";
import { Resource } from "@/infrastructure/resource/resource";


export class StateHome extends BaseState {
  private static mainState = "Home";
  private static transitions: Transition[] = [];

  public constructor() {
    super()    
   
  }
  
  public getStartTransition(condition: string) : StateResult {
    StateHome.transitions = []
    this.getTransitions()
    const t = this.getTransitions()[0];
    return this.getTransition(t);
  }

  public next(rule: StateRule, options: StateTransitionOptions): StateResult {
    return this.calculateState(rule, options, false, StateHome.mainState);
  }
  public previous(
    rule: StateRule,
    options: StateTransitionOptions
  ): StateResult {
    return this.calculateState(rule, options, true, StateHome.mainState);
  }
  protected calculateState(
    rule: StateRule,
    options: StateTransitionOptions,
    isPrevious: boolean,
    // eslint-disable-next-line
    mainState: string
  ): StateResult {

    const context = this.getContext()

    const condition = this.calculateBusinessConditions(
      rule,
      this.getContext().getCurrentState()
    );
    const transition = this.getTransitions().filter(row => {
      if (
        row.oldState === context.getCurrentState().newState &&
        row.condition === condition &&
        row.nav === StateNavigationAction.Previous &&
        isPrevious
      ) {
        return true;
      }
      if (               
        row.condition === condition && condition === "UpdateApp" &&
        row.nav !== StateNavigationAction.Previous &&
        !isPrevious
      ) {
        return true;
      }

      if (        
        row.oldState === context.getCurrentState().newState &&
        row.condition === condition &&
        row.nav !== StateNavigationAction.Previous &&
        !isPrevious
      ) {
        return true;
      }
    });
    return this.checkState(transition[0], options);
  }

  protected checkState(
    transition: Transition,
    options: StateTransitionOptions
  ): StateResult {
    let newResultState = this.getTransition(transition);
    let newState: any
    if (!options.lookahead && transition.newState != "") {
      switch (transition.newMainState) {
        case "Hunting":
          newState = new StateHunting();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState);
          break;
        case "Fishing":
          newState = new StateFishing();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState);
          break;
          case "RegisterUser":
          newState = new StateRegisterUser();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState);
          break;
        case "Login":
          newState = new StateLogin();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState);
          break;
        case "Product":
          newState = new StateProduct();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState);
          break;
        case "Info":
          newState = new StateInfo();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState);
          break;
        case "Settings":
          newState = new StateSettings();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState);
          break;
          case "PrivacyPolicy":
            newState = new StatePrivacyPolicy();
            newResultState = newState.getStartTransition("Home")
            this.getContext().transitionTo(newState,newResultState);
            break;  
          case "PasswordForAuthenticatedUser":
              newState = new StatePasswordForAuthenticatedUser();
              newResultState = newState.getStartTransition("Home")
              this.getContext().transitionTo(newState,newResultState);
              break;              
        case "UserProfile":
            newState = new StateUserProfile();
            newResultState = newState.getStartTransition("Home")
            this.getContext().transitionTo(newState,newResultState);
            break;                      
        default:
          if ( transition.condition === "UpdateApp" ) {
            this.getContext().transitionTo(new StateHome(), newResultState);
          }
          else {
            this.getContext().transitionTo(new StateHome(), newResultState);
          }
      }
    }
    return newResultState;
  }

  protected calculateBusinessConditions(
    rule: StateRule,
    // eslint-disable-next-line
    resultState: StateResult
  ): string {
    if (
      rule.conditions === undefined ||
      rule.conditions === null ||
      rule.conditions.length <= 0
    ) {
      return "";
    }

    if (rule.conditions.length == 1) {
      return rule.conditions[0].value;
    }
    return "";
  }

  public getTransitions(): Transition[] {
    if (StateHome.transitions.length === 0) {

      let t: Transition = { mainState: "PasswordEmail", newMainState: "Home", nav: StateNavigationAction.Next, oldState: "PasswordEmail", newState: "Home", condition: "PasswordEmail"};      
      t = addNavigationElement(t,1,{command: Commands.Logout, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Abmelden",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Abmelden });
      t = addNavigationElement(t,2,{command: Commands.Person, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Person",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Person });
      t = addNavigationElement(t,3,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Settings, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateHome.transitions.push(t);

      t = { mainState: "Home", newMainState: "Product", nav: StateNavigationAction.Next, oldState: "Home", newState: "Products", condition: "Products"};      
      t = addPrevious(t, Commands.PreviousPage);
      StateHome.transitions.push(t);

      t = { mainState: "Home", newMainState: "UserProfile", nav: StateNavigationAction.Next, oldState: "Home", newState: "UserProfile", condition: "UserProfile"};      
      t = addPrevious(t, Commands.PreviousPage);
      StateHome.transitions.push(t);

      t = { mainState: "Home", newMainState: "Hunting", nav: StateNavigationAction.Next, oldState: "Home", newState: "Hunting", condition: "Hunting"};      
      t = addPrevious(t, Commands.PreviousPage);
      StateHome.transitions.push(t);

      t = { mainState: "Home", newMainState: "Fishing", nav: StateNavigationAction.Next, oldState: "Home", newState: "Fishing", condition: "Fishing"};      
      t = addPrevious(t, Commands.PreviousPage);
      StateHome.transitions.push(t);

      t = { mainState: "Home", newMainState: "Test", nav: StateNavigationAction.Next, oldState: "Home", newState: "Test", condition: "Test"};      
      t = addPrevious(t, Commands.PreviousPage);
      StateHome.transitions.push(t);


      t = { mainState: "Home", newMainState: "Settings", nav: StateNavigationAction.Next, oldState: "Home", newState: "Settings", condition: "Settings"};      
      t = addPrevious(t, Commands.PreviousPage);
      StateHome.transitions.push(t);

      t = { mainState: "Home", newMainState: "Info", nav: StateNavigationAction.Next, oldState: "Home", newState: "Info", condition: "Information"};      
      t = addPrevious(t, Commands.PreviousPage);
      StateHome.transitions.push(t);


      t = { mainState: "Home", newMainState: "Login", nav: StateNavigationAction.Next, oldState: "Home", newState: "Login", condition: "Login"};      
      t = addEmptyNavigationElement(t,1)
      t = addEmptyNavigationElement(t,2)
      t = addNavigationElement(t,3,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Settings, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateHome.transitions.push(t);


      t = { mainState: "Home", newMainState: "Home", nav: StateNavigationAction.Next, oldState: "Home", newState: "Home", condition: "UpdateApp"};      
      t = addNavigationElement(t,1,{command: Commands.Logout, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Abmelden",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Abmelden });
      t = addNavigationElement(t,2,{command: Commands.Person, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Person",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Person });
      t = addNavigationElement(t,3,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Settings, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateHome.transitions.push(t);

    }

    return StateHome.transitions;
  }
}
