import { DynamicGrid, DynamicGridRow } from "./DynamicListTypes"

export class DynamicDetailListStore {
    private static _selectedId: DynamicGridRow | undefined = undefined

    public static SetSelectedGridRow(selectedGridRow: DynamicGridRow | undefined) : void {
        DynamicDetailListStore._selectedId = selectedGridRow
    }
    public static GetSelectedGridRow() : DynamicGridRow | undefined {
        return DynamicDetailListStore._selectedId 
    }
}