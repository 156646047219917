// // State
// this.$store.state.auth.userdata
// // Mutation
// this.$store.commit('navigationState/login', { username: 'logged_user' })
// // Action
// this.$store.dispatch('navigationState/authenticate', { username: this.username, password: this.password })
// // Getter
// this.$store.getters['navigationState/loggedIn']

export interface NavigationItem {
  clicked: boolean;
}

export const navigationState = {
  namespaced: true,
  state: {
    navigationItem1: { clicked: "true" },
    navigationItem2: { clicked: "true" },
    navigationItem3: { clicked: "true" },
    navigationItem4: { clicked: "true" },
  },
  mutations: {
    clickNavigationItem1(state: any, payload: NavigationItem) {
      state.navigationItem1 = payload;
    },
    clickNavigationItem2(state: any, payload: NavigationItem) {
      state.navigationItem2 = payload;
    },
    clickNavigationItem3(state: any, payload: NavigationItem) {
      state.navigationItem3 = payload;
    },
    clickNavigationItem4(state: any, payload: NavigationItem) {
      state.navigationItem4 = payload;
    }
  },
  actions: {},
  getters: {
    getNavigationItem1: function(state: any): NavigationItem {
      return state.navigationItem1;
    },
    getNavigationItem2: function(state: any): NavigationItem {
      return state.navigationItem2;
    },
    getNavigationItem3: function(state: any): NavigationItem {
      return state.navigationItem3;
    },
    getNavigationItem4: function(state: any): NavigationItem {
      return state.navigationItem4;
    }
  }
};
