import { LogoutChannelForRoot } from "@/infrastructure/observables/logoutChannel"
import { UserProfile } from "../userProfile"

type RequestResponse = "Request" | "Response" | undefined

type SyncContextData = {
    requestOrResponse: RequestResponse 
    endpoint: string | undefined
}

export class Navigate {

    private static _isLocked = false

    private static _syncContextData: SyncContextData = {
        requestOrResponse: undefined,
        endpoint: undefined
    }

    public static IsLocked(): boolean {
        return Navigate._isLocked
    }

    public static Lock(): void {
        Navigate._isLocked = true
    }

    public static Reset(): void {
        Navigate._isLocked = false
    }

    public static async AuthenticationExpired(response: any) : Promise<boolean> {
        if (!response.status || typeof(response.status) !== "number" || response.status != 401) return false
        Navigate.Lock()
        const userProfile = new UserProfile()
        await userProfile.setExpired()
        // const logout = LogoutChannelForRoot.getService()
        // if (! logout.logoutState.cleanUp ) logout.changeLogoutState({cleanUp: true, login: true})  
        setTimeout(()=> {Navigate.GoToLogin()}, 2000)
        return true
    }

    private static GoToLogin() {
        const logout = LogoutChannelForRoot.getService()
        if (! logout.logoutState.cleanUp ) logout.changeLogoutState({cleanUp: true, login: true})  
    }

    public static CheckForSynchronisation(requestOrResonse: RequestResponse , endpoint: string, payload: any) : void {
        if ( Navigate._syncContextData.requestOrResponse === undefined) {
            if ( requestOrResonse === "Response" ) {
                console.error(`******************* Synchronization-Error Response instead of Request => ${endpoint} *******************` )
                return
            }
            Navigate._syncContextData.requestOrResponse = requestOrResonse
            Navigate._syncContextData.endpoint = endpoint
            return
        }

        if ( Navigate._syncContextData.requestOrResponse === "Request" ) {
            if (requestOrResonse === "Request") {
                console.error(`******************* Synchronization-Error Request followed by Request => ${Navigate._syncContextData.endpoint} - ${endpoint} *******************` )
                Navigate._syncContextData.endpoint = endpoint
                return
            }
            Navigate._syncContextData.requestOrResponse = requestOrResonse;
            Navigate._syncContextData.endpoint = endpoint
         }

        if ( Navigate._syncContextData.requestOrResponse === "Response" ) {
            if (requestOrResonse === "Response") {
                console.error(`******************* Synchronization-Error Response followed by Response => ${Navigate._syncContextData.endpoint} - ${endpoint} *******************` )
                Navigate._syncContextData.endpoint = endpoint
                return
            }
            Navigate._syncContextData.requestOrResponse = requestOrResonse;
            if( Navigate._syncContextData.endpoint !== endpoint) {
                console.error(`******************* Synchronization-Error Endpoints are not equal => ${Navigate._syncContextData.endpoint} - ${endpoint} *******************` )
                Navigate._syncContextData.endpoint = endpoint
            }
        }
    }

}