
interface LocalVerificationStoreElement {
    userId: string
    email: string
    language: string
    password?: string
    firstName?: string
    lastName?: string
    birthDate?: string
    userName?: string
}

export class LocalVerificationStore {
    private static verificationData: LocalVerificationStoreElement
    

    public static SaveVerificationData(verificationData: LocalVerificationStoreElement) {
        LocalVerificationStore.verificationData = verificationData
    }

    public static LoadVerificationData() {
        return LocalVerificationStore.verificationData
    }
}

