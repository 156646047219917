import { Resource } from "@/infrastructure/resource/resource";
import { RemoteWarenKorb } from "./RemoteWarenKorb";

export type WebShopZahlungsStatus = {
    State: "SUCCESSFUL" | "FAILED" | "PROCESSING" | "ERROR" | "UNKNOWN" | "START" | "CONFIRMED" | "-1" | "-9" | "CANCELED" | "STOPPED",
    StateDate?: Date | undefined
    Verzoegert?: string | undefined
}

export class BusinessZahlungAusgefuehrt { 

    public static ShowWebShopNavbar() { 
        const webShopNaveBar = document.getElementById("efjBottomWebShopNavbar");
        const naveBar = document.getElementById("efjBottomNavbar");
        if( naveBar && webShopNaveBar) {
            naveBar.style.display = "none";
            webShopNaveBar.style.display = "block";
        }
    }

    public static HideWebShopNavbar() { 
        const webShopNaveBar = document.getElementById("efjBottomWebShopNavbar");
        const naveBar = document.getElementById("efjBottomNavbar");
        if( naveBar && webShopNaveBar) {
            naveBar.style.display = "block";
            webShopNaveBar.style.display = "none";
        }
    }


    public static ZahlungsStatus: WebShopZahlungsStatus = {
        State: "UNKNOWN",
        StateDate: undefined,
        Verzoegert: undefined
    }

    public static Reset() {
        BusinessZahlungAusgefuehrt.ZahlungsStatus = {
            State: "UNKNOWN",
            StateDate: undefined,
            Verzoegert: undefined
        }
    }

    public static Start() {
        BusinessZahlungAusgefuehrt.ZahlungsStatus = {
            State: "START",
            StateDate: undefined,
            Verzoegert: undefined
        }
    }

    public static SetState(state: "SUCCESSFUL" | "FAILED" | "STOPPED" | "CANCELED" | "PROCESSING" | "ERROR" | "UNKNOWN" | "START" | "CONFIRMED" | "-1" | "-9") {
        if( BusinessZahlungAusgefuehrt.ZahlungsStatus.StateDate === undefined) {
            BusinessZahlungAusgefuehrt.ZahlungsStatus = {
                State: state,
                StateDate: new Date,
            }
        } else {
            if( BusinessZahlungAusgefuehrt.ZahlungsStatus.State === "STOPPED") return
            BusinessZahlungAusgefuehrt.ZahlungsStatus.State = state
        }
    }

    public static IsStateText() : boolean {
        if( BusinessZahlungAusgefuehrt.ZahlungsStatus.Verzoegert && BusinessZahlungAusgefuehrt.ZahlungsStatus.Verzoegert.length > 0) {
            return true
        }
        return false
    }

    public static SetStateText(verzoegert?: string | undefined) {
        BusinessZahlungAusgefuehrt.ZahlungsStatus.Verzoegert = verzoegert
    }

    public static isTimeout() : boolean {

        if( !  BusinessZahlungAusgefuehrt.ZahlungsStatus.StateDate) {
            return false
        }

        const currentDate = new Date()
        const diffInSeconds = Math.abs(currentDate.getTime() - BusinessZahlungAusgefuehrt.ZahlungsStatus.StateDate.getTime()) / 1000;
        if( diffInSeconds > 20) {
            return true
        }

        return false
    }


    public static async WebShopZahlungsHandler() : Promise<WebShopZahlungsStatus | undefined> {
        if( !this.IsStateText ) {
            const abbruch = await Resource.getResourceText(`WebShop.Abbruch.Content`)
            this.SetStateText(abbruch)
        }
      
        const result = await RemoteWarenKorb.istWebshopZahlungAusgefuehrt()
        switch (result) {
            case "SUCCESSFUL":
                this.SetState("SUCCESSFUL")
                break;
            case "9":
                this.SetState("SUCCESSFUL")
                break; 
            case "-1":
                this.SetState("FAILED")
                break;        
            case "FAILED":
                this.SetState("FAILED")
                break;
            case "UKNOWN":
                break;
            case "PROCESSING":
                this.SetState("PROCESSING")
                break;  
            case "CONFIRMED":
                this.SetState("CONFIRMED")
                break; 
            case "ERROR":
                this.SetState("ERROR")
                break;    
        }
        return BusinessZahlungAusgefuehrt.ZahlungsStatus
    }


}