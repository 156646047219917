
import { reactive} from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";

export default {
  name: "WebShopDaten",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "api/Form/loadForm",
      apiSaveMethod: "api/Form/saveForm",
      form: "WebShop",
      page: "WebShopDaten",
      remoteId: "",
      
      
    });
    return {
      request
    };
  }
};
