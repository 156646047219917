
import { defineComponent, reactive } from 'vue';
import DynamicList from '@/components/Shared/dynamicList/DynamicList.vue';


export default defineComponent ({
  name: "WildBook",
  inheritAttrs: false,
  components: {     
    DynamicList
  },
  setup() {
      const request = reactive({
          NavigationState: "WildBook",
          ViewId: "wildBookEntriesWithDetail",          
          DetailId: "WildbookDetailRecord",
          RevierNr: "",
          PageSize: 5,
          TitleResourceId: "Wildbuch.DetailListe.Titel",
          Filter: "WildBookFilter",
          DetailList: false,
          RouteName: "WildBook",
          DetailRouteName: "WildbookDetailRecord",
          Form: "",
          Page: "",     
          Synced: true  
      });

     
      return {
          request
      }
  }
})
