import { ConvertUtcStringToDate } from '../../../../infrastructure/functional/datetimehelper';
import { AsyncCommand, Command } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
import { UserRegistration } from "@/components/registration/userRegistration";

export class RuleEmailVerification extends AsyncCommand {
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  private store: any;

  constructor(dynamicForm: DynamicFormData, dataSet: DynamicDataSet, store: any) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;
    this.store = store;
  }

  public async asyncExecute(): Promise<any> {
    const userRegistration: UserRegistration = new UserRegistration()
    const result = await userRegistration.UserEmailVerification(this.dynamicForm.getValue("Code") as string )
    return result;
  }
}