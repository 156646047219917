import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";


export interface DynamicCloseFormState {
    isClosed: boolean | undefined
    
}

//__ Steuert die Sichtbarkeit des Formulars über die Klasse DynamicNavigation (Proxy)
export class NotifyDynamicList extends StateService<DynamicCloseFormState>{
    public static service: NotifyDynamicList;

    public static getService() : NotifyDynamicList {
        if (!NotifyDynamicList.service) {
            NotifyDynamicList.service = new NotifyDynamicList();
            this.service = NotifyDynamicList.service
        }
        return NotifyDynamicList.service;
    }

    private constructor() {
        super({isClosed: undefined});       
    }
    
    public subscription(): Observable<DynamicCloseFormState> {
        return this.subscribe();
    }

    public visibilityChanged(visibility: DynamicCloseFormState) : void {               
        this.changeState(visibility);
    }

    public reset() : void {
        this.changeState({isClosed: undefined});
    }

    public get visibilityState(): DynamicCloseFormState {
        return this.state;
    }
}