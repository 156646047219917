import { AsyncCommand, Command } from "../Command";
import { DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
import { UserRegistration } from "@/components/registration/userRegistration";

export class RuleEmail extends AsyncCommand {
  private dynamicForm: DynamicFormData;

  constructor(dynamicForm: DynamicFormData) {
    super();
    this.dynamicForm = dynamicForm;
  }

  public async asyncExecute(): Promise<any> {
    const userRegistration: UserRegistration = new UserRegistration()
    const result = await userRegistration.ChangeEmail(this.dynamicForm.getValue("PasswordEmailCode") as string, this.dynamicForm.getValue("Email") as string, this.dynamicForm.getValue("Password") as string )
    return result;
  }
}