import { RuleActivateNewUser } from './../../commands/Concrete/RuleActivateNewUser';
import {
    StateRule,
    StateTransitionOptions,
    StateResult,
    StateNavigationAction,
    Transition,
    addNavigationElement,
    addEmptyNavigationElement,
    addPreviousNext,
    addPrevious,
  } from "../StateInterfaces";
  
  import { BaseState }  from "../StateMachine";
  import { StateHome } from "./StateHome";
  import { StateLogin } from "./StateLogin";
  import { NavigatonIcons } from "@/business/application/NavConst";
  import {Commands} from "@/business/application/commands/Command";
import { Resource } from '@/infrastructure/resource/resource';
  
  
  export class StateManualRegistration extends BaseState {
    private static mainState = "PrivacyPolicy";
    private static transitions: Transition[] = []; 
    private isForwardClicked = true
    private static callerState : StateResult = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''}
  
    public constructor() {
      super()    
    }
  
    public getStartTransition(condition: string) : StateResult {
      StateManualRegistration.transitions = []
      this.getTransitions()
      this.setCallerState(condition)    
      return this.getTransition(this.getTransitions()[0]);
    }
  
    private setCallerState(callerState: string) : StateResult {
      if (callerState === "Login" || callerState === "Home") {      
        StateManualRegistration.callerState.newMainState = "Verification"
        StateManualRegistration.callerState.newState = "WaitOfUserActivation"
        StateManualRegistration.callerState.routeName = callerState
        StateManualRegistration.callerState.oldMainState = callerState
        StateManualRegistration.callerState.oldState = callerState
      }    
      return StateManualRegistration.callerState;
    }
  
    private callerState() : StateResult {
      return StateManualRegistration.callerState
    }
  
    public next(rule: StateRule, options: StateTransitionOptions): StateResult {
      return this.calculateState(
        rule,
        options,
        false,
        StateManualRegistration.mainState
      );
    }
  
    public previous(rule: StateRule,options: StateTransitionOptions): StateResult {
      return this.calculateState(rule,options,true,StateManualRegistration.mainState);
    }
  
    protected checkState(transition: Transition,options: StateTransitionOptions): StateResult {
      const context = this.getContext()
      let newResultState = this.getTransition(transition);
      if ( newResultState.newMainState === "@newMainState@") newResultState.newMainState = this.callerState().oldMainState
      if ( newResultState.newState === "@newState@") newResultState.newState = this.callerState().oldState
  
      let newState: any
      if (!options.lookahead && transition.newState != "") {
        switch (newResultState.newMainState) {
          case "Login":
            newState = new StateLogin();
            newResultState = newState.getStartTransition("Login")
            this.getContext().transitionTo(newState,newResultState)                  
            break;
          case "Home":
            newState = new StateHome();
            newResultState = newState.getStartTransition("Home")
            this.getContext().transitionTo(newState,newResultState)                  
            break;        
          default:
            context.transitionTo(
              new StateManualRegistration(),
              newResultState
            );
        }
      }
      return newResultState;
    }
  
    protected calculateState( rule: StateRule,options: StateTransitionOptions,isPrevious: boolean, _mainState: string): StateResult {    
      const context = this.getContext()
      const currentState = context.getCurrentState()
      this.isForwardClicked = ! isPrevious
      const transition = this.getTransitions().filter(row => {
        if (row.nav === StateNavigationAction.Next && this.isForwardClicked) {        
          
          if(currentState.newState == "ShowInformation" &&  row.oldState == "ManualRegistration") {
            if (  row.condition == "isFinish" ) return true;    
          }
        } 
        
        if (row.nav === StateNavigationAction.Previous && !this.isForwardClicked) {              
          if(currentState.newState == "ShowInformation" &&  row.oldState == "ManualRegistration") {
            if (  row.condition == "isFinish" ) return true;    
          }
  
        }
  
      });            
      return this.checkState(transition[0], options);
    }
  
    protected calculateBusinessConditions(rule: StateRule, _resultState: StateResult): string {
      if (
        rule.conditions === undefined ||
        rule.conditions === null ||
        rule.conditions.length <= 0
      ) {
        return "";
      }
  
      if (rule.conditions.length == 1) {
        if ( rule.conditions[0].value === undefined ) {
          return ""
        }
  
        return rule.conditions[0].value;
      }
      return "";
    }
  
    public getTransitions(): Transition[] {
      if (StateManualRegistration.transitions.length === 0) {
  
        //*****************************************
        //--> Wizzard Start
        //*****************************************        
        let t: Transition = { mainState: "Login", newMainState: "ManualRegistration", nav: StateNavigationAction.Next, oldState: "Login", newState: "ShowInformation", condition: "isStart"};      
        t = addPrevious(t, Commands.PreviousPage);
        StateManualRegistration.transitions.push(t);
  
        //*****************************************
        //--> Wizzard vorward
        //*****************************************
        t = {mainState: "ManualRegistration",newMainState: "Home", oldState: "ManualRegistration", newState: "Home",condition: "isFinish", nav: StateNavigationAction.Next}
        t = addPrevious(t,Commands.PreviousPage);
        StateManualRegistration.transitions.push(t);
  
        //*****************************************
        //--> Wizzard backward
        //*****************************************
        t = {mainState: "ManualRegistration",newMainState: "@newMainState@", oldState: "ManualRegistration", newState: "@newState@" ,condition: "isFinish", nav: StateNavigationAction.Previous}
  
        t = addEmptyNavigationElement(t,1);
        t = addEmptyNavigationElement(t,2);
        t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
        t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
        StateManualRegistration.transitions.push(t);
      
      }
      return StateManualRegistration.transitions;
    }
  }
  