import { Result } from '@/infrastructure/functional/result';
import { StateRegisterUser } from './../../../business/application/statemachine/concrete/StateRegisterUser';
import { Resource } from '../../../infrastructure/resource/resource';
import { RuleEmailVerificationRequest } from '../../../business/application/commands/Concrete/RuleEmailVerificationRequest';
import { RulePasswordForAuthenticatedUser } from '../../../business/application/commands/Concrete/RulePasswordForAuthenticatedUser';
import { DynamicDataSet,DynamicFormData,DynamicRecord,ResponseDataSet } from "@/components/Shared/Dynamicform/DynamicFormData";
import { RuleCondition } from "@/business/application/statemachine/StateInterfaces";
import { ServerLoadForm} from "@/business/application/commands/Concrete/ServerLoadForm";
import { ServerSaveForm } from "@/business/application/commands/Concrete/ServerSaveForm";
import { RuleRegisterFormUserCheck } from "@/business/application/commands/Concrete/RuleRegisterFormUserCheck";
import { RulePasswordEmailRequest } from "@/business/application/commands/Concrete/RulePasswordEmailRequest";
import { RulePasswordEmail } from "@/business/application/commands/Concrete/RulePasswordEmail";
import { RuleSendMailActivateNewUser } from "@/business/application/commands/Concrete/RuleSendMailActivateNewUser"
import { RuleActivateNewUser } from "@/business/application/commands/Concrete/RuleActivateNewUser";
import { RulePassword } from "@/business/application/commands/Concrete/RulePassword";
import { RulePasswordRequest } from "@/business/application/commands/Concrete/RulePasswordRequest";
import { RuleUserProfile} from "@/business/application/commands/Concrete/RuleUserProfile"
import { LocalValidatePage } from "@/business/application/commands/Concrete/LocalValidatePage";
import { LocalLoadPage } from "@/business/application/commands/Concrete/LocalLoadPage";
import { LocalSavePage } from "@/business/application/commands/Concrete/LocalSavePage";
import { RuleEmailVerification } from '../../../business/application/commands/Concrete/RuleEmailVerification';
import { NotificationStateServiceForRoot } from '@/infrastructure/observables/notficationState';
import { ServerLoadDetailForm } from '../../../business/application/commands/Concrete/ServerLoadDetailForm';
import { RuleActivateNewUserForAuthenticatedUser } from '@/business/application/commands/Concrete/RuleActivateNewUserForAuthenticatedUser';
import { RuleEmail } from '@/business/application/commands/Concrete/RuleEmail';
import { RuleEmailRequest } from '@/business/application/commands/Concrete/RuleEmailRequest';
import { RuleUserProfileShowPasswordEmail } from '@/business/application/commands/Concrete/RuleUserProfileShowPasswordEmail';
import { RuleUserProfileSavePasswordEmail } from '@/business/application/commands/Concrete/RuleUserProfileSavePasswordEmail';
import { Out } from '@/infrastructure/frontent/clientMessage';
import { RuleStartFishing } from '@/business/application/commands/Concrete/RuleStartFishing';
import { RuleStopFishing } from '@/business/application/commands/Concrete/RuleStopFishing';
import { RuleFischfangEintrag } from '@/business/application/commands/Concrete/RuleFischfangEintrag';
import { RuleDynamicFilter } from '@/business/application/commands/Concrete/RuleDynamicFilter';
import { RuleDynamicChange } from '@/business/application/commands/Concrete/RuleDynamicChange';
import { RuleDynamicShouldDelete } from '@/business/application/commands/Concrete/RuleDynamicShouldDelete';
import { RuleDynamicDelete } from '@/business/application/commands/Concrete/RuleDynamicDelete';
import { StateMachine,StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
import { TierartRules } from '@/infrastructure/serviceworker-related/TierartZusatzService';
import { UserProfile } from '@/infrastructure/backend/userProfile';
import { GeoValidationStore } from './DynamicFormGeoValidation';


/**
 * Encapsulate the invoker
 * Additional to handle some very special cases, these should not to be in the invoker
 */
export class DynamicFormCommandHandler {
  /**
   * The reactive data are special to vue, it is weird here but necessary
   * at least it is a reactive DataSet
   * @param reactiveData
   * Business Methods for form management
   * @param dynamicForm
   * Data currently in a form
   * @param formData
   * @returns
   */

  private _store: any

  public async loadDetailFormGetPage(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,
    store: any
  ): Promise<DynamicDataSet> {  
    const receiverLoadForm: ServerLoadDetailForm = new ServerLoadDetailForm(dynamicForm, reactiveData, store);
    this._store = store
    
    const formResult = await receiverLoadForm.asyncExecute() as DynamicDataSet



   
    reactiveData.id = "";
    reactiveData.viewId = formResult.viewId;
    reactiveData.view = formResult.view;
    reactiveData.state = formResult.state ?? "";
    reactiveData.stateMessage = formResult.stateMessage ?? "";

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);
    reactiveData = this.loadLocalPage( reactiveData, dynamicForm);

    
    

    

    return reactiveData;
  }


  //NDNDND
  public async loadFormFromPayload( viewId: string, view: DynamicRecord[] | undefined,  reactiveData: DynamicDataSet, dynamicForm: DynamicFormData , store: any): Promise<DynamicDataSet> {  
    reactiveData.id = "";
    reactiveData.viewId = viewId;
    if ( view !== undefined)
      reactiveData.view = view;
    reactiveData.state = "";
    reactiveData.stateMessage = "";

    
    reactiveData = await dynamicForm.getDynamicForm(reactiveData, "")
    
   
    return reactiveData;
  }

  public async loadDynamicFormFromPayload( reactiveData: DynamicDataSet, dynamicForm: DynamicFormData , store: any, clietRouteName: string): Promise<DynamicDataSet> {  
    const result = dynamicForm.getDynamicForm(reactiveData, clietRouteName)
    await dynamicForm.reloadGeoData(reactiveData)
    return result
  }

  public async reloadGeoData( reactiveData: DynamicDataSet, dynamicForm: DynamicFormData) {
    await dynamicForm.reloadGeoData(reactiveData)
  }


  public async loadFormGetPageFromList(reactiveDataValues:DynamicRecord[], reactiveData: DynamicDataSet,dynamicForm: DynamicFormData,store: any): Promise<DynamicDataSet> {      
    
    const receiverLoadForm: ServerLoadForm = new ServerLoadForm(dynamicForm, reactiveData, store, reactiveDataValues);
    this._store = store
    
    const formResult = await receiverLoadForm.asyncExecute() as DynamicDataSet
   
   
    reactiveData.viewId = formResult.viewId;
    reactiveData.view = formResult.view;
    reactiveData.state = formResult.state ?? "";
    reactiveData.stateMessage = formResult.stateMessage ?? "";

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);
    reactiveData = this.loadLocalPage( reactiveData, dynamicForm);

    // try {
    //       reactiveData.systemObjektAenderungZeit = formResult.systemObjektAenderungZeit
        
    // } catch(e) {
    //     Out.error(e)
    // }

    return reactiveData
  }




  public async loadFormGetPage(reactiveData: DynamicDataSet,dynamicForm: DynamicFormData,store: any): Promise<DynamicDataSet> {  
    const receiverLoadForm: ServerLoadForm = new ServerLoadForm(dynamicForm, reactiveData, store, undefined);
    this._store = store
    
    const formResult = await receiverLoadForm.asyncExecute() as DynamicDataSet
   
    if ( !formResult || !formResult.viewId ) return reactiveData
    if ( formResult.viewId.length < 1) return reactiveData

   
    reactiveData.viewId = formResult.viewId;
    reactiveData.view = formResult.view;
    reactiveData.state = formResult.state ?? "";
    reactiveData.stateMessage = formResult.stateMessage ?? "";

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);
    reactiveData = this.loadLocalPage( reactiveData, dynamicForm);

    // try {
    //       reactiveData.systemObjektAenderungZeit = formResult.systemObjektAenderungZeit
        
    // } catch(e) {
    //     Out.error(e)
    // }


    

    return reactiveData;
  }

  public async ruleFischfangEintrag(reactiveData: DynamicDataSet, dynamicForm: DynamicFormData, apiLoadMethod: string, dataView: string , clientRouteName: string ) : Promise<DynamicDataSet> {
    const receiverRuleForm: RuleFischfangEintrag = new RuleFischfangEintrag(dynamicForm,reactiveData,apiLoadMethod,dataView,clientRouteName);
    const formResponse = await receiverRuleForm.asyncExecute()
    reactiveData.state = formResponse.state;
    reactiveData.stateMessage = formResponse.stateMessage;

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }



  public async ruleDynamicFilter(  reactiveData: DynamicDataSet, dynamicForm: DynamicFormData ) : Promise<any> {
    const receiverRuleForm: RuleDynamicFilter = new RuleDynamicFilter(dynamicForm,reactiveData);
    const formResponse = await receiverRuleForm.asyncExecute()
    return formResponse
  }


  public async ruleStartFishing(  reactiveData: DynamicDataSet, dynamicForm: DynamicFormData ) : Promise<any> {
    const receiverRuleForm: RuleStartFishing = new RuleStartFishing(dynamicForm,reactiveData);
    const formResponse = await receiverRuleForm.asyncExecute()
    return formResponse
  }

  public async ruleStopFishing(  reactiveData: DynamicDataSet, dynamicForm: DynamicFormData ) :  Promise<any> {
    const receiverRuleForm: RuleStopFishing = new RuleStopFishing(dynamicForm,reactiveData);
    const formResponse = receiverRuleForm.asyncExecute()
    return formResponse
  }

  public async ruleDynamicChange(  reactiveData: DynamicDataSet, dynamicForm: DynamicFormData ) :  Promise<any> {
    const receiver: RuleDynamicChange = new RuleDynamicChange(dynamicForm,reactiveData);
    const formResponse = receiver.asyncExecute()
    return formResponse
  }

  public async ruleDynamicShouldDelete(  reactiveData: DynamicDataSet, dynamicForm: DynamicFormData ) :  Promise<any> {
    const receiver: RuleDynamicShouldDelete = new RuleDynamicShouldDelete(dynamicForm,reactiveData);
    const formResponse = receiver.asyncExecute()
    return formResponse
  }

  public async ruleDynamicDelete(  reactiveData: DynamicDataSet, dynamicForm: DynamicFormData ) :  Promise<any> {
    const receiver: RuleDynamicDelete = new RuleDynamicDelete(dynamicForm,reactiveData);
    const formResponse = receiver.asyncExecute()
    return formResponse
  }

  public async ruleNavigateUserProfile(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,
    store: any
  ): Promise<DynamicDataSet> {
    

    reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage(reactiveData, dynamicForm);

    const receiverRuleForm: RuleUserProfile = new RuleUserProfile(dynamicForm,reactiveData,store);
    const formResponse = await receiverRuleForm.asyncExecute()
    
    reactiveData.state = formResponse.state;
    reactiveData.stateMessage = formResponse.stateMessage;

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async ruleNavigateRegisterUser(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,
    store: any
  ): Promise<DynamicDataSet> {
    

    reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage(reactiveData, dynamicForm);

    const receiverRuleForm: RuleRegisterFormUserCheck = new RuleRegisterFormUserCheck(dynamicForm,reactiveData,store);
    const formResponse = await receiverRuleForm.asyncExecute()
    
    reactiveData.state = formResponse.state;
    reactiveData.stateMessage = formResponse.stateMessage;

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    await this.notifyUser(reactiveData.state)

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }

  private async notifyUser(message: string) {
    const notficationState = NotificationStateServiceForRoot.getNotificationStateService()
    switch( message) {
      // __ login successful
      case "UserExistence.Valid":
        notficationState.resetNotificationState()
        notficationState.changeNotificationState({
          isNotification: true, text: await Resource.getResourceText("UserExistence.Valid.Content"), title:await Resource.getResourceText("UserExistence.Valid.Title")
        })
        break 
      case "UserExistence.KnownButDifferentEmail":
      case "UserExistence.KnownButHasNoEmail":
        notficationState.resetNotificationState()
        notficationState.changeNotificationState({
          isNotification: true, text: await Resource.getResourceText("UserExistence.KnownButDifferentEmail.Content"), title:await Resource.getResourceText("UserExistence.KnownButDifferentEmail.Title")
        })
        break
      case "UserExistence.ValidButWrongPassword":
        notficationState.resetNotificationState()
        notficationState.changeNotificationState({
          isNotification: true, text: await Resource.getResourceText("UserExistence.ValidButWrongPassword.Content"), title:await Resource.getResourceText("UserExistence.ValidButWrongPassword.Title")
        })
      break
    }
  }

  // public async ruleNavigatePersonProducts(dynamicForm: DynamicFormData,reactiveData: DynamicDataSet, store: any): Promise<DynamicDataSet> {
  //   const receiverRuleForm: ServerLoadForm = new ServerLoadForm(dynamicForm, reactiveData, store);
  //   const formResponse = await receiverRuleForm.asyncExecute()

  //   if (!formResponse) {
  //     reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
  //     return reactiveData;
  //   }

  //   reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
  //   return reactiveData;
  // }

  public async rulePasswordEmailRequest(reactiveData: DynamicDataSet,dynamicForm: DynamicFormData): Promise<DynamicDataSet> {

    reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage(reactiveData, dynamicForm);

    const receiverRuleForm: RulePasswordEmailRequest = new RulePasswordEmailRequest(dynamicForm);
    const formResponse = await receiverRuleForm.asyncExecute()

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async rulePasswordEmail(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,
    store: any
  ): Promise<DynamicDataSet> {
    

    reactiveData = this.validateLocalPage( reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);
    const receiverRuleForm: RulePasswordEmail = new RulePasswordEmail(dynamicForm, reactiveData,store);
    const formResponse = await receiverRuleForm.asyncExecute()

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }

  public async ruleEmailVerificationRequest(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,   
  ): Promise<DynamicDataSet> {

    reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage(reactiveData, dynamicForm);

    const receiverRuleForm: RuleEmailVerificationRequest = new RuleEmailVerificationRequest(dynamicForm);
    const formResponse = await receiverRuleForm.asyncExecute()

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async ruleEmailVerification(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,
    store: any
  ): Promise<DynamicDataSet> {
    

    reactiveData = this.validateLocalPage( reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);
    const receiverRuleForm: RuleEmailVerification = new RuleEmailVerification(dynamicForm, reactiveData,store);
    const formResponse = await receiverRuleForm.asyncExecute()

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async ruleSendMailActivateNewUser(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,   
  ): Promise<DynamicDataSet> {

    reactiveData = this.validateLocalPage( reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);

    const receiverRuleForm: RuleSendMailActivateNewUser = new RuleSendMailActivateNewUser(dynamicForm,reactiveData);
    const formResponse = await receiverRuleForm.asyncExecute()

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async ruleActivateNewUser(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,    
  ): Promise<DynamicDataSet> {
   

    reactiveData = this.validateLocalPage( reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);

    const receiverRuleForm: RuleActivateNewUser = new RuleActivateNewUser(dynamicForm, reactiveData, "");
    const formResponse = await receiverRuleForm.asyncExecute();

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    if ( formResponse && formResponse.resourceId && formResponse.resourceId.indexOf("Error.") >= 0 ) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async ruleActivateNewUserForAuthenticatedUser(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,    
  ): Promise<DynamicDataSet> {
   

    reactiveData = this.validateLocalPage( reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);

    const receiverRuleForm: RuleActivateNewUserForAuthenticatedUser = new RuleActivateNewUserForAuthenticatedUser(dynamicForm);
    const formResponse = await receiverRuleForm.asyncExecute();

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async rulePasswordRequest(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,    
  ): Promise<DynamicDataSet> {
   

    reactiveData = this.validateLocalPage( reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);

    const receiverRuleForm: RulePasswordRequest = new RulePasswordRequest(dynamicForm);
    const formResponse = await receiverRuleForm.asyncExecute()
    
    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }

  public async rulePassword( reactiveData: DynamicDataSet,dynamicForm: DynamicFormData): Promise<DynamicDataSet> {
    reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);

    const receiverRuleForm: RulePassword = new RulePassword(dynamicForm);
    const formResponse = await receiverRuleForm.asyncExecute()
   
    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async ruleEmailRequest(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,   
  ): Promise<DynamicDataSet> {

    reactiveData = this.validateLocalPage( reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);

    const receiverRuleForm: RuleEmailRequest = new RuleEmailRequest(dynamicForm,reactiveData);
    const formResponse = await receiverRuleForm.asyncExecute()

    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async ruleEmail( reactiveData: DynamicDataSet,dynamicForm: DynamicFormData): Promise<DynamicDataSet> {
    reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);

    const receiverRuleForm: RuleEmail = new RuleEmail(dynamicForm);
    const formResponse = await receiverRuleForm.asyncExecute()
   
    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public async rulePasswordForAuthenticatedUser(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,    
  ): Promise<DynamicDataSet> {
    

    reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage( reactiveData, dynamicForm);

    const receiverRuleForm: RulePasswordForAuthenticatedUser = new RulePasswordForAuthenticatedUser(dynamicForm);
    const formResponse = await receiverRuleForm.asyncExecute()
   
    if (!formResponse) {
      reactiveData.tempTaskResult = { errorReason: "Rule", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }

  public ruleUserProfileShowPasswordEmail( reactiveData: DynamicDataSet,dynamicForm: DynamicFormData) {
    const showPasswordEmail: RuleUserProfileShowPasswordEmail = new RuleUserProfileShowPasswordEmail(dynamicForm,reactiveData)
    showPasswordEmail.execute()

  }

  public async ruleUserProfileSavePasswordEmail(reactiveData: DynamicDataSet,dynamicForm: DynamicFormData) {
    const savePasswordEmail: RuleUserProfileSavePasswordEmail = new RuleUserProfileSavePasswordEmail(dynamicForm,reactiveData) 
    return await savePasswordEmail.asyncExecute()
  }

  
  public async saveDetailFormPayload( reactiveData: DynamicDataSet, dynamicForm: DynamicFormData , store: any, clientRouteName: string) : Promise<DynamicDataSet> {
   

    reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {      
      return reactiveData;
    }

    reactiveData = this.saveLocalPage(reactiveData, dynamicForm);
    const receiverSaveForm: ServerSaveForm = new ServerSaveForm(dynamicForm,reactiveData, clientRouteName);
    const formResponse: ResponseDataSet = await receiverSaveForm.asyncExecute()
    reactiveData.state = formResponse.processState;
    reactiveData.stateMessage = formResponse.processStateMessage;
    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }

  public async manageZusatzInfo( store: any) {
    const dataSet = store.getters["dynamicformstore/getDataSet"]    
    const temp = await TierartRules.handleZusatzInfo(dataSet.viewId, dataSet.view)
    await TierartRules.handlePflichtfelder(dataSet.viewId, dataSet.view)

    store.commit("dynamicformstore/addDataSet", dataSet);
    return dataSet
    
  }

  public nextPage( reactiveData: DynamicDataSet,dynamicForm: DynamicFormData, conditions: RuleCondition[]): DynamicDataSet {
    
               // xxx xxx xxx @@@ WildbuchEintragLokalisierungJagd  WildbuchEintragJagd
    if(reactiveData.teilFormularName && reactiveData.view && reactiveData.view.length > 0 && reactiveData.viewId && reactiveData.viewId.indexOf("WildbuchEintrag") >=0 && reactiveData.teilFormularName.indexOf("Lokalisierung") >= 0) {
      const userProfile = new UserProfile()
      if( UserProfile.CurrentProfile.online ) {
        const data = GeoValidationStore.Load()
        //if( data.lv95X !== 0 && data.lv95Y != 0 ) {
          for(const row of reactiveData.view) {
            if(row.formularFeldname && row.formularFeldname === "KoordinateX") {
              if( data.insideRevierBorders ) {
                row.maxLaenge = 999
              } else {
                row.maxLaenge = -1
              }   
            }
            if(row.formularFeldname && row.formularFeldname === "KoordinateY") {
              if( data.insideRevierBorders ) {
                row.maxLaenge = 999
              } else {
                row.maxLaenge = -1
              }   
            }
          }
        //}
      }
    }


    if ( reactiveData.view && reactiveData.view.length > 0 && reactiveData.viewId && reactiveData.viewId.indexOf("WildbuchEintrag") >=0 ) {
      let maxAnzahl = 0
      for(const row of reactiveData.view) {
        if(row.tabellenFeldname && row.tabellenFeldname === "FkTierart" && row.value && row.value.length > 0 
        && row.namedValueListOrigin && row.namedValueListOrigin) {
          for (const item of row.namedValueListOrigin as any ) {
            if(item.key === row.value && item.typ )  {
              console.log(item.value)
              console.log(item.typ)
              if( item.typ === "false") {
                maxAnzahl = 1
                break
              }
            }
          }


          break
        }
      }
   
      for(const row of reactiveData.view) {
        if(row.tabellenFeldname && row.tabellenFeldname === "AnzahlTiere") {
          row.maxLaenge = maxAnzahl
          console.log(row.maxLaenge)
        }
      }
      

    }



    reactiveData = this.saveLocalPage(reactiveData, dynamicForm);
    reactiveData = this.validateLocalPage( reactiveData, dynamicForm);

    if ( reactiveData.teilFormularName === "WildbuchFilter" || reactiveData.teilFormularName === "HomeFischereiDaten") {
      throw("");
    }

    if (!reactiveData.tempTaskResult?.isSucceeded) {      
      return reactiveData;
    }

    this.formConditions(conditions,reactiveData,dynamicForm)
    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };

    return reactiveData;
  }

  public isLocalPageValid(reactiveData: DynamicDataSet,dynamicForm: DynamicFormData): boolean {
     
  
      reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
      if (!reactiveData.tempTaskResult?.isSucceeded) {
        return false
      }
      return true
  }


  public async saveForm(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,   
    clientRouteName: string 
  ): Promise<DynamicDataSet> {
   

    reactiveData = this.validateLocalPage(reactiveData, dynamicForm);
    if (!reactiveData.tempTaskResult?.isSucceeded) {
      return reactiveData;
    }

    reactiveData = this.saveLocalPage(reactiveData, dynamicForm);
    const receiverSaveForm: ServerSaveForm = new ServerSaveForm(dynamicForm,reactiveData, clientRouteName);
    const formResponse: ResponseDataSet = await receiverSaveForm.asyncExecute()
    if ( ! formResponse) {      
      reactiveData.tempTaskResult = { errorReason: "Server error", isSucceeded: false };
      return reactiveData;
    }

    reactiveData.state = formResponse.processState;
    reactiveData.stateMessage = formResponse.processStateMessage;
    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }


  public previousPage(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData,
    conditions: RuleCondition[]
  ): DynamicDataSet {

    reactiveData = this.saveLocalPage(reactiveData, dynamicForm);
    this.formConditions(conditions,reactiveData,dynamicForm)

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }

  private saveLocalPage(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData
  ): DynamicDataSet {
    const receiverLocalSavePage: LocalSavePage = new LocalSavePage(dynamicForm,reactiveData);
    receiverLocalSavePage.execute()
    return reactiveData;
  }

 
  private validateLocalPage(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData
  ): DynamicDataSet {
    const receiverLocalPage: LocalValidatePage = new LocalValidatePage(dynamicForm,reactiveData);
    receiverLocalPage.execute()
    if (!dynamicForm.allFieldsValid(reactiveData.teilFormularName ?? "", reactiveData)) {
      reactiveData.tempTaskResult = {
        errorReason: "Validation",
        isSucceeded: false
      };
      return reactiveData;
    }

    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };
    return reactiveData;
  }

  public loadLocalPage(
    reactiveData: DynamicDataSet,
    dynamicForm: DynamicFormData
  ): DynamicDataSet {
    const command: LocalLoadPage = new LocalLoadPage(dynamicForm,reactiveData);
    const pageResult = command.execute()
    reactiveData.view = pageResult.view;
    reactiveData.tempTaskResult = { errorReason: "", isSucceeded: true };

    return reactiveData;
  }

  private formConditions(conditions: RuleCondition[], reactiveData: DynamicDataSet,dynamicForm: DynamicFormData) {
    if ( reactiveData.viewId === "RegistrierungBenutzer" ) {
      let isFishing = "false"
      let isHunting = "false"

      let temp =  dynamicForm.getValue("IchBinFischer") 
      if (temp !== undefined && temp !== "") {
        isFishing = "false"
        if (typeof(temp) === "boolean" && temp === true ) {
          isFishing = "true"
        }
        if (typeof(temp) === "string" && temp === "true" ) {
          isFishing = "true"
        }
      }      
      conditions.push({nameId: "isFishing", value: isFishing })

      temp =  dynamicForm.getValue("IchBinJaeger") 
      if (temp !== undefined && temp !== "") {
        isHunting = temp === true ? "true" : "false"
      }
      conditions.push({nameId: "isHunting", value: isHunting })
    }
  }

}
