import { ObjectListItems } from '../generics/namedListItem';
import { FischereiRevierTable, JagdRevier, PatentTable, getRecord } from '../database/asyncDb';

export async function getObjectSearchDataSource(sourceName: string, parentCascadeId: string) : Promise<ObjectListItems[] | undefined> {
    try {
        if ( !parentCascadeId || parentCascadeId.length < 1) {
            return undefined
        }

        const source = sourceName.slice().replace("AtOnce","")
        const items: unknown[] = []
        if ( source == "Patent") {
            const result = await getRecord(PatentTable, source)
            return result.objectList
        }
        if ( source == "FischereiRevier" && parentCascadeId && parentCascadeId !== '') {
            const result = await getRecord(FischereiRevierTable, source)
            for(const item of result.objectList ) {
                if ( item.personProductNumber === parentCascadeId) {
                    items.push(item)
                }
            }
            try {
                if ( ! items) return undefined
                const temp = items as unknown as ObjectListItems[]
                if ( ! temp[0] ) return undefined
            } catch(e) {
                return undefined
            }

            return items as unknown as ObjectListItems[]
        }

        if ( source == "JagdRevier") {
            const result = await getRecord(JagdRevier, source)
            return result.objectList
        }

        return undefined
    
    } catch(e) {
        return undefined
    }
}