import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { DynamicRecord } from "../Shared/Dynamicform/DynamicFormData";
import { Identity } from "@/infrastructure/backend/userIdentity";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { BusinessLogicJahresWechsel } from "./BusinessLogicJahresWechsel";
import { correctFormValues } from "@/infrastructure/functional/formHelper";
import { Navigate } from "@/infrastructure/backend/async/Navigate";
import { Resource } from "@/infrastructure/resource/resource";
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
import { getCurrentDayDate } from "@/infrastructure/functional/datetimehelper";
import { pushScopeId } from "vue";
import { Out } from "@/infrastructure/frontent/clientMessage";

type RemoteProduktRequest = {
    userId: string,
    language: string,
    gueltigAb: string,
    items: DynamicRecord[]
}

type JahreswechselRequest = {
    userId: string,
}

export type JahresWechsel = {
    wechsel: string | undefined
    referenzDatum: string | undefined
    aktuell: string | undefined
    loaded: Date | undefined
}

export class RemoteProdukt {

    private static jahresWechselStore: JahresWechsel = {
        wechsel: undefined,
        referenzDatum: undefined,
        aktuell: undefined,
        loaded: undefined
    }


    public static async  setDetails(form: DynamicRecord[]) : Promise<DynamicRecord[] | undefined> {
    

        const jahreswechsel = await RemoteProdukt.getJahresWechsel("RemoteProdukt")
        const businessJahresWechsel = new BusinessLogicJahresWechsel(jahreswechsel)
        
        form = correctFormValues(form)

        const request: RemoteProduktRequest = {
            userId: Identity.getIdentity().getValue().UserId,
            language: (await UserProfile.GetClientSettingsAsync()).language,
            gueltigAb: BusinessLogicJahresWechsel.getReferenzDatum(),
            items: form
        }

    
        if( Navigate.IsLocked() ) {
            return undefined
        }
 
       const client = AsyncRestClient.Create(true);
       if (client.isFailure) throw new Error("Error.getProductProps");
       const result = await client
           .getValue()
           .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/webShop/getProductProps", JSON.stringify(request));  
   
       if (result.isFailure) throw new Error(`${result.error}`) 
               
       if ((result as any).offline) {
        const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
        notficationState.resetNotificationState()

        notficationState.changeNotificationState({
        isNotification: true, text:  Resource.getResourceTextSyncMode("Offline.NotAllowed.Content", ""), title: Resource.getResourceTextSyncMode("Offline.NotAllowed.Title", "")})
        return undefined
       }

       

       const temp = result.getValue() as unknown as any
       if (temp && temp.length > 0) {
           const records = temp as DynamicRecord[]

           for( const row of records) {
            if(row.formularFeldname === "ReferenzDatumFilter") {
                    row.istEditierbar = false
                    if ( businessJahresWechsel.isNextYear()) {
                    row.istEditierbar = true
                    row.value = BusinessLogicJahresWechsel.getReferenzDatumValue()
                     }
                }
            }
           return records
       }
       return undefined
   }


   public static resetJahresWechsel() {
    RemoteProdukt.jahresWechselStore = {
        wechsel: undefined,
        referenzDatum: undefined,
        aktuell: undefined,
        loaded: undefined
    }
   }


   public static async  getJahresWechsel(caller: string) : Promise<JahresWechsel | undefined> {
    
    console.error(caller)

    try {
        if ( RemoteProdukt.jahresWechselStore && RemoteProdukt.jahresWechselStore.loaded ) {
            const dayDate = getCurrentDayDate()
            if ( RemoteProdukt.jahresWechselStore.loaded.getTime() === dayDate.getTime() ) {
                if( RemoteProdukt.jahresWechselStore.aktuell && RemoteProdukt.jahresWechselStore.referenzDatum && RemoteProdukt.jahresWechselStore.wechsel ) {
                    return RemoteProdukt.jahresWechselStore
                }
            }
        }
    } catch(e) {
        Out.noOperation("")
    }

    const request: JahreswechselRequest = {
        userId: Identity.getIdentity().getValue().UserId,
    }

    if( Navigate.IsLocked() ) {
        return undefined
    }


       const client = AsyncRestClient.Create(true);
       if (client.isFailure) throw new Error("Error.getJahreswechsel");
       const result = await client
           .getValue()
           .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/webShop/getJahreswechsel", JSON.stringify(request));  
   
       if (result.isFailure) throw new Error(`${result.error}`)       
       
       if ((result as any).offline) {
        const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
        notficationState.resetNotificationState()

        notficationState.changeNotificationState({
        isNotification: true, text:  Resource.getResourceTextSyncMode("Offline.NotAllowed.Content", ""), title: Resource.getResourceTextSyncMode("Offline.NotAllowed.Title", "")})
        return undefined
       }

       const temp = result.getValue() as unknown as any
       if (temp && temp.referenzDatum) {
           const wechsel = temp as JahresWechsel
           wechsel.loaded = getCurrentDayDate()

           RemoteProdukt.jahresWechselStore = wechsel

           return wechsel
       }
       return undefined
   }
}