
import { reactive} from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";

export default {
  name: "WildBookObserveLocation",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "",
      form: "WildbuchEintragBeobachtung",
      page: "WildbuchEintragOrtBeobachtung",
      remoteId: "",
      
      

    });
    return {
      request
    };
  }
};
