import {
  StateResult,
  StateRule,
  Transition,
  StateTransitionOptions,
  StateNavigationAction,
  
} from "./StateInterfaces";

export abstract class BaseState {
  private _stage!: StateMachineImplementation;
  private static _transitions: Transition[] = [];

  public setContextBackReference(stage: StateMachineImplementation) {
    this._stage = stage;
  }

  protected getCurrentResultState(): StateResult {
    return this._stage.getCurrentResultState();
  }

  protected getContext(): StateMachineImplementation {
    return this._stage;
  }


  public getTransition(transition: Transition) : StateResult {
    const result: StateResult = {
      newMainState: "",
      oldMainState: "",
      oldState: "",
      newState: "",
      routeName: "",
      command: "",
      interaction: {
        isLocalSave: false,
        isRemoteSave: false,
        isValidation: false,
        isRemoteLoad: false
      }
    };
    if (transition != null) {
      result.newState = transition.newState;
      result.oldState = transition.oldState;
      result.routeName = transition.newState;
      result.newMainState = transition.newMainState;
      result.oldMainState = transition.mainState;
      result.interaction = {
        isLocalSave: transition.interaction?.isLocalSave ?? false,
        isRemoteSave: transition.interaction?.isRemoteSave ?? false,
        isRemoteLoad: transition.interaction?.isRemoteLoad ?? false,
        isValidation: transition.interaction?.isValidation ?? false
      };
      result.navigation = {
        nav1: {
          command: transition.navigation?.nav1.command ?? "",
          displayName: transition.navigation?.nav1.displayName ?? "",
          enabled: transition.navigation?.nav1.enabled ?? false,
          existent: transition.navigation?.nav1.existent ?? false,
          imageUrl: transition.navigation?.nav1.imageUrl ?? "",
          visible: transition.navigation?.nav1.visible ?? false
        },
        nav2: {
          command: transition.navigation?.nav2.command ?? "",
          displayName: transition.navigation?.nav2.displayName ?? "",
          enabled: transition.navigation?.nav2.enabled ?? false,
          existent: transition.navigation?.nav2.existent ?? false,
          imageUrl: transition.navigation?.nav2.imageUrl ?? "",
          visible: transition.navigation?.nav2.visible ?? false
        },
        nav3: {
          command: transition.navigation?.nav3.command ?? "",
          displayName: transition.navigation?.nav3.displayName ?? "",
          enabled: transition.navigation?.nav3.enabled ?? false,
          existent: transition.navigation?.nav3.existent ?? false,
          imageUrl: transition.navigation?.nav3.imageUrl ?? "",
          visible: transition.navigation?.nav3.visible ?? false
        },
        nav4: {
          command: transition.navigation?.nav4.command ?? "",
          displayName: transition.navigation?.nav4.displayName ?? "",
          enabled: transition.navigation?.nav4.enabled ?? false,
          existent: transition.navigation?.nav4.existent ?? false,
          imageUrl: transition.navigation?.nav4.imageUrl ?? "",
          visible: transition.navigation?.nav4.visible ?? false
        }
      };
    }
    return result;
  }

  public abstract getStartTransition(condition: string) : StateResult
  public abstract getTransitions(): Transition[];  
  public abstract next(
    rule: StateRule,
    options: StateTransitionOptions
  ): StateResult;
  public abstract previous(
    rule: StateRule,
    options: StateTransitionOptions
  ): StateResult;
  protected abstract calculateState(
    rule: StateRule,
    options: StateTransitionOptions,
    isPrevious: boolean,
    mainState: string
  ): StateResult;
  protected abstract checkState(
    transition: Transition,
    options: StateTransitionOptions
  ): StateResult;
  protected abstract calculateBusinessConditions(
    rule: StateRule,
    resultState: StateResult
  ): string;
}

export interface StateMachine {
  getCurrentResultState(): StateResult;
  getCurrentState() : StateResult;
  getTransitions(mainState: string): Transition[];
  setStartState(state: BaseState) : void;
  setAppUpdateState(state: BaseState) : void;
  setAppUpdateStateDetailed(state: BaseState, stateParams: StateResult): void
  next(rule: StateRule, options: StateTransitionOptions): StateResult;
  previous(rule: StateRule, options: StateTransitionOptions): StateResult;    
  last(): StateResult
}

function getDefaultState(): StateResult {
  const state: StateResult = {   
    newMainState: "",
    oldMainState: "",
    newState: "",
    oldState: "",
    routeName: "",    
  };
  return state;
}

function getAppUpdateState(): StateResult {
  const state: StateResult = {   
    newMainState: "Login",
    oldMainState: "Start",
    newState: "Login",
    oldState: "Start",
    routeName: "",    
  };
  return state;
}

class DefaultState extends BaseState {
  public getStartTransition(condition: string): StateResult {
    throw new Error("Method not implemented.");
  }
  public getTransitions(): Transition[] {
    throw new Error("Method not implemented.");
  }
  public next(rule: StateRule, options: StateTransitionOptions): StateResult {
    return getDefaultState()
  }
  public previous(rule: StateRule, options: StateTransitionOptions): StateResult {
    throw new Error("Method not implemented.");
  }
  protected calculateState(rule: StateRule, options: StateTransitionOptions, isPrevious: boolean, mainState: string): StateResult {
    throw new Error("Method not implemented.");
  }
  protected checkState(transition: Transition, options: StateTransitionOptions): StateResult {
    throw new Error("Method not implemented.");
  }
  protected calculateBusinessConditions(rule: StateRule, resultState: StateResult): string {
    throw new Error("Method not implemented.");
  }
}

class StateMachineImplementation implements StateMachine {

  private _state: BaseState = new DefaultState();
  private _stateResult: StateResult = getDefaultState();
  private _fullStateResult: StateResult  = getDefaultState();

  private _history: [{rule: StateRule, option: StateTransitionOptions} | undefined] = [{rule: {navigation: StateNavigationAction.Next, conditions: []} , option: { lookahead: false}}]
  
  private tx: StateRule = {
    navigation: StateNavigationAction.Next,
    conditions: []
  }

  private ty: StateTransitionOptions = {
    lookahead: false
  }

  constructor() {
    //console.log(this._state)
  }

  public getCurrentResultState(): StateResult {
    return this._fullStateResult
  }

  public getCurrentState() : StateResult {
    return this._stateResult
  }

  public getTransitions(): Transition[] {
    return this._state.getTransitions();
  }

  public setStartState(state: BaseState) {
    this._state = state;
    this._stateResult = getDefaultState();
    this._fullStateResult = getDefaultState();
    this._state.setContextBackReference(this);
  }

  public setAppUpdateState(state: BaseState) {
    this._state = state;
    this._stateResult = getAppUpdateState();
    this._fullStateResult = getAppUpdateState();
    this._state.setContextBackReference(this);
  }

  public setAppUpdateStateDetailed(state: BaseState, stateParams: StateResult) {
    this._state = state;
    this._stateResult = stateParams;
    this._fullStateResult = stateParams;
    this._state.setContextBackReference(this);
  }

  public transitionTo(state: BaseState, stateResult: StateResult): void {
    this._state = state;
    this._stateResult = stateResult;
    this._state.setContextBackReference(this);
  }

  public next(rule: StateRule, options: StateTransitionOptions): StateResult {
    const tempOnlyState = this._state.next(rule, options);
    tempOnlyState.isBackWard = false
    this._history.push({rule: rule,option: options})
    if (!options.lookahead) {
      this._stateResult = tempOnlyState
      this._fullStateResult = tempOnlyState
    }    
    return tempOnlyState
  }

  public last() {
    if ( this._history.length < 3) {
      const state = this._history.pop()
      this._history.push(state)
      return this.previous(state!.rule, state!.option)      
    }       
    this._history.pop()
    const lastState = this._history.pop()
    return this.previous(lastState!.rule, lastState!.option)
  }

  public previous(
    rule: StateRule,
    options: StateTransitionOptions
  ): StateResult {
    const tempOnlyState = this._state.previous(rule, options);
    tempOnlyState.isBackWard = true
    this._history.push({rule: rule,option: options})
    if (!options.lookahead) {
      this._stateResult = tempOnlyState
      this._fullStateResult = tempOnlyState
    }
    return tempOnlyState

  }
}

export class StateMachineServiceLocator {
  private static stateMachine: StateMachineImplementation;
  

  public static get(): StateMachine {
    if (StateMachineServiceLocator.stateMachine === undefined) {
      StateMachineServiceLocator.stateMachine = new StateMachineImplementation();
    }
    return StateMachineServiceLocator.stateMachine as StateMachine;
  }
}
