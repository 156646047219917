import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a1e488c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "warenkorb-liste" }
const _hoisted_2 = { class: "row g-0" }
const _hoisted_3 = { class: "col d-flex justify-content-center" }
const _hoisted_4 = { class: "row g-0 eFj-list-content" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "eFj-list-card" }
const _hoisted_7 = { class: "row g-0" }
const _hoisted_8 = { class: "col-1 eFj-list-card-image" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "col-1" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.shoppingCard.shoppingCardLineItems, (record, id) => {
                return (_openBlock(), _createElementBlock("div", { key: id }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("img", {
                          class: "eFj-image",
                          src: (_ctx.getImagePath(record))
                        }, null, 8, _hoisted_9)
                      ]),
                      _createElementVNode("div", {
                        innerHTML: _ctx.getContent(record),
                        class: _normalizeClass(_ctx.syncSupportedAdjustTitle())
                      }, null, 10, _hoisted_10),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", {
                          onClick: _withModifiers(($event: any) => (_ctx.deleteProduct(record)), ["prevent"]),
                          class: "col eFj-list-card-image"
                        }, [
                          _createElementVNode("img", {
                            class: "eFj-image",
                            src: (_ctx.getTrashImagePath())
                          }, null, 8, _hoisted_13)
                        ], 8, _hoisted_12)
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}