import { newDate } from "@/infrastructure/functional/datetimehelper";
import { Static } from "vue";

export interface LogRecord{
  id: number
  timestamp: string
  type: string
  title: string
  message: string
}

export enum LogTyp{
  Fehler,
  Warnung,
  Info
}

export class LightLog {
  static records: LogRecord[] = []
  static maxcRecords = 50
  static currentRecord = 0

  public static reset() {
    this.records = []
    this.currentRecord = 1
  }

  public static add(title: string, message: string, typ: LogTyp) {
    this.currentRecord = this.currentRecord + 1
    if ( this.currentRecord > 50) {
      this.records = []
      this.currentRecord = 1
    }

    const currentDateTime = newDate().toLocaleString("de-DE")
    LightLog.records.push({id: this.currentRecord, title: title, message: message, type: typ.toString(), timestamp: currentDateTime})
  }

  public static error(moduleIdentifier: string,  e: any) {
    this.currentRecord = this.currentRecord + 1
    if ( this.currentRecord > 50) {
      this.records = []
      this.currentRecord = 1
    }

    const currentDateTime = newDate().toLocaleString("de-DE")
    LightLog.records.push({id: this.currentRecord, title: "Fehler !", message: moduleIdentifier, type: LogTyp.Fehler.toString(), timestamp: currentDateTime})
    console.log("Fehler !" + " " + moduleIdentifier)
  }

  public static getLogs() : LogRecord[] {
    return LightLog.records.reverse();
  }

}