import { RouteRecordName, RouteRecordNormalized } from "vue-router";
import { RouterChangedNotification, RouterState } from "./RouterChangeNotification";
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
import { Resource } from "@/infrastructure/resource/resource";
import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
import { StateNavigationAction } from "@/business/application/statemachine/StateInterfaces";
import { StateLogin } from "@/business/application/statemachine/concrete/StateLogin";
import { deleteDatabase } from "@/infrastructure/database/asyncDb";


export class RouterGuard
{
    public static IsAllowed(routes: RouteRecordNormalized[], routeFrom: RouteRecordName | null | undefined,  routeTo: RouteRecordName | null | undefined, store: any , stateMachine: any , isSynced: boolean ) : boolean {

        const routerNavigationService =  RouterChangedNotification.getService()


        if ( routeFrom === undefined &&  routeTo === undefined)
            return false

            if ( routeTo === "RegistrationLogin") {

                if (isSynced) {                   
                    return true;
                }

                const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
                notficationState.resetNotificationState()
                notficationState.changeNotificationState({
                   isNotification: true, text:  Resource.getResourceTextSyncMode("Registrierung.NotSynced.Content", ""), title: Resource.getResourceTextSyncMode("Registrierung.NotSynced.Title", "")})
                
                   const navigation = StateNavigationAction.Next;
                   const conditions = [];
                   conditions.push({ nameId: "Login", value: "Login" });
                   stateMachine.setStartState(new StateLogin())
                   stateMachine.next(
                     { navigation: navigation,conditions: conditions},
                     { lookahead: false}
                   )
                   changeNavigation(store);     
               
                return false                   
            
            }
    

        for( const route of routes ) {
            if ( route.name === routeFrom || route.name === routeTo) {
                const routerState: RouterState = {
                    routeName: routeTo?.toString(),
                    routeFromName: routeFrom?.toString()

                }
                routerNavigationService.changeRouterState(routerState)
                return true
            }
        }


        return false;
    }

   
}

