import { StateResult } from "@/business/application/statemachine/StateInterfaces";
export const uistatestore = {
  namespaced: true,
  state: {
    stateData: {
      newMainState: "",
      oldMainState: "",
      newState: "",
      oldState: "",
      routeName: "",
      interaction: {
        isValidation: false,
        isLocalSave: false,
        isRemoteSave: false,
        isRemoteLoad: false
      },
      navigation: {
        nav1: {
          displayName: "",
          imageUrl: "",
          enabled: false,
          visible: false,
          existent: false,
          command: ""
        },
        nav2: {
          displayName: "",
          imageUrl: "",
          enabled: false,
          visible: false,
          existent: false,
          command: ""
        },
        nav3: {
          displayName: "",
          imageUrl: "",
          enabled: false,
          visible: false,
          existent: false,
          command: ""
        },
        nav4: {
          displayName: "",
          imageUrl: "",
          enabled: false,
          visible: false,
          existent: false,
          command: ""
        }
      }
    }
  },
  mutations: {
    setStateSet(state: any, payload: StateResult) {
      state.stateData = payload;
    }
  },
  actions: {
    async setStateSet(context: any, payload: StateResult) {
      context.commit("setStateSet", payload);
    }
  },
  getters: {
    getStateSet: function(state: any): StateResult {
      return state.stateData;
    }
  }
};
