
import { onUnmounted, reactive, ref} from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
import { RemoteZahlung } from "./RemoteZahlung";
import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
import router from "@/router";
import { BusinessZahlungAusgefuehrt } from "./BusinessZahlungAusgefuehrt";
import { PaymentCancelService } from "@/hooks/PaymentCancelService";
import { Resource } from "@/infrastructure/resource/resource";

/* eslint-disable no-undef */
/* tslint:disable */
export default {
  name: "WebShopKasse",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "",
      form: "WebShop",
      page: "Bezahlen",
      remoteId: "",
    });
    const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
    const cancelService = PaymentCancelService.getService();

    const src = ref("")
    let interval: number | undefined = undefined 
    let timer: number | undefined = undefined
    
    const onCancelService = cancelService.subscription().subscribe((value) => {
      if (value.IsCanceled) {
        clearInterval(interval)
        interval = undefined
        BusinessZahlungAusgefuehrt.HideWebShopNavbar()
        BusinessZahlungAusgefuehrt.SetState("STOPPED")
        deletePaymentWrapper()
        router.push({ name: 'WebshopFailed'});
      }
    })

    async function load() {

        const abbruch = await Resource.getResourceText(`WebShop.Abbruch.Content`)
        BusinessZahlungAusgefuehrt.SetStateText(abbruch)

        const card = await RemoteZahlung.startZahlung()
        if (!card || !card.postFinanceScriptReference || card.postFinanceScriptReference.length < 1 )  {
          return
        }

        src.value = card.postFinanceScriptReference
        addScript(src.value)
        checkAndStartPayment()

    }


    interval = setInterval(() => {
      handlePayment()        
      }, 10000);

      async function handlePayment() {
        const result = await BusinessZahlungAusgefuehrt.WebShopZahlungsHandler()
        if( result?.State === "SUCCESSFUL") {
          if( BusinessZahlungAusgefuehrt.isTimeout() ) {
            clearInterval(interval)
            BusinessZahlungAusgefuehrt.HideWebShopNavbar()
            interval = undefined
            deletePaymentWrapper()
            router.push({ name: 'WebshopSuccess'});
          }
        } 
        if( result?.State === "FAILED") {
          if( BusinessZahlungAusgefuehrt.isTimeout() ) {
            clearInterval(interval)
            BusinessZahlungAusgefuehrt.HideWebShopNavbar()
            interval = undefined
            deletePaymentWrapper()
            router.push({ name: 'WebshopFailed'});
          }
        } 

        if( result?.State === "PROCESSING" || result?.State === "CONFIRMED") {
          if( ! timer) {
            timer = setTimeout(() => {
              BusinessZahlungAusgefuehrt.ShowWebShopNavbar()
              timer=undefined
            }, 0)
          }
        }

      }
    
      function deletePaymentWrapper() {
        const element = document.getElementById("paymentFrameWrapper")
        if (element) element.remove()
      }

    function addScript(scriptReference: string) {
      const element = document.getElementById("PostFinance");
      if(element)
        element.remove()

      const postFinanceScript = document.createElement('script');
      postFinanceScript.setAttribute(
        'id',
        'PostFinance'
      );
      postFinanceScript.setAttribute(
        'src',
        scriptReference
      );
      postFinanceScript.setAttribute('async', 'true');
      postFinanceScript.setAttribute('defer', 'true');
      document.head.appendChild(postFinanceScript);
    }

    function checkAndStartPayment()  {
      let i = 0;
      
      const paymentStartInterval = setInterval(() =>  {
          if (i == 20 || (checkForPaymentUrl() && i > 1) ) {
            clearInterval(paymentStartInterval)            
            startPayment()
          }
          i++
      }, 500 )
    }

    function checkForPaymentUrl() : boolean {
      const element = document.getElementById("PostFinance")
      if (element) {
        const temp = element as HTMLScriptElement
        const htmlSrc = temp.src
        if (htmlSrc === src.value ) return true
      }
      return false
    }
  
    function resetLoaderState() {
      setTimeout(()=> {
        loaderState.changeLoaderState({isInProgress: false})
      }, 1000)
      
    }

    function startPayment() {
      
      var paymentMethodConfigurationId = 1;
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      if (window.LightboxCheckoutHandler) {
        resetLoaderState()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.LightboxCheckoutHandler.startPayment(paymentMethodConfigurationId, function () {
            alert('An error occurred during the initialization of the payment lightbox.');
        });
      }
    }

    load()

    onUnmounted(() => {
      onCancelService.unsubscribe()
      try { 
        if( interval ) clearInterval(interval)
      } catch(e) {
        console.log(e)
      }
      try { 
        if( timer ) clearTimeout(timer)
      } catch(e) {
        console.log(e)
      }
       
      });

    return {
      request,
      src,
      startPayment
    };
  }
};
