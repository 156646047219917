
    import { onMounted, onUnmounted, ref, reactive, defineComponent, computed } from "vue";
    import {sanitize} from "@/infrastructure/functional/sanitize"
    import { LocalWarenkorb, ShoppingCardItemLocal } from "./LocalWarenkorb";
import { RemoteWarenKorb } from "./RemoteWarenKorb";
import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";


    export default defineComponent ({
        name: "WarenKorbListe",
        inheritAttrs: false,
        components: {        
        },
        props: {
        },
        setup(props) {
        
        const request = reactive({
            TitleResourceId: "WebShop.Warenkorb.Titel",
            Title: "",
        })

        const result = reactive({
            shoppingCard: {}
        })

        const loaderState = LoaderStateServiceForRoot.getLoaderStateService()

        async function load() {
          const temp  = await LocalWarenkorb.GetShoppingCard()
            if(temp)
              result.shoppingCard = temp
        }

        function syncSupportedAdjustTitle() {
            return "col-8 eFj-list-card-text"
        }

        function getImagePath(record: ShoppingCardItemLocal) {
            return record.icon
        }

        function getContent(record: ShoppingCardItemLocal) {
            return record.productName + "<br />" + "Preis: " + record.amountIncludeTax + "<br />" + "Gültig: " + record.timePeriod
        }

        async function deleteProduct(record: ShoppingCardItemLocal) {
          loaderState.changeLoaderState({isInProgress: true})
            await LocalWarenkorb.DeleteProductItem(record.id)
            const temp = await LocalWarenkorb.GetShoppingCard()
            if( temp)
             result.shoppingCard = temp
            const remoteResult = await RemoteWarenKorb.proofWebShopProducts()
            await load()
            loaderState.changeLoaderState({isInProgress: false})
           
        }

        function getTrashImagePath() {
            return "img/icons/trash.svg"
        }

        load()
    
        
    // onMounted(() => {           
    // })

  
    // onUnmounted(() => {
    // });
  
      return {             
        syncSupportedAdjustTitle, 
        getImagePath,
        getContent,
        getTrashImagePath,
        deleteProduct,
        request,
        result
      };
    },
  });
