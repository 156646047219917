import { Identity } from '../../infrastructure/backend/userIdentity';
import { UserProfile } from '@/infrastructure/backend/userProfile';
import { AsyncRestClient } from '../../infrastructure/backend/async/AsyncRestClient';
import { ResponseDataSet } from '@/components/Shared/Dynamicform/DynamicFormData';
import { ConvertEuropeanToDate } from "@/infrastructure/functional/datetimehelper"
import { Navigate } from '@/infrastructure/backend/async/Navigate';
import { LocalVerificationStore } from '@/infrastructure/local-api/LocalVerificationCodeStore';

export class UserRegistration {
    public async CreateUserActivationTicket(email: string) : Promise<boolean> {
        const result = await this.SendRegistrationInformation("api/anonymousRegistration/createActivationTicket", JSON.stringify({
            userId:  Identity.getIdentity().getValue().UserId,
            email: email,            
            language: (await UserProfile.GetClientSettingsAsync()).language
        }))

        LocalVerificationStore.SaveVerificationData({
            userId: Identity.getIdentity().getValue().UserId,
            email: email,
            language: (await UserProfile.GetClientSettingsAsync()).language,       
        })


        return result
    }

    public async CreateUserEmailTicket(email: string) : Promise<boolean> {
        const result = await this.SendRegistrationInformation("api/anonymousRegistration/createEmailTicket", JSON.stringify({
            userId:  Identity.getIdentity().getValue().UserId,
            email: email,            
            language: (await UserProfile.GetClientSettingsAsync()).language
        }))

        LocalVerificationStore.SaveVerificationData({
            userId: Identity.getIdentity().getValue().UserId,
            email: email,
            language: (await UserProfile.GetClientSettingsAsync()).language,       
        })


        return result
    }

    public async UserEmailVerification(code: string) : Promise<boolean> {
        return await this.SendRegistrationInformation("api/anonymousRegistration/userEmailVerfication", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,
            code: code,
        }))
    }


    // public async ActivateUser(code: string) : Promise<boolean> {
    //     return await this.SendRegistrationInformation("api/anonymousRegistration/activateUser", JSON.stringify({
    //         userId: Identity.getIdentity().getValue().UserId,
    //         code: code,
    //     }))
    // }

    public async ActivateAuthenticatedUser() : Promise<boolean> {
        return await this.SendRegistrationInformation("api/Registration/activateUser", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,            
        }))
    }


    
        
    public async CreateUserPasswordTicket(email: string) : Promise<boolean> {
        const result = await this.SendRegistrationInformation("api/Registration/createPasswordChangeTicket", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,
            email: "",       
            language: (await UserProfile.GetClientSettingsAsync()).language
        }))

        LocalVerificationStore.SaveVerificationData({
            userId: Identity.getIdentity().getValue().UserId,
            email: "",
            language: (await UserProfile.GetClientSettingsAsync()).language,       
        })


        return result
    }


    public async ChangePasswordForAuthenticatedUser(password: string)  : Promise<boolean> {
        return await this.SendRegistrationInformation("api/Registration/changePassword", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,            
            password: password
        }))
    }


    public async ChangePassword(code: string,password: string)  : Promise<boolean> {
        return await this.SendRegistrationInformation("api/anonymousRegistration/changePasswordByEmail", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,
            code: code,
            password: password
        }))
    }

    public async ChangePasswordByProfile(password: string , email: string)  : Promise<any> {
        return await this.SendRegistrationInformation("api/registration/changePassword", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,            
            password: password,
            email: email
        }))
    }

    public async ChangeEmail(code: string, email: string, password: string)  : Promise<boolean> {
        return await this.SendRegistrationInformation("api/anonymousRegistration/changeEmailByPassword", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,
            code: code,
            email: email,
            password: password
        }))
    }


    

    public async CreateUserPasswordEmailTicket(email: string) : Promise<boolean> {
        const result = await this.SendRegistrationInformation("api/anonymousRegistration/createPasswordByEmailTicket", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,
            email: email,                        
            language: (await UserProfile.GetClientSettingsAsync()).language
        }))  
        
        LocalVerificationStore.SaveVerificationData({
            userId: Identity.getIdentity().getValue().UserId,
            email: email,
            language: (await UserProfile.GetClientSettingsAsync()).language,       
        })

        return result
    }

    public async ChangePasswordEmail(code: string, email: string, password: string)  : Promise<boolean> {
        const result = await this.SendRegistrationInformation("api/anonymousRegistration/changePasswordByEmail", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,
            email: email,
            code: code,
            password: password
        }))
        return result;
    }

    public async AccountMigration(email: string,password: string ,vorname: string,nachname: string,geburtsDatum: string, benutzerName: string ): Promise<ResponseDataSet> {
        const response = await this.SendRegistrationInformation("api/anonymousRegistration/accountMigration", JSON.stringify({
            userId: Identity.getIdentity().getValue().UserId,
            email: email,
            password: password,
            firstName: vorname,
            lastName: nachname,
            //birthDate: "1959-10-10T23:00:00.000Z",
            birthDate: geburtsDatum,
            userName: benutzerName
        }))

        LocalVerificationStore.SaveVerificationData({
            userId: Identity.getIdentity().getValue().UserId,
            email: email,
            language: (await UserProfile.GetClientSettingsAsync()).language,
            password: password,
            firstName: vorname,
            lastName: nachname,   
            birthDate: geburtsDatum,
            userName: benutzerName         
        })

        const result: ResponseDataSet = response as unknown as ResponseDataSet
        return result
    }


    private async SendRegistrationInformation(uri: string, jsonRequest: string): Promise<boolean>
    {     
        
        if( Navigate.IsLocked() ) {
            return false
        }

        const client = AsyncRestClient.Create(true);
        client.getValue().waitForResponse = true
        if (client.isFailure) return false;
        const result = await client
            .getValue()
            .exchangeForComponents<string, boolean>(process.env.VUE_APP_API + uri, jsonRequest);  
        if (result.isFailure) return false;
        return result.getValue();
    }
}