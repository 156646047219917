export interface ModalDialogParameter {
  name: string;
  isOk: boolean;
  isCancel: boolean;
  titel: string;
  text: string;
  open: boolean;  
  type: string;
}

export const modalddialogstore = {
  namespaced: true,
  state: {    
    parameter: {
      name: "modal",
      isOk: false,
      isCancel: false,
      titel: "",
      text: "",
      open: false,      
      type: "info"
    },
    open: false,
    clicked: 0
  },
  mutations: {
    // eslint-disable-next-line
    openDialog(state: any, payload: ModalDialogParameter) {
      state.parameter = payload;
    },
    // eslint-disable-next-line
    closeDialog(state: any, paylod: boolean) {
      state.open = paylod;
    },
    // eslint-disable-next-line
    click(state: any, paylod: number) {
      state.clicked = paylod;
    },
    // eslint-disable-next-line
    reset(state: any) {
      state.parameter = { isOk: false, isCancel: false, titel: "", text: "" };
      state.open = false;
      state.clicked = 0;
    }
  },
  actions: {},
  getters: {
    // eslint-disable-next-line
    isOpenDialog: function(state: any): boolean {
      return state.open;
    },
    // eslint-disable-next-line
    isClicked: function(state: any): boolean {
      return state.clicked;
    },
      // eslint-disable-next-line
    getParameter: function(state: any): ModalDialogParameter {
        return state.parameter;
    }
  }
};
