import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12d82c26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col top-placeholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dynamic_form = _resolveComponent("dynamic-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_dynamic_form, _normalizeProps(_guardReactiveProps($setup.request)), null, 16)
    ])
  ]))
}