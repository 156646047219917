import { AsyncCommand } from "../Command";
import { DynamicFormData} from "@/components/Shared/Dynamicform/DynamicFormData";
import { UserRegistration } from '@/components/registration/userRegistration';

export class RuleActivateNewUserForAuthenticatedUser extends AsyncCommand {
  private dynamicForm: DynamicFormData;
  
  constructor(dynamicForm: DynamicFormData) {
    super();
    this.dynamicForm = dynamicForm;
  }

  public async asyncExecute(): Promise<any> {
    const userRegistration: UserRegistration = new UserRegistration()
    const result = await userRegistration.ActivateAuthenticatedUser()
    return result;
  }
}
