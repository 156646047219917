import { ConvertUtcStringToDate, addMonths, getCurrentDayDate, newDate } from "@/infrastructure/functional/datetimehelper";
import { JahresWechsel } from "./RemoteProdukt";
import { Out } from "@/infrastructure/frontent/clientMessage";

export class BusinessLogicJahresWechsel{
    private _jahresWechsel: JahresWechsel
    private static referenzDatumValue: any = false
    private static referenzDatum: string 



    constructor(jahresWechsel: JahresWechsel | undefined) {
        if( ! jahresWechsel) {

            this._jahresWechsel = {
                aktuell: this.convertDateToUtcString(this.getReferenzDatumCurrentYear()),
                referenzDatum: this.convertDateToUtcString(this.getReferenzDatumCurrentYear()),
                wechsel: this.convertDateToUtcString(this.getWechselCurrentYear()),
                loaded: undefined
            }
            return
        }

        this._jahresWechsel = jahresWechsel
    }
    public static setReferenzDatum(referenzDatum: string) : void {
        BusinessLogicJahresWechsel.referenzDatum = referenzDatum
    }

    public static getReferenzDatum() : string {
        return BusinessLogicJahresWechsel.referenzDatum
    }

    public static setReferenzDatumValue(referenzDatumValue: any) : void {
        BusinessLogicJahresWechsel.referenzDatumValue = referenzDatumValue
    }

    public static getReferenzDatumValue() : any {
        return BusinessLogicJahresWechsel.referenzDatumValue
    }

    public getReferenzDatum() : string {
        if( ! this._jahresWechsel ) {
            return this.convertDateToUtcString(this.getReferenzDatumCurrentYear())
        }

        if( ! this._jahresWechsel.referenzDatum) {
            return this.convertDateToUtcString(this.getReferenzDatumCurrentYear())
        }

        try {
            const referenzDatum = ConvertUtcStringToDate( this._jahresWechsel.referenzDatum )
            if( referenzDatum.getTime() > this.getReferenzDatumCurrentYear().getTime()) {
                return this.convertDateToUtcString(referenzDatum)
            }
        } catch (error) {
            Out.noOperation("")
        }
        return this.convertDateToUtcString(this.getReferenzDatumCurrentYear())
    }

    public isNextYear() : boolean {
        if( ! this._jahresWechsel ) {
            return false
        }

        if( ! this._jahresWechsel.referenzDatum) {
            return false
        }

        try {
            const referenzDatum = ConvertUtcStringToDate( this._jahresWechsel.referenzDatum )
            if( referenzDatum.getTime() >= this.getNextYear().getTime()) {
                return true
            }
        } catch (error) {
            Out.noOperation("")
        }
        return false
    }

    public getCurrentYearReferenzDatum() : string {
        return this.convertDateToUtcString(this.getReferenzDatumCurrentYear())
    }

    private convertDateToUtcString(date: Date) : string {
       const year = date.getFullYear()
       const month = date.getMonth() + 1
       const day = date.getDate()

       return `${year.toString().padStart(4,"0")}-${month.toString().padStart(2,"0")}-${day.toString().padStart(2,"0")}T00:00:00.00Z`
    }

    private getReferenzDatumCurrentYear() : Date {
        const dateTime = newDate()
        return new Date(dateTime.getUTCFullYear(), 0, 1)
    }

    private getWechselCurrentYear() : Date {
        const dateTime = newDate()
        return new Date(dateTime.getUTCFullYear(), 11, 1)
    }

    private getNextYear() : Date {
        const dateTime = newDate()
        return addMonths( new Date(dateTime.getUTCFullYear(), 0, 1), 12)
    }

}

