import { Command } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
import { FishingEdit } from "@/components/fishing/business/FishingEdit";

export class RuleLoadFishing extends Command {
  
 
  private dataSet: DynamicDataSet;
  
  constructor(    
    dataSet: DynamicDataSet
  ) {
    super();   
    this.dataSet = dataSet;
  }

  public execute(): any {

    

    const fishingEditState = FishingEdit.getEditFishingState()
    if ( fishingEditState.startDate === undefined)
        return true

        if ( fishingEditState.isStarted ) {
            this.dataSet.view.forEach(row => {
                if (row.formularFeldname === "RuleFischfangEintrag") {
                    row.layoutKlasse = "btn action__button_primary btn-block action__button"
                    row.istEditierbar = true
                    row.istSichtbar = true
                }

                if (row.formularFeldname === "RuleStartFishing") {
                    row.istSichtbar = false
                }
                if (row.formularFeldname === "RuleStopFishing") {
                    row.istSichtbar = true
                }


                if (row.formularFeldname === "Auswahlpatent") {               
                    row.value = fishingEditState.patent?.value
                    row.istSichtbar = true
                }

                if (row.formularFeldname === "Patent") {               
                    row.istSichtbar = false
                }

                if (row.formularFeldname === "Auswahlrevier") {               
                    row.value = fishingEditState.fischereiRevier?.value
                    row.istSichtbar = true
                }

                if (row.formularFeldname === "Fischereirevier") {               
                    row.istSichtbar = false
                }


            })       
        } else {
            this.dataSet.view.forEach(row => {
                if (row.formularFeldname === "RuleFischfangEintrag") {
                    row.layoutKlasse = "btn action__button_primary btn-block action__button"
                    row.istEditierbar = true
                    row.istSichtbar = false
                }

                if (row.formularFeldname === "RuleStartFishing") {
                    row.istSichtbar = true
                }
                if (row.formularFeldname === "RuleStopFishing") {
                    row.istSichtbar = false
                }


                if (row.formularFeldname === "Auswahlpatent") {               
                    row.value = fishingEditState.patent?.value
                    row.istSichtbar = false
                }

                if (row.formularFeldname === "Patent") {               
                    row.istSichtbar = true
                }

                if (row.formularFeldname === "Auswahlrevier") {               
                    row.value = fishingEditState.fischereiRevier?.value
                    row.istSichtbar = false
                }

                if (row.formularFeldname === "Fischereirevier") {               
                    row.istSichtbar = true
                }


            })       
        }
    return true
  }
}

