import { AsyncCommand, Command } from "../Command";
import { DynamicDataSet, DynamicFormData} from "@/components/Shared/Dynamicform/DynamicFormData";
import { StateMachine, StateMachineServiceLocator} from "@/business/application/statemachine/StateMachine";

export class ServerSaveForm extends AsyncCommand {
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  private stateMachine: StateMachine;
  private clientRouteName: string

  constructor(
    dynamicForm: DynamicFormData,
    dataSet: DynamicDataSet,
    clientRouteName: string
  ) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;
    this.stateMachine = StateMachineServiceLocator.get();
    this.clientRouteName = clientRouteName
  }

  public async asyncExecute(): Promise<any> {
    const result = await this.dynamicForm.saveRemoteForm(this.dataSet.apiSaveMethod ?? "", this.dataSet.viewId, ["*"], this.clientRouteName)
    //const currentState = this.stateMachine.getCurrentResultState();
    return result;
  }
}