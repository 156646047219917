
import { computed } from "vue";
export default {
  name: "UserError",
  props: ["message"],
  setup(props: any) {
    const errorMessage = computed(() => {
      return props.message;
    });
    const isError = computed(() => {
      return (props.message ?? "") === "" ? false : true;
    });

    return {
      errorMessage,
      isError,
    };
  },
};
