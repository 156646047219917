import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";


export interface IconBarButtonClicked {
    ButtonClicked: number | undefined
}

//__ Gibt die click events an die Klasse DynamicNavigation zurück (Proxy)
export class NotifyDynamicNavigation extends StateService<IconBarButtonClicked>{
    public static service: NotifyDynamicNavigation;

    public static getService() : NotifyDynamicNavigation {
        if (!NotifyDynamicNavigation.service) {
            NotifyDynamicNavigation.service = new NotifyDynamicNavigation();
            this.service = NotifyDynamicNavigation.service
        }
        return NotifyDynamicNavigation.service;
    }

    private constructor() {
        super({ButtonClicked: undefined});       
    }
    
    public subscription(): Observable<IconBarButtonClicked> {
        return this.subscribe();
    }

    public buttonClicked(button: IconBarButtonClicked) : void {               
        this.changeState(button);
    }

    public reset() : void {
        this.changeState({ButtonClicked: undefined});
    }

    public get navigationButtonClicked(): IconBarButtonClicked {
        return this.state;
    }
}