
import { reactive} from "vue";
import DynamicFilterForm from "@/components/Shared/Dynamicform/DynamicFilterForm.vue";

export default {
  name: "FishBookFilter",
  inheritAttrs: false,
  components: {
    DynamicFilterForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "api/Form/loadForm",
      apiSaveMethod: "",
      form: "Fischbuch",
      page: "FischbuchFilter",
      remoteId: "",
      mode: "DynamicForm",
      routeName: "FishBook",
      filter: "FishBookFilter"
    });
    return {
      request
    };
  }
};
