import { GeoState, GeoView } from "./ChangeGeoMap"

export class GeoStoreData {
    private static _isEditable = true
    private static _geoViewActionService =  GeoView.getService()
    private static _geoStateLoaded: GeoState | undefined = undefined 
    
    public static Save( geoState: GeoState ) : void {
        this._geoStateLoaded = geoState
    }

    public static enableEditMode() {
        GeoStoreData._isEditable = true
    }

    public static disableEditMode() {
        GeoStoreData._isEditable = false
    }

    public static getEditMode() : boolean {
        return GeoStoreData._isEditable 
    }


    public static NotifyGeoView() {
        if ( !GeoStoreData._geoStateLoaded ) return
        GeoStoreData._geoViewActionService.changeGeoState(GeoStoreData._geoStateLoaded)
    }

    public static Reset() {
        GeoStoreData._geoStateLoaded = undefined
    }
}