
import { ref } from "vue";
import QrcodeVue from "qrcode.vue";
//height: auto;
export default {
  name: "ShowQrCode",
  props: ["QRCode"],
  inheritAttrs: false,
  components: {
    QrcodeVue,
  },
  setup(props: any) {
    const QrCode = ref(props.QRCode);
    const size = ref(200);
    return {
      QrCode,
      size,
    };
  },
};
