import {
  StateRule,
  StateTransitionOptions,
  StateResult,

  StateNavigationAction,
  Transition,
  // eslint-disable-next-line
  addNavigationElement,
  // eslint-disable-next-line
  addEmptyNavigationElement,
  addPreviousNext,
  // eslint-disable-next-line
  addPrevious
} from "../StateInterfaces";
import { NavigatonIcons } from "@/business/application/NavConst";
import {Commands} from "@/business/application/commands/Command";
import { BaseState } from "../StateMachine";
import { StateHunting } from "./StateHunting";
import { StateFishing } from "./StateFishing";
import { StateWebShop } from "./StateWebShop";
import { AppState } from "@/AppState";
import { Resource } from "@/infrastructure/resource/resource";

export class StateProduct extends BaseState { 
  private startState = "Product"
  private static lastCondition = "Hunting"
  private static mainState = "Product";
  private static transitions: Transition[] = [];

  private static callingState : StateResult = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''}
  private isDirectionNext = false

  // Diesen Status setzen wenn es auf den Main-Status zurück geht. 
  private isDirectionPreviousParent = true


  public constructor() {
    super()    
  }
  

  public next(rule: StateRule, options: StateTransitionOptions): StateResult {
    return this.calculateState(rule, options, false, StateProduct.mainState);
  }
  public previous(
    rule: StateRule,
    options: StateTransitionOptions
  ): StateResult {
    return this.calculateState(rule, options, true, StateProduct.mainState);
  }

  private getCallingState(condition: string) : StateResult {
    if (this.isDirectionPreviousParent) { 
      // Condition = "Login"
      StateProduct.callingState.newMainState = condition
      StateProduct.callingState.newState = condition
      StateProduct.callingState.routeName = condition
      StateProduct.callingState.oldMainState = StateProduct.mainState
      StateProduct.callingState.oldState = this.startState

      StateProduct.callingState.navigation = this.getTransitions()[2].navigation

      return StateProduct.callingState;
    }
     StateProduct.callingState = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''};
     return StateProduct.callingState;
  }

  public getStartTransition(condition: string) : StateResult {
    StateProduct.transitions = []
    this.getTransitions()
    
    if ( condition == "Products") {

      if ( StateProduct.lastCondition === "Hunting" || StateProduct.lastCondition === "Fishing") {

        console.log(AppState.getAppStateSync().WebShopStart)

        if( AppState.getAppStateSync().WebShopStart === "Fishing" ) {
          condition = "Fishing"
        }

        if( AppState.getAppStateSync().WebShopStart === "Hunting" ) {
          condition = "Hunting"
        }

        if( AppState.getAppStateSync().WebShopStart === "Shop" ) {
          condition = "Shop"
        }

      } else {
        condition = StateProduct.lastCondition
      }

     
      
    }
    if ( condition == "Hunting" ) {
      StateProduct.callingState = this.getCallingState(condition);
      StateProduct.lastCondition = condition
      const t = this.getTransitions()[0];
      return this.getTransition(t);
    }
    
    if ( condition == "Fishing") {
      StateProduct.callingState = this.getCallingState(condition);
      StateProduct.lastCondition = condition
    }
    const t = this.getTransitions()[1];
    return this.getTransition(t);

    

  }


  protected calculateState(rule: StateRule,options: StateTransitionOptions,isPrevious: boolean, _mainState: string): StateResult {
    this.isDirectionNext = ! isPrevious
    if ( isPrevious && this.isDirectionPreviousParent) {
      return this.checkResultState(StateProduct.callingState);      
    }

    const context = this.getContext()
    let condition = this.calculateBusinessConditions(rule,this.getContext().getCurrentState());

    if ( condition === undefined || condition === null || condition.length === 0 || condition === "") {
      condition = "WebShopDaten"
    }

    const transition = this.getTransitions().filter(row => {
      if ( row.condition === condition && row.nav === StateNavigationAction.Previous && isPrevious) {
        return true;
      }
      if ( row.condition === condition && row.nav !== StateNavigationAction.Previous && !isPrevious) {
        return true;
      }
    });
    return this.checkState(transition[0], options);
  }

  protected checkState( transition: Transition, options: StateTransitionOptions): StateResult {
    const newResultState = this.getTransition(transition);
    
    if (!options.lookahead && transition.newState != "") {
      return this.changeResultState(transition.newMainState, newResultState);
    }
    return newResultState;
  }

  private checkResultState( stateResult: StateResult ) : StateResult {  
    const newResultState = StateProduct.callingState;    
    return this.changeResultState(stateResult.newMainState, newResultState);      
  }

  private changeResultState(newMainStatePar: string , compResultState: StateResult) : StateResult {
    let newState: any;
    switch (newMainStatePar) {
      case "Hunting":
        newState = new StateHunting();
        compResultState = newState.getStartTransition("Hunting")
        this.getContext().transitionTo(newState,compResultState);
        break;        
      case "Fishing":
        newState = new StateFishing();
        compResultState = newState.getStartTransition("Fishing")
        this.getContext().transitionTo(newState,compResultState);
        break;        
      case "WebShop":
        newState = new StateWebShop();
        compResultState = newState.getStartTransition("Products")
        this.getContext().transitionTo(newState,compResultState);
        break;                
        default:
        this.getContext().transitionTo(new StateProduct(), compResultState);
    }
    return compResultState;
  }


  protected calculateBusinessConditions(rule: StateRule, _resultState: StateResult): string {
    if ( rule.conditions === undefined ||rule.conditions === null || rule.conditions.length <= 0) {
      return "";
    }

    if (rule.conditions.length == 1) {
      return rule.conditions[0].value;
    }
    return "";
  }

  public getTransitions(): Transition[] {
    if (StateProduct.transitions.length === 0) {

      //*****************************************
      //--> Start Request from other Main-State
      //*****************************************
      // let t: Transition = { mainState: "Hunting", newMainState: "Product", nav: StateNavigationAction.Previous, oldState: "Hunting", newState: "Products", condition: "Hunting"};      
      // t = addPrevious(t, Commands.PreviousPage);
      // StateProduct.transitions.push(t);

      let t: Transition = { mainState: "Hunting", newMainState: "Product", nav: StateNavigationAction.Next, oldState: "Hunting", newState:  "Products", condition: "Hunting"};      
      t = addPrevious( t, Commands.PreviousPage )
      t = addNavigationElement(t,1,{command: Commands.PreviousPage, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Zurueck",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Previous });
      t = addNavigationElement(t,2,{command: Commands.WebShop, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Shop",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.WebShop });
      t = addEmptyNavigationElement(t,3);
      t = addEmptyNavigationElement(t,4);
      StateProduct.transitions.push(t);


      // t = { mainState: "Fishing", newMainState: "Product", nav: StateNavigationAction.Previous, oldState: "Fishing", newState: "Products", condition: "Fishing"};      
      // t = addPrevious(t, Commands.PreviousPage);
      // StateProduct.transitions.push(t);


      t = { mainState: "Fishing", newMainState: "Product", nav: StateNavigationAction.Next, oldState: "Fishing", newState:  "Products", condition: "Fishing"};      
      t = addPrevious( t, Commands.PreviousPage )
      t = addNavigationElement(t,1,{command: Commands.PreviousPage, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Zurueck",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Previous });
      t = addNavigationElement(t,2,{command: Commands.WebShop, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Shop",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.WebShop });
      t = addEmptyNavigationElement(t,3);
      t = addEmptyNavigationElement(t,4);
      StateProduct.transitions.push(t);


      
      t = { mainState: "Product", newMainState: "WebShop", nav: StateNavigationAction.Next, oldState: "Product", newState: "WebShop", condition: "WebShopDaten"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateProduct.transitions.push(t);

      t = { mainState: "Product", newMainState: "Product", nav: StateNavigationAction.Next, oldState: "Product", newState:  "Products", condition: "Detail"};      
      t = addPrevious( t, Commands.PreviousPage )
      t = addNavigationElement(t,1,{command: Commands.PreviousPage, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Zurueck",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Previous });
      t = addEmptyNavigationElement(t,2);
      t = addEmptyNavigationElement(t,3);
      t = addEmptyNavigationElement(t,4);
      StateProduct.transitions.push(t);


      //*****************************************
      //--> Start Request from other Main-State
      //*****************************************    
      t = { mainState: "Product", newMainState: StateProduct.callingState.newMainState, nav: StateNavigationAction.Previous, oldState: "Products", newState: StateProduct.callingState.newState, condition: StateProduct.callingState.newMainState};      
      t = addNavigationElement(t,1,{command: Commands.PreviousPage, displayName: "Home", enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Home });
      t = addNavigationElement(t,2,{command: Commands.Products, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Produkte",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Products });
      t = addNavigationElement(t,3,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Wildbuch",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Wildbuch });
      t = addNavigationElement(t,4,{command: Commands.Settings, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      StateProduct.transitions.push(t);
    }

    return StateProduct.transitions;
  }
}
