/* eslint-disable no-console */
import { register } from "register-service-worker";
//import { UpdateAppVersion } from "./infrastructure/serviceworker-related/UpdateAppVersion";

const versionNumber = "001"
let _register: any = {}

export function serviceWorkerUpdate() {
  try {
    _register.update();
  }
  catch (e) {
    //LightLog.add("Update-App-Version", "Manual Trigger", LogTyp.Fehler)
    console.log("Service worker has been registered.");
  } 
}

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: { scope: '/', type: 'module' },
    ready() {    
      console.log(`${process.env.BASE_URL}service-worker.js`)
      //LightLog.add("Update-App", "App from Cache", LogTyp.Info)     
      console.log("Service worker has been ready.");   
    },
    registered(register) {   
      console.log("Service worker has been registered.");
      _register = register
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {    
      //LightLog.add("Update-App", "New Content Refresh", LogTyp.Info)
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration }))
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  })
}

