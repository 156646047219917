import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { LocalWarenkorb, ShoppingCardLocal } from "./LocalWarenkorb";
import { correctFormValues } from "@/infrastructure/functional/formHelper";
import { Navigate } from "@/infrastructure/backend/async/Navigate";
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
import { Resource } from "@/infrastructure/resource/resource";

export type SaveProductResult = {
    id: string
    state: string
    stateMessage: string
    stateMessageResourceId: string
    processState: string
    processStateMessage: string
    processStateMessageResourceId: string
    isError: boolean
    produktRueckMeldungen: DtoGeRueckmeldung[]
}

export type DtoGeRueckmeldung = {
    transientProduktId: string
    success: boolean 
    errorMessage: string
    bestellungId: string
    errorSystemRegelResourceId: string
    errorSystemRegelWebShopResourceId: string
    errorProduktRegelResourceId: string
    errorProduktWebShopResourceId: string
    produkt: DtoWebShopProdukt
    isTimeOut: boolean
}

export type DtoWebShopProdukt =  {
    bestellungZuRechnungId: string
    verkaufsPreis: string

}


export class RemoteWarenKorb {
     public static async  createWebShopProducts() : Promise<SaveProductResult | string> {
        const request: ShoppingCardLocal | undefined = await LocalWarenkorb.GetShoppingCard()

        if ( request && request.shoppingCardLineItems) {
            for(const row of request.shoppingCardLineItems) {
                if(row.form) {
                   row.form = correctFormValues(row.form)
                }
            }
        }

        if( Navigate.IsLocked() ) {
            return ""
        }
 

        const client = AsyncRestClient.Create(true);
        if (client.isFailure) throw new Error("Error.createWebShopProducts");
        const result = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/webShop/createWebShopProducts", JSON.stringify({ "ShoppingCard" : request}));  
    
        if (result.isFailure) throw new Error(`${result.error}`)      
        
        if ((result as any).offline) {
            const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
            notficationState.resetNotificationState()
    
            notficationState.changeNotificationState({
            isNotification: true, text:  Resource.getResourceTextSyncMode("Offline.NotAllowed.Content", ""), title: Resource.getResourceTextSyncMode("Offline.NotAllowed.Title", "")})
            return ""
           }
    

        const temp = result.getValue() as unknown as any
        if (temp.produktRueckMeldungen && temp.produktRueckMeldungen.length > 0) {
            const rueckMeldung = temp as SaveProductResult

            await LocalWarenkorb.RefreshShoppingCardItems(rueckMeldung)

            return rueckMeldung
        }

        if (temp) {
            return temp.stateMessage ?? temp.processStateMessage
        }

        return "Es ist kein Warenkorb vorhanden"
       
        
    }


    public static async  istWebshopZahlungAusgefuehrt() : Promise<string> {
        const request: ShoppingCardLocal | undefined = await LocalWarenkorb.GetShoppingCard()

        const client = AsyncRestClient.Create(true);
        if (client.isFailure) throw new Error("Error.proofWebShopProducts");
        const result = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/webShop/istWebshopZahlungAusgefuehrt", JSON.stringify({ 
                "paymentOrderId" : request?.id,
                "userId" : request?.customer.userId,
            }));  
    
        if (result.isFailure) return "ERROR";
        
        return (result.getValue() as any).paymentStatus
    }



    public static async  proofWebShopProducts() : Promise<SaveProductResult | string> {
        const request: ShoppingCardLocal | undefined = await LocalWarenkorb.GetShoppingCard()

        if ( request && request.shoppingCardLineItems) {
            for(const row of request.shoppingCardLineItems) {
                if(row.form) {
                   row.form = correctFormValues(row.form)
                }
            }
        }
        if( Navigate.IsLocked() ) {
            return ""
        }
 
        const client = AsyncRestClient.Create(true);
        if (client.isFailure) throw new Error("Error.proofWebShopProducts");
        const result = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/webShop/proofWebShopProducts", JSON.stringify({ "ShoppingCard" : request}));  
    
        if (result.isFailure) throw new Error(`${result.error}`)   
        
        if ((result as any).offline) {
            const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
            notficationState.resetNotificationState()
    
            notficationState.changeNotificationState({
            isNotification: true, text:  Resource.getResourceTextSyncMode("Offline.NotAllowed.Content", ""), title: Resource.getResourceTextSyncMode("Offline.NotAllowed.Title", "")})
            return ""
           }
    


        const temp = result.getValue() as unknown as any
        if (temp.produktRueckMeldungen && temp.produktRueckMeldungen.length > 0) {
            const rueckMeldung = temp as SaveProductResult

            await LocalWarenkorb.RefreshShoppingCardItems(rueckMeldung)

            return rueckMeldung
        }

        if (temp) {
            return temp.stateMessage ?? temp.processStateMessage
        }

        return "Es ist kein Warenkorb vorhanden"
       
        
    }



    public static async cleanUpWebShopProducts() : Promise<SaveProductResult | string> {
        const request: ShoppingCardLocal | undefined = await LocalWarenkorb.GetShoppingCard()

        if ( request && request.shoppingCardLineItems) {
            for(const row of request.shoppingCardLineItems) {
                if(row.form) {
                   row.form = correctFormValues(row.form)
                }
            }
        }
        if( Navigate.IsLocked() ) {
            return ""
        }
 
        const client = AsyncRestClient.Create(true);
        if (client.isFailure) throw new Error("Error.cleanUpWebShopProducts");
        const result = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/webShop/cleanUpWebShopProducts", JSON.stringify({ "ShoppingCard" : request}));  
    
        if (result.isFailure) throw new Error(`${result.error}`)            

        const temp = result.getValue() as unknown as any
        if (temp.produktRueckMeldungen && temp.produktRueckMeldungen.length > 0) {
            const rueckMeldung = temp as SaveProductResult
            return rueckMeldung
        }

        if (temp) {
            return temp.stateMessage ?? temp.processStateMessage
        }

        const offline: SaveProductResult = {
            id: "",
            state: "Ok",
            stateMessage: "Ok",
            stateMessageResourceId: "",
            processState: "Ok",
            processStateMessage: "Ok",
            processStateMessageResourceId: "",
            isError: false,
            produktRueckMeldungen: []
        }

        return offline
    }


    public static getProofMessage(productResult: SaveProductResult | string) {
        if (typeof(productResult) === "string") return productResult
        for(const row of productResult.produktRueckMeldungen) {
            if(!row.success) {
                const message = (row.errorMessage ?? "").replaceAll("\r\n", "<br />")
                return message
            }
        }
        return ""
    }

    public static isValid(productResult: SaveProductResult | string) : boolean {
        if (typeof(productResult) === "string") return true

        for(const row of productResult.produktRueckMeldungen) {
            if(!row.success) return false
        }

        return true
    }


    
}


