
  import { reactive } from "vue";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  
  export default {
    name: "KnownRegistration",
    inheritAttrs: false,
    components: {
      DynamicForm
    },
    setup() {    
      const request = reactive({
        apiLoadMethod: "api/anonymousForm/loadForm",
        apiSaveMethod: "api/anonymousForm/saveForm",
        form: "LoginRegistration",
        page: "LoginRegistration",
        remoteId: "",
        waitForResponse: true
        
      });
  
      return {
        request
      };
    }
  };
  