
import { reactive} from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";

export default {
  name: "WildBookObserveCoordinates",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "api/Form/loadForm",
      apiSaveMethod: "",
      form: "WildbuchEintragBeobachtung",
      page: "WildbuchEintragLokalisierungBeobachtung",
      remoteId: "",
      waitForResponse: true,
      geoEditMode: "New",
      geoGpsMode: "OnStartup",
      geoDisplayClass: "additional",
      geoCheckInsidePolygon: true    
      

    });
    return {
      request
    };
  }
};
