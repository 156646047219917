import { StringToStateConstants } from '@/business/application/statemachine/constants/StateConstants';
import { ErrorLog } from './../../../business/error/ErrorLog';
import { Result } from "@/infrastructure/functional/result";
import { CreateUserError, ErrorStateServiceForRoot } from "@/infrastructure/observables/errorState";
import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
import { RestClientHeader } from "../RestClientHeader";
import { Out } from '@/infrastructure/frontent/clientMessage';
import { Resource } from '@/infrastructure/resource/resource';
import { objectToString } from '@vue/shared';
import { Navigate } from './Navigate';
import { OnOfflineListener } from '@/hooks/OnOfllineState';
import { UserProfile } from '../userProfile';
import { HandleErrorMessages } from '@/infrastructure/backend/async/HandleErrorMessages';

export class AsyncRestClient {
  private static _withCookies = false;
  private static _errorResorceId? = ""
  public waitForResponse = false

  public static Create(withCookies: boolean, errorResopurceId?: string): Result<AsyncRestClient> {
    this._withCookies = withCookies
    this._errorResorceId = errorResopurceId
    return Result.ok<AsyncRestClient>(new AsyncRestClient());
  }

  private getHeader(withCookies: boolean) : Result<Map<string,string>> {
    const header: RestClientHeader = new RestClientHeader();
    return header.getHeader(withCookies);
  }


  public async exchangeForComponents<TRequest, TResponse>(
    endpoint: string,
    payload: TRequest): Promise<Result<TResponse>> {

    const errorState = ErrorStateServiceForRoot.getErrorStateService()
    errorState.resetErrorState();

    const loaderState = LoaderStateServiceForRoot.getLoaderStateService()

    if ( this.waitForResponse) loaderState.changeLoaderState({isInProgress: true})
    
    const result = await this.exchange<TRequest,TResponse>(endpoint,payload);
    if ( this.waitForResponse) loaderState.changeLoaderState({isInProgress: false}) 
    
    const temp = await this.ExtractEnvelopeWhenPresentexchange(endpoint, errorState,payload, result)
    if ( temp !== undefined)
        return temp as unknown as Result<TResponse>

    if (result.isFailure) {
      loaderState.changeLoaderState({isInProgress: false})

      // if ( result.error && result.error === "login") {
      //   const errorResoure = await Resource.getResourceText("Error.Login")
      //   errorState.changeErrorState(CreateUserError("Error.Login.Title" , "Error.Login.Content", errorResoure))   
      //   return result 
      // }

      const errorMessageResult = await HandleErrorMessages.PresentError(endpoint , "")

      // if (AsyncRestClient._errorResorceId) {
      //   const errorResoure = await Resource.getResourceText(AsyncRestClient._errorResorceId)
      //   try {
      //     result.error = errorResoure
      //   } catch(e) {
      //     Out.error("");
      //   }
      //   errorState.changeErrorState(CreateUserError("Error.Restclient.Request.Title" , "Error.Restclient.Request.Content", errorResoure))    
      // } else {
      //   errorState.changeErrorState(CreateUserError("Error.Restclient.Request.Title" , "Error.Restclient.Request.Content", result.error))    
      // }
    } 
    
    return result;
  }

  private async ExtractEnvelopeWhenPresentexchange<TRequest, TResponse>(endpoint :string ,errorState: ErrorStateServiceForRoot, payload: TRequest, response: Result<TResponse> | Result<any>) : Promise<Result<TResponse> | undefined> {
    try {
      if ( ! response ) return response
      if ( response instanceof Object) {
        let result: any 
        try {  
          result = response.getValue() 
        } catch(e) {
          return undefined
        }
       
        if ( ! result) return undefined;
        if ( result.functionalSuccess === undefined) return undefined;
        if ( result.resourceId) {
          const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
          loaderState.changeLoaderState({isInProgress: false})
          //errorState.changeErrorState(CreateUserError(`${result.resourceId}.Title`  , `${result.resourceId}.Content`, ""))  
          
         
          
          const errorMessageResult = await HandleErrorMessages.PresentError(endpoint , result.resourceId)
        }

        if ( !result.functionalSuccess && result.result === null ) {
          result.result = false
          return Result.ok( result.result )
        }

                
        if ( ! result.result) return undefined
        return Result.ok( result.result )
      }

      return undefined
    } catch(e) {      
      return undefined
    }
  }


  public async downLoadFile<TRequest>( endpoint: string, payload: TRequest, fileName: string ): Promise<void> {
    try {
      const headerResult = this.getHeader(AsyncRestClient._withCookies);
      if (headerResult.isFailure) return 

      const response = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        mode: "cors",
        headers: Object.fromEntries(headerResult.getValue()),
        body: JSON.parse( JSON.stringify(payload)),
      });

      if ( ! response) {
        return
      }
      
      const blob = await response.blob()
      if ( ! blob) {
        return
      }

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
      const newBlob = new Blob([blob], { type: "application/pdf" });

      // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore        
        window.navigator.msSaveOrOpenBlob(newBlob);
      } else {
        // For other browsers: create a link pointing to the ObjectURL containing the blob.
        const objUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement("a");
        link.onclick= function(event) {event.stopPropagation()}
        link.href = objUrl;
        link.target = "_blank"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
       
        link.download = fileName;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
          window.URL.revokeObjectURL(objUrl);
        }, 250);
      }
    } catch(e) {
      Out.noOperation("")
    }
  }

  public async exchange<TRequest, TResponse>(
    endpoint: string,
    payload: TRequest
  ): Promise<Result<TResponse>> {
    try {
      const headerResult = this.getHeader(AsyncRestClient._withCookies);
      if (headerResult.isFailure) return Result.fail<TResponse>(headerResult.error);

      //Navigate.CheckForSynchronisation("Request" , endpoint, payload)
      
      const response = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        mode: "cors",
        headers: Object.fromEntries(headerResult.getValue()),
        body: JSON.parse( JSON.stringify(payload)),
      });

      //Navigate.CheckForSynchronisation("Response" , endpoint, "")

      if (!response.ok) {  
        const isNavigation = await Navigate.AuthenticationExpired(response)
        if ( isNavigation ) {
          return Result.fail<TResponse>("login")
        }
        const reason = "";
        try {
          return Result.fail<TResponse>(`<br /> <br /> <small>Url: ${endpoint}  ${reason.length > 5 ? ( "Reason: " + reason ) : ""}<br /></small>`);        
        }
        catch(e) {
          Out.error(e);
        }
      }

      const result: TResponse = await response.json();

      if (result && response.status && response.statusText === "ok" ) {
        try {
          if ((result as any).offline !== undefined) {

            //console.error("Offline Check :" + endpoint)

            if ( (result as any).offline ) {
              const userProfile: UserProfile = new UserProfile()
              await userProfile.ChangeOnline(false)
              const onOfflineListener =  OnOfflineListener.getService()
              onOfflineListener.onlineChanged({IsOnline: false}) 
            } 
          } 
        } catch(e) {
          Out.error(e)
        }
      } else {
        if (endpoint && endpoint.length > 0 && 
          ( endpoint.indexOf("getListsByTypes") >= 0 
          || endpoint.indexOf("start") >= 0 
          || endpoint.indexOf("loadResourceSet") >= 0 
          || endpoint.indexOf("geoRevier") >= 0 
          )) {
          const userProfile: UserProfile = new UserProfile()
          await userProfile.ChangeOnline(true)
          const onOfflineListener =  OnOfflineListener.getService()
          onOfflineListener.onlineChanged({IsOnline: true}) 
        }
      }

      return Result.ok<TResponse>(result);

    } catch (e) {
      return Result.fail<TResponse>(`<br /> <br /> <small>Url: ${endpoint}<br /></small> Message: ${e}`);
    }
  }
}
