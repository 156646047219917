import { RoutedButtonBarResponseChannelForRoot } from "@/components/Shared/routed-button-bar/RoutedButtonResponseChannel";
import { AsyncCommand } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
import { BusinessLogicJahresWechsel } from "@/components/webshop/BusinessLogicJahresWechsel";

export class RuleDynamicFilter extends AsyncCommand {
  
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  
  constructor(
    dynamicForm: DynamicFormData,
    dataSet: DynamicDataSet
  ) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;

    if (dataSet.viewId  && dataSet.viewId === "WebShop" ) {
      if( dataSet.view) {
        for( const row of dataSet.view) {
          if (row.formularFeldname === "ReferenzDatumFilter") {
            BusinessLogicJahresWechsel.setReferenzDatumValue(row.value)
          }
        }
      }
    }

  }

  public async asyncExecute(): Promise<any> {
    const routeResponseChannel =  RoutedButtonBarResponseChannelForRoot.getService()

    if ( this.dynamicForm.clientRouteName && this.dynamicForm.clientRouteName === "WildBookFilter") {
      routeResponseChannel.changeRouteState( { targetRoute: "WildBook" , command: "Save" , topic: "WildBookFilter" })
      routeResponseChannel.changeRouteState( { targetRoute: "WildBook" , command: "Undefined" , topic: "WildBookFilter" })
  
    } else {

      if ( this.dynamicForm.clientRouteName && this.dynamicForm.clientRouteName === "WebShopFilter") {
        routeResponseChannel.changeRouteState( { targetRoute: "WebShop" , command: "Save" , topic: "WebShopFilter" })
        routeResponseChannel.changeRouteState( { targetRoute: "WebShop" , command: "Undefined" , topic: "WebShopFilter" })
      }

      else {
        routeResponseChannel.changeRouteState( { targetRoute: "FishBook" , command: "Save" , topic: "FishBookFilter" })
        routeResponseChannel.changeRouteState( { targetRoute: "FishBook" , command: "Undefined" , topic: "FishBookFilter" })  
      }
    }


   
    return true
  }
}

