import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1de890e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid g-0" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col m-12" }
const _hoisted_4 = { class: "row g-0 efj__headline--small" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "web-shop-state" }
const _hoisted_7 = { class: "row line-space" }
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "row line-space" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_popup = _resolveComponent("loader-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.loaderParameter.isInProgress)
      ? (_openBlock(), _createBlock(_component_loader_popup, _normalizeProps(_mergeProps({ key: 0 }, $setup.loaderParameter)), null, 16))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("p", null, _toDisplayString($setup.request.title), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString($setup.request.anbieter), 1),
        _createElementVNode("div", _hoisted_9, _toDisplayString($setup.cardResult.shoppingCard.brand ?? "-"), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString($setup.request.betrag), 1),
        _createElementVNode("div", _hoisted_12, _toDisplayString($setup.cardResult.shoppingCard.amountIncludeTax + " CHF"), 1)
      ])
    ])
  ]))
}