//UserExistence.PrivacyPolicy
//UserExistence.RefreshUserData
//UserExistence.RegistrationLogin

//UserExistence.Valid
//UserExistence.KnownButHasRoles
//UserExistence.Unknown
//UserExistence.KnownButMultiple
//UserExistence.KnownButDifferentEmail
//UserExistence.KnownButHasNoEmail
//UserExistence.MissingRequiredValue
//UserExistence.ValidButWrongPassword




import {
  StateRule,
  StateTransitionOptions,
  StateResult,

  StateNavigationAction,
  Transition,
  // eslint-disable-next-line
  addNavigationElement,
  // eslint-disable-next-line
  addEmptyNavigationElement,
  addPreviousNext,
  // eslint-disable-next-line
  addPrevious,
  // eslint-disable-next-line
  addNext,  
  newTransition,
  newTransitionCondition,
  newMainTransition,
  newMainTransitionCondition
} from "../StateInterfaces";

import { BaseState }  from "../StateMachine";
import { StateHome } from "./StateHome";
import { StateLogin } from "./StateLogin";
import { NavigatonIcons } from "@/business/application/NavConst";
import {Commands} from "@/business/application/commands/Command";
import { StatePasswordEmail } from "./StatePasswordEmail";
import { StatePassword } from "./StatePassword";
import { StatePasswordWithCurrentEmail } from "./StatePasswordWithCurrentEmail";
import { StateEmailWithCurrentPassword } from "./StateEmailWithCurrentPassword";
import { StatePrivacyPolicy } from "./StatePrivacyPolicy";
import { StateManualRegistration } from "./StateManualRegistration";
import { ShowValidFailedLoginRegistration } from "./ShowValidFailedLoginRegistration";
import { StateKnownRegistration } from "./StateKnownRegistration";
import { Resource } from "@/infrastructure/resource/resource";

export class StateRegisterUser extends BaseState {
  private static mainState = "RegisterUser";
  private static transitions: Transition[] = [];
  private isBackward = false

  public constructor() {
    super()    
    
  }

  public getStartTransition(condition: string) : StateResult {
    StateRegisterUser.transitions = []
    this.getTransitions()
    const t = this.getTransitions()[0];
    return this.getTransition(t);
  }


  public next(rule: StateRule, options: StateTransitionOptions): StateResult {
    return this.calculateState(
      rule,
      options,
      false,
      StateRegisterUser.mainState
    );
  }

  public previous(
    rule: StateRule,
    options: StateTransitionOptions
  ): StateResult {
    return this.calculateState(
      rule,
      options,
      true,
      StateRegisterUser.mainState
    );
  }

  protected checkState(
    transition: Transition,
    options: StateTransitionOptions
  ): StateResult {
    const context = this.getContext()
    let newResultState = this.getTransition(transition);
    let newState: any
    if (!options.lookahead && transition.newState != "") {
      switch (transition.newMainState) {
        case "Login":
          newState = new StateLogin();
          newResultState = newState.getStartTransition("Login")
          this.getContext().transitionTo(newState,newResultState)                  
          break;
        case "Home":
          newState = new StateHome();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState)                  
          break;
        case "Password":
          newState = new StatePasswordEmail();
          newResultState = newState.getStartTransition("PasswordEmail")
          this.getContext().transitionTo(newState,newResultState)                  
          break;
        case "PasswordWithCurrentEmail":
          newState = new StatePasswordWithCurrentEmail();
          newResultState = newState.getStartTransition("PasswordWithCurrentEmail")
          this.getContext().transitionTo(newState,newResultState)                  
          break;
          case "EmailWithCurrentPassword":
            newState = new StateEmailWithCurrentPassword();
            newResultState = newState.getStartTransition("EmailWithCurrentPassword")
            this.getContext().transitionTo(newState,newResultState)                  
            break;          
        case "PasswordEmail":
            newState = new StatePassword();
            newResultState = newState.getStartTransition("Password")
            this.getContext().transitionTo(newState,newResultState)                  
            break;    
        case "PrivacyPolicy":
            newState = new StatePrivacyPolicy();
            newResultState = newState.getStartTransition("Login")
            this.getContext().transitionTo(newState,newResultState);
            break;       
        case "ManualRegistration":
            newState = new StateManualRegistration();
            newResultState = newState.getStartTransition("Login")
            this.getContext().transitionTo(newState,newResultState)                     
            break;   
        case "KnownRegistration":
              newState = new StateKnownRegistration();
              newResultState = newState.getStartTransition("Login")
              this.getContext().transitionTo(newState,newResultState)                     
              break;               
        case "ShowValidFailedLoginRegistration":
          newState = new ShowValidFailedLoginRegistration();
          newResultState = newState.getStartTransition("Login")
          this.getContext().transitionTo(newState,newResultState)                     
          break;   


        default:
          context.transitionTo(
            new StateRegisterUser(),
            newResultState
          );
      }
    }
    return newResultState;
  }

  protected calculateState(rule: StateRule,options: StateTransitionOptions,isPrevious: boolean, _mainState: string): StateResult {
    this.isBackward = isPrevious
    const context = this.getContext()
    const condition = this.calculateBusinessConditions(rule,context.getCurrentState());

    const transition = this.getTransitions().filter(row => {
 
      if ( row.condition === condition && condition === "UserExistence.ExistsButNoAccess") {
        return true
      }

      if ( row.condition === condition && condition === "UserExistence.Known") {
        return true
      }

      if ( row.condition === condition && condition === "UserExistence.ValidButWrongLoginData") {
        return true
      }

      // if ( row.condition === condition && condition === "UserExistence.Unknown") {
      //   return true
      // }

      if (row.oldState === context.getCurrentState().newState && row.condition === condition && row.nav === StateNavigationAction.Previous && isPrevious) {
        return true;
      }
      if ( row.oldState === context.getCurrentState().newState && row.condition === condition && row.nav !== StateNavigationAction.Previous &&!isPrevious) {
        return true;
      }

      if ( row.oldState === context.getCurrentState().newState && context.getCurrentState().newState === "RegistrationLogin" && row.nav === StateNavigationAction.Previous && isPrevious) {
        return true;
      }

      if ( row.oldState === context.getCurrentState().newState && context.getCurrentState().newState === "RegistrationWaitForConfirm" && row.nav === StateNavigationAction.Previous && isPrevious) {
        return true;
      }
    });
    return this.checkState(transition[0], options);
  }

  protected calculateBusinessConditions(
    rule: StateRule,
    resultState: StateResult
  ): string {
    if ( rule.conditions === undefined || rule.conditions === null || rule.conditions.length <= 0) {
      return "";
    }

    if (rule.conditions.length == 1 ) {
      if ( rule.conditions[0].value === undefined ) {
        return ""
      }
     
      return rule.conditions[0].value;
    }



    if (!this.isBackward) {

      if (resultState.newState === "RegistrationLogin") {            
        return "isPersonal";            
      }


        if (resultState.newState === "RegistrationPersonal") {            
            return "isCommunication";            
        }

        if (
          resultState.newState === "RegistrationCommunication" &&
          rule.conditions[0].nameId.toLowerCase().trim() === "isFishing".toLowerCase()
        ) {
          if (rule.conditions[0].value == "true") {
            return "isFishing";
          }
        }
        if (
          resultState.newState === "RegistrationCommunication" &&
          rule.conditions[1].nameId.toLowerCase().trim() === "isHunting".toLowerCase()
        ) {
          if (rule.conditions[1].value == "true") {
            return "isHunting";
          }
        }
        if (resultState.newState === "RegistrationCommunication") {
            return "isPrivacy";
        }
        if (
          resultState.newState === "RegistrationFishing" &&
          rule.conditions[1].nameId.toLowerCase().trim() === "isHunting".toLowerCase()
        ) {
          if (rule.conditions[1].value == "true") {
            return "isHunting";
          }
        }
        if (resultState.newState === "RegistrationFishing") {
            return "isPrivacy";
        }
        if (resultState.newState === "RegistrationHunting") {            
            return "isPrivacy";            
        }        
      //   if (resultState.newState === "RegistrationPrivacy") {            
      //     return "isWaitForConfirm";            
      // }               
    }

      else {      
          
        if (
          resultState.newState === "RegistrationPrivacy" &&
          rule.conditions[1].nameId.toLowerCase().trim() === "isHunting".toLowerCase()
        ) {
          if (rule.conditions[1].value == "true") {
            return "isHunting";
          }
        }
        if (
          resultState.newState === "RegistrationPrivacy" &&
          rule.conditions[0].nameId.toLowerCase().trim() === "isFishing".toLowerCase()
        ) {
          if (rule.conditions[0].value == "true") {
            return "isFishing";
          }
        }
        if (resultState.newState === "RegistrationPrivacy") {
            return "isCommunication";
        }

        if (
          resultState.newState === "RegistrationHunting" &&
          rule.conditions[0].nameId.toLowerCase().trim() === "isFishing".toLowerCase()
        ) {
          if (rule.conditions[0].value == "true") {
            return "isFishing";
          }
        }

        if (resultState.newState === "RegistrationHunting") {
            return "isCommunication";
        }

        if (resultState.newState === "RegistrationFishing") {
            return "isCommunication";
        }

        if (resultState.newState === "RegistrationCommunication") {
            return "isPersonal";
        }

        if (resultState.newState === "RegistrationPersonal") {
          return "isLogin";
      }


        if (resultState.newState === "RegistrationLogin") {
          return "";
      }
    }

    return "";
  }

  public getTransitions(): Transition[] {
    if (StateRegisterUser.transitions.length === 0) {

      let t: Transition = { mainState: "Login", newMainState: "RegisterUser", nav: StateNavigationAction.Next, oldState: "Login", newState: "RegistrationLogin", condition: ""};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleRegisterLogin);
      StateRegisterUser.transitions.push(t);

      //*****************************************
      //--> RuleRegisterUserLogin
      //*****************************************
      t = newTransitionCondition("RegisterUser","RegistrationLogin", "RegistrationPersonal", "UserExistence.Unknown" ,  StateNavigationAction.Decision)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newMainTransitionCondition("RegisterUser","RegistrationLogin", "Home", "Home", "UserExistence.Valid" ,  StateNavigationAction.Decision)
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateRegisterUser.transitions.push(t);


      t = newMainTransitionCondition("RegisterUser","RegistrationLogin", "EmailWithCurrentPassword", "EmailWithCurrentPasswordData", "UserExistence.KnownButHasNoEmail" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationLogin", "RegistrationPersonal", "UserExistence.KnownButHasRoles" , StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationLogin", "RegistrationPersonal", "UserExistence.KnownButMultiple" , StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newMainTransitionCondition("RegisterUser","RegistrationLogin", "PasswordWithCurrentEmail", "PasswordWithCurrentEmail", "UserExistence.ValidButWrongPassword" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newMainTransitionCondition("RegisterUser","RegistrationLogin", "EmailWithCurrentPassword", "EmailWithCurrentPasswordData", "UserExistence.KnownButDifferentEmail" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateRegisterUser.transitions.push(t);      

      t = newMainTransitionCondition("RegisterUser","RegistrationLogin", "PrivacyPolicy", "PrivacyPolicy", "UserExistence.PrivacyPolicy" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateRegisterUser.transitions.push(t);      

      t = newMainTransitionCondition("RegisterUser","RegistrationLogin", "ManualRegistration", "ShowInformation", "UserExistence.ExistsButNoAccess" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateRegisterUser.transitions.push(t);      

      t = newMainTransitionCondition("RegisterUser","RegistrationLogin", "KnownRegistration", "ShowKnownInformation", "UserExistence.Known" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateRegisterUser.transitions.push(t);      


      t = newMainTransitionCondition("RegisterUser","RegistrationLogin", "ShowValidFailedLoginRegistration", "ShowValidFailedLoginInformation", "UserExistence.ValidButWrongLoginData" ,  StateNavigationAction.Decision)
      t = addNext(t, Commands.NextPage);
      StateRegisterUser.transitions.push(t);      


      //*****************************************
      //--> Wizzard vorward
      //*****************************************
      t = newTransitionCondition("RegisterUser","RegistrationLogin", "RegistrationPersonal" , "isPersonal")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);      
      t = newTransitionCondition("RegisterUser","RegistrationPersonal", "RegistrationCommunication" , "isCommunication")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationCommunication", "RegistrationFishing", "isFishing")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationFishing", "RegistrationHunting", "isHunting")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationCommunication", "RegistrationHunting", "isHunting")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationCommunication", "RegistrationPrivacy", "isPrivacy")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleSendEmailActivateNewUser);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationHunting", "RegistrationPrivacy", "isPrivacy")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleSendEmailActivateNewUser);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationFishing", "RegistrationPrivacy", "isPrivacy")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleSendEmailActivateNewUser);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationCommunication", "RegistrationPrivacy" , "isPrivacy")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleSendEmailActivateNewUser);
      StateRegisterUser.transitions.push(t);
      
      t = {mainState: "RegisterUser",newMainState: "RegisterUser", oldState: "RegistrationPrivacy", newState: "RegistrationWaitForConfirm",condition: "", nav: StateNavigationAction.Jump}
      t = addPreviousNext(t,Commands.PreviousPage, Commands.RuleActivateNewUser);
      StateRegisterUser.transitions.push(t);

      t = {mainState: "RegisterUser",newMainState: "RegisterUser", oldState: "RegistrationPrivacy", newState: "RegistrationWaitForConfirm",condition: "RuleSendEmailActivateNewUser", nav: StateNavigationAction.Jump}
      t = addPreviousNext(t,Commands.PreviousPage, Commands.RuleActivateNewUser);
      StateRegisterUser.transitions.push(t);

      t = {mainState: "RegisterUser",newMainState: "Home", oldState: "RegistrationWaitForConfirm", newState: "Home",condition: "RuleActivateNewUser", nav: StateNavigationAction.Jump}      
      t = addNext(t, Commands.NextPage );
      StateRegisterUser.transitions.push(t);

      //*****************************************
      //--> Wizzard backward
      //*****************************************
      t = newTransitionCondition("RegisterUser","RegistrationWaitForConfirm", "RegistrationPrivacy", "isPrivacy", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationPrivacy", "RegistrationHunting", "isHunting", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationPrivacy", "RegistrationFishing", "isFishing", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationHunting", "RegistrationFishing", "isFishing", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationFishing", "RegistrationCommunication", "isCommunication", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationHunting", "RegistrationCommunication", "isCommunication", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationPrivacy", "RegistrationCommunication" , "isCommunication", StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationCommunication", "RegistrationPersonal", "isPersonal" , StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateRegisterUser.transitions.push(t);

      t = newTransitionCondition("RegisterUser","RegistrationPersonal", "RegistrationLogin", "isLogin" , StateNavigationAction.Previous)
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RuleRegisterLogin);
      StateRegisterUser.transitions.push(t);


      t = {mainState: "RegisterUser",newMainState: "Login", oldState: "RegistrationLogin", newState: "Login",condition: "", nav: StateNavigationAction.Previous}
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateRegisterUser.transitions.push(t);
    
    }
    return StateRegisterUser.transitions;
  }
}
