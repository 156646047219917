import { FishingEdit } from "@/components/fishing/business/FishingEdit"
import { deleteStore, deleteUserContent, localizationTable, openDatabase } from "../database/asyncDb"
import { RefreshServerObjects } from "../resource/RefreshServerObjects"
import { Resource } from "../resource/resource"
import { UserProfile } from "./userProfile"
import { DynamicGridRequest } from "@/components/Shared/dynamicList/DynamicListTypes"
import { Identity } from "./userIdentity"
import { getDynamicGridFromApi } from "../serviceworker-related/DynamicGridServiceWorker"
import { OfflineForms } from "@/components/Shared/Dynamicform/DynamicFormPreload"
import { TierartRules } from "../serviceworker-related/TierartZusatzService"
import { ComboBoxDataSource } from "@/components/Shared/Dynamicform/ComboBoxDataSource"
import { ObjectSearchDataSource } from "@/components/Shared/Dynamicform/ObjectSearchDataSource"
import { ObjectListItems } from "../generics/namedListItem"
import { RefreshAppLanguage } from "./RefreshAppLanguage"

export class AppLanguage {
    public static async changeLanguage(language: string) {

        const userProfile = new UserProfile()
       
        await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastFormRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastResourceRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridFishBookRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridProductsRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastGridWildBookRefreshDate(RefreshServerObjects.startDateSync.slice())
        await RefreshServerObjects.addLastResourceRefreshDate(RefreshServerObjects.startDateSync.slice())

        await deleteUserContent()
        await deleteStore(localizationTable)
        await openDatabase()

        await userProfile.ChangeLanguage(language)

        await Resource.loadResources("ChangeLanguage")
        await Resource.loadSyncWrapper()

        FishingEdit.reset()
        
        await OfflineForms.initOfflineForms()
        await TierartRules.initRules()
        
        await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.startDateSync.slice())
        await ComboBoxDataSource.getComboBoxes()
        await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.startDateSync.slice())
        await ComboBoxDataSource.GetList("FischereiRevierPerson", "", true)        
        await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.startDateSync.slice())

        await ObjectSearchDataSource.getData("FischereiRevier","", "", "") as unknown as ObjectListItems[]
        await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.startDateSync.slice())
        await ObjectSearchDataSource.getData("JagdRevier","", "", "") as unknown as ObjectListItems[]
        await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.startDateSync.slice())
        await ObjectSearchDataSource.getData("Patent","", "", "") as unknown as ObjectListItems[]

        await this.loadGrids()

        const refreshService = RefreshAppLanguage.getRefreshAppService()
        refreshService.refreshAppLanguageState({refresh: true})
        refreshService.refreshAppLanguageState({refresh: false})
        
    }

    private static async loadGrids() {
        const request1: DynamicGridRequest = {
                  userId: Identity.getIdentity().getValue().UserId,
                  viewId: "fishBookEntriesWithDetail",
                  activeData: true,
                  pageSize: 1,
                  revierNr: "",
                  routeName: "FishBook",
                  detailRouteName: "FishbookDetailRecord",
                  page: "FischbuchEintragPachtDaten",
                  form: "FischbuchEintragPacht",
                  language: (await UserProfile.GetClientSettingsAsync()).language,
                  detailId: 'FishbookDetailRecord',
                  detailList: true,
                  allRecords: true,
                  lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                  lastViewIdRefreshState: await RefreshServerObjects.getLastGridFishBookRefreshDate()
              }
          const gridObject1 = await getDynamicGridFromApi(request1, true)
          const request2: DynamicGridRequest = {
                  userId: Identity.getIdentity().getValue().UserId,
                  viewId: "personProductsWithDetail",
                  activeData: true,
                  pageSize: 1,
                  revierNr: "",
                  routeName: "",
                  detailRouteName: "",
                  page: "1",
                  language: (await UserProfile.GetClientSettingsAsync()).language,
                  detailId: '',
                  detailList: false,
                  allRecords: true,
                  lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                  lastViewIdRefreshState: await RefreshServerObjects.getLastGridProductsRefreshDate()
              }
              
          const gridObject2 = await getDynamicGridFromApi(request2, true)
  
          
          const request3: DynamicGridRequest = {
                  userId: Identity.getIdentity().getValue().UserId,
                  viewId: "wildBookEntriesWithDetail",
                  activeData: true,
                  pageSize: 1,
                  revierNr: "",
                  routeName: "",
                  detailRouteName: "",
                  page: "1",
                  language: (await UserProfile.GetClientSettingsAsync()).language,
                  detailId: '',
                  detailList: false,
                  allRecords: true,
                  lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                  lastViewIdRefreshState: await RefreshServerObjects.getLastGridWildBookRefreshDate()
              }
              
          const gridObject3 = await getDynamicGridFromApi(request3, true)
  
      }

      
}

