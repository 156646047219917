import { BehaviorSubject, Observable } from 'rxjs';

export class StateService<T> {
  private state$: BehaviorSubject<T>;
  private initialSavedState: T
  
  constructor(initialState: T) {
    this.initialSavedState = initialState
    this.state$ = new BehaviorSubject<T>(initialState);
  }

  protected get state(): T {
    return this.state$.getValue();
  }

  protected subscribe() : Observable<T> {
    return this.state$.asObservable()
  }

  protected changeState(newState: T) {    
    this.state$.next(newState)
  }

  protected clearState() {
    this.state$ = new BehaviorSubject<T>(this.initialSavedState);
  }
  
}
