
import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { Identity } from "@/infrastructure/backend/userIdentity";
import { addOrReplaceRecord, getRecord, offlineFormsTable } from "@/infrastructure/database/asyncDb";
import { DynamicDataSet } from "./DynamicFormData";
import { RefreshServerObjects } from "@/infrastructure/resource/RefreshServerObjects";
import { Navigate } from "@/infrastructure/backend/async/Navigate";


export type OfflineFormName = "Fischbuch" | "FischbuchEintragPatentrevier" | "FischbuchEintragPachtrevier" | "FischbuchEintragLinthkanal" 
| "Wildbuch" | "WildbuchEintragJagd" | "WildbuchEintragFallwild" | "WildbuchEintragBeobachtung" | "WildbuchEintragBeobachtungSpez" 

export class OfflineForms {
    
    public static async initOfflineForms() : Promise<void> {
        await this.getForms()
    }

    public static async getForm(formName: string) : Promise<DynamicDataSet> {
         const formResult = await getRecord(offlineFormsTable, formName)
        return formResult.form
    }

  
	

    private static async getForms() : Promise<void> {

        if( Navigate.IsLocked() ) {
            return undefined
        }
 

        const dataSourceClient = AsyncRestClient.Create(true);
        if (dataSourceClient.isFailure) return;
        const result = await dataSourceClient
            .getValue()
            .exchangeForComponents<string, any>(process.env.VUE_APP_API + "api/anonymousForm/preLoadAllOfflineForms", JSON.stringify({           
                userId: Identity.getIdentity().getValue().UserId,  
                lastRefreshDate: await RefreshServerObjects.getLastFormRefreshDate()              
             }));       
        if (result.isFailure) return undefined;

        const response = result.getValue()
         if(response.offline) {
            return
         }

         
        if ( response.forms && response.forms.length > 0 ) {
            for( const form of response.forms) {
                await addOrReplaceRecord(offlineFormsTable, {id:form.viewId, form})
            }
            await RefreshServerObjects.addLastFormRefreshDate(response.lastRefreshDate)
        }
    


       
    }
    


}

