import { StateService } from '../../../../infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { DynamicRecord } from '../../Dynamicform/DynamicFormData';



export interface DynamicDetailFormToDetailListState {  
    viewId: string
    view: DynamicRecord[]
    attachTo: string | undefined
    isSave: boolean
  }
  
 const  viewItem: DynamicRecord = {
     teilFormularName: '',
     formularFeldname: '',
     feldTyp: '',
     istEditierbar: false,
     istPflichtfeld: false,
     istSichtbar: false,
     maxLaenge: 0,
     resourceId: '',
     teilFormularReihenfolge: 0,
     regulaererAusdruck: '',
     resourceIdErrorMeldungPflichtfeld: '',
     resourceIdErrorMeldungValidierung: '',
     resourceIdErrorMeldungMaxLaenge: '',
     layoutKlasse: '',
     isValidationError: false,
     validationMessage: '',
     value: undefined,
     tabellenFeldname: '',
     tabellenName: '',
     infoTextTitle: undefined,
     infoTextContent: undefined,
     infoResourceId: undefined,
     parentCascadeId: undefined
 }


const initialState: DynamicDetailFormToDetailListState = {
    viewId: "",
    view: [viewItem],
    attachTo: undefined,
    isSave: false   
  };


export function createIntitialFormDetailState() : DynamicDetailFormToDetailListState {
    return initialState
}



export class DynamicFormToDetailListChannelForRoot extends StateService<DynamicDetailFormToDetailListState>{
    public static service: DynamicFormToDetailListChannelForRoot;
    private static attachetTo: string | undefined = undefined


    public static getService() : DynamicFormToDetailListChannelForRoot {
        if ( DynamicFormToDetailListChannelForRoot.service === undefined) {
            DynamicFormToDetailListChannelForRoot.service = new DynamicFormToDetailListChannelForRoot();
            this.service = DynamicFormToDetailListChannelForRoot.service
        }
        return DynamicFormToDetailListChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<DynamicDetailFormToDetailListState> {
        return this.subscribe();
    }

    public changeDetailFormState(detailFormState: DynamicDetailFormToDetailListState) : void {       
        if ( DynamicFormToDetailListChannelForRoot.attachetTo !== detailFormState.attachTo ) {
            DynamicFormToDetailListChannelForRoot.attachetTo = detailFormState.attachTo
        }

        this.changeState(detailFormState);
    }

    
    public isAttached(detailName: string | undefined): boolean {
        if ( detailName === undefined ) {
            return false
        }
        if ( DynamicFormToDetailListChannelForRoot.attachetTo == detailName && DynamicFormToDetailListChannelForRoot.attachetTo !== undefined && detailName !== undefined) {
            return true
        }

        return false
    }


    public get detailState(): DynamicDetailFormToDetailListState {
        return this.state;
    }
}