import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04c993fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eFj-list" }
const _hoisted_2 = { class: "row g-0 eFj-list-content-header-headline" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row g-0" }
const _hoisted_6 = { class: "col d-flex justify-content-center" }
const _hoisted_7 = { class: "row g-0 eFj-list-content" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "row g-0" }
const _hoisted_11 = { class: "col-1 eFj-list-card-image" }
const _hoisted_12 = ["src"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "col-3" }
const _hoisted_16 = { class: "row g-0 pr-1" }
const _hoisted_17 = { class: "row g-0 pr-1" }
const _hoisted_18 = { class: "col eFj-list-card-gueltig" }
const _hoisted_19 = { class: "row g-0" }
const _hoisted_20 = { class: "col eFj-list-card-gueltig" }
const _hoisted_21 = { class: "row g-0 eFj-list-content-footer" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "row g-0" }
const _hoisted_24 = { class: "col" }
const _hoisted_25 = { class: "PdfContainer" }
const _hoisted_26 = { style: {"width":"100%"} }
const _hoisted_27 = { class: "pdf__table" }
const _hoisted_28 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_pdfexport = _resolveComponent("pdfexport")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, _toDisplayString(_ctx.content.title), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isFilterVisible())
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createVNode(_component_router_view, _mergeProps({ name: "filterDetail" }, _ctx.request), null, 16)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isListVisible())
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamic.grid.result.rows, (record, id) => {
                return (_openBlock(), _createElementBlock("div", { key: id }, [
                  (_ctx.isGridRowVisible(record))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _withModifiers(($event: any) => (_ctx.openDetail(record)), ["prevent"]),
                        class: "eFj-list-card"
                      }, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("img", {
                              class: "eFj-image",
                              src: (_ctx.getImagePath(record))
                            }, null, 8, _hoisted_12)
                          ]),
                          _createElementVNode("div", {
                            innerHTML: _ctx.getCellTitle(record),
                            class: _normalizeClass(_ctx.syncSupportedAdjustTitle())
                          }, null, 10, _hoisted_13),
                          (_ctx.syncSupported)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.isToSynchronizeClass(record))
                              }, [
                                _createElementVNode("button", {
                                  onClick: _withModifiers(($event: any) => (_ctx.synchronize(record)), ["stop","prevent"]),
                                  class: "btn sync__button btn-block"
                                }, " Sync ", 8, _hoisted_14)
                              ], 2))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["col eFj-list-card-status", _ctx.getImageState(record)])
                              }, _toDisplayString(_ctx.getImageStatusText(record)), 3)
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, _toDisplayString(record.cell.additional.gueltigVon), 1)
                            ]),
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, _toDisplayString(record.cell.additional.gueltigBis), 1)
                            ])
                          ])
                        ])
                      ], 8, _hoisted_9))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isNotListVisibleAndDetailListVisible())
    }, [
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createVNode(_component_router_view, _mergeProps({ name: "listDetail" }, _ctx.request), null, 16)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isNotListVisibleAndDetailFormVisible())
    }, [
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          (_openBlock(), _createBlock(_component_router_view, _mergeProps({
            name: "formDetail",
            key: _ctx.clientRouteName
          }, _ctx.dynamicDetail), null, 16))
        ])
      ])
    ], 2),
    _createElementVNode("div", _hoisted_25, [
      _createVNode(_component_pdfexport, {
        ref: "contentToExport",
        scale: 0.7,
        margin: '1cm',
        "paper-size": 'A4',
        landscape: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("p", null, _toDisplayString(_ctx.report.pdf.title), 1),
            _createElementVNode("table", _hoisted_27, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report.pdf.report.columns, (column, i) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: i,
                      style: _normalizeStyle(column.style)
                    }, _toDisplayString(column.name), 5))
                  }), 128))
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report.pdf.report.rows, (row, i) => {
                  return (_openBlock(), _createElementBlock("tr", { key: i }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.row, (cell, j) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: j,
                        innerHTML: cell.value
                      }, null, 8, _hoisted_28))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["scale"])
    ])
  ]))
}