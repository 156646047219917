
import { reactive } from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";

export default {
  name: "password-component",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
    setup() {    
    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "api/anonymousForm/saveForm",
      form: "PasswortVergessen",
      page: "PasswortZuruecksetzen",
      remoteId: "",
      waitForResponse: true
      

    });  
    return {
      request,
    };
  }
};
