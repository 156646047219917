import { DynamicGridRow, DynamicReadableIdentification } from './../../components/Shared/dynamicList/DynamicListTypes';
import { Out } from '@/infrastructure/frontent/clientMessage';
import { DynamicRecord } from '@/components/Shared/Dynamicform/DynamicFormData';
import { Tierart, TierartZusatzRecord, TierartZusatzRule, addOrReplaceRecord, deleteRecord, getRecord } from '@/infrastructure/database/asyncDb';
//api/rules/GetTierartRules
//api/rules/GetTierartRulesData

import { AsyncRestClient } from "../backend/async/AsyncRestClient";
import { Identity } from "../backend/userIdentity";
import { read } from '@popperjs/core';
import { Navigate } from '../backend/async/Navigate';

export type TierartRule = {
        idTierart: string,
        istWildbuchEingabeZusatzInfo?: boolean,
        istJagdbar?: boolean,
        istFallwild?: boolean,
        istGeschuetzt?: boolean,
        istBeobachtbar?: boolean,
        minGewicht?: number,
        maxGewicht?: number,
        hatWildbuchEingabeZusatzInfo?: boolean,
        istWildbuchEingabeMehrere?: boolean,
        attributName?: string,
        attributResourceId?: string,
        fkGeoGebiet?: string,
        wert?: string,
        wertMin?: number,
        wertMax?: number,
        prozess?: string,
        istAktiv?: boolean,
        reihenfolge?: number,
        durchschnittsGewicht?: number,
        istFischbar?: boolean
        pflichtFeld?: string
}

export type TierartDynamicRecord = {
    idJagdAbgangBeobachtung: string,
    fkPerson: string
    idTierart: string,
    istWildbuchEingabeZusatzInfo?: boolean,
    istJagdbar?: boolean,
    istFallwild?: boolean,
    istGeschuetzt?: boolean,
    istBeobachtbar?: boolean,
    minGewicht?: number,
    maxGewicht?: number,
    hatWildbuchEingabeZusatzInfo?: boolean,
    istWildbuchEingabeMehrere?: boolean,
    attributName?: string,
    attributResourceId?: string,
    fkGeoGebiet?: string,
    wert?: string,
    wertMin?: number,
    wertMax?: number,
    prozess?: string,
    istAktiv?: boolean,
    reihenfolge?: number,
    durchschnittsGewicht?: number,
    istFischbar?: boolean
    zusatzWert?: string
    PflichtFeld?: string
}

export type TierartDaten = {
    key: string
    istWildbuchEingabeZusatzInfo?: boolean,
    filter?: string
    orderBy1?: string,
    orderBy2?: string
    orderBy3?: string
    type?: any
    value?: string
}   


export class TierartRules {

    private static _rules:TierartRule[] | undefined = [] 
    private static _ruleData:TierartDynamicRecord[] | undefined = [] 
    private static _tierarten: TierartDaten[] | undefined 

    public static async initRules() {
        TierartRules._rules = await TierartRuleStore.loadRules()
        TierartRules._ruleData = await TierartRules.loadRuleData()
    }

    private static async ensureData() {
        if ( !TierartRules._rules || TierartRules._rules.length == 0) {
            TierartRules._rules = await TierartRuleStore.loadRules()
        }
        // if ( !TierartRules._ruleData || TierartRules._ruleData.length == 0) {
        //     TierartRules._ruleData = await TierartRules.loadRuleData()
        // }
        if ( !TierartRules._tierarten || TierartRules._tierarten.length == 0) {
            TierartRules._tierarten = await TierartRules.loadTierartenData()
        }
        TierartRules._ruleData = await TierartRules.loadRuleData()
    }

    public static async saveAllData(data: TierartDynamicRecord[] | undefined ) {
        if ( ! data ) return
        await addOrReplaceRecord(TierartZusatzRecord, {id: "tierartZusatzData", data})
    }


    public static async findZusatzInfoDatenRecords(id: string | undefined) : Promise<TierartDynamicRecord[] | undefined> {
        if ( !id || id.length < 1  ) return
        await TierartRules.ensureData()

        if (!TierartRules._ruleData) return undefined

        const records:TierartDynamicRecord[] = []

        for ( const dataRow of TierartRules._ruleData ) {
            if( dataRow.idJagdAbgangBeobachtung === id) records.push(dataRow)
        }
        return records
    }
    public static async allZusatzInfoDatenRecords() : Promise<TierartDynamicRecord[] | undefined> {       
        await TierartRules.ensureData()
        return TierartRules._ruleData
    }

    public static async findZusatzInfoRuleRecords(id: string | undefined) : Promise<TierartRule[] | undefined> {
        if ( !id || id.length < 1  ) return
        await TierartRules.ensureData()

        if (!TierartRules._rules) return undefined

        const records:TierartRule[] = []

        for ( const dataRow of TierartRules._rules ) {
            if( dataRow.idTierart === id) records.push(dataRow)
        }
        return records


    }

    public static async assignZusatzDatenFromForm(formRows: DynamicRecord[] , datenZusatzRows: TierartDynamicRecord[]) {
        for( const datenRow of datenZusatzRows ) {
            for (const formRow of formRows) {
                const fieldName = TierartRules.resolveAttributName(datenRow.attributResourceId)
                if ( !fieldName ) continue
                
                if (formRow.formularFeldname === fieldName) {
                    datenRow.zusatzWert = formRow.value           
                }
            }
        }

    }

    public static async handleZusatzInfoData(gridRow: DynamicGridRow | undefined) {
        if ( !gridRow || !gridRow.cell || !gridRow.cell.readableIdentification || !gridRow.cell.readableIdentification.id || gridRow.cell.readableIdentification.id.length < 1  || ! gridRow.detailForm ) return
        await TierartRules.ensureData()
        const prozess = TierartRules.getProzessFromStartFormular(gridRow.cell.readableIdentification.kategorie)
        if ( !prozess ) return
        if ( !TierartRules._ruleData ) return

        let idTierart: string | undefined = undefined 
        for(const formRow of gridRow.detailForm.view) {
            if ( formRow.formularFeldname && formRow.formularFeldname.indexOf("Tierart") >= 0 ) {
                if ( formRow.value && typeof(formRow.value) === typeof("string"))
                    idTierart = formRow.value
                    break
            }
        }

        if ( ! idTierart || idTierart.length < 1 ) return


        for ( const ruleRow of TierartRules._ruleData ) {
            //if ( !ruleRow.prozess || ruleRow.prozess.indexOf(prozess) < 0 ) continue
            if ( !ruleRow.prozess  ) continue
            const fieldName = TierartRules.resolveAttributName(ruleRow.attributResourceId)
            if ( !fieldName ) continue
            
            if ( idTierart !== ruleRow.idTierart) continue


            let id: string | undefined = undefined
            if( gridRow.rowId === ruleRow.idJagdAbgangBeobachtung) {
                id = ruleRow.idJagdAbgangBeobachtung
            }
            if( gridRow.id === ruleRow.idJagdAbgangBeobachtung) {
                id = ruleRow.idJagdAbgangBeobachtung
            }
            if( gridRow.cell.readableIdentification.id === ruleRow.idJagdAbgangBeobachtung) {
                id = ruleRow.idJagdAbgangBeobachtung
            }

            if ( !id || !fieldName || id.length < 1 || fieldName.length < 1 ) continue
            for(const formRow of gridRow.detailForm.view) {
                if ( formRow.formularFeldname === fieldName ) {
                    formRow.value = ruleRow.zusatzWert
                }
            }
        }

    }

    public static async handlePflichtfelder(formName: string | undefined, form: DynamicRecord[] | undefined) {
        if ( !formName || formName.length < 1 || !form || !TierartRules._rules ) return form
        await TierartRules.ensureData()

        if ( ! TierartRules._tierarten) return 

        let idTierart: string | undefined = undefined 
        for(const formRow of form) {
            if ( formRow.formularFeldname && formRow.formularFeldname.indexOf("Tierart") >= 0 ) {
                if ( formRow.value && typeof(formRow.value) === typeof("string"))
                    idTierart = formRow.value
                    break
            }
        }

        if ( ! idTierart || idTierart.length < 1 ) return form

        for ( const ruleRow of TierartRules._tierarten ) {             
            if ( idTierart !== ruleRow.key) continue

            for(const formRow of form) {                
                if ( formRow.formularFeldname === "Gewicht" ) {
                    formRow.istPflichtfeld = ruleRow.istWildbuchEingabeZusatzInfo ?? false
                    if ( ! ruleRow.istWildbuchEingabeZusatzInfo ?? false ) {
                        formRow.isValidationError = false
                    }
                }
                if ( formRow.formularFeldname === "Tieralter" ) {
                    formRow.istPflichtfeld = ruleRow.istWildbuchEingabeZusatzInfo ?? false
                    if ( ! ruleRow.istWildbuchEingabeZusatzInfo ?? false ) {
                        formRow.isValidationError = false
                    }

                }
                if ( formRow.formularFeldname === "Geschlecht" ) {
                    formRow.istPflichtfeld = ruleRow.istWildbuchEingabeZusatzInfo ?? false
                    if ( ! ruleRow.istWildbuchEingabeZusatzInfo ?? false ) {
                        formRow.isValidationError = false
                    }

                }
            }
        }

    }


    public static async handleZusatzInfo(formName: string, form: DynamicRecord[]) : Promise<DynamicRecord[]> {    
        if ( !formName || formName.length < 1 || !form || !TierartRules._rules ) return form
        await TierartRules.ensureData()
        const prozess = TierartRules.getProzessFromStartFormular(formName)
        if ( ! prozess ) return form

        let idTierart: string | undefined = undefined 
        for(const formRow of form) {
            if ( formRow.formularFeldname && formRow.formularFeldname.indexOf("Tierart") >= 0 ) {
                if ( formRow.value && typeof(formRow.value) === typeof("string"))
                    idTierart = formRow.value
                    break
            }
        }

        for ( const ruleRow of TierartRules._rules ) {
            if ( !ruleRow.prozess || ruleRow.prozess.indexOf(prozess) < 0 ) continue
            const fieldName = TierartRules.resolveAttributName(ruleRow.attributResourceId)
            if ( !fieldName ) continue
            for(const formRow of form) {                
                if ( formRow.formularFeldname === fieldName ) {
                    formRow.istSichtbar = false
                    formRow.istPflichtfeld = false
                }
            }
        }

        if ( ! idTierart || idTierart.length < 1 ) return form

        for ( const ruleRow of TierartRules._rules ) {
            if ( !ruleRow.prozess || ruleRow.prozess.indexOf(prozess) < 0 ) continue
            const fieldName = TierartRules.resolveAttributName(ruleRow.attributResourceId)
            if ( !fieldName ) continue
            
           

            if ( ! idTierart || idTierart.length < 1 ) continue
            if ( idTierart !== ruleRow.idTierart) continue

            for(const formRow of form) {
                if ( formRow.formularFeldname === fieldName ) {                    
                    if( prozess === "99.1" ) {
                        if ( ruleRow.istJagdbar) {
                            formRow.istSichtbar = true
                            if (ruleRow.pflichtFeld && ruleRow.pflichtFeld.indexOf("Pflichtfeld") >= 0) {
                                formRow.istPflichtfeld = true
                            }
                            break
                        }
                    }
                    if( prozess === "99.2" ) {
                        if ( ruleRow.istFallwild) {
                            formRow.istSichtbar = true
                            if (ruleRow.pflichtFeld && ruleRow.pflichtFeld.indexOf("Pflichtfeld") >= 0) {
                                formRow.istPflichtfeld = true
                            }

                            break
                        }
                    }
                    if( prozess === "99.3" ) {
                        if ( ruleRow.istBeobachtbar) {
                            formRow.istSichtbar = true
                            if (ruleRow.pflichtFeld && ruleRow.pflichtFeld.indexOf("Pflichtfeld") >= 0) {
                                formRow.istPflichtfeld = true
                            }

                            break
                        }
                    }

                }
            }
        }

        for( const row of form) {
            if ( row.teilFormularName.indexOf("TierartZusatz") >= 0) {
                if( row.feldTyp === "Text" || row.feldTyp === "ComboBox") {
                    if( !row.istSichtbar) {
                        row.value = ""
                    }
                }
            }
        }
        
        return form
    }

    private static resolveAttributName( name: string | undefined ) {
        if ( ! name ) return undefined

        name = name.trim()
        const temp = name.split(".") 
        if ( temp.length === 0) return undefined
        
        let result = temp[temp.length-1]
        if ( result) result = result.trim()

        if ( result && result.length > 0) {
            result = result.replace(" ", "")
            return result
        }

        if ( temp.length < 2) return undefined

        result = temp[temp.length-2]
        if ( result) result = result.trim()

        if ( result && result.length > 0) {
            result = result.replace(" ", "")
            return result
        }

        return undefined

    }

    private static getProzessFromStartFormular(kategorie: string | undefined) : string | undefined {
        if ( !kategorie || kategorie.length < 1) return undefined

        if ( kategorie === "WildbuchEintragJagd") return "99.1"
        if ( kategorie === "WildbuchEintragFallwild") return "99.2"
        if ( kategorie === "WildbuchEintragBeobachtung") return "99.3"
        if ( kategorie === "WildbuchEintragBeobachtungSpez"  ) return "99.4"

        return undefined

    }

    public static async loadRuleData() : Promise<TierartDynamicRecord[]| undefined> {      

        let done = false
        const result =  await getRecord(TierartZusatzRecord, "tierartZusatzData")
        if ( result) {
            if ( result.data) {
                if (result.data.length == 0 ) {
                    const remoteData = await TierartRules.getRuleData()        
                    if ( remoteData && remoteData.length >= 0 ) {

                        result.data = result.data.concat(remoteData)                        
                        await addOrReplaceRecord(TierartZusatzRecord, {id: "tierartZusatzData", data: result.data} )   
                        return result.data    
                    }                         
                }
                done = true  
                return result.data
            }
        }

        if (!done) {
            const data = await TierartRules.getRuleData()        
            if ( data && data.length >= 0 ) {
                await addOrReplaceRecord(TierartZusatzRecord, {id: "tierartZusatzData", data} )  
                return data              
            }
        }


            
        return undefined
    }

    private static async loadTierartenData() : Promise<TierartDaten[] | undefined> {
        const data =  await getRecord(Tierart, "Tierart")
        if ( data) {
            if ( data.objectList) {
                return data.objectList
            }
        }
        return undefined
    }


    public static async getRuleData() : Promise<any> {
        if( Navigate.IsLocked() )
        return undefined


        const dataSourceClient = AsyncRestClient.Create(true);
        if (dataSourceClient.isFailure) return;

        const result = await dataSourceClient
            .getValue()
            .exchangeForComponents<string, any>(process.env.VUE_APP_API + "api/rules/GetTierartRulesData", JSON.stringify({                     
                userId: Identity.getIdentity().getValue().UserId,                
             }));       
        if (result.isFailure) return undefined;
        return result.getValue()
    }
}

class TierartRuleStore {
    
    public static async loadRules() : Promise<TierartRule[] | undefined> {
        const data =  await getRecord(TierartZusatzRule, "tierartZusatzRules")
        if ( data) {
            if ( data.loadedRules) {
                if (data.loadedRules.length > 0 ) {
                    return data.loadedRules
                }
            }
        }
        const loadedRules = await TierartRuleStore.getRules() 
        await addOrReplaceRecord(TierartZusatzRule, {id:"tierartZusatzRules", loadedRules})
        return loadedRules
    }


    private static async getRules() : Promise<TierartRule[] | undefined> {

        if( Navigate.IsLocked() )
            return undefined


        const dataSourceClient = AsyncRestClient.Create(true);
        if (dataSourceClient.isFailure) return;

        const result = await dataSourceClient
            .getValue()
            .exchangeForComponents<string, any>(process.env.VUE_APP_API + "api/rules/GetTierartRules", JSON.stringify({                     
                userId: Identity.getIdentity().getValue().UserId,                
             }));       
        if (result.isFailure) return undefined;
        return result.getValue()
    }
}