
import { reactive} from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
import { DynamicLoadFromListData } from "../Shared/Dynamicform/DynamicLoadFromListData";
import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
import router from "@/router";

export default {
  name: "WebShopWarenKorb",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "",
      form: "WebShop",
      page: "WebShopWarenkorb",
      remoteId: "",
    });

    const loaderState = LoaderStateServiceForRoot.getLoaderStateService()

    // setTimeout(() => {
    //     //loaderState.changeLoaderState({isInProgress: true})
    //     console.error("Timeout")
    //     //router.push({ name: 'WebshopFailed'});
    //     //loaderState.changeLoaderState({isInProgress: false})
    //   }, 60000);

    DynamicLoadFromListData.reset()
    return {
      request
    };
  }
};
