
import { reactive } from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";

export default {
  name: "UserPrivacyOnly",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {    
    const request = reactive({
      apiLoadMethod: "api/Form/loadForm",
      apiSaveMethod: "",
      form: "Datenschutz",
      page: "Datenschutz",
      remoteId: "",
      waitForResponse: true
      
    });

    return {
      request
    };
  }
};
