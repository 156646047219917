
import { reactive } from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
import { useStore } from "vuex";

export default {
  name: "PasswordEmail",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {    
    const store = useStore()
    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "api/anonymousForm/saveForm",
      form: "PasswordEmail",
      page: "PasswordSaveEmail",
      remoteId: "",
      waitForResponse: true
      
    });  
    return {
      request,
    };
  }
};
