
import { ErrorStateServiceForRoot } from "@/infrastructure/observables/errorState";
import { reactive , defineComponent, computed, ref} from "vue";
import {sanitize} from "@/infrastructure/functional/sanitize"
import { useRoute } from "vue-router";

export default defineComponent ({
  name: "ErrorPopup",
  emits: {
      closed: () => true
    },
  props: {  
    message: {
      type: String,
       default: null,
      require: false
    },
    errorTitleResourceId: {
      type: String,
      default: null,
      require: false
    },
    errorContentResourceId: {
      type: String,
      default: null,
      require: false
    },
    isError: {
      type: Boolean,
       default: null,
      require: false
    },
    dialogName: {
      type: String,
      default: null,
      require: false
    }
  },
  inheritAttrs: false,
  components: {},
  setup( props, {emit}) {

    const errorState = ErrorStateServiceForRoot.getErrorStateService()
    const route=useRoute();    
    const clientRouteName = computed(() =>route.name?.toString())
    
    const sizing = ref<string>("")
    if ( clientRouteName.value === "Login") {
        sizing.value = "sizing__page-landing"
    } else {
        sizing.value = "sizing__page"
    }

    const errorDialogParameter = reactive({   
      isError: props.isError,     
      })
  
    function applyOk() {
      errorDialogParameter.isError=false;
      errorState.resetErrorState(); 
      emit("closed");
    }

    function getText() {        
        return sanitize(props.errorContentResourceId + (props.message ?? ""))
    }

    function getTitle() {
        return sanitize(props.errorTitleResourceId)
    }


   

    return {    
      applyOk,
      getText,
      getTitle,
      sizing,
      errorDialogParameter,
    };
  }
});
