import { getRecord, offlineFormsTable } from '@/infrastructure/database/asyncDb';
import { DynamicDataSet } from '@/components/Shared/Dynamicform/DynamicFormData';

export async function getOfflineForm(viewId: string): Promise<DynamicDataSet> {
    const rows =  await getRecord(offlineFormsTable, viewId)
    const data: DynamicDataSet = {
        viewId: viewId,
        state: 'Ok',
        stateMessage: 'Ok',
        view: rows.form.view
    }
    return data
}