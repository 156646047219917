import { AsyncCommand } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
import { FishingEdit } from "@/components/fishing/business/FishingEdit";

export class RuleStopFishing extends AsyncCommand {
  
  private dynamicForm: DynamicFormData;
  private dataSet: DynamicDataSet;
  
  constructor(
    dynamicForm: DynamicFormData,
    dataSet: DynamicDataSet
  ) {
    super();
    this.dynamicForm = dynamicForm;
    this.dataSet = dataSet;
  }

  public async asyncExecute(): Promise<any> {

   

    const success = FishingEdit.stoppFishing();
    if ( !success)
        return false

        this.dataSet.view.forEach(row => {
            if (row.formularFeldname === "RuleFischfangEintrag") {
                row.layoutKlasse = "btn action__button_primary btn-block action__button"
                row.istEditierbar = false
                row.istSichtbar = false
            }

            if (row.formularFeldname === "RuleStartFishing") {
                row.istSichtbar = true
            }
            if (row.formularFeldname === "RuleStopFishing") {
                row.istSichtbar = false
            }


            if (row.formularFeldname === "Auswahlpatent") {                               
                row.istSichtbar = false
            }

            if (row.formularFeldname === "Patent") {               
                row.istSichtbar = true
            }

            if (row.formularFeldname === "Auswahlrevier") {                               
                row.istSichtbar = false
            }

            if (row.formularFeldname === "Fischereirevier") {               
                row.istSichtbar = true
            }


        })
        const result = this.dynamicForm.saveLocalPage(this.dataSet);
    return true
  }
}

