import { StateService } from '../../../../infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { DynamicGridRow } from '@/components/Shared/dynamicList/DynamicListTypes';
import { DetailsSupported } from './DynamicDetailTypes';


export interface DynamicDetailFormToListState {  
    row: DynamicGridRow
    isNotification: boolean;
    isClosed: boolean
    attachTo: DetailsSupported
  }
  
const initialState: DynamicDetailFormToListState = {
    row: {
        cell: {
          image:{},
          content: {},
          additional: {}
        }
    },    
    isNotification: false,
    isClosed: false,
    attachTo: undefined
  };


export function createIntitialFormDetailState() : DynamicDetailFormToListState {
    return initialState
}

export async function CreateFromDetailState(row: DynamicGridRow, isNotification: boolean, isClosed: boolean, detailState: DetailsSupported) : Promise<DynamicDetailFormToListState> {
    return {       
        row: row,
        isNotification: isNotification,
        isClosed: isClosed,
        attachTo: detailState
    }
}

export class DynamicDetailToFormChannelForRoot extends StateService<DynamicDetailFormToListState>{
    public static service: DynamicDetailToFormChannelForRoot;
    private static attachetTo: DetailsSupported = undefined
    private static firstAttached = true

    public static getService() : DynamicDetailToFormChannelForRoot {
        if ( DynamicDetailToFormChannelForRoot.service === undefined) {
            DynamicDetailToFormChannelForRoot.service = new DynamicDetailToFormChannelForRoot();
            this.service = DynamicDetailToFormChannelForRoot.service
        }
        return DynamicDetailToFormChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<DynamicDetailFormToListState> {
        return this.subscribe();
    }

    public changeDetailState(detailFormState: DynamicDetailFormToListState) : void {
        DynamicDetailToFormChannelForRoot.firstAttached = false
        if ( DynamicDetailToFormChannelForRoot.attachetTo !== detailFormState.attachTo ) {
            DynamicDetailToFormChannelForRoot.attachetTo = detailFormState.attachTo
            DynamicDetailToFormChannelForRoot.firstAttached = true
        }

        this.changeState(detailFormState);
    }

    
    public isAttached(detailName: DetailsSupported): boolean {
        if ( detailName === undefined ) {
            return false
        }
        if ( DynamicDetailToFormChannelForRoot.attachetTo == detailName && DynamicDetailToFormChannelForRoot.attachetTo !== undefined && detailName !== undefined) {
            return true
        }

        return false
    }

    public isFirstAttached() {
        return DynamicDetailToFormChannelForRoot.firstAttached
    }


    public get detailState(): DynamicDetailFormToListState {
        return this.state;
    }
}