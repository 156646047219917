import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";



export interface OnOfflineState {
   IsOnline: boolean | undefined
}

export class OnOfflineListener extends StateService<OnOfflineState>{
    public static service: OnOfflineListener;

    public static getService() : OnOfflineListener {
        if (!OnOfflineListener.service) {
            OnOfflineListener.service = new OnOfflineListener();
            this.service = OnOfflineListener.service
        }
        return OnOfflineListener.service;
    }

    private constructor() {
        super({IsOnline: undefined});       
    }
    
    public subscription(): Observable<OnOfflineState> {
        return this.subscribe();
    }

    public onlineChanged(onlineState: OnOfflineState) : void {               
        this.changeState(onlineState);
    }

    public reset() : void {
        this.changeState({IsOnline: undefined});
    }

    public get OnlineState(): OnOfflineState {
        return this.state;
    }
}