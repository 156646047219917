

import { computed, defineComponent, onUnmounted, reactive} from 'vue';
import { AttachDynamicFilterChannelForRoot } from '@/components/Shared/dynamicList/filter/hooks/AttachDynamicFilterChannelForRoot';
import { ApplyDynamicFilterChannelForRoot } from '@/components/Shared/dynamicList/filter/hooks/ApplyDynamicFilterChannel';
import { useRoute } from 'vue-router';
import { FilterSupportedLists } from './hooks/DynamicFilterTypes';
import { load } from '@progress/kendo-vue-intl';
import { Resource } from '@/infrastructure/resource/resource';
export default defineComponent ({
    name: "FilterSwitch",
    inheritAttrs: false,
    components: {},
    props: 
    {
      viewId: {
        type: String,
        default: "",
        require: true
      }
    },
    setup(props) {

      const filterText = reactive({
        title: "Aktuell"
      })

      async function load() {
        filterText.title = await Resource.getResourceText("Liste.Filter.Switch")
      }

      load()

      const attachedTo = AttachDynamicFilterChannelForRoot.getService()
      let applyFilter:ApplyDynamicFilterChannelForRoot = ApplyDynamicFilterChannelForRoot.getService()
    


      const filter = reactive({
          isActive: applyFilter.filterState.showActiveData,
          isUnlocked: attachedTo.filterState.attachTo === attachedTo.filterState.clientParentRoute
      });        

      const onAttachedSubscription = attachedTo.subscription().subscribe({
      next: (value) => {
        if ( attachedTo.isAttached()) {  
            filter.isActive = applyFilter.filterState.showActiveData,
            filter.isUnlocked = attachedTo.filterState.attachTo ===  attachedTo.filterState.clientParentRoute && attachedTo.filterState.attachTo !== undefined
          }
        }        
      })

      function onSwitchChange() {
          if ( ! filter.isUnlocked) return
          filter.isActive = !filter.isActive
          applyFilter.filterState.showActiveData
          applyFilter.changeFilterState({attachTo: props.viewId as FilterSupportedLists, showActiveData: filter.isActive})
      }
      
      onUnmounted(() => {
        onAttachedSubscription.unsubscribe()       
      });

      return {
          filter,
          filterText,
          onSwitchChange
      }
    }
})
