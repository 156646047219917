import { ConvertUtcStringToDate, getCurrentDayDate, getDateWithoutTime, newDate } from '@/infrastructure/functional/datetimehelper';
import { ObjectListItems } from '../../../infrastructure/generics/namedListItem';
import { GetEndOfDayDateTime } from "@/infrastructure/functional/datetimehelper"
import { DynamicGridRequest } from '@/components/Shared/dynamicList/DynamicListTypes';
import { addOrUpdateLocalFishingParentRecord, stopLocalFishingParentRecord } from './FishingAdd';
import { Identity } from '@/infrastructure/backend/userIdentity';
import { UserProfile } from '@/infrastructure/backend/userProfile';
import { addOrReplaceRecord, fischFangStartStopTable, getRecord } from '@/infrastructure/database/asyncDb';
import { Out } from '@/infrastructure/frontent/clientMessage';
import { RefreshServerObjects } from '@/infrastructure/resource/RefreshServerObjects';

export type FishingEditState = {
    startDate: Date | undefined,
    stoppDate: Date | undefined,
    patent: ObjectListItems | undefined,
    fischereiRevier: ObjectListItems | undefined   
    isStarted:boolean
}

export class FishingEdit {

    private static fishingEditState: FishingEditState = {
        startDate: undefined,
        stoppDate: undefined,
        patent: undefined,
        fischereiRevier: undefined,
        isStarted: false
    }
    
    public static async loadFishingEditSate() {
        try {
            const result =  await getRecord(fischFangStartStopTable, "1")
            if ( result ) {
                if ( result.startDate ) {
                    result.startDate  = ConvertUtcStringToDate(result.startDate)
                }
                if ( result.stoppDate ) {
                    result.stoppDate  = ConvertUtcStringToDate(result.stoppDate)
                }
                FishingEdit.fishingEditState = result as FishingEditState
                
                
            }
        } catch(e) {
            Out.noOperation("loadFishingEditSate");
        }
    }

    private static async saveFishingEditState() {
        const record = JSON.parse(JSON.stringify( FishingEdit.fishingEditState ))
        
        const data = {
            id: "1",        
            ...record
        }

        await addOrReplaceRecord(fischFangStartStopTable, data)
    }

   
    private static syncToAsyncFisshingState() {
        setTimeout(FishingEdit.saveFishingEditState, 0)
    }


    public static async startFishing(patent: ObjectListItems | undefined ,fischereiRevier: ObjectListItems | undefined) : Promise<boolean> {
        if ( patent === undefined || fischereiRevier === undefined)
            return false
        if ( !patent.key || !fischereiRevier.key || patent.key.length < 1 || fischereiRevier.key.length < 1  )
            return false

            FishingEdit.fishingEditState.startDate = newDate()
            FishingEdit.fishingEditState.stoppDate = GetEndOfDayDateTime(FishingEdit.fishingEditState.startDate)

            FishingEdit.fishingEditState.patent = patent
            FishingEdit.fishingEditState.fischereiRevier = fischereiRevier
            FishingEdit.fishingEditState.isStarted = true

            const request: DynamicGridRequest = {
                userId: Identity.getIdentity().getValue().UserId,
                viewId: "fishBookEntriesWithDetail",
                activeData: true,
                pageSize: 1,
                revierNr: "",
                routeName: "",
                detailRouteName: "",
                page: "1",
                language: (await UserProfile.GetClientSettingsAsync()).language,
                detailId: '',
                detailList: false,
                allRecords: true,
                lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                lastViewIdRefreshState: await RefreshServerObjects.getLastGridFishBookRefreshDate()
            }
            await addOrUpdateLocalFishingParentRecord(request.viewId)

            FishingEdit.syncToAsyncFisshingState()

        return true
    }

    public static isFishingOnSameDay() : boolean {
        if ( FishingEdit.fishingEditState.isStarted && FishingEdit.fishingEditState.startDate) {
            const dt = getCurrentDayDate()
            const dtStart = getDateWithoutTime(FishingEdit.fishingEditState.startDate)
            if (dt.getTime() === dtStart.getTime()) return true
            return false
        }
        return true
    }

    public static async stoppFishing() : Promise<boolean> {
        if ( FishingEdit.fishingEditState.startDate === undefined )
            return false            
            FishingEdit.fishingEditState.stoppDate = newDate()
            FishingEdit.fishingEditState.isStarted = false
            await stopLocalFishingParentRecord("fishBookEntriesWithDetail")
            FishingEdit.syncToAsyncFisshingState()
        return true
    }

    public static reset() {
        FishingEdit.fishingEditState.startDate = undefined
        FishingEdit.fishingEditState.stoppDate = undefined

        FishingEdit.fishingEditState.patent = undefined
        FishingEdit.fishingEditState.fischereiRevier = undefined
        FishingEdit.fishingEditState.isStarted = false
        FishingEdit.syncToAsyncFisshingState()
    }

    public static getEditFishingState() : FishingEditState {
        this.handleEditFishingState()
        return FishingEdit.fishingEditState;
    }

    private static handleEditFishingState() : boolean {
        if ( FishingEdit.fishingEditState.startDate === undefined) {
            FishingEdit.fishingEditState.stoppDate = undefined
            FishingEdit.fishingEditState.isStarted = false
            FishingEdit.syncToAsyncFisshingState()
            return false
        }
       
        if ( getDateWithoutTime(FishingEdit.fishingEditState.startDate) < getCurrentDayDate()  ) {
            FishingEdit.fishingEditState.startDate = newDate()
            FishingEdit.fishingEditState.stoppDate = GetEndOfDayDateTime(FishingEdit.fishingEditState.startDate)
            FishingEdit.syncToAsyncFisshingState()
            return true
        }

        return false

    }


    

}