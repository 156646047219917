
import { reactive } from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";

export default {
  name: "UserFishing",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "",
      form: "Benutzer",
      page: "BenutzerFischerei",
      remoteId: "",
    });

    return {
      request
    };
  }
};
