import {
  StateRule,
  StateTransitionOptions,
  StateResult,

  StateNavigationAction,
  Transition,
  // eslint-disable-next-line
  addNavigationElement,
  // eslint-disable-next-line
  addEmptyNavigationElement,
  addPreviousNext,
  // eslint-disable-next-line
  addPrevious,
  // eslint-disable-next-line
  addNext,  
  newTransition,
  newTransitionCondition
} from "../StateInterfaces";
import { NavigatonIcons } from "@/business/application/NavConst";
import {Commands} from "@/business/application/commands/Command";
import { BaseState } from "../StateMachine";
import { StateLogin } from "./StateLogin";
import { StateHome } from "./StateHome";
import { StateProduct } from "./StateProduct";
import { StateWildBookEntry } from "./StateWildBookEntry";
import { StateWildBook } from "./StateWildBook";
import { StateWildBookFall } from "./StateWildBookFall";
import { StateWildBookObserve } from "./StateWildBookObserve";
import { StateWildBookObserveSpec } from "./StateWildBookObserveSpec";
import { Resource } from "@/infrastructure/resource/resource";


export class StateHunting extends BaseState {

  private mainState = "Hunting";
  private startState = "Hunting"
  private static transitions: Transition[] = [];  
  private static callingState : StateResult = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''}
  private isDirectionNext = false

  // Diesen Status setzen wenn es auf den Main-Status zurück geht. 
  private isDirectionPreviousParent = true

  public constructor() {
    super()    
  }


  
  public next(rule: StateRule, options: StateTransitionOptions): StateResult {    
    return this.calculateState(rule, options, false, this.mainState);
  }
  public previous(rule: StateRule,options: StateTransitionOptions): StateResult {    
    return this.calculateState(rule, options, true, this.mainState);
  }

  protected calculateState(rule: StateRule,options: StateTransitionOptions, isPrevious: boolean, mainState: string): StateResult {
    this.isDirectionNext = ! isPrevious
    const context = this.getContext()
    const currentResultState = context.getCurrentResultState();
    
    if ( isPrevious && this.isDirectionPreviousParent) {
      if ( StateHunting.callingState.newMainState === "") {
        StateHunting.callingState.newMainState = "Home"
        StateHunting.callingState.newState = "Home"
        StateHunting.callingState.oldMainState = "Hunting"
        StateHunting.callingState.oldState = "Hunting"
        StateHunting.callingState.routeName = "Home"
      }
      return this.checkResultState("*", StateHunting.callingState);
    }

    let condition = this.calculateBusinessConditions(rule,this.getContext().getCurrentState());
    if ( condition === undefined || condition === null || condition.length === 0 || condition === "") {
     
      if ( currentResultState.newState === "WildBook"  && this.isDirectionNext && condition === "") {
        condition = "WildBookFilter";
      }
     


      if ( currentResultState.newState === "WildBookFilter"  && !this.isDirectionNext && condition === "") {
        condition = "WildBook";
      }
      if ( currentResultState.newState === "WildBook"  && !this.isDirectionNext && condition === "") {
        condition = "Hunting";
      }
    }

    if ( this.isDirectionNext ) {
      const nextTransition = this.getTransitions().filter(row => { 
        if ( row.nav !== StateNavigationAction.Previous && row.condition === condition && condition === "UpdateApp" ) {
          return true
        }
        if ( row.nav !== StateNavigationAction.Previous && row.oldState === context.getCurrentState().newState && row.condition === condition ) {
          return true
        }        
      })
      
      return this.checkState(nextTransition[0], options);
    } else {
      const previosTransition = this.getTransitions().filter(row => { 
        if ( row.nav === StateNavigationAction.Previous && row.oldState === context.getCurrentState().newState && row.condition === condition ) {
          return true
        }
      })
      return this.checkState(previosTransition[0], options);
    }
  }


  protected calculateBusinessConditions(rule: StateRule, resultState: StateResult): string {
    if ( rule.conditions === undefined || rule.conditions === null || rule.conditions.length === 0 ) {
      return "";
    }

    if (rule.conditions.length == 1) {
      return rule.conditions[0].value;
    }

    return "";
  }


  private getCallingState(condition: string) : StateResult {
    if (this.isDirectionPreviousParent) { 
      StateHunting.callingState.newMainState = condition
      StateHunting.callingState.newState = condition
      StateHunting.callingState.routeName = condition
      StateHunting.callingState.oldMainState = this.mainState
      StateHunting.callingState.oldState = this.startState

      return StateHunting.callingState;
    }
     StateHunting.callingState = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''};
     return StateHunting.callingState;
  }

  public getStartTransition(condition: string) : StateResult {
    StateHunting.transitions = []
    this.getTransitions()
    if ( condition !== this.mainState) {
      StateHunting.callingState = this.getCallingState(condition);
    }
    const t = this.getTransitions()[0];
    return this.getTransition(t);
  }



  protected checkState( transition: Transition, options: StateTransitionOptions): StateResult {
    let newMainState = this.mainState    
    let skip = false
    const newResultState: StateResult = this.getTransition(transition)

    if ( this.isDirectionPreviousParent && !this.isDirectionNext) {
      newMainState = StateHunting.callingState.oldMainState
    }
    else {      
      newMainState = transition.newMainState
      if (transition.newState == "")
        skip = true
    }

    if (!options.lookahead && transition.newState != "") {
      return this.changeResultState(transition.condition, transition.newMainState, newResultState);
    }
    return newResultState;
  }

  private checkResultState(condition: string, stateResult: StateResult ) : StateResult {  
    const newResultState = StateHunting.callingState;    
    return this.changeResultState(condition, stateResult.newMainState, newResultState);      
  }

  private changeResultState(condition: string, newMainStatePar: string , compResultState: StateResult) : StateResult {
    let newState: any;
    switch (newMainStatePar) {
        case "WildBookEntry":
          newState = new StateWildBookEntry();
          compResultState = newState.getStartTransition("Hunting")
          this.getContext().transitionTo(newState,compResultState);
          break;
        case "WildBookFall":
          newState = new StateWildBookFall();
          compResultState = newState.getStartTransition("Hunting")
          this.getContext().transitionTo(newState,compResultState);
          break;
        case "WildBookObserve":
          newState = new StateWildBookObserve();
          compResultState = newState.getStartTransition("Hunting")
          this.getContext().transitionTo(newState,compResultState);
          break;
        case "WildBookObserveSpec":
          newState = new StateWildBookObserveSpec();
          compResultState = newState.getStartTransition("Hunting")
          this.getContext().transitionTo(newState,compResultState);
          break;
            
        case "WildBook":
          newState = new StateWildBook();
          compResultState = newState.getStartTransition("Hunting")
          this.getContext().transitionTo(newState,compResultState);
          break;
        case "Home":
        newState = new StateHome();
        compResultState = newState.getStartTransition("Home")
        this.getContext().transitionTo(newState,compResultState);
        break;
        case "Product":
            newState = new StateProduct();
            compResultState = newState.getStartTransition("Hunting")
            this.getContext().transitionTo(newState,compResultState);
            break;          
        case "Login":
          newState = new StateLogin();
          compResultState = newState.getStartTransition("Login")
          this.getContext().transitionTo(newState,compResultState);
          break;      
        default:
          if ( condition === "UpdateApp" ) {
            newState = new StateHunting();
            compResultState = newState.getStartTransition("Home")
            this.getContext().transitionTo(newState,compResultState);
          } else {
            this.getContext().transitionTo(new StateHunting(), compResultState);        
          }
    }
    return compResultState;
  }




  //-- Sammlung aller Statusübergänge
  public getTransitions(): Transition[] {
    if (StateHunting.transitions.length === 0) {

      //__ Start State egal von welcher Stufe aus
      //
      let t: Transition = { mainState: "*", newMainState: this.mainState, nav: StateNavigationAction.Next, oldState: "*", newState: this.startState, condition: "Start"};      
      t = addNavigationElement(t,1,{command: Commands.PreviousPage, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Home",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Home });
      t = addNavigationElement(t,2,{command: Commands.Products, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Produkte",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Products });
      t = addNavigationElement(t,3,{command: Commands.Wildbuch, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Wildbuch",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Wildbuch });
      t = addNavigationElement(t,4,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      StateHunting.transitions.push(t);

      t = { mainState: "Hunting", newMainState: "Product", nav: StateNavigationAction.Next, oldState: "Hunting", newState: "Products", condition: "Products"};      
      t = addPrevious(t, Commands.PreviousPage);
      StateHunting.transitions.push(t);

      t = { mainState: "Hunting", newMainState: "WildBookEntry", nav: StateNavigationAction.Next, oldState: "Hunting", newState: "WildBookEntry", condition: "WildBookEntry"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateHunting.transitions.push(t);

      t = { mainState: "Hunting", newMainState: "WildBookFall", nav: StateNavigationAction.Next, oldState: "Hunting", newState: "WildBookFall", condition: "WildBookFall"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateHunting.transitions.push(t);

      t = { mainState: "Hunting", newMainState: "WildBookObserve", nav: StateNavigationAction.Next, oldState: "Hunting", newState: "WildBookObserve", condition: "WildBookObserve"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateHunting.transitions.push(t);

      t = { mainState: "Hunting", newMainState: "WildBookObserveSpec", nav: StateNavigationAction.Next, oldState: "Hunting", newState: "WildBookObserveSpec", condition: "WildBookObserveSpec"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateHunting.transitions.push(t);


      t = { mainState: "Hunting", newMainState: "WildBook", nav: StateNavigationAction.Next, oldState: "Hunting", newState: "WildBook", condition: "WildBook"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.NextPage);
      StateHunting.transitions.push(t);

  
      //__ Zurück wenn der Main State sich ändert
      //
      t = { mainState: this.mainState, newMainState: "Home", nav: StateNavigationAction.Previous, oldState: this.startState, newState: "Home", condition: "Home"};      
      t = addNavigationElement(t,1,{command: Commands.Person, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Person",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Person });
      t = addEmptyNavigationElement(t,2)
      t = addNavigationElement(t,3,{command: Commands.Settings, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      t = addNavigationElement(t,3,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      StateHunting.transitions.push(t);

      t = { mainState: this.mainState, newMainState: "Login", nav: StateNavigationAction.Previous, oldState: this.startState, newState: "Login", condition: "Login"};      
      t = addEmptyNavigationElement(t,1)
      t = addEmptyNavigationElement(t,2)
      t = addNavigationElement(t,3,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Settings, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StateHunting.transitions.push(t);

      t = { mainState: "*", newMainState: this.mainState, nav: StateNavigationAction.Next, oldState: "*", newState: this.startState, condition: "UpdateApp"};      
      t = addNavigationElement(t,1,{command: Commands.PreviousPage, displayName: "Home", enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Home });
      t = addNavigationElement(t,2,{command: Commands.Products, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Produkte",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Products });
      t = addNavigationElement(t,3,{command: Commands.Wildbuch, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Wildbuch",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Wildbuch });
      t = addNavigationElement(t,4,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      StateHunting.transitions.push(t);      

    }
    return StateHunting.transitions;
  }
}
