import { Resource } from "@/infrastructure/resource/resource";
import {
  StateRule,
  StateTransitionOptions,
  StateResult,

  StateNavigationAction,
  Transition,
  // eslint-disable-next-line
  addNavigationElement,
  // eslint-disable-next-line
  addEmptyNavigationElement,
  addPreviousNext,
  // eslint-disable-next-line
  addPrevious,
  // eslint-disable-next-line
  addNext,  
  newTransition,
  newTransitionCondition
} from "../StateInterfaces";

import { BaseState }  from "../StateMachine";
import { StateHome } from "./StateHome";
import { StateLogin } from "./StateLogin";
import { StateRegisterUser } from "./StateRegisterUser";
import { NavigatonIcons } from "@/business/application/NavConst";
import {Commands} from "@/business/application/commands/Command";

export class StatePasswordEmail extends BaseState {
  private static mainState = "PasswordEmail";
  private static transitions: Transition[] = [];
  private isBackward = false


  public constructor() {
    super()    
   
  }

  public getStartTransition(condition: string) : StateResult {
    StatePasswordEmail.transitions = []
    this.getTransitions()
    const t = this.getTransitions()[0];
    return this.getTransition(t);
  }

  public next(rule: StateRule, options: StateTransitionOptions): StateResult {
    return this.calculateState(
      rule,
      options,
      false,
      StatePasswordEmail.mainState
    );
  }

  public previous(
    rule: StateRule,
    options: StateTransitionOptions
  ): StateResult {
    return this.calculateState(
      rule,
      options,
      true,
      StatePasswordEmail.mainState
    );
  }

  protected checkState(
    transition: Transition,
    options: StateTransitionOptions
  ): StateResult {
    const context = this.getContext()
    let newResultState = this.getTransition(transition);
    let newState: any
    if (!options.lookahead && transition.newState != "") {
      switch (transition.newMainState) {
        case "RegisterUser":
          newState = new StateRegisterUser()
          newResultState = newState.getStartTransition("RegistrationLogin")
          this.getContext().transitionTo(newState,newResultState)      
          break;
        case "Home":
          newState = new StateHome();
          newResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,newResultState)             
          break;
        case "Login":
          newState = new StateLogin();
          newResultState = newState.getStartTransition("Login")
          this.getContext().transitionTo(newState,newResultState)             
          break;
        default:
          context.transitionTo(
            new StatePasswordEmail(),
            newResultState
          );
      }
    }
    return newResultState;
  }

  protected calculateState(
    rule: StateRule,
    options: StateTransitionOptions,
    isPrevious: boolean,
    // eslint-disable-next-line
    mainState: string
  ): StateResult {
    this.isBackward = isPrevious
    const context = this.getContext()
    const condition = this.calculateBusinessConditions( rule, context.getCurrentState());
    const currentState = context.getCurrentState()

    if ( isPrevious) {
      let transition = this.getTransitions().filter(row => {
        if (row.nav === StateNavigationAction.Previous && row.oldState === context.getCurrentState().newState && row.condition === "TargetHome" ) {
          return true;
        }
      })
      if ( transition.length > 0 ) {
        return this.checkState(transition[0], options);
      }
      transition = this.getTransitions().filter(row => {
        if (row.nav === StateNavigationAction.Previous && row.oldState === context.getCurrentState().newState && row.condition === "" ) {
          return true;
        }
      })
      return this.checkState(transition[0], options);
    }

    else {
      const transitionNext = this.getTransitions().filter(row => {
        if (row.nav === StateNavigationAction.Next && row.oldState === context.getCurrentState().newState && row.condition === condition ) {
          return true;
        }
      })
      return this.checkState(transitionNext[0], options);
    }   
  }

  protected calculateBusinessConditions(
    rule: StateRule,
    resultState: StateResult
  ): string {
    if (
      rule.conditions === undefined ||
      rule.conditions === null ||
      rule.conditions.length <= 0
    ) {
      return "";
    }

    if (rule.conditions.length == 1) {
      if ( rule.conditions[0].value === undefined ) {
        return ""
      }
      return rule.conditions[0].value;
    }
    return "";
  }

  public getTransitions(): Transition[] {
    if (StatePasswordEmail.transitions.length === 0) {

      //*****************************************
      //--> Start Request from other Main-State
      //*****************************************
      let t: Transition = { mainState: "RegisterUser", newMainState: "PasswordEmail", nav: StateNavigationAction.Next, oldState: "RegisterUserLogin", newState: "WaitForEmailConfirm", condition: "RegisterUser"};      
      t = addPreviousNext(t,Commands.PreviousPage, Commands.RulePasswordEmailRequest);
      StatePasswordEmail.transitions.push(t);

      t = { mainState: "Login", newMainState: "PasswordEmail", nav: StateNavigationAction.Next, oldState: "Login", newState: "WaitForEmailConfirm", condition: "Login"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RulePasswordEmailRequest);
      StatePasswordEmail.transitions.push(t);

      t = { mainState: "Login", newMainState: "PasswordEmail", nav: StateNavigationAction.Next, oldState: "WaitForEmailConfirm", newState: "PasswordEmail", condition: "RulePasswordEmailRequest"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RulePasswordEmail);
      StatePasswordEmail.transitions.push(t);

      t = { mainState: "Login", newMainState: "PasswordEmail", nav: StateNavigationAction.Next, oldState: "WaitForEmailConfirm", newState: "PasswordEmail", condition: "PasswordEmail"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RulePasswordEmail);
      StatePasswordEmail.transitions.push(t);



      t = { mainState: "Login", newMainState: "PasswordEmail", nav: StateNavigationAction.Next, oldState: "Login", newState: "WaitForEmailConfirm", condition: "Home"};      
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RulePasswordEmailRequest);
      StatePasswordEmail.transitions.push(t);

     

      //*****************************************
      //--> Wizzard vorward
      //*****************************************
      t = newTransitionCondition("PasswordEmail","WaitForEmailConfirm", "PasswordEmail" , "")
      t = addPreviousNext(t, Commands.PreviousPage, Commands.RulePasswordEmail);
      StatePasswordEmail.transitions.push(t);

      t = { mainState: "PasswordEmail", newMainState: "Login", nav: StateNavigationAction.Next, oldState: "PasswordEmail", newState: "Login", condition: "RulePassword"};      
      t = addNext(t, Commands.RulePasswordEmail)
      StatePasswordEmail.transitions.push(t);


      t = { mainState: "PasswordEmail", newMainState: "Login", nav: StateNavigationAction.Next, oldState: "PasswordEmail", newState: "Login", condition: "RuleLoginPassword"};      
      t = addNext(t, Commands.RulePasswordEmail)
      StatePasswordEmail.transitions.push(t);

      t = { mainState: "PasswordEmail", newMainState: "Login", nav: StateNavigationAction.Next, oldState: "PasswordEmail", newState: "Login", condition: "PasswordEmail"};      
      t = addNext(t, Commands.RulePasswordEmail)
      StatePasswordEmail.transitions.push(t);

      //*****************************************
      //--> Wizzard backward
      //*****************************************
      t = newTransitionCondition("PasswordEmail","PasswordEmail", "WaitForEmailConfirm", "", StateNavigationAction.Previous)
      t = addPreviousNext(t,Commands.PreviousPage, Commands.RulePasswordEmailRequest);
      StatePasswordEmail.transitions.push(t);

      t = { mainState: "PasswordEmail", newMainState: "Login", nav: StateNavigationAction.Previous, oldState: "WaitForEmailConfirm", newState: "Login", condition: "TargetLogin"};  
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StatePasswordEmail.transitions.push(t);

      t = { mainState: "PasswordEmail", newMainState: "Login", nav: StateNavigationAction.Previous, oldState: "WaitForEmailConfirm", newState: "Login", condition: "TargetHome"};      
      t = addEmptyNavigationElement(t,1);
      t = addEmptyNavigationElement(t,2);
      t = addNavigationElement(t,3,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
      t = addNavigationElement(t,4,{command: Commands.Jump, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
      StatePasswordEmail.transitions.push(t);


    }
    return StatePasswordEmail.transitions;
  }
}
