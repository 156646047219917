import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-0 eFj-list-content-header-switch" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "form-check-label form-check-toggle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onSwitchChange()), ["prevent"])),
        class: "form-check"
      }, [
        _createElementVNode("label", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input",
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.isActive) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.filter.isActive]
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.filterText.title), 1)
        ])
      ])
    ])
  ]))
}