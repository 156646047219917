import { RouteRecordName, RouteRecordNormalized } from "vue-router";


export class RouterUiControl
{
    
    public static ControlWaitForResponse(routes: RouteRecordNormalized[], routeFrom: RouteRecordName | null | undefined,  routeTo: RouteRecordName | null | undefined, store: any ) : boolean {

        if ( routeFrom === undefined &&  routeTo === undefined)
            return false

        for( const route of routes ) {
            if ( route.name === routeFrom || route.name === routeTo) {
                return true
            }
        }

        return false;
    }
}

