import "@babel/polyfill";
import "mutationobserver-shim";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App);
app.use(store).use(router).use(Vue3TouchEvents).mount("#app");

