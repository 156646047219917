import { Observable } from "rxjs";
import { StateService } from "../observables/stateService";

export interface RefreshLoginState {   
    refresh: boolean;
  }
  
const initialState: RefreshLoginState = {   
    refresh: false
  };



export class RefreshLoginWhenResourceLoaded extends StateService<RefreshLoginState>{
    public static service: RefreshLoginWhenResourceLoaded;

    public static getRefreshLoginService() : RefreshLoginWhenResourceLoaded {
        if ( RefreshLoginWhenResourceLoaded.service === undefined) {
            RefreshLoginWhenResourceLoaded.service = new RefreshLoginWhenResourceLoaded();
            this.service = RefreshLoginWhenResourceLoaded.service
        }
        return RefreshLoginWhenResourceLoaded.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<RefreshLoginState> {
        return this.subscribe();
    }

    public refreshLoginState(refresh: RefreshLoginState) : void {
        this.changeState(refresh);
    }

    public get loginState(): RefreshLoginState {
        return this.state;
    }
}