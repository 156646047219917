import { Observable } from "rxjs";
import { StateService } from "./stateService";

export interface MessageBus {
    topic: string;
    source: string;
    jsonPayload: string;
  }
  
const initialState: MessageBus = {
    topic: "",
    source: "",
    jsonPayload: "",
  };


export class MessageBusServiceForRoot extends StateService<MessageBus>{
    public static service: MessageBusServiceForRoot;

    public static getMessageStateService() : MessageBusServiceForRoot {
        if ( MessageBusServiceForRoot.service === undefined) {
            MessageBusServiceForRoot.service = new MessageBusServiceForRoot();
            this.service = MessageBusServiceForRoot.service
        }
        return MessageBusServiceForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<MessageBus> {
        return this.subscribe();
    }

    public changeMessageState(error: MessageBus) : void {
        this.changeState(error);
    }

    public get state(): MessageBus {
        return this.state;
    }
}