import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";

export enum GeoStateListeningAction {
    OnDragEnded,
    OnEdited,
    OnReadOnly,
    OnCoordinateChangedManually
}

export interface GeoStateListening {
    action: GeoStateListeningAction | undefined
    wgs84X: number | undefined
    wgs84Y: number | undefined
    lv95X: number | undefined
    lv95Y: number | undefined
    isInsidePoligon: boolean | undefined
    revierNumber: string | undefined
    revierTyp: string | undefined
    editActivated: boolean | undefined
}


export class GeoViewListener extends StateService<GeoStateListening>{
    public static service: GeoViewListener;

    public static getService() : GeoViewListener {
        if (!GeoViewListener.service) {
            GeoViewListener.service = new GeoViewListener();
            this.service = GeoViewListener.service
        }
        return GeoViewListener.service;
    }

    private constructor() {
        super({action: undefined, wgs84X: undefined, wgs84Y: undefined, lv95X: undefined, lv95Y: undefined, isInsidePoligon: undefined, revierNumber: undefined, revierTyp: undefined, editActivated: undefined});       
    }   
    
    public subscription(): Observable<GeoStateListening> {
        return this.subscribe();
    }

    public geoChanged(geoState: GeoStateListening) : void {               
        this.changeState(geoState);
    }

    public get geoState(): GeoStateListening {
        return this.state;
    }
}