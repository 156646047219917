import { StateService } from '../../../../infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { DynamicRecord } from '../DynamicFormData';


export interface DynamicUIFilterState {
    showFilter: boolean | undefined
    attachToForm: string | undefined    
    rows: DynamicRecord[] | undefined
}

export function createIntitialFilterState(attachToForm: string | undefined) : DynamicUIFilterState {
    return {
        showFilter: false,
        attachToForm: attachToForm,
        rows: undefined
      };
}

export class DynamicFilterFormStateForRoot extends StateService<DynamicUIFilterState>{
    public static service: DynamicFilterFormStateForRoot;
    private static attachedForm: string | undefined = undefined

    public static getService(attachTo: string | undefined) : DynamicFilterFormStateForRoot {
        if (!DynamicFilterFormStateForRoot.service) {
            
            DynamicFilterFormStateForRoot.service = new DynamicFilterFormStateForRoot(attachTo);
            this.service = DynamicFilterFormStateForRoot.service
        }
        DynamicFilterFormStateForRoot.attachedForm = attachTo
        return DynamicFilterFormStateForRoot.service;
    }

    private constructor(attachTo: string | undefined) {
        super(createIntitialFilterState(attachTo));
        DynamicFilterFormStateForRoot.attachedForm = attachTo
    }
    
    public subscription(): Observable<DynamicUIFilterState> {
        return this.subscribe();
    }

    public changeFilterState(formState: DynamicUIFilterState) : void {               
        this.changeState(formState);
    }

    public isAttached(attachedTo: string | undefined): boolean {
        if ( attachedTo === undefined || DynamicFilterFormStateForRoot.attachedForm === undefined )  return false   
        if ( DynamicFilterFormStateForRoot.attachedForm === "Wildbuch" && attachedTo === "WildBookFilter" ) return true
        if ( DynamicFilterFormStateForRoot.attachedForm === "Fischbuch" && attachedTo === "FishBookFilter" ) return true
        if ( DynamicFilterFormStateForRoot.attachedForm === "WebShop" && attachedTo === "WebShopFilter" ) return true
        if ( DynamicFilterFormStateForRoot.attachedForm === "WebShopFilter" && attachedTo === "WebShopFilter" ) return true
        if ( DynamicFilterFormStateForRoot.attachedForm !== attachedTo ) return false
        return true
    }

    public get filterState(): DynamicUIFilterState {
        return this.state;
    }
}