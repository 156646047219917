import { StateService } from "@/infrastructure/observables/stateService";
import { Observable } from "rxjs";

export enum GeoStateAction {
    ChangeRevierAndType,
    ChangeCurrentGpsPosition,
    ChangePosition,
    ChangeToDefaultPosition,
    ChangeEditMode,    
    SetPositionOnly,
    ChangeData,
    None,
    SetEditMode
}

export interface GeoMapStateStore {
    longitude: number | undefined
    latitude: number | undefined
    editActice: boolean |  undefined
    longitudeSouthWest: number | undefined
    latitudeSouthWest: number | undefined
    longitudeNorthEast: number | undefined
    latitudeNorthEast: number | undefined

}

export interface GeoState {
    action: GeoStateAction | undefined
    wgs84X: number | undefined
    wgs84Y: number | undefined
    revierNumber: string | undefined
    typ: string | undefined    
    lastState: boolean | undefined
    isEditable: boolean | undefined
}


export class GeoView extends StateService<GeoState>{
    public static service: GeoView;
    public static geoMapStateStore: GeoMapStateStore = {
        longitude: undefined,
        latitude: undefined,
        editActice: undefined,
        longitudeSouthWest: undefined,
        latitudeSouthWest: undefined,
        longitudeNorthEast: undefined,
        latitudeNorthEast: undefined
    };     
    

    public static getService() : GeoView {
        if (!GeoView.service) {
            GeoView.service = new GeoView();
            this.service = GeoView.service
        }
        return GeoView.service;
    }

    private constructor() {
        super({action: undefined, wgs84X: undefined, wgs84Y: undefined, revierNumber: undefined, typ: undefined, lastState: undefined, isEditable: undefined});       
    }
    
    public subscription(): Observable<GeoState> {
        return this.subscribe();
    }

    public changeGeoState(geoState: GeoState) : void {              
        this.changeState(geoState);
    }

    public reset() {
        this.changeState({action: undefined, wgs84X: undefined, wgs84Y: undefined, revierNumber: undefined, typ: undefined, lastState: undefined, isEditable: undefined})
    }

    public getGeoMapStateStore() : GeoMapStateStore  {
        return GeoView.geoMapStateStore
    }
    public changeGeoMapStateStore(stateStore: GeoMapStateStore) : GeoMapStateStore | undefined {
        return GeoView.geoMapStateStore = stateStore
    }

    public get geoState(): GeoState {
        return this.state;
    }
}