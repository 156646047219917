
import { reactive} from "vue";
import DynamicFilterForm from "@/components/Shared/Dynamicform/DynamicFilterForm.vue";

export default {
  name: "WebShopFilter",
  inheritAttrs: false,
  components: {
    DynamicFilterForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "api/Form/loadForm",
      apiSaveMethod: "",
      form: "WebShop",
      page: "WebShopFilter",
      remoteId: "",
      mode: "DynamicForm",
      routeName: "WebShop",
      filter: "WebShopFilter"
    });
    return {
      request
    };
  }
};
