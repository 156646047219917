
  
    import { onUnmounted, reactive, ref} from "vue";
    import { PaymentCancelService } from "@/hooks/PaymentCancelService";
    import { Resource } from "@/infrastructure/resource/resource";

  
    
    export default {
      name: "WebshopPaymentNavBar",
      inheritAttrs: false,
      components: {},
    
      setup() {
        
        
        const content = ref("")
        const buttonText = ref("")

        const cancelService = PaymentCancelService.getService();
        function stopPayment() {
          cancelService.cancelStateChanged({ IsCanceled: true });
          cancelService.cancelStateChanged({ IsCanceled: undefined });
        }

        async function load() {
          content.value = await Resource.getResourceText(`WebShop.Navbar.Content`)
          buttonText.value = await Resource.getResourceText(`WebShop.Navbar.ButtonText`)
        }

        load()

        return {
          content,
          buttonText,
          stopPayment
        };
      },
    };
    