import { Command } from "../Command";
import { DynamicDataSet, DynamicFormData} from "@/components/Shared/Dynamicform/DynamicFormData";


export class RuleUserProfileShowPasswordEmail extends Command {
  public execute() {
    const fieldsShown = ["Space_Password1","Email","Space_Password2","Titel_Password","Space_Password3","Password","PasswortBestaetigung","RuleUserProfileSavePasswordEmail"]
    this.dynamicForm.showHideFields(this.dataSet, fieldsShown , true);    

    const fieldsHidden = ["RuleUserProfileShowPasswordEmail", "Anrede", "Geburtsdatum"]
    this.dynamicForm.showHideFields(this.dataSet, fieldsHidden , false);    
  }
  private dynamicForm: DynamicFormData;  
  private dataSet: DynamicDataSet;
  

  constructor(dynamicForm: DynamicFormData, dataSet: DynamicDataSet ) {
    super();
    this.dynamicForm = dynamicForm;   
    this.dataSet = dataSet;
  }
 
}
