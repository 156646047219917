
    import { onMounted, onUnmounted, ref, reactive, defineComponent, computed } from "vue";
    import { useStore } from "vuex";  
    import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
    import { StateNavigationAction } from "@/business/application/statemachine/StateInterfaces";
    import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
    import { Commands } from "@/business/application/commands/Command"; 
    import { Identity } from "@/infrastructure/backend/userIdentity";
    import router from "@/router";
    import { DynamicListToDetailFormChannelForRoot} from "@/components/Shared/dynamicList/Hooks/DynamicListToDetailFormChannel"
    import { Resource } from "@/infrastructure/resource/resource";  
    import { DynamicDetailListParentListChannelForRoot} from "@/components/Shared/dynamicListDetailList/Hooks/DynamicDetailListParentListToDatailListChannel"
    import { DynamicGrid, DynamicGridRequest, DynamicGridRow, SyncStateRequest, SyncStateResponse } from "@/components/Shared/dynamicList/DynamicListTypes"
    import { useRoute } from "vue-router";
    import { AttachDynamicFilterChannelForRoot } from '@/components/Shared/dynamicList/filter/hooks/AttachDynamicFilterChannelForRoot';
    import { ApplyDynamicFilterChannelForRoot } from '@/components/Shared/dynamicList/filter/hooks/ApplyDynamicFilterChannel';

    import {sanitize} from "@/infrastructure/functional/sanitize"
    import { LocalStore } from "@/infrastructure/local-api/LocalStore"

    import { openDialog } from "@/infrastructure/observables/ModalDialogNotification";
    import { UserProfile } from "@/infrastructure/backend/userProfile";
    import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";

    import { Out } from "@/infrastructure/frontent/clientMessage";
    import { DynamicNavigation } from "@/components/Shared/dynamicNavigation/DynamicNavigation";

    import webShopListBase from "./WebShopListBase.vue";
    import { RoutedButtonBarCleanupChannelForRoot } from "../Shared/routed-button-bar/RoutedButtonBarCleanupChannel";
    import { RoutedButtonBarResponseChannelForRoot } from "../Shared/routed-button-bar/RoutedButtonResponseChannel";
    import { DynamicTriggerSaveFormForRoot } from "../Shared/Dynamicform/hooks/TriggerSaveForm";
    import { DynamicFilterFormStateForRoot } from "../Shared/Dynamicform/hooks/FilterForm";
    import { DynamicRecord } from "../Shared/Dynamicform/DynamicFormData";
    import { DynamicSaveFormForRoot } from "../Shared/Dynamicform/hooks/SaveFormData";
    import { NotifyDynamicList } from "../Shared/dynamicNavigation/Hooks/NotifyDynamicList";
    import { DynamicLoadFormData, DynamicLoadFromListData } from "../Shared/Dynamicform/DynamicLoadFromListData";
    import { DynamicListStore } from "../Shared/dynamicList/DynamicListStore";
    import { DetailsSupported } from "../Shared/dynamicList/Hooks/DynamicDetailTypes";
    import { DynamicDetailToFormChannelForRoot, createIntitialFormDetailState } from "../Shared/dynamicList/Hooks/DynamicDetailFormToListChannel";
    import { DynamicDetailListChannelForRoot, createIntitialListDetailState } from "../Shared/dynamicListDetailList/Hooks/DynamicDetailListToParentListChannel";
import { RemoteProdukt } from "./RemoteProdukt";
import { ConvertDateToIsoString } from "@/infrastructure/functional/datetimehelper";
import { BusinessLogicJahresWechsel } from "./BusinessLogicJahresWechsel";
import { RefreshServerObjects } from "@/infrastructure/resource/RefreshServerObjects";
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";

  export default defineComponent ({
    name: "DynamicList",
    inheritAttrs: false,
    components: {        
    },
    props: {
    },
    setup(props) {
      
      const store = useStore();
      let unsubscribe: any = {};
      
      const route=useRoute();    
      const clientRouteName = computed(() =>route.name?.toString())

      const webShopProps = reactive({
        ViewId: "webShopEntriesWithDetail",
        NavigationState: "WebShop",
        DetailId: "webShopDetailRecord",
        RevierNr: "",
        PageSize: 5,
        TitleResourceId: "WebShop.DetailListe.Titel",
        Title: "",
        Filter: "WebShopFilter",
        DetailList: false,
        RouteName: "WebShop",
        DetailRouteName: "WebShopDetailRecord",
        Form: "WebShop",
        Page: "WebShopDaten",     
        Synced: true  
      })

      const stateMachine = StateMachineServiceLocator.get();
      const currentState = stateMachine.getCurrentResultState();
      
      const toDetailFormNotfication = DynamicListToDetailFormChannelForRoot.getService();
      const onDetailFormNotfication = DynamicDetailToFormChannelForRoot.getService();
    
      let applyFilter:ApplyDynamicFilterChannelForRoot = ApplyDynamicFilterChannelForRoot.getService()
    
      const uIDynamicFilterFormService = DynamicFilterFormStateForRoot.getService(webShopProps.Filter)
      const routedButtonBarCleanup = RoutedButtonBarCleanupChannelForRoot.getService()
      const routeButtonBarResponse = RoutedButtonBarResponseChannelForRoot.getService()

      const triggerForm =  DynamicTriggerSaveFormForRoot.getService(webShopProps.Filter)
      const loaderState = LoaderStateServiceForRoot.getLoaderStateService()

    

      const request: DynamicGridRequest = {
        userId: Identity.getIdentity().getValue().UserId, 
        viewId: webShopProps.ViewId , 
        detailId: "",
        activeData: true,
        pageSize: undefined,
        revierNr: undefined,
        detailList: false,
        routeName: undefined,
        detailRouteName: undefined,
        page: webShopProps.Page,
        form: webShopProps.Form,
        language: UserProfile.GetClientSettingsSync().language,
        allRecords: false,
        records: undefined,
        remoteId: "",
        lastRefreshDate: RefreshServerObjects.getLastGridFormRefreshDateSync(),
        lastViewIdRefreshState: RefreshServerObjects.getLastGridFormRefreshDateSync(),
      }

      const formRecords: DynamicRecord[] = [{
          teilFormularName: "",
          formularFeldname: "",
          feldTyp: "",
          istEditierbar: false,
          istPflichtfeld: false,
          istSichtbar: false,
          maxLaenge: 0,
          resourceId: "",
          teilFormularReihenfolge: 0,
          regulaererAusdruck: "",
          resourceIdErrorMeldungPflichtfeld: "",
          resourceIdErrorMeldungValidierung: "",
          resourceIdErrorMeldungMaxLaenge: "",
          layoutKlasse: "",
          isValidationError: false,
          validationMessage: "",
          value: undefined,
          tabellenFeldname: "",
          tabellenName: "",
          infoTextTitle: undefined,
          infoTextContent: undefined,
          infoResourceId: undefined,
          parentCascadeId: undefined
          }]

      const dynamicDetail = reactive({        
          apiLoadMethod: "",
          apiSaveMethod: "",
          form: "FischbuchEintragPacht",
          page: "FischbuchEintragPachtDaten",
          remoteId: "",
          loadErrorTitle: "",
          loadErrorMessage: "",
          detailData: {},
          hiddenFields: "",
          parentId: "",
          records: formRecords,
          routeName: "",    
          filter: "",
          mode: "DynamicNavigation",
          geoEditMode: "change",
          geoGpsMode: "Allowed",
          geoDisplayClass: "small",
          geoCheckInsidePolygon: true

      })


      const dynamic = webShopListBase(request)
      const listVisible = ref(true);
      const filterVisible = ref(false)
      const dynamicFilterVisible = ref(false)
     

      function syncSupportedAdjustTitle() {
        return "col-8 eFj-list-card-text"
      }
  
      function isListVisible() {
        if (listVisible.value && !dynamicFilterVisible.value) {
          return "show__div";
        }
        return "hide__div";
      }



      function isNotListVisibleAndDetailFormVisible() {
        if( filterVisible.value ) {
          return "hide__div";
        }

        if (listVisible.value) {
          return "hide__div";
        }
        
        return "show__div"
      }

      function isNotListVisible() {
        if (!listVisible.value && !dynamicFilterVisible.value && !filterVisible.value) {
          return "show__div";
        }
        return "hide__div";
      }

      function isFilterVisible() {
        if (filterVisible.value) {
          return "row g-0 eFj-list-content-header-filter show__div";
        }
        return "row g-0 eFj-list-content-header-filter hide__div";
      }
    
    

      async function load () {       
        request.language = (await (UserProfile.GetClientSettingsAsync())).language   
        webShopProps.Title = await Resource.getResourceText(webShopProps.TitleResourceId)
 
        request.language = (await (UserProfile.GetClientSettingsAsync())).language   
        LoaderStateServiceForRoot.lock = true
        loaderState.changeLoaderState({isInProgress: true})
        try {
          await dynamic.getDynamicGridData(request, undefined)
        } catch(e) {
            Out.noOperation("")
        }

        LoaderStateServiceForRoot.lock = false
        loaderState.changeLoaderState({isInProgress: false})   
      }

      async function loadWithFilter(rows: Array<DynamicRecord>) {
        request.language = (await (UserProfile.GetClientSettingsAsync())).language   
        if (!rows) return
        if( rows.length <= 1) return

        LoaderStateServiceForRoot.lock = true
        loaderState.changeLoaderState({isInProgress: true})
        try {
          await dynamic.getDynamicGridData(request, rows)
        } catch(e) {
          Out.noOperation("")
        }
        LoaderStateServiceForRoot.lock = false
        loaderState.changeLoaderState({isInProgress: false})
      }
  
          
      load()  
      
  
      async function openDetail( record: DynamicGridRow) {
        const userProfile = new UserProfile()
        if( ! (await (userProfile.GetProfileData())).online) {
          const offlineTextTitle = await Resource.getResourceText(`Offline.NotAllowed.Title`)
          const offlineTextContent = await Resource.getResourceText(`Offline.NotAllowed.Content`)
          const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
          notficationState.changeNotificationState({isNotification: true, text:  offlineTextContent, title: offlineTextTitle})
          return
        }
          openDetailForm(record)
      }

      //---------------------- Filtersteuerung ----------------------
      if ( webShopProps.Filter && webShopProps.Filter.length > 0 ) {     
         filterVisible.value = true   
         router.push({name: webShopProps.Filter})
         Out.pusRouterOut(`DynamicList.Filter => RouteName: ${webShopProps.Filter}` )
      }

      //__ Dynamischen Formularfilter anzeigen verbergen
      const onUiDynamicFilterForm = uIDynamicFilterFormService.subscription().subscribe({
        next: (value) => {
          if ( value.showFilter !== undefined && uIDynamicFilterFormService.isAttached(webShopProps.Filter)) {
            filterVisible.value = true   
            router.push({name: webShopProps.Filter})
            listVisible.value = !value.showFilter 
            dynamicFilterVisible.value = value.showFilter           
          }
        }
      })

      const saveForm = DynamicSaveFormForRoot.getService(webShopProps.Filter)
      const onSaveForm = saveForm.subscription().subscribe({
        next: (value) => {
          if (saveForm.isAttached(webShopProps.Filter) ) {            
            if ( value.rows !== undefined) loadWithFilter( value.rows )
          }
        }
      })
      
      //---------------------- Navigation Element (Zurück Button) ----------------------
      const onRouteButtonBarResponse = routeButtonBarResponse.subscription().subscribe({
        next: (value) => {
          if ( value.targetRoute !== undefined && value.topic === webShopProps.Filter )
          {
            if ( value.command === "Save") {
              triggerForm.changeFormState({formName: webShopProps.Filter, trigger: true})                                        
            }

            if ( value.command === "Save" || value.command === "Back") {
              setTimeout( () => { onNavigationClosed() } , 0)
            }
           
          }
        }
      })      

      async function onNavigationClosed() {                   
        routedButtonBarCleanup.changeRouteState({targetRoutes: {backTargetRoute: webShopProps.RouteName , vorwardTargetRoute: webShopProps.RouteName} })        
        filterVisible.value = true
        listVisible.value = true;               
        router.push({name: webShopProps.Filter})  
        Out.pusRouterOut(`DynamicList.onNavigationClosed => RouteName: ${webShopProps.Filter}` )

      }	  

      //--- Nachricht von der Klasse DanymicNavigation (Proxy) um das Formular zu verbergen
      const notifyDynamicList = NotifyDynamicList.getService()
      const onNotifyDynamicList = notifyDynamicList.subscription().subscribe({
        next: (value) => {
          if ( ! value ) return
          if ( value.isClosed ) closeDetail()          
        }
      })
      


      //---------------------- Detailform zur Dateneingabe ----------------------
      async function openDetailForm(record: DynamicGridRow) {        
          listVisible.value = false
          filterVisible.value = false
            if ( record.detailForm) {
             
              const dynamicDetailData: DynamicLoadFormData = {
                form: "WebShop",
                page: "WebShopDaten",
                filter: "",
                route: clientRouteName.value ?? "",
                mode: "DynamicNavigation",
                geoDisplayClass: "small",
                records: record.detailForm?.view,
                remoteId: record.id ?? ""
              }

              if ( record && record.detailForm && record.detailForm.view) {
                for (const row of record.detailForm.view) {
                  if( row.formularFeldname === "ReferenzJahr") {
                    try {
                      row.value = BusinessLogicJahresWechsel.getReferenzDatum().substring(0,4)
                    } catch(e) {
                      Out.noOperation("")
                    } 
                    
                  }
                }
              }
              const temp = await RemoteProdukt.setDetails(record.detailForm?.view )
              if (temp) {
                record.detailForm.view = temp as unknown as DynamicRecord[]
                dynamicDetailData.records = temp as unknown as DynamicRecord[]
              }

              DynamicLoadFromListData.storeDynamicData(dynamicDetailData)
              DynamicListStore.SetSelectedGridRow(record)
            }

            const detail = webShopProps.Page
            router.push({name: detail})
            Out.pusRouterOut(`DynamicList.dynamicDetail => RouteName: ${detail}` )
            DynamicNavigation.start("WebShop", detail, "IconNavigationBar")           
            return
      }

     


      const onDetailFormSubscription = onDetailFormNotfication.subscription().subscribe({
        next: (value) => {        
          if ( onDetailFormNotfication.isAttached(clientRouteName.value as unknown as DetailsSupported )) {  
           if (value.isClosed) {
            listVisible.value = true  
            filterVisible.value = true                   
            router.push({name: webShopProps.Filter})
            Out.pusRouterOut(`DynamicList.onDetailFormSubscription => RouteName: ${webShopProps.Filter}` )
           }
          }
        }
      })

      function closeDetail() {
          listVisible.value = true;   
          filterVisible.value = true
          router.push({name: webShopProps.Filter})   
          Out.pusRouterOut(`DynamicList.closeDetail => RouteName: ${webShopProps.Filter}` )   
      }

      function getImagePath( record: DynamicGridRow) {         
          return record.cell.image.imagePath ?? ''
      }

      function getImageStatusText( record: DynamicGridRow) {
          return record.cell.additional.text ?? ""
      }

      function getImageState( record: DynamicGridRow) {  
        return record.cell.image.imageState ?? ""
      }

      function getCellTitle( record: DynamicGridRow) {
        if (record?.cell?.content?.title )
          return sanitize(record.cell.content.title)
      }
    
      async function navigate(nav: any) {
        if ( ! isListVisible()) {
          closeDetail()
          return
        }
        let isNavigation = true;
        let isNext = true;
        const navigation = StateNavigationAction.Next;
        const conditions = [];        
      
        switch (nav.command) {
          

          case Commands.Information.toString(): {
            isNext = true;
            isNavigation = false;
            let title = await Resource.getResourceText("InfoText.WebShop.Title")
            let content = await Resource.getResourceText("InfoText.WebShop.Content")
            openDialog(store, {name: "Information", isOk: true, isCancel: false, titel: title, text: content, open: true, type: "info"})
            break
          }

          case Commands.PreviousPage.toString(): {
            isNext = false;
            conditions.push({ nameId: webShopProps.NavigationState, value: "BackToParent" });
            break;
          }
        }
  
        if (isNavigation) {
          if (isNext) {

            const userProfile = new UserProfile()
            if( ! (await (userProfile.GetProfileData())).online) {
              const offlineTextTitle = await Resource.getResourceText(`Offline.NotAllowed.Title`)
              const offlineTextContent = await Resource.getResourceText(`Offline.NotAllowed.Content`)
              const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
              notficationState.changeNotificationState({isNotification: true, text:  offlineTextContent, title: offlineTextTitle})
              return
            }


            stateMachine.next({navigation: navigation,conditions: conditions,},{lookahead: false,});
            changeNavigation(store);
            return 
          }
          stateMachine.previous({navigation: navigation,conditions: conditions,},{lookahead: false,});
          changeNavigation(store);
        }
      }
      
      onMounted(() => {        
        if ( !DynamicNavigation.isNavigationEnabled()) {
          if (currentState.navigation) {
            try {
              DynamicNavigation.saveLastStaticNavigationState([currentState.navigation?.nav1,currentState.navigation?.nav2,currentState.navigation?.nav3,currentState.navigation?.nav4])
            } catch(e) {
              Out.noOperation("")
            }
          }

        }

        if (!DynamicNavigation.isAssigned("DynamicList")) return       
        
        LocalStore.DisableFormNavigationOnce()
        unsubscribe = store.subscribe((mutation) => {
          if (mutation.type === "navigationState/clickNavigationItem1") {
            navigate(currentState.navigation?.nav1);
          }
          if (mutation.type === "navigationState/clickNavigationItem2") {
            navigate(currentState.navigation?.nav2);
          }
          if (mutation.type === "navigationState/clickNavigationItem3") {
            navigate(currentState.navigation?.nav3);
          }
          if (mutation.type === "navigationState/clickNavigationItem4") {
            navigate(currentState.navigation?.nav4);
          }
        });
      });
  
      onUnmounted(() => {
        
        LocalStore.EnableFormNavigationOnce()
        
        applyFilter.changeFilterState( { showActiveData: true, attachTo: undefined })
        saveForm.changeFormState({attachTo: undefined, formName: "", rows: undefined })
        onDetailFormNotfication.changeDetailState( createIntitialFormDetailState() )
        

        onDetailFormSubscription.unsubscribe()
        onUiDynamicFilterForm.unsubscribe()
        onRouteButtonBarResponse.unsubscribe()
        onSaveForm.unsubscribe()
        onNotifyDynamicList.unsubscribe()

        triggerForm.changeFormState({formName: '', trigger: false})

        unsubscribe();
      });
  
      return {             
        dynamic,        
        listVisible,
        webShopProps,
        request,
        filterVisible,
        dynamicFilterVisible,
        syncSupportedAdjustTitle,
        isListVisible,
        openDetail,
        closeDetail,       
        getImagePath,
        getImageState ,
        getImageStatusText,
        getCellTitle,
        isFilterVisible,
        isNotListVisible,
        isNotListVisibleAndDetailFormVisible,
        dynamicDetail,
        clientRouteName,
      };
    },
  });
