
  import { onMounted, onUnmounted, reactive, } from "vue";
  import { useStore } from "vuex";
  
  import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
  import { StateNavigationAction } from "@/business/application/statemachine/StateInterfaces";
  import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
  import { Commands } from "@/business/application/commands/Command";
  import { StateWebShop } from '@/business/application/statemachine/concrete/StateWebShop';
  import { DynamicNavigation } from "@/components/Shared/dynamicNavigation/DynamicNavigation";
  import { Out } from "@/infrastructure/frontent/clientMessage";
  import { LocalWarenkorb, ShoppingCardLocal } from "./LocalWarenkorb";
  import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
  import { RemoteWarenKorb } from "./RemoteWarenKorb";
  import { RemoteZahlung } from "./RemoteZahlung";
  import { Result } from "@/infrastructure/functional/result";
  import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
  import LoaderPopup from "@/components/Shared/LoaderPopup.vue";
  import { AppState } from "@/AppState";
  import { Resource } from "@/infrastructure/resource/resource";
  import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
import { interval } from "rxjs";
import { BusinessZahlungAusgefuehrt } from "./BusinessZahlungAusgefuehrt";

  
  export default {
    name: "WebshopSuccess",
    inheritAttrs: false,
    components: {
     LoaderPopup
    },
  
    setup() {
      const store = useStore();
      DynamicNavigation.reset()
      let unsubscribe:any = {};
  
      let card: ShoppingCardLocal = {
        artificalId: "",
        id: "",
        language: "",
        autoConfirmationEnabled: false,
        currency: "",
        customer: {
        userId: ""
        },
        shoppingCardLineItems: undefined,
        postFinanceScriptReference: "",
        transactionId: "",
        transactionReference: "",
        amountIncludeTax: "",
        brand: "",
        providerStatus: "",
        transferDatum: "",
        errorResourceId: ""
      }

      const cardResult = reactive({
        shoppingCard: card
      })
      
      const loaderParameter = reactive({
      isInProgress: true
    })

    
    const request = reactive({
      title: "",
      anbieter: "",
      betrag: ""
    })

    const zahlungsStatus: string  =  BusinessZahlungAusgefuehrt.ZahlungsStatus.State
    const backGroundZahlung = reactive({ 
      state: zahlungsStatus
    })

    BusinessZahlungAusgefuehrt.Reset()

    BusinessZahlungAusgefuehrt.HideWebShopNavbar()
    
      async function load() {
        loaderParameter.isInProgress = true
        request.title = await Resource.getResourceText("WebShop.Success.Title")
        request.anbieter = await Resource.getResourceText("WebShop.Success.Anbieter")
        request.betrag = await Resource.getResourceText("WebShop.Success.Betrag")
        AppState.getAppState()
        let card = await LocalWarenkorb.GetShoppingCard()
        if (card && card.id && card.id.length > 0) {
          cardResult.shoppingCard = card
           card = await RemoteZahlung.acceptZahlung()
           if ( card ) {
            cardResult.shoppingCard = card
            await RemoteWarenKorb.cleanUpWebShopProducts()
            await LocalWarenkorb.Clear()
            loaderParameter.isInProgress = false

            let content =  await Resource.getResourceText(`Report.Auftrag.Email.Content`)
            let title =  await Resource.getResourceText(`Report.Auftrag.Email.Titel`)

              try {
                if ( card.errorResourceId && card.errorResourceId.length > 0) {
                      content =  await Resource.getResourceText(`${card.errorResourceId}.Content`)
                      title =  await Resource.getResourceText(`${card.errorResourceId}.Titel`)
                  }
              } catch(e) {
                  Out.noOperation("")
              }

              const notficationState = NotificationStateServiceForRoot.getNotificationStateService()  
              notficationState.resetNotificationState()  
              notficationState.changeNotificationState({isNotification: true, text:  content, title: title})


           

           } else {
            loaderParameter.isInProgress = false
           }
        } else {
          await RemoteWarenKorb.cleanUpWebShopProducts()
          await LocalWarenkorb.Clear()
          loaderParameter.isInProgress = false
        }
        
        
      }

      load()

      function deletePaymentWrapper() {
        const element = document.getElementById("paymentFrameWrapper")
        if (element) element.remove()
      }

      const stateMachine = StateMachineServiceLocator.get();
      stateMachine.setAppUpdateStateDetailed( new StateWebShop(), {
          oldMainState: "WebShop", 
          oldState: "WebShopWarenKorb", 
          newMainState: "WebShop", 
          newState: "WebShopKasseSuccess", 
          routeName: "WebShopKasse",   
        })

      let currentState = stateMachine.getCurrentResultState();

      stateMachine.next( { navigation: StateNavigationAction.Next, conditions: []}, { lookahead: false,});
      changeNavigation(store);

      
  
      async function navigate(nav: any) {

        stateMachine.setAppUpdateStateDetailed( new StateWebShop(), {
          oldMainState: "WebShop", 
          oldState: "WebShopKasse", 
          newMainState: "WebShop", 
          newState: "WebShopWarenKorb", 
          routeName: "WebShopWarenKorb",   
        })

        stateMachine.previous( { navigation: StateNavigationAction.Next, conditions: []}, { lookahead: false,});
        changeNavigation(store);
      }
  

  
      onMounted(() => {    
        deletePaymentWrapper()  
        
        unsubscribe = store.subscribe((mutation) => {
          if ( !DynamicNavigation.isNavigationEnabled()) {
            if (currentState.navigation) {
              try {
                DynamicNavigation.saveLastStaticNavigationState([currentState.navigation?.nav1,currentState.navigation?.nav2,currentState.navigation?.nav3,currentState.navigation?.nav4])
              } catch(e) {
                Out.noOperation("")
              }
            }
          }
  
          if (!DynamicNavigation.isAssigned("WebShop")) return       
  
          if (mutation.type === "navigationState/clickNavigationItem1") {
            navigate(currentState.navigation?.nav1);
          }
          if (mutation.type === "navigationState/clickNavigationItem2") {
            navigate(currentState.navigation?.nav2);
          }
          if (mutation.type === "navigationState/clickNavigationItem3") {
            navigate(currentState.navigation?.nav3);
          }
          if (mutation.type === "navigationState/clickNavigationItem4") {
            navigate(currentState.navigation?.nav4);
          }
        });
  
        
      });
  
      onUnmounted(() => {
        unsubscribe();
      });
  
      return {
        cardResult,
        loaderParameter,
        request,
        backGroundZahlung
      };
    },
  };
  