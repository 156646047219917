import { Dictionary } from "@/infrastructure/generics/dictionary"
import { NavigationItem, NavigationButton, NavigationChain } from "./DynamicNavigationTypes"
import { Resource } from "@/infrastructure/resource/resource"

export class GenerateWildBookNavigationChains {
    
    public static generateChains(navigationChains: Dictionary<NavigationChain>) : void {
        GenerateWildBookNavigationChains.wildBookEntry(navigationChains)
        GenerateWildBookNavigationChains.wildBookFall(navigationChains)
        GenerateWildBookNavigationChains.wildBookObserve(navigationChains)
        GenerateWildBookNavigationChains.wildBookObserveSpec(navigationChains)
    }

    private static wildBookEntry(navigationChains: Dictionary<NavigationChain>) : void {

        const items: NavigationItem[] = []
        const chain: NavigationChain = {
            navigationItems: items,
            currentIndex: 0,
            sourceRouteName: "WildBook",
            targetRoteName: "WildBook"
        }
        navigationChains["WildBookEntry"] = chain
        
        
        let buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragLokalisierungJagd", buttons) )
        
        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragOrtJagd", buttons) )

        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragJagdDaten", buttons) )

        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragTierartJagd", buttons) )

        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(),GenerateWildBookNavigationChains.genEmpty()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragTierartZusatzJagd", buttons) )
        
    }

    private static wildBookFall(navigationChains: Dictionary<NavigationChain>) : void {

        const items: NavigationItem[] = []
        const chain: NavigationChain = {
            navigationItems: items,
            currentIndex: 0,
            sourceRouteName: "WildBook",
            targetRoteName: "WildBook"
        }
        navigationChains["WildBookFall"] = chain
        
        
        let buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragLokalisierungFallwild", buttons) )
        
        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragOrtFallwild", buttons) )

        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragFallwildDaten", buttons) )

        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragTierartFallwild", buttons) )

        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragTierartZusatzFallwild", buttons) )
        
    }

    private static wildBookObserve(navigationChains: Dictionary<NavigationChain>) : void {

        const items: NavigationItem[] = []
        const chain: NavigationChain = {
            navigationItems: items,
            currentIndex: 0,
            sourceRouteName: "WildBook",
            targetRoteName: "WildBook"
        }
        navigationChains["WildBookObserve"] = chain
        
        
        let buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragLokalisierungBeobachtung", buttons) )
        
        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragOrtBeobachtung", buttons) )

        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragBeobachtungDaten", buttons) )

        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragTierartBeobachtung", buttons) )
        
    }

    private static wildBookObserveSpec(navigationChains: Dictionary<NavigationChain>) : void {

        const items: NavigationItem[] = []
        const chain: NavigationChain = {
            navigationItems: items,
            currentIndex: 0,
            sourceRouteName: "WildBook",
            targetRoteName: "WildBook"
        }
        navigationChains["WildBookObserveSpec"] = chain
        
        let buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragLokalisierungBeobachtungSpezial", buttons) )
        
        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genNext()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragOrtBeobachtungSpezial", buttons) )

        buttons = [GenerateWildBookNavigationChains.genPrevious(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty(), GenerateWildBookNavigationChains.genEmpty()]
        items.push( GenerateWildBookNavigationChains.generateNavigationItem("WildbuchEintragBeobachtungDatenSpez", buttons) )
        
    }


    private static generateNavigationItem(page: string , buttons: NavigationButton[] ) : NavigationItem {
        const item: NavigationItem = {
            page: page,
            navigationButtons: buttons
        }

        return item
    }

    private static genNext() : NavigationButton {
        const button: NavigationButton = {
            displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Vor",""),
            imageUrl: 'img/icons/next_white.svg',
            enabled: true,
            visible: true,
            existent: true,
            command: 'next'
        }
        return button
    }

    private static genPrevious() : NavigationButton {
        const button: NavigationButton = {
            displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Zurueck",""),
            imageUrl: 'img/icons/previous_white.svg',
            enabled: true,
            visible: true,
            existent: true,
            command: 'previous'
        }
        return button
    }


    private static genAction(displayName: string , imageUrl: string , commandName: string) : NavigationButton {
        const button: NavigationButton = {
            displayName: displayName,
            imageUrl: imageUrl,
            enabled: true,
            visible: true,
            existent: true,
            command: commandName
        }
        return button
    }

    private static genEmpty() : NavigationButton {
        const button: NavigationButton = {
            displayName: '',
            imageUrl: '',
            enabled: false,
            visible: false,
            existent: false,
            command: ''
        }
        return button
    }
}