import { newDate } from "../functional/datetimehelper";

export class Out {

    private static _traceSection = "DynamicGrid,BereichSelect,GeraetSelect,SendCommand,EditFrame,MandantEdit";

    public static log(message: unknown) :void {
        console.log(`"%c${message}"`, "color: gray")
    }    

    public static logImportant(message: unknown) :void {
        console.log(`"%c${message}"`, "color: red;font-size: 1.5rem")
    }  
    
    public static warning(message: unknown) :void {
        console.log(`"%c${message}"`, "color: yellow;font-size: 1.5rem")
    }    

    public static error(message: unknown) :void {
        console.error(message)
    }    

    public static trace(section: string, message: unknown) :void {
        if ( this._traceSection.indexOf(section) >= 0)
            console.log(`%c${newDate().toLocaleString()}: ${message}`, "color: blue;font-size: 0.8rem")
    }    

    public static logTable(rows: unknown) : void {
        console.table(rows);
    }

    public static logTree(obj: unknown) : void {
        console.dir(obj);
    }

    public static logXml(obj: unknown) : void {
        console.dirxml(obj);
    }

    public static time(nameOfTime: string) {
        console.time(nameOfTime)
    }

    public static timeEnd(nameOfTime: string) {
        console.timeEnd(nameOfTime)
    }

    public static logGroup(obj: unknown) {
        console.group(obj)
    }

    public static logGroupEnd() {
        console.groupEnd()
    }

    public static noOperation(obj: unknown = "") {
        const noOp = "No Operation " + obj
    }
    
    public static pusRouterOut(msg: string) {
        console.log(msg)
    }
}