import { AsyncCommand } from "../Command";
import { DynamicDataSet, DynamicFormData} from "@/components/Shared/Dynamicform/DynamicFormData";


export class RuleDynamicChange extends AsyncCommand {
  
  private dynamicForm: DynamicFormData;  
  private dataSet: DynamicDataSet;
  

  constructor(dynamicForm: DynamicFormData, dataSet: DynamicDataSet ) {
    super();
    this.dynamicForm = dynamicForm;   
    this.dataSet = dataSet;
  }

  public async asyncExecute(): Promise<any> {

    const fieldsHidden = ["RuleDynamicChange","RuleDynamicShouldDelete","RuleDynamicDelete"]
    this.dynamicForm.showHideFields(this.dataSet, fieldsHidden , false);    

    const fieldsShown = ["RuleDynamicSave"]
    this.dynamicForm.showHideFields(this.dataSet, fieldsShown , true)

    const fieldsEnabled = ["Fischart","Anzahl","Gewicht","Laenge","Dauer"]
    this.dynamicForm.enableDisableFields(this.dataSet, fieldsEnabled , true)
   
    return true    
  }
}
