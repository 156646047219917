import { Resource } from '../../../infrastructure/resource/resource';
import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { Navigate } from '@/infrastructure/backend/async/Navigate';
import { Identity } from "@/infrastructure/backend/userIdentity";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { addOrReplaceRecord, getRecord, deleteRecord, storeExists } from '@/infrastructure/database/asyncDb';
import { Out } from '@/infrastructure/frontent/clientMessage';
import { Dictionary } from "@/infrastructure/generics/dictionary";
import { NamedListItem } from "@/infrastructure/generics/namedListItem";
import { RefreshServerObjects } from '@/infrastructure/resource/RefreshServerObjects';
import { orderBy } from '@progress/kendo-data-query';

export class ComboBoxDataSource {
    
    private static sources: Dictionary<Array<NamedListItem> | undefined> = {}

    public static async initNamedLists(refresh: boolean) : Promise<void> {

        await ComboBoxDataSource.getComboBoxes()
          
    }

    public static async initUserNamedList() : Promise<void> {

        await ComboBoxDataSource.getComboBoxes()
        await ComboBoxDataSource.GetList("FischereiRevierPerson", "", true)        
        
        // await ComboBoxDataSource.GetList("WildbuchTodesursache", "", false)
        // await ComboBoxDataSource.GetList("WildbuchTyp", "", false)
        // await ComboBoxDataSource.GetList("WildbuchTierGeschlecht", "", false)
        // await ComboBoxDataSource.GetList("WildbuchTieralter", "", false)
        // await ComboBoxDataSource.GetList("WildbuchJagdBetriebsart", "", false)
        // await ComboBoxDataSource.GetList("WildbuchNachsuche", "", false)
        // await ComboBoxDataSource.GetList("WildbuchBeobachtungSpezialFall", "", false)
        // await ComboBoxDataSource.GetList("WildbuchBeobachtungMethode", "", false)
        // await ComboBoxDataSource.GetList("WildbuchTodesursache", "", false)
        // await ComboBoxDataSource.GetList("WildbuchZeit", "", false)
        // await ComboBoxDataSource.GetList("WildbuchErfassungStatus", "", false)
        // await ComboBoxDataSource.GetList("WildbuchOrtsbeschreibung", "", false)
        // await ComboBoxDataSource.GetList("Vermarktung", "", false)                                          
        // await ComboBoxDataSource.GetList("Nachtsichtzielhilfe", "", false)
        // await ComboBoxDataSource.GetList("Nachtsichthilfe", "", false)
        
    }

    public static async GetValue( sourceName: string, id: string | undefined) : Promise<string | undefined>  {
        await ComboBoxDataSource.ensureSource(sourceName)
        if ( !id || id.length < 1) return undefined

        if (!this.sources || !this.sources[sourceName]) return undefined
       
        for(const row of this.sources[sourceName]!) {
            if ( row.key === id) {
                return row.value
            }
        }

        return undefined
    }

     public static async GetValueAsIntByKey(sourceName: string, key: string) : Promise<number> {
        await ComboBoxDataSource.ensureSource(sourceName)
        const currentSource = this.sources[sourceName];
        if ( currentSource) {
            for( const row of currentSource) {
                if ( row.key === key) {
                    return  Number.parseInt(row.value)
                }
            }
        }
        return 0
    }

    private static GetStoreName(store: string) : string {
        if ( store === "Patent") return "fischerei-patent-v1"
        if ( store === "FischereiRevier" ) return "fischerei-revier-v1";
        return store + "-" + "v1"
    }

    private static async ensureSource(sourceName: string) {
        
        try {
            const currentSource = this.sources[sourceName]
            if ( !currentSource ) {
                await this.GetList(sourceName, "", true)
            }
        } catch(e) {
            await this.GetList(sourceName, "", true)
        }
    }

    public static async GetList(sourceName: string, filter: string, alwaysRefresh: boolean) : Promise<Array<NamedListItem> | undefined>  {

        if ( sourceName === "WildbuchJagdBetriebsart") {
            Out.noOperation("")
        }

        if ( this.IsStore(sourceName)) alwaysRefresh = true
        const data = await getRecord(this.GetStoreName(sourceName), sourceName)
        let hasData = false
        let toDelete = false
        if ( data ) {
            if ( data.objectList ) {
                if ( data.objectList.length === 1 ) {
                    toDelete = true
                }

                if (   data.objectList.key && data.objectList.key.length > 0  ) {
                    hasData = true
                }

                if (data.objectList[0] &&  data.objectList[0].key && data.objectList[0].key.length > 0  ) {
                    hasData = true
                }

                if ( data.objectList.length > 0 ) {
                    hasData = true
                }
            }
        }

        if ( ! hasData || alwaysRefresh) {
            if ( toDelete ) {
                try {
                    await deleteRecord(this.GetStoreName(sourceName), "")
                } catch(e) {
                    Out.noOperation()
                }
            }
            const result = await this.getData(sourceName, filter) 
            if ( result === undefined) {
                return
            }
            for( const item of result ) {
                if ( item.value && item.value !== null) {
                    const tempValue = await Resource.getResourceTextOrUndefined(item.resourceId)
                    if ( tempValue ) {
                        item.value = tempValue
                    } 
                }
            }
            const objectList = result
            await addOrReplaceRecord(this.GetStoreName(sourceName), {id:sourceName, objectList})
        } else {
            this.sources[sourceName] = data.objectList
            return this.sortByValueAsc(sourceName, this.applyFilter( this.sources[sourceName] , filter ), filter)
        }

        const dbResult = await getRecord(this.GetStoreName(sourceName), sourceName)
        if ( dbResult) {
            this.sources[sourceName] = dbResult.objectList
            return this.sortByValueAsc(sourceName, this.applyFilter( this.sources[sourceName] , filter ), filter)
        }
        return
    }

    private static sortByValueAsc(sourceName: string, objectList: any, filter: string) : any {
        if ( sourceName === "WildbuchTieralter" || sourceName === "WildbuchZeit") {
            objectList.sort(function(a: any,b: any){                    
                return (a.value ?? "").localeCompare(b.value ?? "");
              });
            return objectList    
        }


        if ( sourceName === "Tierart" ) {
            if ( filter && filter === "IstJagdbar" ) {
                objectList.sort(function(a: any,b: any){                    
                    return ((a.orderBy1 ?? 0).toString() + "." + a.value ?? "").localeCompare((b.orderBy1 ?? 0).toString() + "." + b.value ?? "");
                });
                return objectList 
            }
            if ( filter && filter === "IstFallwild" ) {
                objectList.sort(function(a: any,b: any){                    
                    return ((a.orderBy1 ?? 0).toString() + "." + a.value ?? "").localeCompare((b.orderBy1 ?? 0).toString() + "." + b.value ?? "");
                });
                return objectList 
            }
            if ( filter && filter === "IstBeobachtbar" ) {
                objectList.sort(function(a: any,b: any){                    
                    return ((a.orderBy1 ?? 0).toString() + "." + a.value ?? "").localeCompare((b.orderBy1 ?? 0).toString() + "." + b.value ?? "");
                });
                return objectList 
            }
        }

        objectList.sort(function(a: any,b: any){                    
            return ((a.orderBy1 ?? 0).toString() + "." + a.value ?? "").localeCompare((b.orderBy1 ?? 0).toString() + "." + b.value ?? "");
          });
        return objectList    
    }

    private static applyFilter(objectList: any, filter: string | undefined) : any {
        const filteredItems:any = []
        if ( !objectList || !filter || filter.length < 1 )
            return objectList

        for(const item of objectList) {
            if ( !item.filter || item.filter.length < 1) 
                continue
            
            if ( item.filter.indexOf(filter) < 0 ) 
                continue

            filteredItems.push(item)
        }
        
        return filteredItems
    }

    private static IsStore(sourceName: string) : boolean {
        const isStore = sourceName === "Patent" || sourceName === "FischereiRevier" || sourceName === "FischereiRevierPerson" || sourceName === "JagdRevier"
        if ( isStore) return true

        return storeExists(sourceName)
    }


  


    private static async getData(sourceName: string, filter: string) : Promise<any> {

        if( Navigate.IsLocked() ) {
            return undefined
        }


        const dataSourceClient = AsyncRestClient.Create(true);
        if (dataSourceClient.isFailure) return;

        const result = await dataSourceClient
            .getValue()
            .exchangeForComponents<string, any>(process.env.VUE_APP_API + "api/comboBox/getListByType", JSON.stringify({
                DataSourceName: sourceName, 
                Filter: filter,
                userId: Identity.getIdentity().getValue().UserId,
                language: (await UserProfile.GetClientSettingsAsync()).language,
                lastRefreshDate: await RefreshServerObjects.getLastComboRefreshDate()
             }));       
        if (result.isFailure) {
            return undefined}
        return result.getValue()
    }


    public static async getComboBoxes() : Promise<void> {

        if( Navigate.IsLocked() ) {
            return undefined
        }

        const dataSourceClient = AsyncRestClient.Create(true);
        if (dataSourceClient.isFailure) return;

        const result = await dataSourceClient
            .getValue()
            .exchangeForComponents<string, any>(process.env.VUE_APP_API + "api/comboBox/getListsByTypes", JSON.stringify({
                userId: Identity.getIdentity().getValue().UserId,
                language: (await UserProfile.GetClientSettingsAsync()).language,
                lastRefreshDate: await RefreshServerObjects.getLastComboRefreshDate()
             }));       
        if (!result || result.isFailure) {
            return undefined}

        let comboBoxes: any
        try {
             comboBoxes = result.getValue()
        } catch(e) {
            return
        }

        if ( comboBoxes.offline) return

        if (comboBoxes) {
            if ( comboBoxes.comboBoxItems && comboBoxes.comboBoxItems.length > 0) {
                for(const item of comboBoxes.comboBoxItems) {
                    if (item) {
                        const objectList = item.items
                        const sourceName = item.name

                        //  for( const item of objectList ) {
                        //     if ( item.value && item.value !== null) {
                        //         const tempValue = await Resource.getResourceTextOrUndefined(item.resourceId)
                        //         if ( tempValue ) {
                        //             item.value = tempValue
                        //         } 
                        //     }
                        // }
                      await addOrReplaceRecord(this.GetStoreName(sourceName), {id:sourceName, objectList})
                      this.sources[sourceName] = objectList
                    }
                }

                
                await RefreshServerObjects.addLastComboRefreshDate(RefreshServerObjects.getCurrentLastRefreshDate())
            }
           
        }
        return 
    }
}