import { DynamicGridRow, DynamicGridRowParentChild } from "@/components/Shared/dynamicList/DynamicListTypes";
import { addOrReplaceRecord, fishbookTable, loadAllRecords } from "@/infrastructure/database/asyncDb";
import { getFishBookEntriesWithDetail } from "@/infrastructure/serviceworker-related/DynamicGridServiceWorker";
import { FishingRecordParent } from "./FishingRecordParent";
import { FishingEdit } from "./FishingEdit";
import { ConvertDateToIsoString, ConvertUtcStringToDate, GetDateDiffMinutes, getDateWithoutTime } from "@/infrastructure/functional/datetimehelper";
import { DynamicDataSet } from "@/components/Shared/Dynamicform/DynamicFormData";
import { syncFishbook } from "@/infrastructure/serviceworker-related/SyncFishbook";




export async function stopLocalFishingParentRecord(viewId: string): Promise<DynamicGridRow | undefined>  {
    
    const fishingEditState = FishingEdit.getEditFishingState()
    if (!fishingEditState.startDate) {
        throw new Error("FishingEditState.Error.StartDate")
    }


    const row = await findLocalFishingParentRecord(viewId)
    if (row && row.cell.readableIdentification && row.cell.readableIdentification.dauer !== undefined) {
        row.cell.readableIdentification.dauer +=   GetDateDiffMinutes(fishingEditState.stoppDate, fishingEditState.startDate)
        row.cell.readableIdentification.stopDate = ConvertDateToIsoString( fishingEditState.stoppDate! )
        await addOrReplaceRecord(fishbookTable, {id: row.id, row})   
        //findAndUpdateRow(row)   
    }
    if ( row ) await syncFishbook({parentRow: row, childRow: undefined}, "stop")   
    return row    
}



export async function addOrUpdateLocalFishingParentRecord(viewId: string): Promise<DynamicGridRow | undefined>  {
    
    const fishingEditState = FishingEdit.getEditFishingState()
    if (!fishingEditState.startDate) {
        throw new Error("FishingEditState.Error.StartDate")
    }
    const row = await findLocalFishingParentRecord(viewId)
    if (row) {
        if( row.cell.readableIdentification && row.cell.readableIdentification.stopDate ) {
            row.cell.readableIdentification.startDate = ConvertDateToIsoString( fishingEditState.startDate! )
            row.cell.readableIdentification.stopDate = ConvertDateToIsoString( fishingEditState.stoppDate! )            
        }
        await addOrReplaceRecord(fishbookTable, {id: row.id, row})          
        await syncFishbook({parentRow: row, childRow: undefined}, "startChange")  
        return row    
    }
    const result = await addLocalFishingParentRecord()
    await syncFishbook({parentRow: result, childRow: undefined}, "startNew")

    return result
}

export async function findLocalFishingDetailRecordByRowId(viewId: string, rowId: string) : Promise<DynamicGridRowParentChild | undefined> {
    const resultGrid = await getFishBookEntriesWithDetail(viewId, true)
    if ( ! resultGrid) return undefined
    const grid = resultGrid.getValue()
    if ( !grid || !grid.rows || grid.rows.length === 0) return undefined
    
    for ( const parentRow of grid.rows ) {
        if (! parentRow.rows || parentRow.rows.length === 0) continue
        for ( const detailRow of parentRow.rows ) {
            if ( detailRow.detailForm && detailRow.detailForm.view && detailRow.detailForm.view.length > 0) {
                if (detailRow.detailForm.id === rowId) {
                    return { parentRow: parentRow, childRow: detailRow} 
                }
            }
        }
    }
    return undefined
}

export async function findLocalFishingParentRecordById(id: string) : Promise<DynamicGridRow | undefined> {
    const data = await loadAllRecords(fishbookTable) as any
    if ( data ) {
        for( const item of data) {
            const row = item.row as unknown as DynamicGridRow
            if ( row ) {
                if (row.id === id) {
                    return row;
                }
            }

        }
    }
    return
}


export async function findLocalFishingDetailRecordById(viewId: string, id: string) : Promise<DynamicGridRowParentChild | undefined> {
    const resultGrid = await getFishBookEntriesWithDetail(viewId, true)
    if ( ! resultGrid) return undefined
    const grid = resultGrid.getValue()
    if ( !grid || !grid.rows || grid.rows.length === 0) return undefined
    
    for ( const parentRow of grid.rows ) {
        if (! parentRow.rows || parentRow.rows.length === 0) continue
        for ( const detailRow of parentRow.rows ) {
            if ( detailRow.detailForm && detailRow.detailForm.view && detailRow.detailForm.view.length > 0) {
                if (detailRow.detailForm.id === id) {
                    return { parentRow: parentRow, childRow: detailRow} 
                }
            }
        }
    }
    return undefined
}


export async function findLocalFishingDetailRecord(viewId: string, reactiveData: DynamicDataSet) : Promise<DynamicGridRowParentChild | undefined> {
    const resultGrid = await getFishBookEntriesWithDetail(viewId, true)
    if ( ! resultGrid) return undefined
    const grid = resultGrid.getValue()
    if ( !grid || !grid.rows || grid.rows.length === 0) return undefined
    
    for ( const parentRow of grid.rows ) {
        if (! parentRow.rows || parentRow.rows.length === 0) continue
        for ( const detailRow of parentRow.rows ) {
            if ( detailRow.detailForm && detailRow.detailForm.view && detailRow.detailForm.view.length > 0) {
                if (detailRow.detailForm.id === reactiveData.id || detailRow.id === reactiveData.id) {
                    return { parentRow: parentRow, childRow: detailRow} 
                }
            }
        }
    }
    return undefined
}

export async function findLocalFishingParentRecord(viewId: string) : Promise<DynamicGridRow | undefined> {
    
    const resultGrid = await getFishBookEntriesWithDetail(viewId, true)
    if ( ! resultGrid) return
    const grid = resultGrid.getValue()
    if ( !grid || !grid.rows) return


    const fishingEditState = FishingEdit.getEditFishingState()
    if (!fishingEditState.startDate) {
        throw new Error("FishingEditState.Error.StartDate")
    }


    for (const parentRow of grid.rows ) {
        if ( parentRow.cell.readableIdentification?.readableNumber === fishingEditState.fischereiRevier?.key && parentRow.cell.readableIdentification?.readablePatentNumber === fishingEditState.patent?.key) {
            if ( parentRow.cell.readableIdentification?.startDate && parentRow.cell.readableIdentification?.startDate.length > 0  ) {
                const timestamp = ConvertUtcStringToDate( parentRow.cell.readableIdentification?.startDate )
                const dayDate   = getDateWithoutTime(timestamp)
                const editTimestamp = fishingEditState.startDate
                const editDayDate = getDateWithoutTime(editTimestamp)
                if ( dayDate.getTime() === editDayDate.getTime()) {
                    return parentRow
                }
            }
        }
    }
    return
}

async function addLocalFishingParentRecord() : Promise<DynamicGridRow> {
    const parent: FishingRecordParent = new FishingRecordParent()
    const row = await parent.CreateFischereiFangRow()
    const rows: DynamicGridRow[] = []
    rows.push(row)
    await addOrReplaceRecord(fishbookTable, {id: row.id, row})       

    return row
}

