import { categorizeIcon } from "@progress/kendo-svg-icons";
import { AsyncRestClient } from "./AsyncRestClient";

export class InitialStartRequest {

    //private static locked = false

    public static async  Start(url: string , caller: string) : Promise<boolean> {

        //console.error("start: " + caller)
        // if (this.locked ) return true
        // this.locked = true
        try {
            const startClient = AsyncRestClient.Create(true);
            if (startClient.isFailure) {
                //this.locked = false
                return false
            }
            const startResult = await startClient
            .getValue()
            .exchangeForComponents<string, string>(url, JSON.stringify({appName: "eFJ"}));   
            if (startResult.isFailure) {
                //this.locked = false
                return false;
            } 

            try {
                const temp = startResult.getValue()
                if (temp && (temp as any).offline) {
                    //this.locked = false
                    return false
                }
              } 
              catch(e) {
                //this.locked = false
                return false
              }

            //this.locked = false
            return true
        } catch(e) {

            //this.locked = false
            return false
        }
    }
}