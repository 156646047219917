import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-725d2591"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter__pos" }
const _hoisted_2 = { class: "col top-placeholder" }
const _hoisted_3 = { class: "form__filter--text" }
const _hoisted_4 = { class: "col top-placeholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dynamic_form = _resolveComponent("dynamic-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.filterSymbolVisibleClass())
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showFilter()), ["prevent"])),
          src: "img/icons/filter.svg",
          class: "form__filter--symbol"
        }),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.filterActive), 1)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.filterVisibleClass())
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_dynamic_form, _normalizeProps(_guardReactiveProps(_ctx.request)), null, 16)
      ])
    ], 2)
  ]))
}