import { StateService } from '@/infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { DynamicGrid,DynamicGridRow } from '@/components/Shared/dynamicList/DynamicListTypes';
import { Identity } from '@/infrastructure/backend/userIdentity';
import { DetailsSupported } from './DynamicDetailTypes';



export interface DynamicListToDetailFormState {
    grid: DynamicGrid
    row: DynamicGridRow
    isNotification: boolean;
    attachTo: DetailsSupported
  }
  

  const helper = () => {
    try {
        return Identity.getIdentity().getValue().UserId
    } catch(e) {
        return ""
    }
}

const initialState: DynamicListToDetailFormState = {
    grid: {
        viewId: "",
        userId: helper()
    },
    row: {
        cell: {
          image:{},
          content: {},
          additional: {}
        }
    },
    isNotification: false,
    attachTo: undefined
  };


export async function CreateDetailState(grid: DynamicGrid, row: DynamicGridRow, isNotification: boolean, detailState: DetailsSupported) : Promise<DynamicListToDetailFormState> {
    return {
        grid: grid,
        row: row,
        isNotification: isNotification,
        attachTo: detailState
    }
}



export class DynamicListToDetailFormChannelForRoot extends StateService<DynamicListToDetailFormState>{
    public static service: DynamicListToDetailFormChannelForRoot;
    private static attachetTo: DetailsSupported = undefined
    private static firstAttached = true

    public static getService() : DynamicListToDetailFormChannelForRoot {
        if ( DynamicListToDetailFormChannelForRoot.service === undefined) {
            DynamicListToDetailFormChannelForRoot.service = new DynamicListToDetailFormChannelForRoot();
            this.service = DynamicListToDetailFormChannelForRoot.service
        }
        return DynamicListToDetailFormChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<DynamicListToDetailFormState> {
        return this.subscribe();
    }

    public changeDetailState(detailFormState: DynamicListToDetailFormState) : void {

        DynamicListToDetailFormChannelForRoot.firstAttached = false
        if ( DynamicListToDetailFormChannelForRoot.attachetTo !== detailFormState.attachTo ) {
            DynamicListToDetailFormChannelForRoot.attachetTo = detailFormState.attachTo
            DynamicListToDetailFormChannelForRoot.firstAttached = true
        }

        this.changeState(detailFormState);
    }

  

    public isAttached(detailName: DetailsSupported): boolean {
        if ( detailName === undefined ) {
            return false
        }
        if ( DynamicListToDetailFormChannelForRoot.attachetTo == detailName && DynamicListToDetailFormChannelForRoot.attachetTo !== undefined  && detailName !== undefined) {
            return true
        }

        return false
    }

    public isFirstAttached() {
        return DynamicListToDetailFormChannelForRoot.firstAttached
    }


    public get state(): DynamicListToDetailFormState {
        return this.state;
    }
}