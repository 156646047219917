import { StateService } from '../../../../infrastructure/observables/stateService';
import { Observable } from "rxjs";

export interface DynamicTriggerFormState {
    formName: string
    trigger: boolean

}

export function createIntitialTriggerFormState(attachTo: string | undefined) : DynamicTriggerFormState {
    return {
        formName: "",
        trigger: false       
      };
}

export class DynamicTriggerSaveFormForRoot extends StateService<DynamicTriggerFormState>{
    public static service: DynamicTriggerSaveFormForRoot;
    private static attached: string | undefined = undefined

    public static getService(attachTo: string | undefined) : DynamicTriggerSaveFormForRoot {
        if (!DynamicTriggerSaveFormForRoot.service) {
            
            DynamicTriggerSaveFormForRoot.service = new DynamicTriggerSaveFormForRoot(attachTo);
            this.service = DynamicTriggerSaveFormForRoot.service
        }
        DynamicTriggerSaveFormForRoot.attached = attachTo
        return DynamicTriggerSaveFormForRoot.service;
    }

    private constructor(attachTo: string | undefined) {
        super(createIntitialTriggerFormState(attachTo));
        DynamicTriggerSaveFormForRoot.attached = attachTo
    }
    
    public subscription(): Observable<DynamicTriggerFormState> {
        return this.subscribe();
    }

    public changeFormState(formState: DynamicTriggerFormState) : void {               
        this.changeState(formState);
    }

    public isAttached(attachedTo: string | undefined): boolean {
        if ( attachedTo === undefined || DynamicTriggerSaveFormForRoot.attached === undefined )  return false     
        if ( DynamicTriggerSaveFormForRoot.attached === "Wildbuch" && attachedTo === "WildBookFilter" ) return true
        if ( DynamicTriggerSaveFormForRoot.attached === "Fischbuch" && attachedTo === "FishBookFilter" ) return true    
        if ( DynamicTriggerSaveFormForRoot.attached !== attachedTo ) return false
        return true
    }

    public get formState(): DynamicTriggerFormState {
        return this.state;
    }
}