import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dffb5910"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-bar row align-items-center justify-content-center d-flex" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconbutton = _resolveComponent("iconbutton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["col", { showasdisabled: !$setup.buttonState1.enabled }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.buttonState1Command && $setup.buttonState1Command(...args)))
    }, [
      ($setup.buttonState1.existent)
        ? (_openBlock(), _createBlock(_component_iconbutton, {
            key: 0,
            disabled: $setup.disabledNavigationButton
          }, {
            icontext: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString($setup.buttonState1.displayName), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: $setup.buttonState1.imageUrl,
                alt: "Hidden",
                class: "button-container"
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["col", { showasdisabled: !$setup.buttonState2.enabled }]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.buttonState2Command && $setup.buttonState2Command(...args)))
    }, [
      ($setup.buttonState2.existent)
        ? (_openBlock(), _createBlock(_component_iconbutton, {
            key: 0,
            disabled: $setup.disabledNavigationButton
          }, {
            icontext: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString($setup.buttonState2.displayName), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: $setup.buttonState2.imageUrl,
                alt: "Hidden",
                class: "button-container"
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["col", { showasdisabled: !$setup.buttonState3.enabled }]),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.buttonState3Command && $setup.buttonState3Command(...args)))
    }, [
      ($setup.buttonState3.existent)
        ? (_openBlock(), _createBlock(_component_iconbutton, {
            key: 0,
            disabled: $setup.disabledNavigationButton
          }, {
            icontext: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString($setup.buttonState3.displayName), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: $setup.buttonState3.imageUrl,
                alt: "Hidden",
                class: "button-container"
              }, null, 8, _hoisted_4)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["col", { showasdisabled: !$setup.buttonState4.enabled }]),
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.buttonState4Command && $setup.buttonState4Command(...args)))
    }, [
      ($setup.buttonState4.existent)
        ? (_openBlock(), _createBlock(_component_iconbutton, {
            key: 0,
            disabled: $setup.disabledNavigationButton
          }, {
            icontext: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString($setup.buttonState4.displayName), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: $setup.buttonState4.imageUrl,
                alt: "Hidden",
                class: "button-container"
              }, null, 8, _hoisted_5)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}