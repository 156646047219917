import { DynamicRecord } from '@/components/Shared/Dynamicform/DynamicFormData';
import { StateService } from '@/infrastructure/observables/stateService';
import { Observable } from "rxjs";

export interface RoutedButtonBarResponseState {  
    targetRoute: string | undefined,
    command: string | undefined, 
    topic: string | undefined,
    rows?: DynamicRecord[] | undefined
}



const initialState = {
    targetRoute: undefined,
    command: undefined,
    topic: undefined,
    rows: undefined
}

export function createIntitialButtonBarRoute() : RoutedButtonBarResponseState {
    return  {       
        targetRoute: undefined,
        command: undefined,
        topic: undefined,
        rows: undefined
    }
}

export class RoutedButtonBarResponseChannelForRoot extends StateService<RoutedButtonBarResponseState>{
    public static service: RoutedButtonBarResponseChannelForRoot;
  

    public static getService() : RoutedButtonBarResponseChannelForRoot {
        if ( RoutedButtonBarResponseChannelForRoot.service === undefined) {
            RoutedButtonBarResponseChannelForRoot.service = new RoutedButtonBarResponseChannelForRoot();
            this.service = RoutedButtonBarResponseChannelForRoot.service
        }
        return RoutedButtonBarResponseChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<RoutedButtonBarResponseState> {
        return this.subscribe();
    }

    public changeRouteState(state: RoutedButtonBarResponseState) : void {
       
        this.changeState(state);
    }
    
    public get RoutedState(): RoutedButtonBarResponseState {
        return this.state;
    }
}