import { UserProfile } from '@/infrastructure/backend/userProfile';
import { AsyncCommand, Command } from "../Command";
import { DynamicDataSet, DynamicFormData} from "@/components/Shared/Dynamicform/DynamicFormData";


export class RuleUserProfile extends AsyncCommand {
  private dynamicForm: DynamicFormData;  
  private dataSet: DynamicDataSet;
  private store: any;

  constructor(dynamicForm: DynamicFormData, dataSet: DynamicDataSet, store: any ) {
    super();
    this.dynamicForm = dynamicForm;   
    this.dataSet = dataSet;
    this.store = store;
  }

  public async asyncExecute(): Promise<any> {
    const userProfile: UserProfile = new UserProfile()
    const result = await userProfile.GetProfileData()
    return result;
  }
}
