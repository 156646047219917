import { DynamicGrid, DynamicGridRequest, DynamicGridRow } from "@/components/Shared/dynamicList/DynamicListTypes";
import { AsyncRestClient } from "../backend/async/AsyncRestClient";
import { ConvertUtcStringToDate, getDateWithoutTime, isDifferentAppSynchronId, isGreaterAppSynchronId, makeValidUtcString } from "../functional/datetimehelper";
import { TierartZusatzRecord, addOrReplaceRecord, deleteRecord, getRecord, loadAllRecords, wildbookTable } from "../database/asyncDb";
import { Identity } from "../backend/userIdentity";
import { UserProfile } from "../backend/userProfile";
import { refCount } from "rxjs";
import { TierartDynamicRecord, TierartRules } from "./TierartZusatzService";
import { Out } from "../frontent/clientMessage";
import { RefreshServerObjects } from "../resource/RefreshServerObjects";
import { Navigate } from "../backend/async/Navigate";


export async function reSyncWildBookApi() : Promise<DynamicGrid | undefined> {
    const request: DynamicGridRequest = {
        userId: Identity.getIdentity().getValue().UserId,
        viewId: "wildBookEntriesWithDetail",
        activeData: true,
        pageSize: 1,
        revierNr: "",
        routeName: "WildBook",
        detailRouteName: "WildbookRecord",
        page: "WildbuchEintragLokalisierungJagd",
        form: "WildbuchEintragJagd",
        language: (await UserProfile.GetClientSettingsAsync()).language,
        detailId: 'WildbookRecord',
        detailList: true,
        allRecords: true,
        lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
        lastViewIdRefreshState: await RefreshServerObjects.getLastGridWildBookRefreshDate()
    }
    return await reSyncWildbookGrid(request)
}

export async function reSyncWildbookGrid(request: DynamicGridRequest) : Promise<DynamicGrid | undefined> {
    let gridObject : DynamicGrid = {userId: request.userId , viewId: request.viewId}
    if ( gridObject.viewId !== "wildBookEntriesWithDetail" && gridObject.rows) {
        return
    }

    if( Navigate.IsLocked() ) {
     return undefined
    }
    const client = AsyncRestClient.Create(true);
    request.lastRefreshDate = await RefreshServerObjects.getLastGridFormRefreshDate()

    if (request.viewId === "personProductsWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridProductsRefreshDate()
    }
    if (request.viewId === "wildBookEntriesWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridWildBookRefreshDate()
    }
    if (request.viewId === "fishBookEntriesWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridFishBookRefreshDate()
    }
    
    if (client.isFailure) throw new Error("Error.getDynamicGridData");
    const result = await client
        .getValue()
        .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/grid/loadDynamicGridData", JSON.stringify(request));  

    if (result.isFailure) return undefined        
    try {
        gridObject = JSON.parse(JSON.stringify(result.getValue())) as unknown as DynamicGrid 
    } catch(e) {
        return undefined
    }  
   

    if ( ! (gridObject.isModified ?? true) )
    return undefined


    if(gridObject && gridObject.rows && gridObject.rows.length > 0)
        await RefreshServerObjects.addLastGridWildBookRefreshDate(gridObject.lastRefreshDate ?? RefreshServerObjects.getCurrentLastRefreshDate())

    return await reSyncWildbook(gridObject)
}

export async function reSyncWildbook(gridObject: DynamicGrid | undefined) : Promise<DynamicGrid | undefined> {    
    if ( !gridObject ||  gridObject.viewId !== "wildBookEntriesWithDetail" || !gridObject.rows) {
        return
    }

    const zusatzDatenTemp =  await TierartRules.getRuleData()  
    let zusatzDaten: TierartDynamicRecord[] | undefined = undefined
    if ( zusatzDatenTemp && zusatzDatenTemp.length > 0) {
        zusatzDaten = zusatzDatenTemp as TierartDynamicRecord[]
    }
    
    
    if (gridObject && gridObject.rows) {
        for (const row of gridObject.rows) {
            if (row.cell.readableIdentification?.startDate && row.cell.readableIdentification?.startDate.length > 0) {
                row.cell.readableIdentification.startDate = makeValidUtcString(row.cell.readableIdentification?.startDate);
            }
            if (row.cell.readableIdentification?.stopDate && row.cell.readableIdentification?.stopDate.length > 0) {
                row.cell.readableIdentification.stopDate = makeValidUtcString(row.cell.readableIdentification?.stopDate);
            }
            if (row.cell.readableIdentification?.startDateForNextDetail && row.cell.readableIdentification?.startDateForNextDetail.length > 0) {
                row.cell.readableIdentification.startDateForNextDetail = makeValidUtcString(row.cell.readableIdentification?.startDateForNextDetail);
            }
        }
        const localItems = await loadAllRecords(wildbookTable)
        if ( localItems && localItems.length > 0 ) {
            const localRows: DynamicGridRow[] = []
            
            for( const localItem of localItems as any) {
                localRows.push(localItem.row as DynamicGridRow)
            }


            await reSyncRows(gridObject , localRows as DynamicGridRow[], zusatzDaten)
        } else {
            for (const row of gridObject.rows) { 
                await addOrReplaceRecord(wildbookTable, {id: row.rowId, row})   
            }
        }       
    }

    const rows = []
    const dataRows = await loadAllRecords(wildbookTable)
    if ( dataRows === undefined ) return  gridObject

    for ( const item of dataRows as any ) {
      rows.push( item.row  )
    }

    gridObject.rows = rows
    return gridObject

}

// master rows only
async function reSyncRows( grid: DynamicGrid , localRows: DynamicGridRow[], zusatzDaten: TierartDynamicRecord[] | undefined ) : Promise<DynamicGrid | undefined> {
    if ( !grid  || !grid.rows) {
        return
    }
    for ( const apiRow of grid.rows) { 
        const localRow = await findlocalRow(apiRow,localRows)
        if ( !localRow ) {            
            await addOrReplaceRecord(wildbookTable, {id: apiRow.rowId, row: apiRow}) 
            await assignZusatzDaten(apiRow.rowId, apiRow, zusatzDaten)
        } else {
            if ( isDifferentAppSynchronId(localRow.appSynchronId,apiRow.appSynchronId)) {
                apiRow.id = localRow.id
                await addOrReplaceRecord(wildbookTable, {id: localRow.id, row: apiRow}) 
                await assignZusatzDaten(localRow.id, apiRow, zusatzDaten)
            } else {
                await addOrReplaceRecord(wildbookTable, {id: localRow.id, row: localRow}) 
            }
        }
    }
    return grid
}

async function assignZusatzDaten(id: string | undefined , dynamicRow: DynamicGridRow, zusatzDaten: any) {
    try {
    
        if (!zusatzDaten || !id ) return

        const localZusatzDaten =  await getRecord(TierartZusatzRecord, "tierartZusatzData")
        if ( localZusatzDaten) {
            if ( localZusatzDaten.data) {
                for(let i=0; i < 100; i++) {
                    try{
                        const findIndex = (localZusatzDaten.data as TierartDynamicRecord[]).findIndex(a => a.idJagdAbgangBeobachtung === id)
                        if (findIndex < 0 ) break 
                        localZusatzDaten.data.splice(findIndex,1)
                    } catch(e) {
                        break
                    }
                }
            } else {
                localZusatzDaten.data = []
            }
        }

        for(const row of zusatzDaten) {
            if (row.idJagdAbgangBeobachtung === dynamicRow.rowId) {
                localZusatzDaten.data.push(row)
                continue
            }
            if (row.idJagdAbgangBeobachtung === id) {
                localZusatzDaten.data.push(row)
            }
        }
        await addOrReplaceRecord(TierartZusatzRecord, {id: "tierartZusatzData", data: localZusatzDaten.data})

    } catch(e) {
        Out.noOperation("")
    }
}
async function findlocalRow(apiRow: DynamicGridRow,localRows: DynamicGridRow[]) : Promise<DynamicGridRow | undefined> {
    if ( ! localRows )  return undefined
    if ( ! apiRow) return undefined

    for( const row of localRows) {
        if (apiRow.appSynchronId  && row.appSynchronId && row.appSynchronId.length > 0 && apiRow.appSynchronId === row.appSynchronId) {
            return row
        }
    }

    for( const row of localRows) {
        if (apiRow.rowId  && row.rowId && row.rowId.length > 0 && apiRow.rowId === row.rowId) {
            return row
        }
    }

    for( const row of localRows) {
        if (apiRow.id  && row.id && row.id.length > 0 && apiRow.id === row.id) {
            return row
        }
    }

    return undefined
}