import { StateService } from '@/infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { RouteTargets } from './RoutedButtonBarTypes';


export interface RoutedButtonBarRequestState {  
    sourceRoute: string | undefined,
    targetRoutes: RouteTargets | undefined,
    command: string | undefined
    topic: string | undefined
}



const initialState = {
    sourceRoute: undefined,
    targetRoutes: undefined,
    command: undefined,
    topic: undefined
}

export function createIntitialButtonBarRoute() : RoutedButtonBarRequestState {
    return  {
        sourceRoute: undefined,
        targetRoutes: undefined,
        command: undefined,
        topic: undefined
    }
}

export class RoutedButtonBarRequestChannelForRoot extends StateService<RoutedButtonBarRequestState>{
    public static service: RoutedButtonBarRequestChannelForRoot;
  

    public static getService() : RoutedButtonBarRequestChannelForRoot {
        if ( RoutedButtonBarRequestChannelForRoot.service === undefined) {
            RoutedButtonBarRequestChannelForRoot.service = new RoutedButtonBarRequestChannelForRoot();
            this.service = RoutedButtonBarRequestChannelForRoot.service
        }
        return RoutedButtonBarRequestChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<RoutedButtonBarRequestState> {
        return this.subscribe();
    }

    public changeRouteState(detailFormState: RoutedButtonBarRequestState) : void {
       
        this.changeState(detailFormState);
    }
    
    public get RoutedState(): RoutedButtonBarRequestState {
        return this.state;
    }
}