import {
  StateRule,
  StateTransitionOptions,
  StateResult,

  StateNavigationAction,
  Transition,
  // eslint-disable-next-line
  addNavigationElement,
  // eslint-disable-next-line
  addEmptyNavigationElement,
  addPreviousNext,
  // eslint-disable-next-line
  addPrevious,
  // eslint-disable-next-line
  addNext,  
  newTransition,
  newTransitionCondition
} from "../StateInterfaces";
import { NavigatonIcons } from "@/business/application/NavConst";
import {Commands} from "@/business/application/commands/Command";
import { BaseState } from "../StateMachine";
import { StateHunting } from "./StateHunting";
import { StateWildBook } from "./StateWildBook"



export class StateWildBookFall extends BaseState {

  private mainState = "WildBookFall";
  private startState = "WildBookFall"
  private static transitions: Transition[] = [];  
  private static callingState : StateResult = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''}
  private isDirectionNext = false

  // Diesen Status setzen wenn es auf den Main-Status zurück geht. 
  private isDirectionPreviousParent = true

  public constructor() {
    super()    
  }


  
  public next(rule: StateRule, options: StateTransitionOptions): StateResult {    
    return this.calculateState(rule, options, false, this.mainState);
  }
  public previous(rule: StateRule,options: StateTransitionOptions): StateResult {    
    return this.calculateState(rule, options, true, this.mainState);
  }

  protected calculateState(rule: StateRule,options: StateTransitionOptions, isPrevious: boolean, mainState: string): StateResult {
    this.isDirectionNext = ! isPrevious
    const context = this.getContext()
    const currentResultState = context.getCurrentResultState();

    if ( isPrevious && this.isDirectionPreviousParent && currentResultState.newState === "WildBookFall") {
      return this.checkResultState(StateWildBookFall.callingState);
    }

    let condition = this.calculateBusinessConditions(rule,this.getContext().getCurrentState());
    if ( condition === undefined || condition === null || condition.length === 0 || condition === "") {
      if ( currentResultState.newState === "WildBookFall"  && this.isDirectionNext ) {
        condition = "WildBookFallLocation";
      }
      if ( currentResultState.newState === "WildBookFallLocation"  && this.isDirectionNext && condition === "" ) {
        condition = "WildBookFallData";
      }
      if ( currentResultState.newState === "WildBookFallData"  && this.isDirectionNext && condition === "" ) {
        condition = "WildBookFallAnimal";
      }
      if ( currentResultState.newState === "WildBookFallAnimal"  && this.isDirectionNext && condition === "") {
        condition = "WildBookFallAnimalExtension";
      }
      if ( currentResultState.newState === "WildBookFallAnimalExtension"  && this.isDirectionNext && condition === "SaveAndJump") {
        condition = "SaveAndJump";
      }
     

      if ( currentResultState.newState === "WildBookFallAnimalExtension"  && !this.isDirectionNext && condition === "") {
        condition = "WildBookFallAnimal";
      }
      if ( currentResultState.newState === "WildBookFallAnimal"  && !this.isDirectionNext && condition === "") {
        condition = "WildBookFallData";
      }
      if ( currentResultState.newState === "WildBookFallData"  && !this.isDirectionNext && condition === "") {
        condition = "WildBookFallLocation";
      }

      if ( currentResultState.newState === "WildBookFallLocation"  && !this.isDirectionNext && condition === "") {
        condition = "WildBookFall";
      }
    }

    if ( this.isDirectionNext ) {
      const nextTransition = this.getTransitions().filter(row => { 
        if ( row.nav !== StateNavigationAction.Previous && row.condition === condition ) {
          return true
        }
      })
      return this.checkState(nextTransition[0], options);
    } else {
      const previosTransition = this.getTransitions().filter(row => { 
        if ( row.nav === StateNavigationAction.Previous && row.condition === condition ) {
          return true
        }
      })
      return this.checkState(previosTransition[0], options);
    }
  }


  protected calculateBusinessConditions(rule: StateRule, resultState: StateResult): string {
    if ( rule.conditions === undefined || rule.conditions === null || rule.conditions.length === 0 ) {
      return "";
    }

    if (rule.conditions.length == 1) {
      return rule.conditions[0].value;
    }

    return "";
  }


  private getCallingState(condition: string) : StateResult {
    if (this.isDirectionPreviousParent) { 
      // Condition = "Login"
      StateWildBookFall.callingState.newMainState = condition
      StateWildBookFall.callingState.newState = condition
      StateWildBookFall.callingState.routeName = condition
      StateWildBookFall.callingState.oldMainState = this.mainState
      StateWildBookFall.callingState.oldState = this.startState

      return StateWildBookFall.callingState;
    }
     StateWildBookFall.callingState = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''};
     return StateWildBookFall.callingState;
  }

  public getStartTransition(condition: string) : StateResult {
    StateWildBookFall.transitions = []
    this.getTransitions()
    if ( condition !== this.mainState) {
      StateWildBookFall.callingState = this.getCallingState(condition);
    }
    const t = this.getTransitions()[0];
    return this.getTransition(t);
  }



  protected checkState( transition: Transition, options: StateTransitionOptions): StateResult {
    let newMainState = this.mainState    
    let skip = false
    const newResultState: StateResult = this.getTransition(transition)

    if ( this.isDirectionPreviousParent && !this.isDirectionNext) {
      newMainState = StateWildBookFall.callingState.oldMainState
    }
    else {      
      newMainState = transition.newMainState
      if (transition.newState == "")
        skip = true
    }

    if (!options.lookahead && transition.newState != "") {
      return this.changeResultState(transition.newMainState, newResultState);
    }
    return newResultState;
  }

  private checkResultState( stateResult: StateResult ) : StateResult {  
    const newResultState = StateWildBookFall.callingState;    
    return this.changeResultState(stateResult.newMainState, newResultState);      
  }

  private changeResultState(newMainStatePar: string , compResultState: StateResult) : StateResult {
    let newState: any;
    switch (newMainStatePar) {
        case "Hunting":
          newState = new StateHunting();
          compResultState = newState.getStartTransition("Hunting")
          this.getContext().transitionTo(newState,compResultState);
          break;
        case "WildBook":
          newState = new StateWildBook();
          compResultState = newState.getStartTransition("Hunting")
          this.getContext().transitionTo(newState,compResultState);
          break;                         
        default:          
          this.getContext().transitionTo(new StateWildBookFall(), compResultState);        
    }
    return compResultState;
  }




  //-- Sammlung aller Statusübergänge
  public getTransitions(): Transition[] {
    if (StateWildBookFall.transitions.length === 0) {

      //__ Start State egal von welcher Stufe aus
      //
      let t: Transition = { mainState: "*", newMainState: this.mainState, nav: StateNavigationAction.Next, oldState: "*", newState: this.startState, condition: "Start"};      
      t = addPreviousNext( t, Commands.PreviousPage , Commands.NextPage)
      StateWildBookFall.transitions.push(t);

      t = { mainState: this.mainState, newMainState: this.mainState, nav: StateNavigationAction.Next, oldState: this.startState, newState: "WildBookFallLocation", condition: "WildBookFallLocation"};      
      t = addPreviousNext( t, Commands.PreviousPage , Commands.NextPage)
      StateWildBookFall.transitions.push(t);

      t = { mainState: this.mainState, newMainState: this.mainState, nav: StateNavigationAction.Next, oldState: this.startState, newState: "WildBookFallData", condition: "WildBookFallData"};      
      t = addPreviousNext( t, Commands.PreviousPage , Commands.NextPage)
      StateWildBookFall.transitions.push(t);

      t = { mainState: this.mainState, newMainState: this.mainState, nav: StateNavigationAction.Next, oldState: this.startState, newState: "WildBookFallAnimal", condition: "WildBookFallAnimal"};      
      t = addPreviousNext( t, Commands.PreviousPage , Commands.NextPage)
      StateWildBookFall.transitions.push(t);

      t = { mainState: this.mainState, newMainState: this.mainState, nav: StateNavigationAction.Next, oldState: this.startState, newState: "WildBookFallAnimalExtension", condition: "WildBookFallAnimalExtension"};      
      t = addPrevious( t, Commands.PreviousPage )
      StateWildBookFall.transitions.push(t);

      t = { mainState: this.mainState, newMainState: "Hunting", nav: StateNavigationAction.Next, oldState: this.startState, newState: "Hunting", condition: "SaveAndJump"};      
      t = addPreviousNext( t, Commands.PreviousPage, Commands.NextPage )
      StateWildBookFall.transitions.push(t);


      //__ Backward Previous gesetzt
      t = { mainState: this.mainState, newMainState: this.mainState, nav: StateNavigationAction.Previous, oldState: "WildBookFallAnimalExtension", newState: "WildBookFallAnimal", condition: "WildBookFallAnimal"};      
      t = addPreviousNext( t, Commands.PreviousPage , Commands.NextPage)
      StateWildBookFall.transitions.push(t);
      
      t = { mainState: this.mainState, newMainState: this.mainState, nav: StateNavigationAction.Previous, oldState: "WildBookFallAnimal", newState: "WildBookFallData", condition: "WildBookFallData"};      
      t = addPreviousNext( t, Commands.PreviousPage , Commands.NextPage)
      StateWildBookFall.transitions.push(t);

      t = { mainState: this.mainState, newMainState: this.mainState, nav: StateNavigationAction.Previous, oldState: "WildBookFallData", newState: "WildBookFallLocation", condition: "WildBookFallLocation"};      
      t = addPreviousNext( t, Commands.PreviousPage , Commands.NextPage)
      StateWildBookFall.transitions.push(t);

      
      t = { mainState: this.mainState, newMainState: this.mainState, nav: StateNavigationAction.Previous, oldState: "WildBookFallLocation", newState: "WildBookFall", condition: "WildBookFall"};      
      t = addPreviousNext( t, Commands.PreviousPage , Commands.NextPage)
      StateWildBookFall.transitions.push(t);
      
      


      //__ Save Button
      t = { mainState: this.mainState, newMainState: "WildBook", nav: StateNavigationAction.Next, oldState:this.startState, newState: "WildBook", condition: "WildBook"};      
      t = addNext(t, Commands.PreviousPage);
      StateWildBookFall.transitions.push(t);

  
     
    }
    return StateWildBookFall.transitions;
  }
}
