/**
*  @module Command
*  GOF command-Pattern fo complex command
*  Norbert Dries April 2021
*/

import { RuleDynamicChange } from "./Concrete/RuleDynamicChange";

export enum Commands {
NextPage = "NextPage",
PreviousPage = "Previous",
RuleRegisterLogin = "RuleRegisterLogin",
RulePasswordEmailRequest = "RulePasswordEmailRequest",
RulePasswordEmail = "RulePasswordEmail",
RulePasswordRequest = "RulePasswordRequest",
RulePassword = "RulePassword",
RuleLoginPassword = "RuleLoginPassword",
RuleSendEmailActivateNewUser = "RuleSendEmailActivateNewUser",
RuleActivateNewUser = "RuleActivateNewUser",
Save = "Save",
SaveAndJump = "SaveAndJump",
Jump = "Jump",
NoOperation = "NoOperation",
Products = "Products",
Home = "Home",
Wildbuch = "Wildbuch",
Fischbuch = "Fischbuch",
Information = "Information",
Settings = "Settings",
GeneralSettings = "GeneralSettings",
Person = "Person",
Logout = "Logout",
Pdf = "Pdf",
Warenkorb = "Warenkorb",
WebShop = "WebShop",
RulePasswordForAuthenticatedUser = "RulePasswordForAuthenticatedUser",
RuleUserProfile = "RuleUserProfile",
RuleActivateNewUserForAuthenticatedUser = "RuleActivateNewUserForAuthenticatedUser",
RuleEmailVerificationRequest = "RuleEmailVerificationRequest",
RuleEmailVerification = "RuleEmailVerification",
RuleEmail = "RuleEmail",
RuleSendEmail = "RuleSendEmail",
RuleUserProfileShowPasswordEmail = "RuleUserProfileShowPasswordEmail",
RuleUserProfileSavePasswordEmail = "RuleUserProfileSavePasswordEmail",
RuleStartFishing = "RuleStartFishing",
RuleStopFishing = "RuleStopFishing",
RuleFischfangEintrag = "RuleFischfangEintrag",
RuleDynamicSave = "RuleDynamicSave",
RuleDynamicChange = "RuleDynamicChange",
RuleDynamicDelete = "RuleDynamicDelete",
RuleDynamicShouldDelete = "RuleDynamicShouldDelete",
RuleSendActivationTicket = "RuleSendActivationTicket",
RuleSendPasswordTicket = "RuleSendPasswordTicket"
}

export type TaskStateResult = {
  success: boolean;
  value: any;
};

export abstract class Command {
  public abstract execute(): any;
}

export abstract class AsyncCommand {
  public abstract asyncExecute(): Promise<any>;
}

