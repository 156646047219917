import { StateService } from '@/infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { RouteTargets } from './RoutedButtonBarTypes';

export interface RoutedButtonBarCleanupState {  
    targetRoutes: RouteTargets | undefined,
}


const initialState = {
    targetRoutes: undefined,
}

export function createIntitialButtonBarRoute() : RoutedButtonBarCleanupState {
    return  {       
        targetRoutes: undefined,
    }
}

export class RoutedButtonBarCleanupChannelForRoot extends StateService<RoutedButtonBarCleanupState>{
    public static service: RoutedButtonBarCleanupChannelForRoot;
  

    public static getService() : RoutedButtonBarCleanupChannelForRoot {
        if ( RoutedButtonBarCleanupChannelForRoot.service === undefined) {
            RoutedButtonBarCleanupChannelForRoot.service = new RoutedButtonBarCleanupChannelForRoot();
            this.service = RoutedButtonBarCleanupChannelForRoot.service
        }
        return RoutedButtonBarCleanupChannelForRoot.service;
    }

    private constructor() {
        super(initialState);
    }
    
    public subscription(): Observable<RoutedButtonBarCleanupState> {
        return this.subscribe();
    }

    public changeRouteState(detailFormState: RoutedButtonBarCleanupState) : void {
       
        this.changeState(detailFormState);
    }
    
    public get RoutedState(): RoutedButtonBarCleanupState {
        return this.state;
    }
}