import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { Navigate } from "@/infrastructure/backend/async/Navigate";
import { Identity } from "@/infrastructure/backend/userIdentity";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { JagdRevier, addOrReplaceRecord } from "@/infrastructure/database/asyncDb";
import { Dictionary } from "@/infrastructure/generics/dictionary";
import { ObjectListItems } from "@/infrastructure/generics/namedListItem";
import { Resource } from "@/infrastructure/resource/resource";
import { getObjectSearchDataSource } from '@/infrastructure/serviceworker-related/ObjectDataSourceServiceWorker';

export class ObjectSearchDataSource {

    private static loadOnceSources: Dictionary<string> = {}
    
    constructor() {
        ObjectSearchDataSource.loadOnceSources["Patent"] = "PreLoad"
        ObjectSearchDataSource.loadOnceSources["JagdRevier"] = "PreLoad"
    }

    public static async initUserNamedList() : Promise<void> {
        await ObjectSearchDataSource.getData("FischereiRevier","", "", "") as unknown as ObjectListItems[]
        await ObjectSearchDataSource.getData("JagdRevier","", "", "") as unknown as ObjectListItems[]
        await ObjectSearchDataSource.getData("Patent","", "", "") as unknown as ObjectListItems[]
     }

    public async getList( sourceName: string, parentCascadeId: string, kategorie: string, filter: string) : Promise<ObjectListItems[] | undefined> {
        if (ObjectSearchDataSource.loadOnceSources[sourceName] === undefined || ObjectSearchDataSource.loadOnceSources[sourceName] !== "PreLoad") 
            return undefined
        return await ObjectSearchDataSource.getData(sourceName,parentCascadeId, kategorie, filter) as unknown as ObjectListItems[]
    }

    public async search( sourceName: string, parentCascadeId: string | undefined, kategorie: string, filter: string) : Promise<ObjectListItems[] | undefined>  {
        if ( parentCascadeId === undefined)
            return await ObjectSearchDataSource.getData(sourceName,"", kategorie, filter) as unknown as ObjectListItems[]
        return await ObjectSearchDataSource.getData(sourceName,parentCascadeId, kategorie, filter) as unknown as ObjectListItems[]
    }

    public static async getData(sourceName: string, parentCascadeId: string, kategorie: string, filter: string) : Promise<ObjectListItems[] | undefined> {        
        const source = sourceName.slice().replace("AtOnce","")  
        
        if( Navigate.IsLocked() ) {
            return undefined
        }
 
        
        const dataSourceClient = AsyncRestClient.Create(true);
        if (dataSourceClient.isFailure) return;
        const result = await dataSourceClient
            .getValue()
            .exchangeForComponents<string, ObjectListItems[]>(process.env.VUE_APP_API + "api/comboBox/getObjectList", JSON.stringify({
                DataSourceName: source, 
                ParentCascadeId: parentCascadeId,                
                Filter: filter,
                Kategorie: kategorie,
                userId: Identity.getIdentity().getValue().UserId,
                language: (await UserProfile.GetClientSettingsAsync()).language
             }));       
        if (result.isFailure) {
            let local = await getObjectSearchDataSource(sourceName, parentCascadeId)
            if ( local !== undefined) {
                local = ObjectSearchDataSource.applyFilter(local, filter)
                return local
            }
            return
        }
        let objectList = result.getValue()
        objectList = await this.applyResources(objectList)

        if ( sourceName === "JagdRevier" || sourceName === "jagdrevier") {
            await addOrReplaceRecord(JagdRevier, {id:sourceName, objectList})
        }


        let local = await getObjectSearchDataSource(sourceName, parentCascadeId)
        if ( local !== undefined) {
            local = ObjectSearchDataSource.sortByValueAsc( ObjectSearchDataSource.applyFilter(local, filter))
            return local
        }
        if ( objectList ) {
            objectList = ObjectSearchDataSource.sortByValueAsc(ObjectSearchDataSource.applyFilter(objectList, filter))
            return objectList
        }
    }


    private static async applyResources(objectList: ObjectListItems[]) : Promise<ObjectListItems[]> {
        for(const item of objectList) {         
            const value = await Resource.getResourceTextOrUndefined(item.valueResourecId)
            if ( value && value.length > 0) {
                item.value = value
            }
        }
        return objectList
    }
    

    private static sortByValueAsc(objectList: ObjectListItems[]) : ObjectListItems[] {
        objectList.sort(function(a: ObjectListItems,b: ObjectListItems){                    
            return (a.value ?? "").localeCompare(b.value ?? "");
          });
        return objectList
    }

   

    private static applyFilter( objectList: ObjectListItems[], filter: string |undefined ) : ObjectListItems[]  {

        const filteredItems: ObjectListItems[] = []
        if ( !objectList || !filter || filter.length < 1 )
            return objectList

        for(const item of objectList) {
            if ( !item.filter || item.filter.length < 1) 
                continue
            
            if ( item.filter.indexOf(filter) < 0 ) 
                continue

            filteredItems.push(item)
        }
        
        return filteredItems

    }

}