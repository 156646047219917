import { DynamicDataSet } from "@/components/Shared/Dynamicform/DynamicFormData";
import { UserProfile } from "@/infrastructure/backend/userProfile";

export const dynamicformstore = {
  namespaced: true,
  state: {
    formData: {
      id: "",
      language: UserProfile.GetClientSettingsSync().language,
      viewId: "",
      teilFormularName: "",
      state: "",
      stateMessage: "",
      apiLoadMethod: "",
      apiSaveMethod: "",
      view: [],
      tempTaskResult: {},
      
      
    }
  },
  mutations: {
    addDataSet(state: any, payload: DynamicDataSet) {
      state.formData = payload;
    }
  },
  actions: {},
  getters: {
    getDataSet: function(state: any): DynamicDataSet {
      return state.formData;
    }
  }
};
