import {
    StateRule,
    StateTransitionOptions,
    StateResult,
  
    StateNavigationAction,
    Transition,
    // eslint-disable-next-line
    addNavigationElement,
    // eslint-disable-next-line
    addEmptyNavigationElement,
    addPreviousNext,
    // eslint-disable-next-line
    addPrevious,
    // eslint-disable-next-line
    addNext,  
    newTransition,
    newTransitionCondition
  } from "../StateInterfaces";
  import { NavigatonIcons } from "@/business/application/NavConst";
  import {Commands} from "@/business/application/commands/Command";
  import { BaseState } from "../StateMachine";
  import { StateLogin } from "./StateLogin";
  import { StateHome } from "./StateHome";
import { Resource } from "@/infrastructure/resource/resource";
  
  export class StateGeneralSettings extends BaseState {
  
    private mainState = "GeneralSettings";
    private startState = "GeneralSettings"
    private static transitions: Transition[] = [];  
    private static callingState : StateResult = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''}
    private isDirectionNext = false
  
    // Diesen Status setzen wenn es auf den Main-Status zurück geht. 
    private isDirectionPreviousParent = true
  
    public constructor() {
      super()    
    }
  
  
    
    public next(rule: StateRule, options: StateTransitionOptions): StateResult {    
      return this.calculateState(rule, options, false, this.mainState);
    }
    public previous(rule: StateRule,options: StateTransitionOptions): StateResult {    
      return this.calculateState(rule, options, true, this.mainState);
    }
  
    protected calculateState(rule: StateRule,options: StateTransitionOptions, isPrevious: boolean, mainState: string): StateResult {
      this.isDirectionNext = ! isPrevious
      if ( isPrevious && this.isDirectionPreviousParent) {
        return this.checkResultState(StateGeneralSettings.callingState);
      }
  
      const context = this.getContext()
      const condition = this.calculateBusinessConditions(rule,this.getContext().getCurrentState());
      if ( condition === undefined || condition === null || condition.length === 0 || condition === "") {
        return this.checkState(this.getTransitions()[0], options);
      }
  
      if ( this.isDirectionNext ) {
        const nextTransition = this.getTransitions().filter(row => { 
          if ( row.nav !== StateNavigationAction.Previous && row.oldState === context.getCurrentState().newState && row.condition === condition ) {
            return true
          }
        })
        return this.checkState(nextTransition[0], options);
      } else {
        const previosTransition = this.getTransitions().filter(row => { 
          if ( row.nav === StateNavigationAction.Previous && row.oldState === context.getCurrentState().newState && row.condition === condition ) {
            return true
          }
        })
        return this.checkState(previosTransition[0], options);
      }
    }
  
  
    protected calculateBusinessConditions(rule: StateRule, resultState: StateResult): string {
      if ( rule.conditions === undefined || rule.conditions === null || rule.conditions.length === 0 ) {
        return "SaveAndJump";
      }
  
      if (rule.conditions.length == 1) {
        return rule.conditions[0].value;
      }
  
      return "";
    }
  
  
    private getCallingState(condition: string) : StateResult {
      if (this.isDirectionPreviousParent) { 
        // Condition = "Login"
        StateGeneralSettings.callingState.newMainState = condition
        StateGeneralSettings.callingState.newState = condition
        StateGeneralSettings.callingState.routeName = condition
        StateGeneralSettings.callingState.oldMainState = this.mainState
        StateGeneralSettings.callingState.oldState = this.startState
  
        return StateGeneralSettings.callingState;
      }
       StateGeneralSettings.callingState = {oldMainState: '' , oldState: '' , newMainState: '' , newState: '', routeName: ''};
       return StateGeneralSettings.callingState;
    }
  
    public getStartTransition(condition: string) : StateResult {
      StateGeneralSettings.transitions = []
      this.getTransitions()
      StateGeneralSettings.callingState = this.getCallingState(condition);
      const t = this.getTransitions()[0];
      return this.getTransition(t);
    }
  
    protected checkState( transition: Transition, options: StateTransitionOptions): StateResult {
      let newMainState = this.mainState    
      let skip = false
      const newResultState: StateResult = this.getTransition(transition)
  
      if ( this.isDirectionPreviousParent && !this.isDirectionNext) {
        newMainState = StateGeneralSettings.callingState.oldMainState
      }
      else {      
        newMainState = transition.newMainState
        if (transition.newState == "")
          skip = true
      }
  
      if (!options.lookahead && transition.newState != "") {
        return this.changeResultState(transition.newMainState, newResultState);
      }
      return newResultState;
    }
  
    private checkResultState( stateResult: StateResult ) : StateResult {  
      const newResultState = StateGeneralSettings.callingState;    
      return this.changeResultState(stateResult.newMainState, newResultState);      
    }
  
    private changeResultState(newMainStatePar: string , compResultState: StateResult) : StateResult {
      let newState: any;
      switch (newMainStatePar) {
        case "Home":
          newState = new StateHome();
          compResultState = newState.getStartTransition("Home")
          this.getContext().transitionTo(newState,compResultState);
          break;
        case "Login":
          newState = new StateLogin();
          compResultState = newState.getStartTransition("Login")
          this.getContext().transitionTo(newState,compResultState);
          break;      
        default:
          this.getContext().transitionTo(new StateGeneralSettings(), compResultState);     
      }
      return compResultState;
    }
  
  
  
  
    //-- Sammlung aller Statusübergänge
    public getTransitions(): Transition[] {
      if (StateGeneralSettings.transitions.length === 0) {
  
        //__ Start State egal von welcher Stufe aus
        //
        let t: Transition = { mainState: "*", newMainState: this.mainState, nav: StateNavigationAction.Next, oldState: "*", newState: this.startState, condition: "Start"};      
        t = addPreviousNext(t,Commands.PreviousPage, Commands.SaveAndJump)    
        StateGeneralSettings.transitions.push(t);
  
  
        t = { mainState: this.mainState, newMainState: "Login", nav: StateNavigationAction.Next, oldState: this.startState, newState: "Login", condition: "SaveAndJump"};      
        t = addEmptyNavigationElement(t,1)
        t = addEmptyNavigationElement(t,2)
        t = addNavigationElement(t,3,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
        t = addNavigationElement(t,4,{command: Commands.GeneralSettings, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
        StateGeneralSettings.transitions.push(t);
        
        //__ Zurück wenn der Main State sich ändert
        //
        t = { mainState: this.mainState, newMainState: "Login", nav: StateNavigationAction.Previous, oldState: this.startState, newState: "Login", condition: "Home"};      
        t = addEmptyNavigationElement(t,1)
        t = addEmptyNavigationElement(t,2)
        t = addNavigationElement(t,3,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
        t = addNavigationElement(t,4,{command: Commands.GeneralSettings, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
        StateGeneralSettings.transitions.push(t);
  
        t = { mainState: this.mainState, newMainState: "Login", nav: StateNavigationAction.Previous, oldState: this.startState, newState: "Login", condition: "Login"};      
        t = addEmptyNavigationElement(t,1)
        t = addEmptyNavigationElement(t,2)
        t = addNavigationElement(t,3,{command: Commands.Information, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Info",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Information });
        t = addNavigationElement(t,4,{command: Commands.GeneralSettings, displayName: Resource.getResourceTextSyncMode("Navigation.Icon.Settings",""), enabled: true, existent: true, visible: true , imageUrl: NavigatonIcons.Settings });
        StateGeneralSettings.transitions.push(t);
      }
      return StateGeneralSettings.transitions;
    }
  }
  