import {  RouteRecordName } from "vue-router";
import { DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
export class ClearFormStore
{
    public static ShouldClear(routeFrom: RouteRecordName | null | undefined,  routeTo: RouteRecordName | null | undefined, store: any ) : boolean {
        
        if ( routeTo == "Login") {
            const form = new DynamicFormData("", store);
            form.clearForm()
        }
        if ( routeTo == "Home") {
            const form = new DynamicFormData("", store);
            form.clearForm()
        }

        return true;
    }
}