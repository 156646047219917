import { Dictionary } from '@/infrastructure/generics/dictionary';
import { DynamicRecord } from '@/components/Shared/Dynamicform/DynamicFormData';

interface LocalStoreElement {
    formName: string,
    rows: DynamicRecord[]
}


export class LocalStore {
    private static forms: Dictionary<LocalStoreElement> = {}
    private static formNavigation = true

    public static DisableFormNavigationOnce() {
        LocalStore.formNavigation = false
    }

    public static EnableFormNavigationOnce() {
        LocalStore.formNavigation = true
    }


    public static IsFormNavigation() : boolean {
        return LocalStore.formNavigation 
    }

    public static AddForm( formName: string , rows: DynamicRecord[]) : void {
        LocalStore.forms[formName] = {formName: formName, rows: rows}
    }

    public static GetForm(formName: string) : DynamicRecord[] | undefined {
        try {
            return LocalStore.forms[formName].rows
        } catch(e) {
            return undefined
        }
    }
}

