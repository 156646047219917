import { StateService } from '../../../../infrastructure/observables/stateService';
import { Observable } from "rxjs";
import { DynamicFormState, createIntitialFormState } from './FormHookTypes';


export class DynamicSaveFormForRoot extends StateService<DynamicFormState>{
    public static service: DynamicSaveFormForRoot;
    private static attached: string | undefined = undefined

    public static getService(attachTo: string | undefined) : DynamicSaveFormForRoot {
        if (!DynamicSaveFormForRoot.service) {
           
            DynamicSaveFormForRoot.service = new DynamicSaveFormForRoot(attachTo);
            this.service = DynamicSaveFormForRoot.service
        }
        DynamicSaveFormForRoot.attached = attachTo
        return DynamicSaveFormForRoot.service;
    }

    private constructor(attachTo: string | undefined) {
        super(createIntitialFormState(attachTo));
        DynamicSaveFormForRoot.attached = attachTo
    }
    
    public subscription(): Observable<DynamicFormState> {
        return this.subscribe();
    }

    public changeFormState(formState: DynamicFormState) : void {               
        this.changeState(formState);
    }

    public isAttached(attachedTo: string | undefined): boolean {
        if ( attachedTo === undefined || DynamicSaveFormForRoot.attached === undefined )  return false       
        if ( DynamicSaveFormForRoot.attached === "Wildbuch" && attachedTo === "WildBookFilter" ) return true
        if ( DynamicSaveFormForRoot.attached === "Fischbuch" && attachedTo === "FishBookFilter" ) return true 
        if ( DynamicSaveFormForRoot.attached === "WebShop" && attachedTo === "WebShopFilter" ) return true 
        if ( DynamicSaveFormForRoot.attached !== attachedTo ) return false
        return true
    }

    public get formState(): DynamicFormState {
        return this.state;
    }
}